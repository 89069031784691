import React from 'react'
import { useNavigate } from 'react-router'

const Privacy = (props) => {
  let member =
    props?.affiliate === 'pep'
      ? 'Property Edge Pro'
      : props?.affiliate === 'pacemorby' ||
          props?.affiliate === 'astroflip'
        ? 'Dealsauce'
        : 'Housefolios'

  if (member === 'Dealsauce') {
    window.location.href = 'https://www.dealsauce.io/privacy-policy/'
  }

  return (
    <section
      className="section"
      style={{
        backgroundColor: '#FFFFFF',
        padding: '40px 0', // Vertical padding for the section
      }}
    >
      <div
        className="container"
        style={{
          maxWidth: '800px', // Limit container width
          margin: '0 auto', // Center container
          padding: '0 20px', // Horizontal padding for small screens
        }}
      >
        <div className="row">
          <div
            className="box-lg"
            style={{
              padding: '20px',
              backgroundColor: '#f9f9f9', // Light background for content area
              borderRadius: '8px', // Rounded corners
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)', // Subtle shadow
            }}
          >
            <h1 style={{ marginBottom: '10px' }}>
              Housefolios Privacy Policy
            </h1>
            <i style={{ display: 'block', marginBottom: '20px' }}>
              Last Updated: Dec 23, 2020
            </i>

            <p style={{ lineHeight: '1.6', marginBottom: '20px' }}>
              Housefolios, Inc. (“Housefolios,” “we,” “our,” or “us”)
              values the privacy of individuals who use our websites,
              and related services (collectively, the “Service”). This
              privacy policy (the “Privacy Policy”) explains how we
              collect, use, and share information from users of our
              Service. By using our Service, you agree to the
              collection, use, disclosure, and procedures this Privacy
              Policy describes. Beyond the Privacy Policy, your use of
              our Service is also subject to our
              <a href="/Terms" style={{ color: '#007bff' }}>
                Terms of Service
              </a>
            </p>

            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              1. Information We Collect
            </p>
            <ul
              style={{
                paddingLeft: '20px',
                marginBottom: '20px',
                lineHeight: '1.6',
              }}
            >
              <li>
                We may collect a variety of information from or about
                you or your devices from various sources, as described
                below.
              </li>

              <li>
                <strong>A. Information You Provide to Us.</strong>
                <ul
                  style={{
                    paddingLeft: '20px',
                    marginTop: '10px',
                    lineHeight: '1.6',
                  }}
                >
                  <li>
                    <strong>
                      Registration, Profile, and Other Information You
                      Provide.
                    </strong>{' '}
                    When you register to use our Service, we may ask
                    you for your first and last name, email address,
                    phone number, organization name, and password. You
                    may also provide us with your profile photo,
                    organization address and logo, property listing
                    details, and other content and information that
                    you choose to provide.
                  </li>
                  <li>
                    <strong>User Content.</strong> We will collect any
                    information you post through the Service and
                    information you submit to third party service
                    providers in connection with third party offers
                    and use of third party applications provided
                    through the Service. For example, when you submit
                    an application or order form to a third party
                    service provider, the Service will process the
                    information you provide in such submissions, to
                    the extent permitted under applicable law.
                  </li>
                  <li>
                    <strong>Payment Information.</strong> If you make
                    a purchase on the Service (for example, if you
                    purchase a subscription to our premium services),
                    your payment-related information, such as your
                    credit and/or debit card and other financial
                    information, is collected by our third party
                    payment processor on our behalf.
                  </li>
                  <li>
                    <strong>Communications.</strong> If you contact us
                    directly, we may receive additional information
                    about you. For example, when you contact our
                    customer support team, we will collect your
                    request, name, and email address.
                  </li>
                </ul>
              </li>

              <li>
                <strong>
                  B. Information We Collect When You Use Our Service.
                </strong>
                <ul
                  style={{
                    paddingLeft: '20px',
                    marginTop: '10px',
                    lineHeight: '1.6',
                  }}
                >
                  <li>
                    <strong>Location Information.</strong> When you
                    use our Service, we collect your location
                    information, including general location
                    information (e.g. IP address, city/state and/or
                    zip code associated with an IP address) from your
                    computer or mobile device.
                  </li>
                  <li>
                    <strong>Usage Information.</strong> To help us
                    understand how you use our Service, to help us
                    improve them, and to track and analyze your
                    responses, and we automatically receive
                    information about your interactions with our
                    Service, like the amount of time spent on
                    particular pages, the date and time you used the
                    Service and upload or post content, error logs,
                    and other similar information.
                  </li>
                  <li>
                    <strong>
                      Information from Cookies and Similar
                      Technologies.
                    </strong>{' '}
                    We and third party partners collect information
                    using cookies, pixel tags, or similar
                    technologies. Our third party partners, such as
                    analytics and advertising partners, may use these
                    technologies to collect information about your
                    online activities over time and across different
                    services. Cookies are small text files containing
                    a string of alphanumeric characters. We may use
                    both session cookies and persistent cookies. A
                    session cookie disappears after you close your
                    browser. A persistent cookie remains after you
                    close your browser and may be used by your browser
                    on subsequent visits to our Service.
                  </li>
                  <li>
                    Please review your web browser’s “Help” file to
                    learn the proper way to modify your cookie
                    settings. Please note that if you delete or choose
                    not to accept cookies from the Service, you may
                    not be able to utilize the features of the Service
                    to their fullest potential.
                  </li>
                </ul>
              </li>

              <li>
                <strong>
                  C. Information We Receive from Third Parties.
                </strong>
                <ul
                  style={{
                    paddingLeft: '20px',
                    marginTop: '10px',
                    lineHeight: '1.6',
                  }}
                >
                  <li>
                    <strong>
                      Information from Third Party Services.
                    </strong>{' '}
                    If you choose to link our Service to a third party
                    account, we may receive information about you. If
                    you wish to limit the information available to us,
                    you should visit the privacy settings of your
                    third party accounts to learn about your options.
                  </li>
                </ul>
              </li>
            </ul>

            {/* Section 2 */}
            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              2. How We Use the Information We Collect
            </p>
            <ul
              style={{
                paddingLeft: '20px',
                marginBottom: '20px',
                lineHeight: '1.6',
              }}
            >
              <li>We use the information we collect:</li>
              <br />
              <li>
                To provide, maintain, improve, and enhance our
                Service;
              </li>
              <li>
                To personalize your experience on our Service such as
                by providing tailored content and recommendations;
              </li>
              <li>
                To understand and analyze how you use our Service and
                develop new products, services, features, and
                functionality;
              </li>
              <li>
                To communicate with you, provide you with updates and
                other information relating to our Service and your
                account, and to provide you with information that you
                request, respond to comments and questions, and
                otherwise provide customer support;
              </li>
              <li>
                For marketing and advertising purposes, such as
                developing and providing promotional and advertising
                materials that may be useful, relevant, valuable or
                otherwise of interest to you;
              </li>
              <li>
                To aggregate or remove identifying data elements from
                your information for marketing and analytical
                purposes;
              </li>
              <li>To facilitate transactions and payments;</li>
              <li>
                To find and prevent fraud, and respond to trust and
                safety issues that may arise;
              </li>
              <li>
                For compliance purposes, including enforcing our Terms
                of Service or other legal rights, or as may be
                required by applicable laws and regulations or
                requested by any judicial process or governmental
                agency; and
              </li>
              <li>
                For other purposes for which we provide specific
                notice at the time the information is collected.
              </li>
            </ul>

            {/* Section 3 */}
            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              3. How We Share the Information We Collect
            </p>
            <ul
              style={{
                paddingLeft: '20px',
                marginBottom: '20px',
                lineHeight: '1.6',
              }}
            >
              <li>
                <strong>Other Users:</strong> Any information that you
                voluntarily choose to include on or through the
                Service, including information in a publicly
                accessible area of the Service (such as property
                listing details) or information you share with or
                allow to be shared with other users in your
                organization, will be available to anyone who has
                access to that content.
              </li>
              <li>
                <strong>Vendors and Service Providers:</strong> We may
                share any information we receive with vendors and
                service providers retained in connection with the
                provision of our Service.
              </li>
              <li>
                <strong>Analytics Partners:</strong> We use analytics
                services to collect and process certain analytics
                data. These services may also collect information
                about your use of other websites, apps, and online
                resources. We work with Google Analytics, which uses
                cookies and similar technologies to collect and
                analyze information about use of the website and
                report on activities and trends. You can learn about
                Google’s practices by going to{' '}
                <a href="https://www.google.com/policies/privacy/partners/">
                  https://www.google.com/policies/privacy/partners/
                </a>
                , and opt-out of them by downloading the Google
                Analytics opt-out browser add-on, available at{' '}
                <a href="https://tools.google.com/dlpage/gaoptout">
                  https://tools.google.com/dlpage/gaoptout
                </a>
                .
              </li>
              <li>
                <strong>Advertising Partners:</strong> We may work
                with third party advertising partners to show you ads
                that we think may interest you. Some of our
                advertising partners may be members of the Network
                Advertising Initiative
                (http://optout.networkadvertising.org/?c=1#!/) or the
                Digital Advertising Alliance (
                <a href="http://optout.aboutads.info/?c=2&lang=EN">
                  http://optout.aboutads.info/?c=2&lang=EN
                </a>
                ). If you do not wish to receive personalized ads,
                please visit their opt-out pages to learn about how
                you may opt out of receiving web-based personalized
                ads from member companies. You can access any settings
                offered by your browser to limit ad tracking.
              </li>
              <li>
                <strong>In Aggregated Form:</strong> We may make
                certain automatically-collected, aggregated, or
                otherwise de-identified information available to third
                parties for various purposes, including (i) compliance
                with various reporting obligations; (ii) for business
                or marketing purposes; or (iii) to assist such parties
                in understanding our users’ interests, habits, and
                usage patterns for certain programs, content,
                services, advertisements, promotions, and/or
                functionality available through the Service.
              </li>
              <li>
                <strong>
                  As Required By Law and Similar Disclosures:
                </strong>{' '}
                We may access, preserve, and disclose your information
                if we believe doing so is required or appropriate to:
                (a) comply with law enforcement requests and legal
                process, such as a court order or subpoena; (b)
                respond to your requests; or (c) protect your, our, or
                others’ rights, property, or safety. For the avoidance
                of doubt, the disclosure of your information may occur
                if you post any objectionable content on or through
                the Service.
              </li>
              <li>
                <strong>
                  Merger, Sale, or Other Asset Transfers:
                </strong>{' '}
                We may transfer your information to service providers,
                advisors, potential transactional partners, or other
                third parties in connection with the consideration,
                negotiation, or completion of a corporate transaction
                in which we are acquired by or merged with another
                company or we sell, liquidate, or transfer all or a
                portion of our assets. The use of your information
                following any of these events will be governed by the
                provisions of this Privacy Policy in effect at the
                time the applicable information was collected.
              </li>
              <li>
                <strong>Consent:</strong> We may also disclose your
                information with your permission.
              </li>
            </ul>

            {/* Section 4 */}
            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              4. Your Choices
            </p>
            <ul
              style={{
                paddingLeft: '20px',
                marginBottom: '20px',
                lineHeight: '1.6',
              }}
            >
              <li>
                <strong>Account Information:</strong> You may update,
                correct, or delete your account information and
                preferences at any time by accessing your account
                settings page on the Service. If you wish to access or
                amend any other information we hold about you, you may
                contact us at hi@housefolios.com.
              </li>
              <li>
                <strong>Marketing Communications:</strong> You can
                unsubscribe from our promotional emails via the link
                provided in the emails. Even if you opt-out of
                receiving promotional messages from us, you will
                continue to receive administrative messages from us.
              </li>
              <li>
                <strong>Do Not Track:</strong> Some web browsers
                incorporate a “Do Not Track” feature. Because there is
                not yet an accepted standard for how to respond to Do
                Not Track signals, our website does not currently
                respond to such signals.
              </li>
              <li>
                <strong>California Privacy Rights:</strong> A business
                subject to California Civil Code section 1798.83 is
                required to disclose to its customers, upon request,
                the identity of any third parties to whom the business
                has disclosed “personal information” regarding that
                customer within the immediately preceding calendar
                year. If you are a California resident and would like
                to make such a request, please submit your request in
                writing via email to hi@housefolios.com.
              </li>
            </ul>

            {/* Section 5 */}
            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              5. Third Parties
            </p>
            <ul
              style={{
                paddingLeft: '20px',
                marginBottom: '20px',
                lineHeight: '1.6',
              }}
            >
              <li>
                Our Service may contain links to other websites,
                products, or services that we do not own or operate or
                offers from third party service providers. We are not
                responsible for the privacy practices of these third
                parties. Please be aware that this Privacy Policy does
                not apply to your activities on these third party
                services or any information you disclose to these
                third parties. We encourage you to read their privacy
                policies before providing any information to them.
              </li>
            </ul>

            {/* Section 6 */}
            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              6. Security
            </p>
            <ul
              style={{
                paddingLeft: '20px',
                marginBottom: '20px',
                lineHeight: '1.6',
              }}
            >
              <li>
                We make reasonable efforts to protect your information
                by using physical and electronic safeguards designed
                to improve the security of the information we
                maintain. However, as our Service is hosted
                electronically, we can make no guarantees as to the
                security or privacy of your information.
              </li>
            </ul>

            {/* Section 7 */}
            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              7. Children’s Privacy
            </p>
            <ul
              style={{
                paddingLeft: '20px',
                marginBottom: '20px',
                lineHeight: '1.6',
              }}
            >
              <li>
                We do not knowingly collect, maintain, or use personal
                information from children under 13 years of age, and
                no part of our Service is directed to children. If you
                learn that a child has provided us with personal
                information in violation of this Privacy Policy, then
                you may alert us at hi@housefolios.com.
              </li>
            </ul>

            {/* Section 8 */}
            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              8. International Transfers of Data
            </p>
            <ul
              style={{
                paddingLeft: '20px',
                marginBottom: '20px',
                lineHeight: '1.6',
              }}
            >
              <li>
                Our Service is hosted in the United States and
                intended for visitors located within the United
                States. If you choose to use the Service from the
                European Union or other regions of the world with laws
                governing data collection and use that may differ from
                U.S. law, then please note that you are transferring
                your personal information outside of those regions to
                the United States for storage and processing. Also, we
                may transfer your data from the U.S. to other
                countries or regions in connection with storage and
                processing of data, fulfilling your requests, and
                operating the Service. By providing any information,
                including personal information, on or to the Service,
                you consent to such transfer, storage, and processing.
              </li>
            </ul>

            {/* Section 9 */}
            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              9. Changes and Updates to this Policy
            </p>
            <ul
              style={{
                paddingLeft: '20px',
                marginBottom: '20px',
                lineHeight: '1.6',
              }}
            >
              <li>
                Please revisit this page periodically to stay aware of
                any changes to this Privacy Policy, which we may
                update from time to time. If we modify the Privacy
                Policy, we will make it available through the Service,
                and indicate the date of the latest revision. In the
                event that the modifications materially alter your
                rights or obligations hereunder, we will make
                reasonable efforts to notify you of the change. For
                example, we may send a message to your email address,
                if we have one on file, or generate a pop-up or
                similar notification when you access the Service for
                the first time after such material changes are made.
                Your continued use of the Service after the revised
                Policy has become effective indicates that you have
                read, understood and agreed to the current version of
                the Policy.
              </li>
            </ul>

            {/* Section 10 */}
            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              10. Contact Information
            </p>
            <ul
              style={{
                paddingLeft: '20px',
                marginBottom: '20px',
                lineHeight: '1.6',
              }}
            >
              <li>
                Housefolios is responsible for processing your
                information. If you have any questions, comments, or
                concerns about our processing activities, please email
                us at hi@housefolios.com
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Privacy
