import { gql } from '@apollo/client'

export const GET_SUBSCRIPTION_PLAN = gql`
  query subscriptionPlan($subscriptionPlan: String!) {
    subscriptionPlan(subscriptionPlan: $subscriptionPlan) {
      _id
      subscriptionPlan
      price
      planId
      trialPeriodDays
      propertyCount
      portfolioCount
      teamCount
      marketplaceCount
    }
  }
`

export const GET_AFFILIATE = gql`
  query affiliate($memberValue: String) {
    affiliate(memberValue: $memberValue) {
      _id
      name
      orgId
      hfpid
      memberValue
      defaultStrategy
      companyName
      email
      phone
      createdAt
      commission
      active
      rehabTiers
      defaultMarketType
      marketTypes
      createAccount
      signIn
      launchpad
      sidebar
      training
      planDetails
      workflows {
        _id
        name
        label
        label2
        sideIcon
        member
        views
        toggle
        checklist
        complex
        workflow
        pinned
      }
    }
  }
`

export const GET_AFFILIATES = gql`
  query affiliates {
    affiliates {
      _id
      name
      orgId
      hfpid
      memberValue
      defaultStrategy
      companyName
      email
      phone
      createdAt
      commission
      active
      rehabTiers
      defaultMarketType
      marketTypes
      createAccount
      signIn
      launchpad
      sidebar
      training
      planDetails
      workflows {
        _id
        name
        label
        label2
        sideIcon
        member
        views
        toggle
        checklist
        complex
        workflow
        pinned
      }
    }
  }
`

export const GET_PARTNER = gql`
  query partner($name: String!) {
    partner(name: $name) {
      name
      createAccount
    }
  }
`
