/**
 * @ Author: Housefolios
 * @ Create Time: 2025-04-08 17:54:30
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-04-09 11:41:01
 * @ Description: Tours for Wholesale strategy page
 */

import Reactour from 'reactour'

import { Button, Typography } from '@mui/material'

import '../../style.css'

import { useLocation } from 'react-router-dom'

export const WholesaleStrategyTour = ({
  isOpenWholesaleStrategyTour,
  closeWholesaleStrategyTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour-wholesale="strategy-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Want to purchase this property? Fill out the Make Offer
              form to take the next steps!
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenWholesaleStrategyTour}
      onRequestClose={closeWholesaleStrategyTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const WholesaleTargetsTour = ({
  isOpenWholesaleTargetsTour,
  closeWholesaleTargetsTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="targets-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount of money left over after subtracting the
              Purchase Price from the Resale Price
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="targets-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount of money left over for the End Buyer after
              subtracting Expenses from the ARV
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenWholesaleTargetsTour}
      onRequestClose={closeWholesaleTargetsTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const WholesaleNetProfitFromSaleTour = ({
  isOpenWholesaleNetProfitFromSaleTour,
  closeWholesaleNetProfitFromSaleTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="netProfitFromSale-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The selling price of the property
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="netProfitFromSale-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Monthly Expenses is a sum of all the monthly values from
              the all the fields in the Monthly Expenses card below
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="netProfitFromSale-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The Net Profit is the Resale Price minus the Total
              Expenses
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenWholesaleNetProfitFromSaleTour}
      onRequestClose={closeWholesaleNetProfitFromSaleTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const WholesaleWholesaleDetailsTour = ({
  isOpenWholesaleWholesaleDetailsTour,
  closeWholesaleWholesaleDetailsTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="wholesaleDetails-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The price that the seller is asking for this home. *Not
              necessarily the price that the investor will purchase
              the home for.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="wholesaleDetails-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The ARV is the value of a property after you have
              conducted repairs and are ready to sell
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="wholesaleDetails-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The price you will resell the property for once the
              rehab is completed.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="wholesaleDetails-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              An estimate for how much rehab is needed for the subject
              property.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="wholesaleDetails-step-5"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The targeted percentage profit the buyer expects.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="wholesaleDetails-step-6"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The estimated closing cost the end buyer will have for a
              wholesale property. Expressed as a dollar amount or
              percentage of ARV minus the rehab.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="wholesaleDetails-step-7"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount that you, the seller, expects to make on the
              wholesale property. Expressed as a dollar amount or
              percentage of ARV minus the rehab and closing costs.
              Expressed as a dollar amount or percentage of ARV minus
              the rehab and closing costs.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenWholesaleWholesaleDetailsTour}
      onRequestClose={closeWholesaleWholesaleDetailsTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const WholesaleComparablePropertiesTour = ({
  isOpenWholesaleComparablePropertiesTour,
  closeWholesaleComparablePropertiesTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="comparableProperties-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount of Comparable Properties we found
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount of Potential Comps we found
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The average adjusted value of the comparable properties
              that we found
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The lowest and highest comparable property prices make
              up the range
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-5"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The average adjusted value of the comparable properties
              divided by sqft
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-6"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The average adjusted value of the three lowest priced
              comparable properties
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-7"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Click this button to get a detailed look at your
              comparable properties and potential comps
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenWholesaleComparablePropertiesTour}
      onRequestClose={closeWholesaleComparablePropertiesTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}
