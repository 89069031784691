/**
 * @ Author: Housefolios
 * @ Create Time: 2025-04-08 14:28:03
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-04-09 11:37:01
 * @ Description: Tours for the Build to Rent Strategy page
 */

import Reactour from 'reactour'

import { Button, Typography } from '@mui/material'

import '../../style.css'

import { useLocation } from 'react-router-dom'

export const BuildToRentStrategyTour = ({
  isOpenBuildToRentStrategyTour,
  closeBuildToRentStrategyTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour-buildToRent="strategy-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Want to purchase this property? Fill out the Make Offer
              form to take the next steps!
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuildToRentStrategyTour}
      onRequestClose={closeBuildToRentStrategyTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuildToRentTargetsTour = ({
  isOpenBuildToRentTargetsTour,
  closeBuildToRentTargetsTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="targets-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Net cashflow is the amount of money left after the
              mortgage and any other expenses are paid.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="targets-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A cash-on-cash return calculates the return on the money
              put in by the investor on a property with a mortgage.
              Commonly referred to as a Leveraged Return.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="targets-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The capitalization rate (cap rate) is utilized for
              several reasons inside real estate investing including
              real estate valuation, comparing properties to each
              other, analyzing a market, etc. The higher the cap rate
              the higher your returns/risk.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="targets-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The internal rate of return (IRR) measures the rate of
              return earned on an investment during a specific time
              frame. It includes the recurring cash flow (rent, etc.)
              and any profits made from the property’s sale. Simply
              put, IRR represents a property’s net cash flow and
              expected appreciation divided by the hold time. While
              IRR is a helpful way to estimate your asset’s
              performance over the entire time that you plan to hold
              it, it relies heavily on forecasting years of cash flow
              and a projected sale price.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuildToRentTargetsTour}
      onRequestClose={closeBuildToRentTargetsTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuildToRentMonthlyCashflowTour = ({
  isOpenBuildToRentMonthlyCashflowTour,
  closeBuildToRentMonthlyCashflowTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="monthlyCashflow-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Monthly Income includes Total Rental Income minus the
              Vacancy Rate plus the Other Monthly Income
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyCashflow-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Monthly Expenses is a sum of all the monthly values from
              the all the fields in the Monthly Expenses card below
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyCashflow-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Monthly Cashflow is your Monthly Income minus your
              Monthly Expenses
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuildToRentMonthlyCashflowTour}
      onRequestClose={closeBuildToRentMonthlyCashflowTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuildToRentGrossMonthlyIncomeTour = ({
  isOpenBuildToRentGrossMonthlyIncomeTour,
  closeBuildToRentGrossMonthlyIncomeTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="grossMonthlyIncome-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A tenant's monthly payment to a landlord for the use of
              property or land.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="grossMonthlyIncome-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The percentage of time that a property is not rented out
              during the course of a year.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="grossMonthlyIncome-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Additional income you earn through the property. Some
              examples might be coin-op laundry, parking, storage,
              etc.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuildToRentGrossMonthlyIncomeTour}
      onRequestClose={closeBuildToRentGrossMonthlyIncomeTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuildToRentMonthlyExpensesTour = ({
  isOpenBuildToRentMonthlyExpensesTour,
  closeBuildToRentMonthlyExpensesTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="monthlyExpenses-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Annual property insurance premium expressed as a dollar
              amount or percentage of the purchase price.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyExpenses-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Property management fee expressed as a dollar amount or
              percentage of Gross Rental Income (rent - vacancy).
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyExpenses-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              An organization in a subdivision, planned community, or
              condominium building that makes and enforces rules for
              the properties and its residents. These residents
              automatically become members and are required to pay
              dues, known as HOA fees.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyExpenses-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A tax paid on property owned by an individual or other
              legal entity, such as a corporation.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyExpenses-step-5"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The annual cost of maintenance repairs to the property
              expressed as a percentage of Gross Rental Income (rent -
              vacancy).
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyExpenses-step-6"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Includes electricity, water, sewer, gas, garbage, etc.
              Expressed as a dollar amount or a percentage of Monthly
              Gross Rental Income (rent - vacancy).
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyExpenses-step-7"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount of monthly income you want to put aside each
              month as a reserve for future expenses. This includes
              capital expenditure (capex) items such as the roof,
              appliances, water, heater, etc. Expressed as a dollar
              amount or a percentage of Monthly Gross Rental Income
              (rent - vacancy).
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyExpenses-step-8"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Any annual expense that has not already been labeled.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyExpenses-step-9"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount you pay every month toward your mortgage
              loan. This field is not editable. To change your
              mortgage amount, see the Financing card below.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuildToRentMonthlyExpensesTour}
      onRequestClose={closeBuildToRentMonthlyExpensesTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuildToRentFinancingTour = ({
  isOpenBuildToRentFinancingTour,
  closeBuildToRentFinancingTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="financing-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The dollar amount of the loan meant for the lot.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="financing-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The date the lot loan started.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="financing-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Investor pays 100% of the purchase price in cash and
              does not have a mortgage payment.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="financing-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount of cash that a purchaser must put down,
              toward the purchase price, to buy real estate. The down
              payment amount is a percentage of the total purchase
              price, dictated by the lender.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="financing-step-5"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The interest rate is the amount a lender charges for the
              use of capital, expressed as a percentage of the
              principal. The interest rate is typically noted on an
              annual basis known as the annual percentage rate (APR).
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="financing-step-6"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Often when you take out a loan, you will pay "points"
              (aka: fees) on the loan. It is essentially prepaid
              interest charged by the lender. One point is equal to 1%
              of the loan amount. For example, a $100,000 loan with 2
              points would be $2,000.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="financing-step-7"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The dollar amount of the loan meant for the lot.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="financing-step-8"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The entity lending you money
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="financing-step-9"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A loan that only requires the payment of interest, not
              principal.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="financing-step-10"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Your down payment adjusts what percentage of your
              repairs will be financed.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuildToRentFinancingTour}
      onRequestClose={closeBuildToRentFinancingTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuildToRentConstructionLoanTour = ({
  isOpenBuildToRentConstructionLoanTour,
  closeBuildToRentConstructionLoanTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="constructionLoan-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The dollar amount of the loan meant for the
              construction.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="constructionLoan-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The date the construction loan started.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="constructionLoan-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Investor pays 100% of the purchase price in cash and
              does not have a mortgage payment.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="constructionLoan-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount of cash that a purchaser must put down,
              toward the purchase price, to buy real estate. The down
              payment amount is a percentage of the total purchase
              price, dictated by the lender.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="constructionLoan-step-5"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The interest rate is the amount a lender charges for the
              use of capital, expressed as a percentage of the
              principal. The interest rate is typically noted on an
              annual basis known as the annual percentage rate (APR).
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="constructionLoan-step-6"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Often when you take out a loan, you will pay "points"
              (aka: fees) on the loan. It is essentially prepaid
              interest charged by the lender. One point is equal to 1%
              of the loan amount. For example, a $100,000 loan with 2
              points would be $2,000.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="constructionLoan-step-7"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The entity lending you money
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="constructionLoan-step-8"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A loan that only requires the payment of interest, not
              principal.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="constructionLoan-step-9"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Your down payment adjusts what percentage of your
              repairs will be financed.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuildToRentConstructionLoanTour}
      onRequestClose={closeBuildToRentConstructionLoanTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuildToRentMezzanineLoanTour = ({
  isOpenBuildToRentMezzanineLoanTour,
  closeBuildToRentMezzanineLoanTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="mezzanineLoan-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The dollar amount of the loan meant for the additional
              expenses that may occur.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="mezzanineLoan-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The date the mezzanine loan started.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="mezzanineLoan-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Investor pays 100% of the purchase price in cash and
              does not have a mortgage payment.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="mezzanineLoan-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount of cash that a purchaser must put down,
              toward the purchase price, to buy real estate. The down
              payment amount is a percentage of the total purchase
              price, dictated by the lender.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="mezzanineLoan-step-5"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The interest rate is the amount a lender charges for the
              use of capital, expressed as a percentage of the
              principal. The interest rate is typically noted on an
              annual basis known as the annual percentage rate (APR).
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="mezzanineLoan-step-6"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Often when you take out a loan, you will pay "points"
              (aka: fees) on the loan. It is essentially prepaid
              interest charged by the lender. One point is equal to 1%
              of the loan amount. For example, a $100,000 loan with 2
              points would be $2,000.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="mezzanineLoan-step-7"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The entity lending you money
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="mezzanineLoan-step-8"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A loan that only requires the payment of interest, not
              principal.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="mezzanineLoan-step-9"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Your down payment adjusts what percentage of your
              repairs will be financed.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuildToRentMezzanineLoanTour}
      onRequestClose={closeBuildToRentMezzanineLoanTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuildToRentEndBuyerFinancingTour = ({
  isOpenBuildToRentEndBuyerFinancingTour,
  closeBuildToRentEndBuyerFinancingTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="endBuyerFinancing-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Investor pays 100% of the purchase price in cash and
              does not have a mortgage payment.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="endBuyerFinancing-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount of cash that a purchaser must put down,
              toward the purchase price, to buy real estate. The down
              payment amount is a percentage of the total purchase
              price, dictated by the lender.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="endBuyerFinancing-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The interest rate is the amount a lender charges for the
              use of capital, expressed as a percentage of the
              principal. The interest rate is typically noted on an
              annual basis known as the annual percentage rate (APR).
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="endBuyerFinancing-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The length of time that the loan is being amortized.
              Expressed in months or years.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="endBuyerFinancing-step-5"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Often when you take out a loan, you will pay "points"
              (aka: fees) on the loan. It is essentially prepaid
              interest charged by the lender. One point is equal to 1%
              of the loan amount. For example, a $100,000 loan with 2
              points would be $2,000.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="endBuyerFinancing-step-6"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A loan that only requires the payment of interest, not
              principal.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="endBuyerFinancing-step-7"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Your down payment adjusts what percentage of your
              repairs will be financed.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuildToRentEndBuyerFinancingTour}
      onRequestClose={closeBuildToRentEndBuyerFinancingTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuildToRentValueOverTimeTour = ({
  isOpenBuildToRentValueOverTimeTour,
  closeBuildToRentValueOverTimeTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="valueOverTime-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A holding period is the amount of time the investment is
              held by an investor or the period between the purchase
              and sale of the property. Expressed in years.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="valueOverTime-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The % of Appreciation in Total Dollar Value that the
              Home has gained or lost each year. Expressed as a
              percentage of ARV (after repair value). If your holding
              period is less than 12 months, this field will have no
              effect.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="valueOverTime-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The % that the annual income, from rent, has increased
              each year. Expressed as a percentage of gross rental
              income.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="valueOverTime-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The % increase in property expenses per year. Expressed
              as a percentage of gross rental income. If your holding
              period is less than 12 months, this field will have no
              effect.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="valueOverTime-step-5"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Paydown Accelerator is expressed as a percentage of net
              cashflow that is used as an additional principal payment
              on a mortgage
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="valueOverTime-step-6"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Includes the rent and expense growth rate values in the
              Property Paid off calculation
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuildToRentValueOverTimeTour}
      onRequestClose={closeBuildToRentValueOverTimeTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuildToRentCashPositioningTour = ({
  isOpenBuildToRentCashPositioningTour,
  closeBuildToRentCashPositioningTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="cashPositioning-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            {/* //SOURCE COMMENT: Needed Total Return definition https://www.millionacres.com/real-estate-basics/articles/how-to-calculate-a-rental-propertys-return-on-investment/#:~:text=For%20a%20simplified%20example%2C%20if,performance%20after%20you%20sell%20it. */}
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Total return is a combination of income and equity
              appreciation. For a simplified example, if you pay
              $100,000 in cash to acquire a property and it generates
              net income of $10,000 in a year and increases in value
              by $8,000, you have a total return of $18,000, or 18%.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="cashPositioning-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            {/* //SOURCE COMMENT: Needed Total Return definition https://www.millionacres.com/real-estate-basics/articles/how-to-calculate-a-rental-propertys-return-on-investment/#:~:text=For%20a%20simplified%20example%2C%20if,performance%20after%20you%20sell%20it. */}
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Total return is a combination of income and equity
              appreciation. For a simplified example, if you pay
              $100,000 in cash to acquire a property and it generates
              net income of $10,000 in a year and increases in value
              by $8,000, you have a total return of $18,000, or 18%.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuildToRentCashPositioningTour}
      onRequestClose={closeBuildToRentCashPositioningTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuildToRentDispositionExpensesTour = ({
  isOpenBuildToRentDispositionExpensesTour,
  closeBuildToRentDispositionExpensesTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="dispositionExpenses-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The price you will resell the property for once the
              rehab is completed.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="dispositionExpenses-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Closing Costs Paid by the Seller. Expressed as a dollar
              amount or percentage of the resale price
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="dispositionExpenses-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Sales Commission on the Future Sale of Property.
              Expressed as a dollar amount or percentage of resale
              price.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="dispositionExpenses-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Advanced Fee - Additional marketing fee on top of sales
              commission. Expressed as a dollar amount or percentage
              of resale price.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="dispositionExpenses-step-5"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The % of Appreciation in Total Dollar Value that the
              Home has gained or lost each year. Expressed as a
              percentage of ARV (after repair value). If your holding
              period is less than 12 months, this field will have no
              effect.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="dispositionExpenses-step-6"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The % increase in property expenses per year. Expressed
              as a percentage of gross rental income. If your holding
              period is less than 12 months, this field will have no
              effect.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="dispositionExpenses-step-7"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              This field is not editable. The remaining loan amount at
              the end of your flip holding period.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuildToRentDispositionExpensesTour}
      onRequestClose={closeBuildToRentDispositionExpensesTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuildToRentComparablePropertiesTour = ({
  isOpenBuildToRentComparablePropertiesTour,
  closeBuildToRentComparablePropertiesTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="comparableProperties-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount of Comparable Properties we found
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount of Potential Comps we found
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The average adjusted value of the comparable properties
              that we found
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The lowest and highest comparable property prices make
              up the range
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-5"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The average adjusted value of the comparable properties
              divided by sqft
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-6"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The average adjusted value of the three lowest priced
              comparable properties
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-7"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Click this button to get a detailed look at your
              comparable properties and potential comps
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuildToRentComparablePropertiesTour}
      onRequestClose={closeBuildToRentComparablePropertiesTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
    />
  )
}
