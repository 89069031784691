/**
 * @ Author: Housefolios
 * @ Create Time: 2021-12-16 11:36:56
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-04-09 11:37:13
 * @ Description: Build to Rent Strategy Analysis page
 */

import React, { useEffect, Suspense, lazy, useState } from 'react'
import { useQuery } from '@apollo/client'
import Reactour from 'reactour'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CountUp from 'react-countup'
import clsx from 'clsx'
import map from 'lodash/map'
import maxBy from 'lodash/maxBy'
import meanBy from 'lodash/meanBy'
import minBy from 'lodash/minBy'
import isUndefined from 'lodash/isUndefined'
import sum from 'lodash/sum'
import round from 'lodash/round'
import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import difference from 'lodash/difference'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import zip from 'lodash/zip'
import concat from 'lodash/concat'
import slice from 'lodash/slice'
import isNil from 'lodash/isNil'
import isEqual from 'lodash/isEqual'
import replace from 'lodash/replace'
import head from 'lodash/head'
import last from 'lodash/last'
import compact from 'lodash/compact'
import uniq from 'lodash/uniq'
import { useLocation } from 'react-router-dom'

const Chart = lazy(() => import('react-apexcharts'))

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormGroup,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  LinearProgress,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Drawer,
  IconButton,
  FormControl,
  Collapse,
} from '@mui/material'

import dark365Square from '@/assets/images/365_square_Dark.png'

import { arrayMoveImmutable } from 'array-move'
import {
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc'

import ContactAgent from '../../../Marketplace/ContactAgent'
import GaugeChart from 'react-gauge-chart'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import PropertyPDF from '../../Proforma/pdf'
import { Mutation } from '@apollo/client/react/components'
import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'
import { useMutation } from '@apollo/client'
import { Close } from '@mui/icons-material'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import withSession from '@/housefolios-components/Session/withSession'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { enqueueSnackbar } from 'notistack'
import Add from '@mui/icons-material/Add'
import { GET_CARDS } from '@/housefolios-components/Properties/queries'

import { assumptionsOptions } from '../../../AssumptionsSettings/Accordion'
import { DEFAULT_ANALYTICS } from '@/housefolios-components/Property/fragments'
import useAmortizationDialog from '@/hooks/useAmortizationDialog'
import AmortizationDialog from '@/housefolios-components/Dialogs/Amortization'
import { loanBalance } from '@/utils/loanBalance'
import {
  BuildToRentCashPositioningTour,
  BuildToRentComparablePropertiesTour,
  BuildToRentConstructionLoanTour,
  BuildToRentDispositionExpensesTour,
  BuildToRentEndBuyerFinancingTour,
  BuildToRentFinancingTour,
  BuildToRentGrossMonthlyIncomeTour,
  BuildToRentMezzanineLoanTour,
  BuildToRentMonthlyCashflowTour,
  BuildToRentMonthlyExpensesTour,
  BuildToRentStrategyTour,
  BuildToRentTargetsTour,
  BuildToRentValueOverTimeTour,
} from './tours'

const pieColors = [
  '#bebebd',
  '#008ffb',
  '#ff6efb',
  '#69A251',
  '#38CDC3',
  '#e3e44f',
  '#feb019',
  '#45818E',
  '#d26767',
]

function CenterPageBuildToRent(props) {
  const {
    property,
    analysisInput,
    assumptionsInput,
    renderInputFields,
  } = props

  const {
    openAmortization,
    handleOpenAmortization,
    handleCloseAmortization,
  } = useAmortizationDialog()

  const [
    openBuildToRentStrategyTour,
    setOpenBuildToRentStrategyTour,
  ] = useState(false)
  const [openBuildToRentTargetsTour, setOpenBuildToRentTargetsTour] =
    useState(false)
  const [
    openBuildToRentMonthlyCashflowTour,
    setOpenBuildToRentMonthlyCashflowTour,
  ] = useState(false)
  const [
    openBuildToRentGrossMonthlyIncomeTour,
    setOpenBuildToRentGrossMonthlyIncomeTour,
  ] = useState(false)
  const [
    openBuildToRentMonthlyExpensesTour,
    setOpenBuildToRentMonthlyExpensesTour,
  ] = useState(false)
  const [
    openBuildToRentFinancingTour,
    setOpenBuildToRentFinancingTour,
  ] = useState(false)
  const [
    openBuildToRentConstructionLoanTour,
    setOpenBuildToRentConstructionLoanTour,
  ] = useState(false)
  const [
    openBuildToRentMezzanineLoanTour,
    setOpenBuildToRentMezzanineLoanTour,
  ] = useState(false)
  const [
    openBuildToRentEndBuyerFinancingTour,
    setOpenBuildToRentEndBuyerFinancingTour,
  ] = useState(false)
  const [
    openBuildToRentValueOverTimeTour,
    setOpenBuildToRentValueOverTimeTour,
  ] = useState(false)
  const [
    openBuildToRentCashPositioningTour,
    setOpenBuildToRentCashPositioningTour,
  ] = useState(false)
  const [
    openBuildToRentDispositionExpensesTour,
    setOpenBuildToRentDispositionExpensesTour,
  ] = useState(false)

  const [
    openBuildToRentComparablePropertiesTour,
    setOpenBuildToRentComparablePropertiesTour,
  ] = useState(false)

  let results = useQuery(GET_CARDS, {
    variables: { view: 'BuildToRent' },
  })
  let data = results.data || {}
  const cardsLoading = results.loading
  const cardsError = results.error
  let { cards = [] } = data

  const { activeOrg = {} } = props.session.me || {}
  const { acquisition = {}, assumptions = {} } = property
  const { analytics = DEFAULT_ANALYTICS, analysis = {} } = acquisition
  let amortizationData = loanBalance({
    financeAmount: analytics.B2R.lotLoanAmount,
    netOperatingIncome: analytics.B2R.netOperatingIncomeB2R,
    mortgagePayment: analytics.B2R.lotMortgage,
    monthlyRate: assumptions.rate / 100 / 12,
    holdingPeriod: assumptions.rentalHoldingPeriodB2R * 12,
    payDownAccelerator: assumptions.payDownAccelerator,
    payDownGrowth: assumptions.payDownGrowth,
    growthRateIncome: assumptions.growthRateIncome,
    growthRateExpense: assumptions.growthRateIncome,
  })

  // const [openAmortization, setOpenAmortization] = useState(false)
  // const handleOpenAmortization = () => {
  //   setOpenAmortization(true)
  // }
  // const handleCloseAmortization = () => {
  //   setOpenAmortization(false)
  // }

  const tableHeaders = [
    { title: '', name: 'Date', align: 'left' },
    { title: '', name: 'Interest', align: 'center' },
    { title: '', name: 'Principal', align: 'center' },
    { title: '', name: 'Additional Principal', align: 'center' },
    { title: '', name: 'Balance', align: 'center' },
  ]

  const dollarPercentAmount = (ofAmount, value, peramt) => {
    let typeData = []
    let dollarAmount = 0
    let percentAmount = 0

    if (peramt === '%') {
      dollarAmount = (value / 100) * ofAmount
      percentAmount = value
    } else if (peramt === '$') {
      dollarAmount = value
      if (ofAmount !== 0)
        percentAmount = ((value / ofAmount) * 100).toFixed(2)
    }

    typeData.push({
      dollar: dollarAmount,
      percent: percentAmount,
    })

    return typeData
  }

  let IRRDataFirst = {}
  let IRRData = []
  let IRRDataLast = {}

  const IRRTable = (args) => {
    let {
      valueAppreciation, //arv ? arv : sellPrice
      grossAnnualRent,
      annualExpenses,
      mp_monthly,
      rentalHoldingPeriod,
      growthRateIncomeMonthly,
      growthRateExpenseMonthly,
      rehabPeriod,
      totalOutOfPocket,
      homePriceAppreciation,
      listPriceToSalePriceRatio,
      salesCommission,
      salesCommissionType,
      sellingClosingCostDollar,
      loanBalanceAtHoldingPeriod,
      dispositionFee,
      dispositionFeeType,
    } = args

    let cashFlowTotal = 0
    let arrInHoldingPeriodMonthly = grossAnnualRent / 12
    let expensesInHoldingPeriodMonthly = annualExpenses / 12
    let cashFlowInHoldingPeriodMonthly = 0
    let salePrice = 0

    let month
    let year
    let monthYear

    const today = new Date()

    const IRRDate = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      1,
    )

    for (let index = 1; index < rentalHoldingPeriod + 1; index++) {
      if (index > 1) {
        arrInHoldingPeriodMonthly *= 1 + growthRateIncomeMonthly
        expensesInHoldingPeriodMonthly *= 1 + growthRateExpenseMonthly
      }
      let income =
        index > rehabPeriod + 1 ? arrInHoldingPeriodMonthly : 0
      cashFlowInHoldingPeriodMonthly =
        mp_monthly < 0
          ? income - expensesInHoldingPeriodMonthly
          : income - expensesInHoldingPeriodMonthly - mp_monthly
      if (index === 1) {
        month = IRRDate.toLocaleString('default', { month: 'long' })
        year = IRRDate.getFullYear().toString()
        monthYear = month + ' ' + year
        IRRDataFirst = {
          totalOutOfPocket,
          monthYear,
        }
      } else {
        valueAppreciation *= 1 + homePriceAppreciation / 12 / 100
        IRRDate.setMonth(IRRDate.getMonth() + 1)
        let month = IRRDate.toLocaleString('default', {
          month: 'long',
        })
        let year = IRRDate.getFullYear().toString()
        monthYear = month + ' ' + year
        if (index < rentalHoldingPeriod) {
          IRRData.push({
            flow: cashFlowInHoldingPeriodMonthly,
            income,
            expenses: expensesInHoldingPeriodMonthly,
            mortgage: mp_monthly,
            monthYear,
          })
        } else if (index === rentalHoldingPeriod) {
          let cashFlowInHoldingPeriod = cashFlowTotal

          salePrice =
            (listPriceToSalePriceRatio / 100) * valueAppreciation
          let salesCommissionTotal =
            salesCommissionType === '$'
              ? salesCommission
              : (salesCommission / 100) * salePrice

          const dispositionFeeIRRDP = dollarPercentAmount(
            salePrice,
            dispositionFee,
            dispositionFeeType,
          )

          const lastMonthCashFlow =
            salePrice -
            loanBalanceAtHoldingPeriod -
            salesCommissionTotal -
            sellingClosingCostDollar -
            dispositionFeeIRRDP[0].dollar +
            cashFlowInHoldingPeriodMonthly

          IRRDataLast = {
            lastMonthCashFlow,
            monthYear,
          }
        }
      }
    }
  }

  IRRTable({
    valueAppreciation: analytics.B2R.arvTotalB2R
      ? analytics.B2R.arvTotalB2R
      : analysis.resellPriceB2R,
    grossAnnualRent: sum(analytics.grossAnnualRent),
    annualExpenses: analytics.B2R.annualExpensesB2R,
    mp_monthly:
      analytics.B2R.lotMortgage +
      analytics.B2R.constructionMortgage +
      sum(analytics.B2R.mezzanineMortgage),
    rentalHoldingPeriod: assumptions.rentalHoldingPeriodB2R,
    growthRateIncomeMonthly: assumptions.growthRateIncome / 12 / 100,
    growthRateExpenseMonthly:
      assumptions.growthRateExpense / 12 / 100,
    rehabPeriod: -1,
    totalOutOfPocket: analytics.B2R.totalOutOfPocketB2R,
    homePriceAppreciation: assumptions.homePriceAppreciation,
    listPriceToSalePriceRatio: assumptions.listPriceToSalePriceRatio,
    salesCommission: assumptions.salesCommissionB2R,
    salesCommissionType: assumptions.salesCommissionB2RType,
    sellingClosingCostDollar:
      analytics.B2R.sellingClosingCostDollarB2R,
    loanBalanceAtHoldingPeriod:
      analytics.B2R.lotLoanBalanceAtHoldingPeriod +
      analytics.B2R.constructionLoanBalanceAtHoldingPeriod +
      sum(analytics.B2R.mezzanineLoanBalanceAtHoldingPeriod),
    dispositionFee: assumptions.dispositionFeeB2R,
    dispositionFeeType: assumptions.dispositionFeeB2RType,
  })

  const IRRTableFirstHeaders = [
    { title: '', name: 'Date', align: 'left' },
    { title: '', name: 'Total Out of Pocket', align: 'center' },
  ]

  const IRRTableHeaders = [
    { title: '', name: 'Date', align: 'left' },
    { title: '', name: 'Income', align: 'center' },
    { title: '', name: 'Expenses', align: 'center' },
    { title: '', name: 'Mortgage', align: 'center' },
    { title: '', name: 'Cashflow', align: 'center' },
  ]

  const IRRTableLastHeaders = [
    { title: '', name: 'Date', align: 'left' },
    { title: '', name: 'Last Month Cashflow', align: 'center' },
  ]

  const [activeTab, setActiveTab] = useState(0)

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const [openConfirmLender, setOpenConfirmLender] = useState(false)

  const toggleOpenConfirmLender = () => {
    setOpenConfirmLender(!openConfirmLender)
  }

  useEffect(() => {
    if (
      analysisInput.lotLender !== analysis.lotLender ||
      analysisInput.constructionLender !==
        analysis.constructionLender ||
      (!isEqual(
        analysisInput.mezzanineLender,
        analysis.mezzanineLender,
      ) &&
        (analysisInput.mezzanineLender || ['']).length ===
          (analysis.mezzanineLender || ['']).length)
    )
      toggleOpenConfirmLender()
  }, [
    analysisInput.lotLender,
    analysisInput.constructionLender,
    analysisInput.mezzanineLender,
  ])

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const currencyFormat2 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const percentageFormat = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
  })

  const [openManagementCompanies, setOpenManagementCompanies] =
    useState(false)
  const handleOpenManagementCompanies = () => {
    setOpenManagementCompanies(true)
  }
  const handleCloseManagementCompanies = () => {
    setOpenManagementCompanies(false)
  }

  //const [loader, setLoader] = useState(false)
  const [modal1, setModal1] = useState(null)

  const toggle1 = (name) => {
    setModal1(name)
    //setLoader(false)
  }

  const toggle1Close = () => {
    setModal1(null)
    //setLoader(false)
  }

  const [accordion, setAccordion] = useState(-1)

  const toggleAccordion = (index) => {
    if (index === accordion) setAccordion(-1)
    else setAccordion(index)
  }

  const [maxOfferDescription, setMaxOfferDescription] =
    useState(false)

  const toggleMaxOffer = () => {
    setMaxOfferDescription(!maxOfferDescription)
  }

  const [getFinancing, setGetFinancing] = useState(null)

  const toggleGetFinancing = (name) => {
    setGetFinancing(name)
  }

  const toggleGetFinancingClose = () => {
    setGetFinancing(null)
  }

  const cashFlowAccumulationCalculate = (args) => {
    const {
      accelerator,
      term,
      rentAmount,
      vacancy,
      propertyTaxes,
      homeInsurance,
      monthlyHOA,
      propertyMgmtPercent,
      maintenance,
      utilities,
      annualRevenueIncrease,
      annualOperatingExpenseIncrease,
      //added so that cash flow is with Financing
      mortgagePayment,
    } = args

    let pdAccelerator = accelerator / 100
    let rent = rentAmount
    let rentIncrease = map(rent, (value) => 0)
    let vac = map(
      zip(rentAmount, vacancy),
      ([rentAmount, vacancy]) => rentAmount * vacancy,
    )
    let revenue = 0
    let propTax = propertyTaxes
    let propTaxIncrease = 0
    let insurance = homeInsurance
    let insuranceIncrease = 0
    let hoa = monthlyHOA
    let hoaIncrease = 0
    let propMgmt = sum(rentAmount) * propertyMgmtPercent
    let main = sum(rentAmount) * maintenance
    let mainIncrease = 0
    let uti = utilities
    let utiIncrease = 0
    let expenses = 0
    let cashFlow = 0
    let cfyear = []
    //added so that cash flow is with Financing
    let mortgage = 0

    for (let index = 1; index < term; index++) {
      if (index > 1 && index < 12) {
        rent = map(
          zip(rent, rentAmount),
          ([rent, rentAmount]) => rent + rentAmount,
        )
        propTax += propertyTaxes
        insurance += homeInsurance
        hoa += monthlyHOA
        //added so that cash flow is with Financing
        mortgage += mortgagePayment
      } else if (index % 12 === 0) {
        if (index / 12 === 1) {
          rent = map(
            zip(rent, rentAmount),
            ([rent, rentAmount]) => rent + rentAmount,
          )
          vac = map(
            zip(rent, vacancy),
            ([rent, vacancy]) => rent * vacancy,
          )
          revenue = sum(rent) - sum(vac)

          propTax += propertyTaxes
          insurance += homeInsurance
          hoa += monthlyHOA
          propMgmt = revenue * propertyMgmtPercent
          main = sum(rent) * maintenance
          expenses = propTax + insurance + hoa + propMgmt + main + uti

          //added so that cash flow is with Financing
          mortgage += mortgagePayment

          cashFlow = revenue - expenses - mortgage
          cashFlow = cashFlow - pdAccelerator * cashFlow

          cfyear.push({
            year: index / 12,
            cashFlow: cashFlow,
          })

          rentIncrease = map(
            rentAmount,
            (rentAmount) =>
              rentAmount + rentAmount * annualRevenueIncrease,
          )
          propTaxIncrease =
            propTax * (1 + annualOperatingExpenseIncrease)
          insuranceIncrease =
            insurance * (1 + annualOperatingExpenseIncrease)
          hoaIncrease = hoa * (1 + annualOperatingExpenseIncrease)
          mainIncrease = main * (1 + annualOperatingExpenseIncrease)
          utiIncrease = uti * (1 + annualOperatingExpenseIncrease)
        } else {
          rent = map(
            zip(rent, rentIncrease),
            ([rent, rentIncrease]) => rent + rentIncrease,
          )
          vac = map(
            zip(rent, vacancy),
            ([rent, vacancy]) => rent * vacancy,
          )
          revenue = sum(rent) - sum(vac)

          propTax += propTaxIncrease
          insurance += insuranceIncrease
          hoa += hoaIncrease
          propMgmt = revenue * propertyMgmtPercent
          main += mainIncrease
          uti += utiIncrease
          expenses = propTax + insurance + hoa + propMgmt + main + uti

          //added so that cash flow is with Financing
          mortgage += mortgagePayment

          cashFlow = revenue - expenses - mortgage
          cashFlow = cashFlow - pdAccelerator * cashFlow

          cfyear.push({
            year: index / 12,
            cashFlow: cashFlow,
          })

          rentIncrease = map(
            rentIncrease,
            (rentIncrease) =>
              rentIncrease + rentIncrease * annualRevenueIncrease,
          )
          propTaxIncrease =
            propTaxIncrease * (1 + annualOperatingExpenseIncrease)
          insuranceIncrease =
            insuranceIncrease * (1 + annualOperatingExpenseIncrease)
          hoaIncrease =
            hoaIncrease * (1 + annualOperatingExpenseIncrease)
          mainIncrease =
            mainIncrease * (1 + annualOperatingExpenseIncrease)
          utiIncrease =
            utiIncrease * (1 + annualOperatingExpenseIncrease)
        }
      } else {
        rent = map(
          zip(rent, rentIncrease),
          ([rent, rentIncrease]) => rent + rentIncrease,
        )
        //added so that cash flow is with Financing
        mortgage += mortgagePayment
      }
    }

    return cfyear
  }

  const cashFlowAccumulation = cashFlowAccumulationCalculate({
    accelerator: assumptions.payDownAccelerator || 0,
    term:
      assumptions.termTurnkeyType === 'Years'
        ? (assumptions.termTurnkey || 0) * 12 + 1
        : (assumptions.termTurnkey || 0) + 1,
    rentAmount: analysis.rent || [0],
    vacancy: assumptions.vacancy
      ? map(assumptions.vacancy, (vacancy) => vacancy / 100)
      : [0],
    propertyTaxes: (analysis.propertyTaxes || 0) / 12,
    homeInsurance:
      (analytics.turnkey.propertyInsuranceDollarTurnkey || 0) / 12,
    monthlyHOA: analytics.B2R.hoaTotal / 12 || 0,
    propertyMgmtPercent:
      (assumptions.propertyManagementTurnkey || 0) / 100,
    maintenance: (assumptions.maintenanceTurnkey || 0) / 100,
    utilities: analytics.turnkey.utilitiesTypeDollarTurnkey || 0,
    annualRevenueIncrease: (assumptions.growthRateIncome || 0) / 100,
    annualOperatingExpenseIncrease:
      (assumptions.growthRateExpense || 0) / 100,
    mortgagePayment: analytics.turnkey.mortgageTurnkey || 0,
  })

  let cashFlow1 = 0
  let cashFlow3 = 0
  let cashFlow5 = 0
  let cashFlow10 = 0

  for (let key in cashFlowAccumulation) {
    const tmp = cashFlowAccumulation[key]
    if (tmp.year === 1) cashFlow1 = tmp.cashFlow
    if (tmp.year === 3) cashFlow3 = tmp.cashFlow
    if (tmp.year === 5) cashFlow5 = tmp.cashFlow
    if (tmp.year === 10) cashFlow10 = tmp.cashFlow
  }

  const dispositionFields = [
    {
      type: 'dollar',
      name: 'resellPriceB2R',
      label: 'Resale Price',
      value:
        analysisInput.resellPriceB2R === null
          ? ''
          : analysisInput.resellPriceB2R.toString(),
      xs: 12,
      lg: 6,
      tooltipField:
        'The price you will resell the property for once the rehab is completed.',
      dataTour: 'dispositionExpenses-step-1',
    },
    {
      type: 'dropdown',
      name: 'sellerClosingCostB2R',
      label: 'Seller Closing Cost',
      value: assumptionsInput.sellerClosingCostB2R,
      adornmentToggle: assumptionsInput.sellerClosingCostB2RType,
      adornment: assumptionsInput.sellerClosingCostB2RType,
      adornmentName: 'sellerClosingCostB2RType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Closing Costs Paid by the Seller. Expressed as a dollar amount or percentage of the resale price',
      dataTour: 'dispositionExpenses-step-2',
    },
    {
      type: 'dropdown',
      name: 'financingFeeB2R',
      label: 'Financing Fee',
      value: assumptionsInput.financingFeeB2R,
      adornment: assumptionsInput.financingFeeB2RType,
      adornmentToggle: assumptionsInput.financingFeeB2RType,
      adornmentName: 'financingFeeB2RType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Advanced Fee - A fee that the real estate professional charges for securing financing on the property. Expressed as a percentage of the loan amount.',
    },
    {
      type: 'dollar',
      name: 'ownerReservesB2R',
      label: '1x Owner Reserve',
      value: assumptionsInput.ownerReservesB2R,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'A single contribution of money paid shortly after closing to keep in the bank to be used for large future expenses (capex items, etc.)',
    },
    {
      type: 'dropdown',
      name: 'salesCommissionB2R',
      label: 'Sales Commission',
      value: assumptionsInput.salesCommissionB2R,
      adornmentToggle: assumptionsInput.salesCommissionB2RType,
      adornment: assumptionsInput.salesCommissionB2RType,
      adornmentName: 'salesCommissionB2RType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Sales Commission on the Future Sale of Property. Expressed as a dollar amount or percentage of resale price.',
      dataTour: 'dispositionExpenses-step-3',
    },
    {
      type: 'dropdown',
      name: 'dispositionFeeB2R',
      label: 'Disposition Fee',
      value: assumptionsInput.dispositionFeeB2R,
      adornmentToggle: assumptionsInput.dispositionFeeB2RType,
      adornment: assumptionsInput.dispositionFeeB2RType,
      adornmentName: 'dispositionFeeB2RType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Advanced Fee - Additional marketing fee on top of sales commission. Expressed as a dollar amount or percentage of resale price.',
      dataTour: 'dispositionExpenses-step-4',
    },
    {
      type: 'percentage',
      name: 'homePriceAppreciation',
      label: 'Home Price Appreciation',
      value: assumptionsInput.homePriceAppreciation,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The % of Appreciation in Total Dollar Value that the Home has gained or lost each year. Expressed as a percentage of ARV (after repair value). If your holding period is less than 12 months, this field will have no effect.',
      dataTour: 'dispositionExpenses-step-5',
    },
    {
      type: 'percentage',
      name: 'growthRateExpense',
      label: 'Expense Growth Rate',
      value: assumptionsInput.growthRateExpense,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The % increase in property expenses per year. Expressed as a percentage of gross rental income. If your holding period is less than 12 months, this field will have no effect.',
      dataTour: 'dispositionExpenses-step-6',
    },
    {
      type: 'dollar',
      name: 'loanBalanceAtHoldingPeriod',
      label: 'Loan Balance at Resale',
      value:
        analytics.B2R.lotLoanBalanceAtHoldingPeriod +
          analytics.B2R.constructionLoanBalanceAtHoldingPeriod +
          sum(analytics.B2R.mezzanineLoanBalanceAtHoldingPeriod) ===
        null
          ? ''
          : (
              analytics.B2R.lotLoanBalanceAtHoldingPeriod +
              analytics.B2R.constructionLoanBalanceAtHoldingPeriod +
              sum(analytics.B2R.mezzanineLoanBalanceAtHoldingPeriod)
            ).toString(),
      xs: 12,
      lg: 6,
      disabled: true,
      tooltipField:
        'This field is not editable. The remaining loan amount at the end of your flip holding period.',
      dataTour: 'dispositionExpenses-step-7',
    },
  ]

  const dispositionChartOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              formatter: (value) => currencyFormat.format(value),
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              formatter: (value) => {
                const total = sum(value.config.series)
                return currencyFormat.format(total)
              },
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => currencyFormat.format(value),
      },
    },
    legend: {
      position: 'bottom',
      show: false,
    },
    colors: pieColors,
    labels: [
      'Seller Closing Cost',
      'Sales Commission',
      'Disposition Fee',
      'Loan Balance at Resale',
    ],
  }
  const dispositionChartSeries = [
    round(analytics.B2R.sellingClosingCostDollarB2R, 2) || 0.0001,
    round(analytics.B2R.salesCommissionTotalB2R, 2) || 0,
    round(analytics.B2R.dispositionFeeDollarB2R, 2) || 0,
    round(
      analytics.B2R.lotLoanBalanceAtHoldingPeriod +
        analytics.B2R.constructionLoanBalanceAtHoldingPeriod +
        sum(analytics.B2R.mezzanineLoanBalanceAtHoldingPeriod),
      2,
    ) || 0,
  ]

  const options = {
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['1 yr', '3 yrs', '5 yrs', '10 yrs'],
    },
    yaxis: {
      labels: {
        formatter: currencyFormat.format,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box">' +
          '<span>' +
          //[w.globals.labels][dataPointIndex] +
          ['1 yr', '3 yrs', '5 yrs', '10 yrs'][dataPointIndex] +
          ': ' +
          currencyFormat.format(series[seriesIndex][dataPointIndex]) +
          '</span>' +
          '</div>'
        )
      },
    },
  }
  const series = [
    {
      data: [
        round(cashFlow1),
        round(cashFlow3),
        round(cashFlow5),
        round(cashFlow10),
      ],
    },
  ]

  const propertyManagementCompanies = [
    {
      image:
        'https://assets-global.website-files.com/5ebdcacf6fe55d6b95c4f2a1/5f244161238395a76fe1c352_logo-dark-vertical.svg',
      name: 'Mynd',
      //  link: 'https://www.mynd.co/partners/housefolios',
      link: 'https://www.mynd.co',
      value: 'mynd',
    },
    {
      image: dark365Square,
      name: 'home365',
      link: 'https://www.home365.co',
      value: 'home365',
    },
    {
      image: '/images/guardianAsset.jpeg',
      name: 'Guardian Asset',
      link: 'https://guardianassetmgt.com/Home/Contact',
      value: 'guardianAsset',
    },
  ]

  const grossMonthlyIncomeFields = filter(
    [
      {
        type: 'select',
        name: 'rentType',
        label: 'Rent Type',
        value: assumptionsInput.rentType,
        options: assumptionsOptions(activeOrg)?.rentTypes,
        xs: 12,
        lg: 12,
        assumptions: true,
      },
      {
        type: 'dollar',
        name: `rent.${props.activeUnit}`,
        label: 'Total Rental Income',
        tooltipButtonText: 'Show Total Rent data',
        value:
          analysisInput.rent[props.activeUnit] === null
            ? ''
            : analysisInput.rent[props.activeUnit].toString(),
        helper:
          property.sqft &&
          property.sqft[props.activeUnit] &&
          analysisInput.rent[props.activeUnit]
            ? currencyFormat2.format(
                analysisInput.rent[props.activeUnit] /
                  property.sqft[props.activeUnit],
              ) + ' rent per sqft'
            : '$0.00 rent per sqft',
        xs: 12,
        lg: 6,
        definition: 'rent',
        // disabled: !isNil(analysisInput.ownerOccupiedB2R) && props.activeUnit === analysisInput.ownerOccupiedB2R,
        tooltipField:
          "A tenant's monthly payment to a landlord for the use of property or land.",
        hide: assumptionsInput.rentType !== 'singleRent',
        dataTour: 'grossMonthlyIncome-step-1',
      },
      {
        type: 'dollar',
        name: `rentLow.${props.activeUnit}`,
        label: 'Rent Range Low',
        tooltipButtonText: 'Show Total Rent data',
        value:
          analysisInput.rentLow[props.activeUnit] === null
            ? ''
            : analysisInput.rentLow[props.activeUnit].toString(),
        xs: 12,
        lg: 6,
        definition: 'rent',
        tooltipField: 'Lower end of projected rent',
        hide: assumptionsInput.rentType !== 'rentRange',
        dataTour: '',
      },
      {
        type: 'dollar',
        name: `rentHigh.${props.activeUnit}`,
        label: 'Rent Range High',
        tooltipButtonText: 'Show Total Rent data',
        value:
          analysisInput.rentHigh[props.activeUnit] === null
            ? ''
            : analysisInput.rentHigh[props.activeUnit].toString(),
        xs: 12,
        lg: 6,
        definition: 'rent',
        tooltipField: 'Higher end of projected rent',
        hide: assumptionsInput.rentType !== 'rentRange',
        dataTour: '',
      },
      {
        type: 'percentage',
        name: `vacancy.${props.activeUnit}`,
        label: 'Vacancy Rate',
        value: assumptionsInput.vacancy[props.activeUnit],
        xs: 12,
        lg: 6,
        assumptions: true,
        tooltipField:
          'The percentage of time that a property is not rented out during the course of a year.',
        dataTour: 'grossMonthlyIncome-step-2',
      },
      {
        type: 'dollar',
        name: `otherIncome.${props.activeUnit}`,
        label: 'Other Monthly Income',
        value:
          analysisInput.otherIncome[props.activeUnit] === null
            ? ''
            : analysisInput.otherIncome[props.activeUnit].toString(),
        xs: 12,
        lg: 6,
        tooltipField:
          'Additional income you earn through the property. Some examples might be coin-op laundry, parking, storage, etc.',
        dataTour: 'grossMonthlyIncome-step-3',
      },
    ],
    (field) => !field.hide,
  )

  const grossMonthlyIncomeChartOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              formatter: (value) => currencyFormat.format(value),
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              formatter: (value) => {
                const total = sum(value.config.series)
                return currencyFormat.format(total)
              },
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => currencyFormat.format(value),
      },
    },
    legend: {
      position: 'bottom',
      show: false,
    },
    colors: pieColors,
    labels: concat(
      map(
        analytics.grossAnnualRent,
        (value, index) => `Unit ${index + 1} Monthly Rental Income`,
      ),
      map(
        analysis.otherIncome,
        (value, index) => `Unit ${index + 1} Other Monthly Income`,
      ),
    ),
  }
  const grossMonthlyIncomeChartSeries = concat(
    map(
      analytics.grossAnnualRent,
      (grossAnnualRent, index) =>
        round(grossAnnualRent / 12, 2) || (index === 0 ? 0.0001 : 0),
    ),
    map(
      analysis.otherIncome,
      (otherIncome) => round(otherIncome, 2) || 0,
    ),
  )

  const expenseCard =
    find(
      cards,
      (card) =>
        card.name === 'expenses' && card.member === activeOrg.member,
    ) ||
    find(
      cards,
      (card) => card.name === 'expenses' && card.member === null,
    ) ||
    {}
  const monthlyExpensesFields = map(
    get(expenseCard, 'card.fields'),
    (field) => {
      const input = field.analytics
        ? analytics.B2R
        : field.assumptions
          ? assumptionsInput
          : analysisInput
      const name = field.suffix
        ? includes(field.name, 'Type')
          ? replace(field.name, 'Type', 'B2RType')
          : field.name + 'B2R'
        : field.name
      const adornmentName =
        field.suffix && field.adornmentName
          ? includes(field.adornmentName, 'Type')
            ? replace(field.adornmentName, 'Type', 'B2RType')
            : field.adornmentName + 'B2R'
          : field.adornmentName
      const definition =
        field.suffix && field.definition
          ? includes(field.definition, 'Type')
            ? replace(field.definition, 'Type', 'B2RType')
            : field.definition + 'B2R'
          : field.definition
      const options =
        field.options && field.assumptions
          ? get(assumptionsOptions(activeOrg), field.options) ||
            get(activeOrg, field.options) ||
            field.options
          : get(activeOrg, field.options) || field.options
      return {
        ...field,
        name,
        value:
          name === 'mortgageB2R'
            ? get(input, 'lotMortgage') +
              get(input, 'constructionMortgage') +
              sum(get(input, 'mezzanineMortgage'))
            : get(input, name),
        adornmentToggle: get(input, adornmentName),
        adornment: get(input, adornmentName),
        adornmentName: adornmentName,
        definition: definition,
        options: options,
      }
    },
  )

  const monthlyExpensesChartOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              formatter: (value) => currencyFormat.format(value),
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              formatter: (value) => {
                const total = sum(value.config.series)
                return currencyFormat.format(total)
              },
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => currencyFormat.format(value),
      },
    },
    legend: {
      position: 'bottom',
      show: false,
    },
    colors: pieColors,
    labels: concat(
      [
        'Property Insurance',
        'Property Mgmt Fee',
        'Monthly HOA',
        'Property Taxes',
        'Maintenance',
        'Utilities',
        'Capex',
        'Other Expenses',
        'Mortgage',
      ],
      map(analysis.customMonthlyExpenses, (expense) => expense.type),
    ),
  }
  const monthlyExpensesChartSeries = concat(
    [
      round(analytics.B2R.propertyInsuranceDollarB2R / 12, 2) ||
        0.0001,
      round(analytics.B2R.propertyManagementDollarB2R / 12, 2) || 0,
      round(analytics.B2R.hoaTotal / 12, 2) || 0,
      round(analysis.propertyTaxes / 12, 2) || 0,
      round(analytics.B2R.maintenanceCostB2R / 12, 2) || 0,
      round(analytics.B2R.utilitiesTypeDollarB2R, 2) || 0,
      round(analytics.B2R.monthlyReservesTypeDollarB2R, 2) || 0,
      round(assumptions.otherExpenseB2R / 12, 2) || 0,
      round(
        analytics.B2R.lotMortgage +
          analytics.B2R.constructionMortgage +
          sum(analytics.B2R.mezzanineMortgage),
        2,
      ) || 0,
    ],
    map(
      analysis.customMonthlyExpenses,
      (expense) => round(expense.value, 2) || 0,
    ),
  )

  const financingFields = [
    {
      type: 'checkbox',
      label: 'Cash Purchase',
      name: 'allCashPurchaseTurnkey',
      value: assumptionsInput.allCashPurchaseTurnkey,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Investor pays 100% of the purchase price in cash and does not have a mortgage payment.',
      dataTour: 'endBuyerFinancing-step-1',
    },
    {
      type: 'percentage',
      name: 'downPaymentTurnkey',
      label: 'Down Payment',
      value: assumptionsInput.downPaymentTurnkey || 0,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
      dataTour: 'endBuyerFinancing-step-2',
    },
    {
      type: 'percentage',
      name: 'financingRepairsPercentageTurnkey',
      label: '% of Rehab',
      value: analysisInput.financingRepairsPercentageTurnkey || 0,
      xs: 12,
      lg: 6,
      tooltipField:
        'The percentage of the repair cost that is being covered by the loan',
      dataTour: 'endBuyerFinancing-step-7',
    },
    {
      type: 'percentage',
      name: 'rateTurnkey',
      label: 'Interest Rate',
      value: assumptionsInput.rateTurnkey || 0,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The interest rate is the amount a lender charges for the use of capital, expressed as a percentage of the principal. The interest rate is typically noted on an annual basis known as the annual percentage rate (APR).',
      dataTour: 'endBuyerFinancing-step-3',
    },
    {
      type: 'dropdown',
      name: 'termTurnkey',
      label: 'Financing Term',
      value: assumptionsInput.termTurnkey || 0,
      adornment: assumptionsInput.termTurnkeyType,
      adornmentToggle: assumptionsInput.termTurnkeyType,
      adornmentName: 'termTurnkeyType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'The length of time that the loan is being amortized. Expressed in months or years.',
      dataTour: 'endBuyerFinancing-step-4',
    },
    {
      type: 'number',
      name: 'pointsTurnkey',
      label: 'Points',
      value: assumptionsInput.pointsTurnkey || 0,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Often when you take out a loan, you will pay "points" (aka: fees) on the loan. It is essentially prepaid interest charged by the lender. One point is equal to 1% of the loan amount. For example, a $100,000 loan with 2 points would be $2,000.',
      dataTour: 'endBuyerFinancing-step-5',
    },
    {
      type: 'checkbox',
      name: 'interestOnlyLoanTurnkey',
      label: 'Interest Only Loan',
      value: assumptionsInput.interestOnlyLoanTurnkey,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'A loan that only requires the payment of interest, not principal.',
      dataTour: 'endBuyerFinancing-step-6',
    },
    // {
    //   type: 'checkbox',
    //   name: 'financingRepairsTurnkey',
    //   label: 'Finance Repairs',
    //   value: analysisInput.financingRepairsTurnkey || false,
    //   xs: 12,
    //   lg: 6,
    //   tooltipField: 'Your down payment adjusts what percentage of your repairs will be financed.',
    //   dataTour: "endBuyerFinancing-step-7",
    // },
  ]

  const lotFinancingFields = [
    {
      type: 'dollar',
      name: 'b2rLotOfferPrice',
      label: 'Purchase Price',
      value:
        analysisInput.b2rLotOfferPrice === null
          ? ''
          : analysisInput.b2rLotOfferPrice.toString(),
      xs: 12,
      lg: 6,
      tooltipField:
        'The dollar amount of the loan meant for the lot.',
      dataTour: 'financing-step-1',
    },
    {
      type: 'date',
      name: 'lotStartDate',
      label: 'Start Date',
      value: assumptionsInput.lotStartDate,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField: 'The date the lot loan started.',
      dataTour: 'financing-step-2',
    },
    {
      type: 'checkbox',
      label: 'Cash Purchase',
      name: 'lotCashPurchase',
      value: assumptionsInput.lotCashPurchase,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Investor pays 100% of the purchase price in cash and does not have a mortgage payment.',
      dataTour: 'financing-step-3',
    },
    {
      type: 'percentage',
      name: 'lotDownPayment',
      label: 'Down Payment',
      value: assumptionsInput.lotDownPayment || 0,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
      dataTour: 'financing-step-4',
    },
    {
      type: 'percentage',
      name: 'lotRate',
      label: 'Interest Rate',
      value: assumptionsInput.lotRate || 0,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The interest rate is the amount a lender charges for the use of capital, expressed as a percentage of the principal. The interest rate is typically noted on an annual basis known as the annual percentage rate (APR).',
      dataTour: 'financing-step-5',
    },
    //  {
    //    type: 'dropdown',
    //    name: 'lotTerm',
    //    label: 'Financing Term',
    //    value: assumptionsInput.lotTerm || 0,
    //    adornment: assumptionsInput.lotTermType,
    //    adornmentToggle: assumptionsInput.lotTermType,
    //    adornmentName: 'lotTermType',
    //    xs: 12,
    //    lg: 6,
    //    assumptions: true,
    //    dropdown: true,
    //    tooltipField: 'The length of time that the loan is being amortized. Expressed in months or years.',
    //  },
    {
      type: 'number',
      name: 'lotPoints',
      label: 'Points',
      value: assumptionsInput.lotPoints || 0,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Often when you take out a loan, you will pay "points" (aka: fees) on the loan. It is essentially prepaid interest charged by the lender. One point is equal to 1% of the loan amount. For example, a $100,000 loan with 2 points would be $2,000.',
      dataTour: 'financing-step-6',
    },
    {
      type: 'number',
      name: 'lotDaysOfLoan',
      label: 'Days of loan',
      value: assumptionsInput.lotDaysOfLoan,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The dollar amount of the loan meant for the lot.',
      dataTour: 'financing-step-7',
    },
    {
      name: 'lotLender',
      value: analysisInput.lotLender || '',
      label: 'Lender',
      options: filter(activeOrg.lenders, (lender) =>
        includes(lender.type, 'Lot'),
      ),
      optionName: 'lenders',
      removeOption: true,
      type: 'select',
      xs: 12,
      lg: 6,
      dataTour: 'financing-step-8',
    },
    {
      type: 'checkbox',
      name: 'lotInterestOnlyLoan',
      label: 'Interest Only Loan',
      value: assumptionsInput.lotInterestOnlyLoan,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'A loan that only requires the payment of interest, not principal.',
      dataTour: 'financing-step-9',
    },
    //  {
    //    type: 'checkbox',
    //    name: 'financingRepairsB2R',
    //    label: 'Finance Repairs',
    //    value: analysisInput.financingRepairsB2R || false,
    //    xs: 12,
    //    lg: 6,
    //    tooltipField: 'Your down payment adjusts what percentage of your repairs will be financed.',
    //    dataTour: "financing-step-10",
    //  },
  ]

  const constructionFinancingFields = [
    {
      type: 'dollar',
      name: 'b2rConstructionOfferPrice',
      label: 'Loan Amount',
      value:
        analysisInput.b2rConstructionOfferPrice == null
          ? ''
          : analysisInput.b2rConstructionOfferPrice.toString(),
      xs: 12,
      lg: 6,
      tooltipField:
        'The total value of the loan meant for the construction.',
      dataTour: 'constructionLoan-step-1',
    },
    {
      type: 'date',
      name: 'constructionStartDate',
      label: 'Start Date',
      value: assumptionsInput.constructionStartDate,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField: 'The date the construction loan started.',
      dataTour: 'constructionLoan-step-2',
    },
    {
      type: 'checkbox',
      label: 'Cash Purchase',
      name: 'constructionCashPurchase',
      value: assumptionsInput.constructionCashPurchase,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Investor pays 100% of the purchase price in cash and does not have a mortgage payment.',
      dataTour: 'constructionLoan-step-3',
    },
    {
      type: 'percentage',
      name: 'constructionDownPayment',
      label: 'Down Payment',
      value: assumptionsInput.constructionDownPayment || 0,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
      dataTour: 'constructionLoan-step-4',
    },
    {
      type: 'percentage',
      name: 'constructionRate',
      label: 'Interest Rate',
      value: assumptionsInput.constructionRate || 0,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The interest rate is the amount a lender charges for the use of capital, expressed as a percentage of the principal. The interest rate is typically noted on an annual basis known as the annual percentage rate (APR).',
      dataTour: 'constructionLoan-step-5',
    },
    // {
    //   type: 'dropdown',
    //   name: 'constructionTerm',
    //   label: 'Financing Term',
    //   value: assumptionsInput.constructionTerm || 0,
    //   adornment: assumptionsInput.constructionTermType,
    //   adornmentToggle: assumptionsInput.constructionTermType,
    //   adornmentName: 'constructionTermType',
    //   xs: 12,
    //   lg: 6,
    //   assumptions: true,
    //   dropdown: true,
    //   tooltipField: 'The length of time that the loan is being amortized. Expressed in months or years.',
    // },
    {
      type: 'number',
      name: 'constructionPoints',
      label: 'Points',
      value: assumptionsInput.constructionPoints || 0,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Often when you take out a loan, you will pay "points" (aka: fees) on the loan. It is essentially prepaid interest charged by the lender. One point is equal to 1% of the loan amount. For example, a $100,000 loan with 2 points would be $2,000.',
      dataTour: 'constructionLoan-step-6',
    },
    {
      name: 'constructionLender',
      value: analysisInput.constructionLender || '',
      label: 'Lender',
      options: filter(activeOrg.lenders, (lender) =>
        includes(lender.type, 'Construction'),
      ),
      optionName: 'lenders',
      removeOption: true,
      type: 'select',
      xs: 12,
      lg: 6,
      dataTour: 'constructionLoan-step-7',
    },
    {
      type: 'checkbox',
      name: 'constructionInterestOnlyLoan',
      label: 'Interest Only Loan',
      value: assumptionsInput.constructionInterestOnlyLoan,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'A loan that only requires the payment of interest, not principal.',
      dataTour: 'constructionLoan-step-8',
    },
    // {
    //   type: 'checkbox',
    //   name: 'financingRepairsB2R',
    //   label: 'Finance Repairs',
    //   value: analysisInput.financingRepairsB2R || false,
    //   xs: 12,
    //   lg: 6,
    //   tooltipField: 'Your down payment adjusts what percentage of your repairs will be financed.',
    //   dataTour: "constructionLoan-step-9",
    // },
  ]

  const mezzanineFinancingFields = (index) => [
    {
      type: 'dollar',
      name: `b2rMezzanineOfferPrice.${index}`,
      label: 'Loan Amount',
      value: analysisInput.b2rMezzanineOfferPrice[index],
      xs: 12,
      lg: 6,
      tooltipField:
        'The dollar amount of the loan meant for the additional expenses that may occur.',
      dataTour: 'mezzanineLoan-step-1',
    },
    {
      type: 'date',
      name: `mezzanineStartDate.${index}`,
      label: 'Start Date',
      value: get(assumptionsInput, `mezzanineStartDate.${index}`),
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField: 'The date the mezzanine loan started.',
      dataTour: 'mezzanineLoan-step-2',
    },
    {
      type: 'checkbox',
      label: 'Cash Purchase',
      name: `mezzanineCashPurchase.${index}`,
      value: assumptionsInput.mezzanineCashPurchase[index],
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Investor pays 100% of the purchase price in cash and does not have a mortgage payment.',
      dataTour: 'mezzanineLoan-step-3',
    },
    {
      type: 'percentage',
      name: `mezzanineDownPayment.${index}`,
      label: 'Down Payment',
      value: assumptionsInput.mezzanineDownPayment[index] || 0,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
      dataTour: 'mezzanineLoan-step-4',
    },
    {
      type: 'percentage',
      name: `mezzanineRate.${index}`,
      label: 'Interest Rate',
      value: assumptionsInput.mezzanineRate[index] || 0,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The interest rate is the amount a lender charges for the use of capital, expressed as a percentage of the principal. The interest rate is typically noted on an annual basis known as the annual percentage rate (APR).',
      dataTour: 'mezzanineLoan-step-5',
    },
    // {
    //   type: 'dropdown',
    //   name: `mezzanineTerm.${index}`,
    //   label: 'Financing Term',
    //   value: assumptionsInput.mezzanineTerm[index] || 0,
    //   adornment: assumptionsInput.mezzanineTermType[index],
    //   adornmentToggle: assumptionsInput.mezzanineTermType[index],
    //   adornmentName: `mezzanineTermType.${index}`,
    //   xs: 12,
    //   lg: 6,
    //   assumptions: true,
    //   dropdown: true,
    //   tooltipField: 'The length of time that the loan is being amortized. Expressed in months or years.',
    // },
    {
      type: 'number',
      name: `mezzaninePoints.${index}`,
      label: 'Points',
      value: assumptionsInput.mezzaninePoints[index] || 0,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Often when you take out a loan, you will pay "points" (aka: fees) on the loan. It is essentially prepaid interest charged by the lender. One point is equal to 1% of the loan amount. For example, a $100,000 loan with 2 points would be $2,000.',
      dataTour: 'mezzanineLoan-step-6',
    },
    {
      name: `mezzanineLender.${index}`,
      value: get(analysisInput, `mezzanineLender.${index}`),
      label: 'Lender',
      options: filter(activeOrg.lenders, (lender) =>
        includes(lender.type, 'Mezzanine'),
      ),
      optionName: 'lenders',
      removeOption: true,
      type: 'select',
      xs: 12,
      lg: 6,
      dataTour: 'mezzanineLoan-step-7',
    },
    {
      type: 'checkbox',
      name: `mezzanineInterestOnlyLoan.${index}`,
      label: 'Interest Only Loan',
      value: assumptionsInput.mezzanineInterestOnlyLoan[index],
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'A loan that only requires the payment of interest, not principal.',
      dataTour: 'mezzanineLoan-step-8',
    },
    // {
    //   type: 'checkbox',
    //   name: 'financingRepairsB2R',
    //   label: 'Finance Repairs',
    //   value: analysisInput.financingRepairsB2R || false,
    //   xs: 12,
    //   lg: 6,
    //   tooltipField: 'Your down payment adjusts what percentage of your repairs will be financed.',
    //   dataTour: "mezzanineLoan-step-9",
    // },
  ]

  const projectionCard =
    find(
      cards,
      (card) =>
        card.name === 'projection' &&
        card.member === activeOrg.member,
    ) ||
    find(
      cards,
      (card) => card.name === 'projection' && card.member === null,
    ) ||
    {}
  const projectionAssumptionsFields = map(
    get(projectionCard, 'card.fields'),
    (field) => {
      const input = field.analytics
        ? analytics.B2R
        : field.assumptions
          ? assumptionsInput
          : analysisInput
      const name = field.suffix
        ? includes(field.name, 'Type')
          ? replace(field.name, 'Type', 'B2RType')
          : field.name + 'B2R'
        : field.name
      const adornmentName =
        field.suffix && field.adornmentName
          ? includes(field.adornmentName, 'Type')
            ? replace(field.adornmentName, 'Type', 'B2RType')
            : field.adornmentName + 'B2R'
          : field.adornmentName
      const definition =
        field.suffix && field.definition
          ? includes(field.definition, 'Type')
            ? replace(field.definition, 'Type', 'B2RType')
            : field.definition + 'B2R'
          : field.definition
      return {
        ...field,
        name,
        value: get(input, name),
        adornmentToggle: get(input, adornmentName),
        adornment: get(input, adornmentName),
        adornmentName: adornmentName,
        definition: definition,
      }
    },
  )

  const chartOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    colors: ['#1CC943', '#F83244', '#04A3E3'],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: true,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box">' +
          '<span>' +
          //[w.globals.labels][dataPointIndex] +
          ['Income', 'Expenses', 'Cashflow'][dataPointIndex] +
          ': ' +
          currencyFormat.format(series[seriesIndex][dataPointIndex]) +
          '</span>' +
          '</div>'
        )
      },
    },
    xaxis: {
      categories: ['Inc...', 'Exp...', 'Cash...'],
      labels: {
        rotate: -30,
        rotateAlways: true,
        offsetY: -10,
        formatter: currencyFormat.format,
      },
    },
  }
  const chartSeries = [
    {
      data: [
        round(sum(grossMonthlyIncomeChartSeries)),
        round(sum(monthlyExpensesChartSeries)),
        round(
          sum(grossMonthlyIncomeChartSeries) -
            sum(monthlyExpensesChartSeries),
        ),
      ],
      // colors: [
      //   ranges: [
      //     {
      //       from: 100,
      //       to: 0,
      //       color: '#f83145',
      //     },
      //   ],
      // ],
    },
  ]

  const strategySummary = {
    name: 'Build to Rent',
    percentage:
      (analytics.B2R.onTargetB2R + analytics.B2R.aboveTargetB2R) /
      (analytics.B2R.belowTargetB2R +
        analytics.B2R.onTargetB2R +
        analytics.B2R.aboveTargetB2R),
    analytics: [
      {
        value: analytics.B2R.cashFlowB2R / 12 || '-',
        arrow: analytics.B2R.cashFlowB2RArrow || 0,
        label: 'Net Cashflow',
        type: 'currency',
        target: assumptions.belowB2RCashFlow,
        targetLabel: 'Min Net Cashflow Target',
        targetName: 'belowB2RCashFlow',
        tourStep: 'targets-step-1',
        min: 0,
        max: 2000,
        minLabel: '$ 0k',
        maxLabel: '$ 2k',
        step: 100,
        valueLabelFormat: (x) =>
          x >= 1000 ? `${x / 1000}k` : `${x}`,
        description:
          'Net cashflow is the amount of money left after the mortgage and any other expenses are paid.',
        calculation: (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(sum(grossMonthlyIncomeChartSeries) * 12),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Property Mgmt
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.B2R.propertyManagementDollarB2R,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.B2R.maintenanceCostB2R, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.B2R.propertyInsuranceDollarB2R,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analysis.propertyTaxes, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.B2R.hoaTotal, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.B2R.assetManagementFeeTotalB2R,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.B2R.utilitiesTypeDollarB2R * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.B2R.monthlyReservesTypeDollarB2R * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(assumptions.otherExpenseB2R, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Custom Expenses
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.customMonthlyExpenseTotal * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(analytics.B2R.netOperatingIncomeB2R, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Annual Mortgage
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      (analytics.B2R.lotMortgage +
                        analytics.B2R.constructionMortgage +
                        sum(analytics.B2R.mezzanineMortgage)) *
                        12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Net Annual Cashflow
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(analytics.B2R.cashFlowB2R, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  12 months
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  12
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Net Monthly Cashflow
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(analytics.B2R.cashFlowB2R / 12, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
          </>
        ),
      },
      {
        value: analytics.B2R.cashOnCashReturnB2R || '-',
        arrow: analytics.B2R.cashOnCashReturnB2RArrow || 0,
        label: 'CoC ROI',
        target: assumptions.belowB2RCashOnCashReturn,
        targetLabel: 'Min Cash on Cash Return Target',
        targetName: 'belowB2RCashOnCashReturn',
        tourStep: 'targets-step-2',
        min: 0,
        max: 25,
        minLabel: '0%',
        maxLabel: '25%',
        description:
          'A cash-on-cash return calculates the return on the money put in by the investor on a property with a mortgage.  Commonly referred to as a Leveraged Return.',
        calculation: (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(sum(grossMonthlyIncomeChartSeries) * 12),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Property Mgmt
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.B2R.propertyManagementDollarB2R,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.B2R.maintenanceCostB2R, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.B2R.propertyInsuranceDollarB2R,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analysis.propertyTaxes, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.B2R.hoaTotal, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.B2R.assetManagementFeeTotalB2R,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.B2R.utilitiesTypeDollarB2R * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.B2R.monthlyReservesTypeDollarB2R * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(assumptions.otherExpenseB2R, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Custom Expenses
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.customMonthlyExpenseTotal * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(analytics.B2R.netOperatingIncomeB2R, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Annual Mortgage
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      (analytics.B2R.lotMortgage +
                        analytics.B2R.constructionMortgage +
                        sum(analytics.B2R.mezzanineMortgage)) *
                        12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Net Annual Cashflow
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(analytics.B2R.cashFlowB2R, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  Total Out-of-Pocket
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  {currencyFormat.format(
                    round(analytics.B2R.totalOutOfPocketB2R, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Cash on Cash Return
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {percentageFormat.format(
                    analytics.B2R.cashOnCashReturnB2R,
                  )}
                </Typography>
              </Grid>
            </Grid>
          </>
        ),
      },
      {
        value: analytics.B2R.capRateB2R,
        arrow: analytics.B2R.capRateB2RArrow || 0,
        label: 'Cap Rate',
        target: assumptions.belowB2RCapRate,
        targetLabel: 'Min Cap Rate Target',
        targetName: 'belowB2RCapRate',
        tourStep: 'targets-step-3',
        min: 0,
        max: 25,
        minLabel: '0%',
        maxLabel: '25%',
        description:
          'The capitalization rate (cap rate) is utilized for several reasons inside real estate investing including real estate valuation, comparing properties to each other, analyzing a market, etc. The higher the cap rate the higher your returns/risk.',
        calculation: (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(sum(grossMonthlyIncomeChartSeries) * 12),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Property Mgmt
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.B2R.propertyManagementDollarB2R,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.B2R.maintenanceCostB2R, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.B2R.propertyInsuranceDollarB2R,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analysis.propertyTaxes, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.B2R.hoaTotal, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.B2R.assetManagementFeeTotalB2R,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.B2R.utilitiesTypeDollarB2R * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.B2R.monthlyReservesTypeDollarB2R * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(assumptions.otherExpenseB2R, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Custom Expenses
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.customMonthlyExpenseTotal * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(analytics.B2R.netOperatingIncomeB2R, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  All-in-cost
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  {currencyFormat.format(
                    round(analytics.B2R.allInCostB2R, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Cap Rate
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {percentageFormat.format(analytics.B2R.capRateB2R)}
                </Typography>
              </Grid>
            </Grid>
          </>
        ),
      },
      {
        value: analytics.B2R.IRRB2R || '-',
        arrow: analytics.B2R.IRRB2RArrow || 0,
        label: 'IRR',
        target: assumptions.belowB2RIRR,
        targetLabel: 'Min IRR Target',
        targetName: 'belowB2RIRR',
        tourStep: 'targets-step-4',
        min: 0,
        max: 30,
        minLabel: '0%',
        maxLabel: '30%',
        description:
          'The internal rate of return (IRR) measures the rate of return earned on an investment during a specific time frame. It includes the recurring cash flow (rent, etc.) and any profits made from the property’s sale. Simply put, IRR represents a property’s net cash flow and expected appreciation divided by the hold time.\nWhile IRR is a helpful way to estimate your asset’s performance over the entire time that you plan to hold it, it relies heavily on forecasting years of cash flow and a projected sale price.',
        calculation: (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography
                  style={{ color: '#9198a8', marginBottom: 20 }}
                >
                  Due to the complexity of this calculation, we only
                  show an overview of what goes into calculating the
                  IRR
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Initial Investment (Out of pocket)
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(analytics.B2R.totalOutOfPocketB2R, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Monthly Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(sum(grossMonthlyIncomeChartSeries)),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Rent Growth Rate
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {assumptions.growthRateIncome}%
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Monthly Expenses
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(sum(monthlyExpensesChartSeries)),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Expenses Growth Rate
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {assumptions.growthRateExpense}%
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  After Repair Value
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(analytics.B2R.arvTotalB2R, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Appreciation Rate
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {assumptions.homePriceAppreciation}%
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Holding Period (years)
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {assumptions.rentalHoldingPeriodB2R}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Future property sale
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(
                      analytics.B2R.lotFutureSaleValue +
                        analytics.B2R.constructionFutureSaleValue +
                        sum(analytics.B2R.mezzanineFutureSaleValue),
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  IRR
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {percentageFormat.format(analytics.B2R.IRRB2R)}
                </Typography>
              </Grid>
            </Grid>
            {/* <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
               <Grid item>
                 <Typography style={{ color: '#9198a8' }}>-Annual Expenses</Typography>
               </Grid>
               <Grid item>
                 <Typography style={{ color: '#9198a8' }}>-1,308</Typography>
               </Grid>
             </Grid>
             <Divider style={{ backgroundColor: '#9198a8' }} />
             <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
               <Grid item>
                 <Typography style={{ color: '#9198a8' }}>Net Operating Income</Typography>
               </Grid>
               <Grid item>
                 <Typography style={{ color: '#9198a8' }}>14892</Typography>
               </Grid>
             </Grid>
             <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
               <Grid item>
                 <Typography style={{ color: '#9198a8' }}>
                   <FontAwesomeIcon icon={['fal', 'divide']} style={{ marginRight: 3 }} />All-in-cost
                 </Typography>
               </Grid>
               <Grid item>
                 <Typography style={{ color: '#9198a8' }}>
                   <FontAwesomeIcon icon={['fal', 'divide']} style={{ marginRight: 3 }} />14,892
                 </Typography>
               </Grid>
             </Grid>
             <Divider style={{ backgroundColor: '#9198a8' }} />
             <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
               <Grid item>
                 <Typography style={{ color: '#9198a8' }}>Cap Rate</Typography>
               </Grid>
               <Grid item>
                 <Typography style={{ color: '#9198a8' }}>12.82%</Typography>
               </Grid>
             </Grid> */}
          </>
        ),
      },
    ],
  }

  const financials = [
    {
      value: analytics.B2R.allInCostB2R,
      label: 'All-in Cost',
      description:
        'Total amount of initial investment into a property, including renovation and points paid for financing.',
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Purchase Price
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                {currencyFormat.format(
                  round(
                    analysis.b2rLotOfferPrice +
                      analysis.b2rConstructionOfferPrice +
                      sum(analysis.b2rMezzanineOfferPrice),
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Commission
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(
                    analytics.B2R.b2rAcquisitionCommissionDollar,
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Fee
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(assumptions.acquisitionFee, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Closing Costs
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.B2R.b2rCloseCostDollar, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Loan Points
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(
                    analytics.B2R.lotPointsAmount +
                      analytics.B2R.constructionPointsAmount +
                      sum(analytics.B2R.mezzaninePointsAmount),
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Financing Fee
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(
                    analytics.B2R.lotFinancingFeeTotal +
                      analytics.B2R.constructionFinancingFeeTotal +
                      sum(analytics.B2R.mezzanineFinancingFeeTotal),
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Owner Reserves
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(assumptions.ownerReservesB2R, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Custom Acquisition Expenses
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.customAcquisitionExpenseTotal, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                All-in Cost
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'white' }}>
                {currencyFormat.format(
                  round(analytics.B2R.allInCostB2R, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      value: analytics.B2R.totalOutOfPocketB2R,
      label: 'Total Out-of-Pocket',
      description:
        'Total amount of cash that a buyer has put into a property of his/her own capital.',
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Down Payment
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                {currencyFormat.format(
                  round(
                    analytics.B2R.lotDownPaymentTotal +
                      analytics.B2R.constructionDownPaymentTotal +
                      sum(analytics.B2R.mezzanineDownPaymentTotal),
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Commission
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(
                    analytics.B2R.b2rAcquisitionCommissionDollar,
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Fee
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(assumptions.acquisitionFee, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Closing Costs
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.B2R.b2rCloseCostDollar, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Financing Fee
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(
                    analytics.B2R.lotFinancingFeeTotal +
                      analytics.B2R.constructionFinancingFeeTotal +
                      sum(analytics.B2R.mezzanineFinancingFeeTotal),
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Loan Points
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(
                    analytics.B2R.lotPointsAmount +
                      analytics.B2R.constructionPointsAmount +
                      sum(analytics.B2R.mezzaninePointsAmount),
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                Total Out-of-Pocket
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'white' }}>
                {currencyFormat.format(
                  round(analytics.B2R.totalOutOfPocketB2R, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      value: analytics.turnkey.allInCostTurnkey,
      label: 'All-in Cost End Buyer',
      description:
        'Total amount of initial investment into a property, including renovation and points paid for financing.',
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Purchase Price
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                {currencyFormat.format(
                  round(
                    analysis.turnkeyOfferPrice ||
                      analysis.resellPriceTurnkey ||
                      analytics.turnkey.arvTotalTurnkey,
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Commission
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(
                    analytics.acquisitionCommissionDollarTurnkey,
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Fee
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(assumptions.acquisitionFee, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Closing Costs
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.turnkey.closeCostDollarTurnkey, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Loan Points
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.turnkey.pointsAmountTurnkey, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Rehab Amount
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(
                    analytics.turnkey.repairsOutOfPocketTurnkey,
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Financing Fee
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(
                    analytics.turnkey.financingFeeTotalTurnkey,
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Owner Reserves
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(assumptions.ownerReservesTurnkey, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Custom Acquisition Expenses
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.customAcquisitionExpenseTotal, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                All-in Cost
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'white' }}>
                {currencyFormat.format(
                  round(analytics.turnkey.allInCostTurnkey, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      value: analytics.turnkey.totalOutOfPocketTurnkey,
      label: 'Total Out-of-Pocket End Buyer',
      description:
        'Total amount of cash that a buyer has put into a property of his/her own capital.',
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Down Payment
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                {currencyFormat.format(
                  round(analytics.turnkey.downPaymentTotalTurnkey, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Commission
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(
                    analytics.acquisitionCommissionDollarTurnkey,
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Fee
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(assumptions.acquisitionFee, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Closing Costs
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.turnkey.closeCostDollarTurnkey, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Financing Fee
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(
                    analytics.turnkey.financingFeeTotalTurnkey,
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Loan Points
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.turnkey.pointsAmountTurnkey, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Custom Acquisition Expenses
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.customAcquisitionExpenseTotal, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Rehab Amount
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(
                    analytics.turnkey.repairsOutOfPocketTurnkey,
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                Total Out-of-Pocket
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'white' }}>
                {currencyFormat.format(
                  round(analytics.turnkey.totalOutOfPocketTurnkey, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
  ]

  const compData = [
    {
      label: 'Avg. Adjusted Value',
      value: isEmpty(props.compValues)
        ? 0
        : meanBy(
            filter(props.compValues, 'propertyPrice'),
            'propertyPrice',
          ),
    },
    {
      range: true,
      label: 'Adjusted Range',
      value1: isEmpty(props.compValues)
        ? 0
        : minBy(props.compValues, 'propertyPrice').propertyPrice /
          1000,
      value2: isEmpty(props.compValues)
        ? 0
        : maxBy(props.compValues, 'propertyPrice').propertyPrice /
          1000,
    },
    {
      label: 'Avg Adjusted Price/sqft',
      value: isEmpty(props.compValues)
        ? 0
        : meanBy(
            filter(props.compValues, 'propertyPerSqft'),
            'propertyPerSqft',
          ),
    },
    {
      label: 'Lowest 3 comp avg',
      value: props.lowestAverage.afterRepairValue,
    },
  ]

  const buildToRentCardsToOrder = [
    {
      name: 'strategy',
      card: (
        <Card className="card-box w-100">
          <div className="card-header">
            <div className="card-header--title">
              <small className="d-block text-uppercase mt-1">
                Strategy
              </small>
              <b>Build to Rent</b>
            </div>
            <div>
              <Tooltip title="Quick glance determination seen on the main page">
                <Button
                  onClick={() => setOpenBuildToRentStrategyTour(true)}
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <div className="d-flex flex-column flex-grow-1 justify-content-center w-100">
            <div className="card-header-alt d-flex justify-content-center px-4 pt-4 pb-3" />
            <div className="mx-auto" style={{ paddingBottom: 20 }}>
              <GaugeChart
                id="chartsGauges3B"
                nrOfLevels={6}
                colors={['#f83245', '#f4772e', '#1bc943']}
                arcWidth={0.4}
                hideText
                percent={strategySummary.percentage}
              />
            </div>
            <p
              className="mb-3 text-black-50 text-center"
              style={{ paddingLeft: '10px', paddingRight: '10px' }}
            >
              According to your inputs, a {strategySummary.name}{' '}
              strategy meets{' '}
              {strategySummary.percentage <= 0.33 && (
                <b style={{ color: '#f83245' }}>
                  {round(strategySummary.percentage * 100)}%
                </b>
              )}
              {strategySummary.percentage > 0.33 &&
                strategySummary.percentage < 0.66 && (
                  <b style={{ color: '#f4772e' }}>
                    {round(strategySummary.percentage * 100)}%
                  </b>
                )}
              {strategySummary.percentage >= 0.66 && (
                <b style={{ color: '#1bc943' }}>
                  {round(strategySummary.percentage * 100)}%
                </b>
              )}{' '}
              of your targets
            </p>
            {!props.shared &&
              activeOrg.member !== 'gorillacapital' && (
                <div className="px-4 pb-4 pt-2">
                  <ContactAgent
                    property={property}
                    propertyPage={true}
                    canDrag={props.canDrag}
                  />
                </div>
              )}
          </div>
        </Card>
      ),
    },
    {
      name: 'targets',
      card: (
        <Card className="card-box w-100">
          <div className="card-header">
            <div className="card-header--title">
              <small className="d-block text-uppercase mt-1">
                Does it meet your
              </small>
              <b>Targets</b>
            </div>
            <div>
              <Tooltip title="Does this property meet your specified targets?">
                <Button
                  onClick={() => setOpenBuildToRentTargetsTour(true)}
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="space-around"
            onClick={toggleMaxOffer}
            className="d-flex bg-white hover-scale-rounded justify-content-between py-3"
          >
            <Grid item className="gridItem2">
              {/* TODO COMMENT: need to fix max price in calcs */}
              <Typography variant="h3">
                {analytics.B2R.maxB2RPrice
                  ? currencyFormat.format(analytics.B2R.maxB2RPrice)
                  : '-'}
              </Typography>
            </Grid>
            <Grid
              item
              className="gridItem2"
              style={{ textAlign: 'center' }}
            >
              <Typography style={{ color: 'grey' }}>
                Max offer based on targets
              </Typography>
            </Grid>
          </Grid>
          <Dialog
            open={maxOfferDescription}
            onClose={toggleMaxOffer}
            maxWidth="lg"
            scroll="body"
            classes={{
              paper:
                'modal-content rounded border-0 bg-white p-3 p-xl-0',
            }}
          >
            {/* <BlockUi blocking={loader} loader={<PacmanLoader color={'var(--primary)'} loading={true} />}> */}
            <Grid container spacing={0} direction="row">
              <Grid item sm={5}>
                <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100  br-xl-right-0">
                  <div className="flex-grow-1 w-100 d-flex align-items-center">
                    <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                    <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                      <div className="text-white mt-3">
                        <Typography
                          style={{ fontSize: 30, fontWeight: 600 }}
                        >
                          Relevant Data
                        </Typography>
                      </div>
                      <>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              New Asking Price:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {currencyFormat.format(
                                analysisInput.resellPriceB2R,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              Purchase Price:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {currencyFormat.format(
                                analysisInput.b2rLotOfferPrice,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              ARV:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {currencyFormat.format(
                                analytics.B2R.arvTotalB2R,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              All In Cost:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {currencyFormat.format(
                                analytics.B2R.allInCostB2R,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              Total Out Of Pocket:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {currencyFormat.format(
                                analytics.B2R.totalOutOfPocketB2R,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              Annual Cash Flow:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {currencyFormat.format(
                                analytics.B2R.cashFlowB2R,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              Holding Period (yr):
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {
                                assumptionsInput.rentalHoldingPeriodB2R
                              }
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item sm={7}>
                <div className="hero-wrapper h-100 br-xl-right-0">
                  <Card className="flex-grow-1 w-100">
                    <CardHeader title="Max Offer" />
                    <Divider />
                    <CardContent>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid
                          item
                          xs={12}
                          style={{ marginBottom: 25 }}
                        >
                          <Card
                            style={{
                              backgroundColor: '#eff2f5',
                              borderRadius: 0,
                            }}
                          >
                            <CardContent>
                              <Typography>
                                Max Offer is the highest purchase
                                price that still meets all of your
                                strategy's targets. The Max Offer will
                                show a dash if the calculation is less
                                than zero or no purchase price can
                                meet all targets.
                              </Typography>
                              <Typography>
                                The Max Offer Price is calculated by
                                determining the the highest purchase
                                price that will meet each specific
                                target then taking the lowest value
                                from them. This means that any value
                                that affects a target besides purchase
                                price can affect the Max Offer Price
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={toggleMaxOffer}
                          >
                            Close
                          </Button>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                </div>
              </Grid>
            </Grid>
            {/* </BlockUi> */}
          </Dialog>
          <Divider />
          {/* <PerfectScrollbar> */}
          <List component="div" className="list-group-flush">
            {map(strategySummary.analytics, (section) => (
              <>
                <ListItem
                  component="a"
                  button
                  data-tour={section.tourStep}
                  href={null}
                  disableRipple
                  onClick={() => toggle1(section.label)}
                  className="d-flex bg-white hover-scale-rounded align-items-center py-3"
                >
                  <div className="avatar-icon-wrapper avatar-initials d-50 mr-3">
                    {!isUndefined(section.arrow) && (
                      <>
                        {section.arrow ? (
                          section.arrow === 2 ? (
                            <Avatar
                              style={{
                                backgroundColor: '#2296F3',
                              }}
                            >
                              <ArrowUpwardIcon
                                style={{
                                  color: 'white',
                                }}
                              />
                            </Avatar>
                          ) : (
                            <Avatar
                              style={{
                                backgroundColor: '#46de64',
                              }}
                            >
                              <ArrowUpwardIcon
                                style={{
                                  color: 'white',
                                }}
                              />
                            </Avatar>
                          )
                        ) : (
                          <Avatar
                            style={{
                              backgroundColor: '#F05248',
                            }}
                          >
                            <ArrowDownwardIcon
                              style={{
                                color: 'white',
                              }}
                            />
                          </Avatar>
                        )}
                      </>
                    )}
                  </div>
                  <div className="flex-fill">
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Grid
                        item
                        className="font-weight-bold text-black"
                      >
                        {section.label}
                      </Grid>
                      <Grid item className="ml-auto">
                        <b>
                          {section.value !== '-'
                            ? section.type === 'currency'
                              ? currencyFormat.format(section.value)
                              : section.value > 10
                                ? '+1000%'
                                : percentageFormat.format(
                                    section.value,
                                  )
                            : section.value}
                        </b>
                      </Grid>
                    </Grid>
                    <LinearProgress
                      variant="determinate"
                      className={
                        section.arrow === 0
                          ? 'progress-xs progress-animated-alt my-2 progress-bar-danger'
                          : 'progress-xs progress-animated-alt my-2 progress-bar-success'
                      }
                      value={
                        !section.target
                          ? 0
                          : section.arrow
                            ? 100
                            : section.type === 'currency'
                              ? (section.value / section.target) * 100
                              : (section.value / section.target) *
                                10000
                      }
                    />
                    <div className="d-flex justify-content-between font-size-xs">
                      {!isUndefined(section.arrow) && (
                        <div
                          className={
                            section.arrow === 0
                              ? 'text-danger'
                              : 'text-success'
                          }
                        >
                          {section.arrow === 0
                            ? 'Below target'
                            : section.arrow === 1
                              ? 'Target met'
                              : 'Above target'}
                        </div>
                      )}
                      <div className="opacity-5">
                        Target:{' '}
                        {section.type === 'currency'
                          ? currencyFormat.format(section.target)
                          : section.target + '%'}
                      </div>
                    </div>
                  </div>
                </ListItem>
                <Dialog
                  open={modal1 === section.label}
                  onClose={toggle1Close}
                  maxWidth="lg"
                  scroll="body"
                  classes={{
                    paper:
                      'modal-content rounded border-0 bg-white p-3 p-xl-0',
                  }}
                >
                  {/* <BlockUi blocking={loader} loader={<PacmanLoader color={'var(--primary)'} loading={true} />}> */}
                  <Grid container spacing={0}>
                    <Grid item sm={5}>
                      <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100 rounded br-xl-right-0">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                          <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                            <div className="text-white mt-3">
                              <Typography
                                style={{
                                  fontSize: 30,
                                  fontWeight: 600,
                                }}
                              >
                                Calculation
                              </Typography>
                            </div>
                            {section.calculation}
                            <div className="text-white mt-3">
                              <Typography>
                                * Calculation may be a few dollars off
                                due to rounding
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item sm={7}>
                      <div className="hero-wrapper h-100 br-xl-right-0">
                        <Card className="flex-grow-1 w-100">
                          <CardHeader title={section.label} />
                          <Divider />
                          <CardContent>
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Grid
                                item
                                xs={12}
                                style={{ marginBottom: 25 }}
                              >
                                <Card
                                  style={{
                                    backgroundColor: '#eff2f5',
                                    borderRadius: 0,
                                  }}
                                >
                                  <CardContent>
                                    <Typography>
                                      {section.description}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Grid>
                              {includes(section.label, 'IRR') && (
                                <Grid
                                  item
                                  xs={12}
                                  style={{ marginBottom: 10 }}
                                >
                                  <div className="accordion">
                                    <Card
                                      className={clsx('card-box', {
                                        'panel-open': accordion === 0,
                                      })}
                                    >
                                      <Card>
                                        <div className="card-header">
                                          <div className="panel-title">
                                            <div className="accordion-toggle">
                                              <Button
                                                variant="text"
                                                size="large"
                                                className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                                                onClick={() =>
                                                  toggleAccordion(0)
                                                }
                                                aria-expanded={
                                                  accordion === 0
                                                }
                                              >
                                                <span>
                                                  Start Month
                                                </span>
                                                <FontAwesomeIcon
                                                  icon={[
                                                    'fas',
                                                    'angle-up',
                                                  ]}
                                                  className="font-size-xl accordion-icon"
                                                />
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                        <Collapse
                                          in={accordion === 0}
                                        >
                                          <Paper
                                            style={{
                                              width: '100%',
                                              overflow: 'hidden',
                                            }}
                                          >
                                            <TableContainer
                                              style={{
                                                maxHeight: 440,
                                              }}
                                            >
                                              <Table
                                                stickyHeader
                                                aria-label="sticky table"
                                              >
                                                <TableHead>
                                                  <TableRow>
                                                    {IRRTableFirstHeaders.map(
                                                      (row) => (
                                                        <TableCell
                                                          align={
                                                            row.align
                                                          }
                                                        >
                                                          {row.name}
                                                        </TableCell>
                                                      ),
                                                    )}
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  <TableRow
                                                    key={
                                                      IRRDataFirst.name
                                                    }
                                                    style={{
                                                      '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                    }}
                                                  >
                                                    <TableCell
                                                      component="th"
                                                      scope="row"
                                                    >
                                                      {
                                                        IRRDataFirst.monthYear
                                                      }
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {currencyFormat2.format(
                                                        IRRDataFirst.totalOutOfPocket,
                                                      )}
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </Paper>
                                        </Collapse>
                                      </Card>
                                    </Card>
                                    <Card
                                      className={clsx('card-box', {
                                        'panel-open': accordion === 1,
                                      })}
                                    >
                                      <Card>
                                        <div className="card-header">
                                          <div className="panel-title">
                                            <div className="accordion-toggle">
                                              <Button
                                                variant="text"
                                                size="large"
                                                className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                                                onClick={() =>
                                                  toggleAccordion(1)
                                                }
                                                aria-expanded={
                                                  accordion === 1
                                                }
                                              >
                                                <span>
                                                  Appreciation Months
                                                </span>
                                                <FontAwesomeIcon
                                                  icon={[
                                                    'fas',
                                                    'angle-up',
                                                  ]}
                                                  className="font-size-xl accordion-icon"
                                                />
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                        <Collapse
                                          in={accordion === 1}
                                        >
                                          <Paper
                                            style={{
                                              width: '100%',
                                              overflow: 'hidden',
                                            }}
                                          >
                                            <TableContainer
                                              style={{
                                                maxHeight: 440,
                                              }}
                                            >
                                              <Table
                                                stickyHeader
                                                aria-label="sticky table"
                                              >
                                                <TableHead>
                                                  <TableRow>
                                                    {IRRTableHeaders.map(
                                                      (row) => (
                                                        <TableCell
                                                          align={
                                                            row.align
                                                          }
                                                        >
                                                          {row.name}
                                                        </TableCell>
                                                      ),
                                                    )}
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {IRRData.map(
                                                    (row) => (
                                                      <>
                                                        <TableRow
                                                          key={
                                                            row.name
                                                          }
                                                          style={{
                                                            '&:last-child td, &:last-child th':
                                                              {
                                                                border: 0,
                                                              },
                                                          }}
                                                        >
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            {
                                                              row.monthYear
                                                            }
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {currencyFormat2.format(
                                                              row.income,
                                                            )}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {currencyFormat2.format(
                                                              row.expenses,
                                                            )}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {currencyFormat2.format(
                                                              row.mortgage,
                                                            )}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {currencyFormat2.format(
                                                              row.flow,
                                                            )}
                                                          </TableCell>
                                                        </TableRow>
                                                      </>
                                                    ),
                                                  )}
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </Paper>
                                        </Collapse>
                                      </Card>
                                    </Card>
                                    <Card
                                      className={clsx('card-box', {
                                        'panel-open': accordion === 2,
                                      })}
                                    >
                                      <Card>
                                        <div className="card-header">
                                          <div className="panel-title">
                                            <div className="accordion-toggle">
                                              <Button
                                                variant="text"
                                                size="large"
                                                className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                                                onClick={() =>
                                                  toggleAccordion(2)
                                                }
                                                aria-expanded={
                                                  accordion === 2
                                                }
                                              >
                                                <span>
                                                  Final Month
                                                </span>
                                                <FontAwesomeIcon
                                                  icon={[
                                                    'fas',
                                                    'angle-up',
                                                  ]}
                                                  className="font-size-xl accordion-icon"
                                                />
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                        <Collapse
                                          in={accordion === 2}
                                        >
                                          <Paper
                                            style={{
                                              width: '100%',
                                              overflow: 'hidden',
                                            }}
                                          >
                                            <TableContainer
                                              style={{
                                                maxHeight: 440,
                                              }}
                                            >
                                              <Table
                                                stickyHeader
                                                aria-label="sticky table"
                                              >
                                                <TableHead>
                                                  <TableRow>
                                                    {IRRTableLastHeaders.map(
                                                      (row) => (
                                                        <TableCell
                                                          align={
                                                            row.align
                                                          }
                                                        >
                                                          {row.name}
                                                        </TableCell>
                                                      ),
                                                    )}
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  <TableRow
                                                    key={
                                                      IRRDataLast.name
                                                    }
                                                    style={{
                                                      '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                    }}
                                                  >
                                                    <TableCell
                                                      component="th"
                                                      scope="row"
                                                    >
                                                      {
                                                        IRRDataLast.monthYear
                                                      }
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {currencyFormat2.format(
                                                        IRRDataLast.lastMonthCashFlow,
                                                      )}
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </Paper>
                                        </Collapse>
                                      </Card>
                                    </Card>
                                  </div>
                                </Grid>
                              )}
                              {renderInputFields({
                                type: 'slider',
                                name: section.targetName,
                                label: section.targetLabel,
                                value:
                                  assumptionsInput[
                                    section.targetName
                                  ],
                                xs: 12,
                                assumptions: true,
                                valueLabelFormat:
                                  section.valueLabelFormat
                                    ? section.valueLabelFormat
                                    : (x) =>
                                        x === section.max
                                          ? `${x}+`
                                          : `${x}%`,
                                step: section.step,
                                min: section.min,
                                max: section.max,
                              })}
                            </Grid>
                          </CardContent>
                          <Divider />
                          <CardActions>
                            <Grid container justifyContent="center">
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={toggle1Close}
                                >
                                  Close
                                </Button>
                              </Grid>
                            </Grid>
                          </CardActions>
                        </Card>
                      </div>
                    </Grid>
                  </Grid>
                  {/* </BlockUi> */}
                </Dialog>
              </>
            ))}
          </List>
          {/* </PerfectScrollbar> */}
        </Card>
      ),
    },
    {
      name: 'monthlyCashflow',
      card: (
        <Card className="card-box w-100">
          <div className="card-header">
            <div className="card-header--title">
              <small className="d-block text-uppercase mt-1">
                Summary
              </small>
              <b>Monthly Cashflow</b>
            </div>
            <div>
              <Tooltip title="Shows the income, expenses, and projected overall cashflow of the property">
                <Button
                  onClick={() =>
                    setOpenBuildToRentMonthlyCashflowTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Grid content>
              <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
                <Grid
                  container
                  className="text-black-50 font-size-sm"
                >
                  <Grid
                    item
                    md={4}
                    data-tour="monthlyCashflow-step-1"
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">Income</div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          <div className="badge badge-success badge-circle mr-2">
                            fast
                          </div>
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={
                                sum(grossMonthlyIncomeChartSeries) /
                                1000
                              }
                              duration={1}
                              separator=","
                              delay={2}
                              decimals={1}
                              decimal="."
                              prefix="$"
                              suffix="k"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    data-tour="monthlyCashflow-step-2"
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">Expenses</div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          <div className="badge badge-danger badge-circle mr-2">
                            normal
                          </div>
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={
                                sum(monthlyExpensesChartSeries) / 1000
                              }
                              duration={1}
                              delay={2}
                              decimals={1}
                              decimal="."
                              prefix="$"
                              suffix="k"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    data-tour="monthlyCashflow-step-3"
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">Cashflow</div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          <div className="badge badge-primary badge-circle mr-2">
                            slow
                          </div>
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={
                                (sum(grossMonthlyIncomeChartSeries) -
                                  sum(monthlyExpensesChartSeries)) /
                                1000
                              }
                              duration={1}
                              delay={2}
                              decimals={1}
                              decimal="."
                              prefix="$"
                              suffix="k"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <>
                <Suspense fallback={<div>Loading Chart...</div>}>
                  <Chart
                    options={chartOptions}
                    series={chartSeries}
                    type="bar"
                  />
                </Suspense>
              </>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'income',
      card: (
        <Card className="card-box w-100">
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            direction="row"
          >
            <Grid item xs={8} style={{ overflow: 'scroll' }}>
              <List
                className="nav-line d-flex"
                style={{
                  width:
                    analysisInput.rent.length > 1
                      ? analysisInput.rent.length * 100
                      : '100%',
                }}
              >
                {analysisInput.rent.length > 1 ? (
                  map(analysisInput.rent, (value, index) => (
                    <ListItem
                      button
                      disableRipple
                      selected={props.activeUnit === index}
                      onClick={() => {
                        props.setActiveUnit(index)
                      }}
                      className="mt-3"
                    >
                      Unit {index + 1}
                    </ListItem>
                  ))
                ) : (
                  <ListItem className="my-1">
                    <div className="card-header--title">
                      <b>Gross Monthly Income</b>
                    </div>
                  </ListItem>
                )}
              </List>
            </Grid>
            <Grid item style={{ marginRight: 20 }}>
              <Tooltip title="Total income generated from the property on a monthly basis">
                <Button
                  onClick={() =>
                    setOpenBuildToRentGrossMonthlyIncomeTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          <Divider />
          <CardContent>
            <Grid content>
              <Grid item>
                <div className="d-flex justify-content-center">
                  <Suspense fallback={<div>Loading Chart...</div>}>
                    <Chart
                      options={grossMonthlyIncomeChartOptions}
                      series={grossMonthlyIncomeChartSeries}
                      type="donut"
                      width="300"
                    />
                  </Suspense>
                </div>
              </Grid>
              <Divider style={{ marginBottom: 15, marginTop: 15 }} />
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  spacing={1}
                >
                  {map(grossMonthlyIncomeFields, (field) =>
                    renderInputFields(field),
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'expenses',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Monthly Expenses</b>
            </div>
            <div>
              <Tooltip title="Projected monthly expenditures and applicable costs associated with the property">
                <Button
                  onClick={() =>
                    setOpenBuildToRentMonthlyExpensesTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Grid content>
              <Grid item>
                <div className="d-flex justify-content-center">
                  <Suspense fallback={<div>Loading Chart...</div>}>
                    <Chart
                      options={monthlyExpensesChartOptions}
                      series={monthlyExpensesChartSeries}
                      type="donut"
                      width="300"
                    />
                  </Suspense>
                </div>
              </Grid>
              <Divider style={{ marginBottom: 15, marginTop: 15 }} />
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  {map(monthlyExpensesFields, (field) =>
                    renderInputFields(field),
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Grid item container direction="row" spacing={1}>
                  {!isEmpty(property.propertyManagementGroups) && (
                    <Grid item xs={12}>
                      <Button
                        fullWidth={true}
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={handleOpenManagementCompanies}
                      >
                        Property Management Companies
                      </Button>
                    </Grid>
                  )}
                  {/* {activeOrg.member === 'birmingham' && ( */}
                  <Grid item xs={12}>
                    <Button
                      fullWidth={true}
                      variant="contained"
                      size="small"
                      color="primary"
                      target="_blank"
                      href="https://jjc.arcanainsurancehub.com"
                      style={{ marginTop: 25 }}
                    >
                      Get Insurance Quote
                    </Button>
                  </Grid>
                  {/* )} */}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'financing',
      card: (
        <Card className="card-box w-100">
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            direction="row"
          >
            <Grid item xs={8} style={{ overflow: 'scroll' }}>
              <List
                className="nav-line d-flex mt-3"
                style={{
                  width:
                    (analytics.B2R.mezzanineLoanAmount.length + 2) *
                    100,
                }}
              >
                <ListItem
                  button
                  disableRipple
                  selected={activeTab === 0}
                  onClick={() => {
                    toggle(0)
                  }}
                >
                  <Typography
                    className="card-header--title"
                    style={{ textAlign: 'center' }}
                  >
                    Lot Loan
                  </Typography>
                  <div className="divider" style={{ width: '80%' }} />
                </ListItem>
                <ListItem
                  button
                  disableRipple
                  selected={activeTab === 1}
                  onClick={() => {
                    toggle(1)
                  }}
                >
                  <Typography
                    className="card-header--title"
                    style={{ textAlign: 'center' }}
                  >
                    Construction Loan
                  </Typography>
                  <div className="divider" style={{ width: '80%' }} />
                </ListItem>
                {map(
                  analytics.B2R.mezzanineLoanAmount,
                  (mezzanineLoanAmount, index) => (
                    <ListItem
                      button
                      disableRipple
                      selected={activeTab === index + 2}
                      onClick={() => {
                        toggle(index + 2)
                      }}
                    >
                      <Typography
                        className="card-header--title"
                        style={{ textAlign: 'center' }}
                      >
                        Mezzanine Loan {index + 1}
                      </Typography>
                      <div
                        className="divider"
                        style={{ width: '80%' }}
                      />
                    </ListItem>
                  ),
                )}
              </List>
            </Grid>
            <Grid item>
              <IconButton
                onClick={props.createNewMezzanine}
                size="large"
              >
                <Add />
              </IconButton>
            </Grid>
            <Grid item>
              <Tooltip title="Projected fund totals an investor will need to produce in order to acquire the property">
                <Button
                  onClick={() =>
                    activeTab > 1
                      ? setOpenBuildToRentMezzanineLoanTour(true)
                      : activeTab === 1
                        ? setOpenBuildToRentConstructionLoanTour(true)
                        : setOpenBuildToRentFinancingTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 mr-4 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          <Divider />
          <CardContent>
            {activeTab === 0 && (
              <>
                <Grid
                  container
                  className="rounded p-2 mb-2 bg-secondary text-center border-light border-1"
                >
                  <Grid
                    item
                    md={4}
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">Loan</div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          {/* <div className="badge badge-success badge-circle mr-2">
                       fast
                     </div> */}
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={analytics.B2R.lotLoanAmount}
                              duration={1}
                              separator=","
                              decimals={0}
                              prefix="$"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">
                        Mortgage/mo
                      </div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          {/* <div className="badge badge-warning badge-circle mr-2">
                       normal
                     </div> */}
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={analytics.B2R.lotMortgage}
                              duration={1}
                              delay={2}
                              separator=","
                              decimals={0}
                              prefix="$"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">DSCR</div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          {/* <div className="badge badge-danger badge-circle mr-2">
                       slow
                     </div> */}
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={analytics.B2R.debtCoverageRatioLot}
                              duration={1}
                              delay={2}
                              separator=","
                              decimals={2}
                              suffix="x"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <p className="mb-3 text-black-50 text-center">
                  Based on your{' '}
                  <b>
                    {currencyFormat.format(
                      analytics.B2R.allInCostB2R,
                    )}
                  </b>{' '}
                  <Tooltip title="The total amount of money this deal costs, including rehab, closing costs, points, etc.">
                    <span
                      className="text-first"
                      onClick={() => toggle1('All-in Cost')}
                      style={{ textDecoration: 'underline' }}
                    >
                      all-in cost
                    </span>
                  </Tooltip>
                  , you'll need to pay{' '}
                  <b>
                    {currencyFormat.format(
                      analytics.B2R.totalOutOfPocketB2R,
                    )}
                  </b>{' '}
                  <Tooltip title="Total amount that the buyer has to cover with their own money.">
                    <span
                      className="text-first"
                      onClick={() => toggle1('Total Out-of-Pocket')}
                      style={{ textDecoration: 'underline' }}
                    >
                      out-of-pocket
                    </span>
                  </Tooltip>{' '}
                  to cover the downpayment, closing costs, and rehab
                  for this property.
                </p>
                <Divider
                  style={{ marginBottom: 15, marginTop: 15 }}
                />
                <Grid container>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    >
                      {map(lotFinancingFields, (section) =>
                        renderInputFields(section),
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {activeTab === 1 && (
              <>
                <Grid
                  container
                  className="rounded p-2 mb-2 bg-secondary text-center border-light border-1"
                >
                  <Grid
                    item
                    md={4}
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">Loan</div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          {/* <div className="badge badge-success badge-circle mr-2">
                       fast
                     </div> */}
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={
                                analytics.B2R.constructionLoanAmount
                              }
                              duration={1}
                              separator=","
                              decimals={0}
                              prefix="$"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">
                        Mortgage/mo
                      </div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          {/* <div className="badge badge-warning badge-circle mr-2">
                       normal
                     </div> */}
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={analytics.B2R.constructionMortgage}
                              duration={1}
                              delay={2}
                              separator=","
                              decimals={0}
                              prefix="$"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">DSCR</div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          {/* <div className="badge badge-danger badge-circle mr-2">
                       slow
                     </div> */}
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={
                                analytics.B2R
                                  .debtCoverageRatioConstruction
                              }
                              duration={1}
                              delay={2}
                              separator=","
                              decimals={2}
                              suffix="x"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <p className="mb-3 text-black-50 text-center">
                  Based on your{' '}
                  <b>
                    {currencyFormat.format(
                      analytics.B2R.allInCostB2R,
                    )}
                  </b>{' '}
                  <Tooltip title="The total amount of money this deal costs, including rehab, closing costs, points, etc.">
                    <span
                      className="text-first"
                      onClick={() => toggle1('All-in Cost')}
                      style={{ textDecoration: 'underline' }}
                    >
                      all-in cost
                    </span>
                  </Tooltip>
                  , you'll need to pay{' '}
                  <b>
                    {currencyFormat.format(
                      analytics.B2R.totalOutOfPocketB2R,
                    )}
                  </b>{' '}
                  <Tooltip title="Total amount that the buyer has to cover with their own money.">
                    <span
                      className="text-first"
                      onClick={() => toggle1('Total Out-of-Pocket')}
                      style={{ textDecoration: 'underline' }}
                    >
                      out-of-pocket
                    </span>
                  </Tooltip>{' '}
                  to cover the downpayment, closing costs, and rehab
                  for this property.
                </p>
                <Divider
                  style={{ marginBottom: 15, marginTop: 15 }}
                />
                <Grid container>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    >
                      {map(constructionFinancingFields, (section) =>
                        renderInputFields(section),
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {activeTab >= 2 && (
              <>
                <Grid
                  container
                  className="rounded p-2 mb-2 bg-secondary text-center border-light border-1"
                >
                  <Grid
                    item
                    md={4}
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">Loan</div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          {/* <div className="badge badge-success badge-circle mr-2">
                       fast
                     </div> */}
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={
                                analytics.B2R.mezzanineLoanAmount[
                                  activeTab - 2
                                ]
                              }
                              duration={1}
                              separator=","
                              decimals={0}
                              prefix="$"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">
                        Mortgage/mo
                      </div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          {/* <div className="badge badge-warning badge-circle mr-2">
                       normal
                     </div> */}
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={
                                analytics.B2R.mezzanineMortgage[
                                  activeTab - 2
                                ]
                              }
                              duration={1}
                              delay={2}
                              separator=","
                              decimals={0}
                              prefix="$"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">DSCR</div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          {/* <div className="badge badge-danger badge-circle mr-2">
                       slow
                     </div> */}
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={
                                analytics.B2R
                                  .debtCoverageRatioMezzanine[
                                  activeTab - 2
                                ]
                              }
                              duration={1}
                              delay={2}
                              separator=","
                              decimals={2}
                              suffix="x"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <p className="mb-3 text-black-50 text-center">
                  Based on your{' '}
                  <b>
                    {currencyFormat.format(
                      analytics.B2R.allInCostB2R,
                    )}
                  </b>{' '}
                  <Tooltip title="The total amount of money this deal costs, including rehab, closing costs, points, etc.">
                    <span
                      className="text-first"
                      onClick={() => toggle1('All-in Cost')}
                      style={{ textDecoration: 'underline' }}
                    >
                      all-in cost
                    </span>
                  </Tooltip>
                  , you'll need to pay{' '}
                  <b>
                    {currencyFormat.format(
                      analytics.B2R.totalOutOfPocketB2R,
                    )}
                  </b>{' '}
                  <Tooltip title="Total amount that the buyer has to cover with their own money.">
                    <span
                      className="text-first"
                      onClick={() => toggle1('Total Out-of-Pocket')}
                      style={{ textDecoration: 'underline' }}
                    >
                      out-of-pocket
                    </span>
                  </Tooltip>{' '}
                  to cover the downpayment, closing costs, and rehab
                  for this property.
                </p>
                <Divider
                  style={{ marginBottom: 15, marginTop: 15 }}
                />
                <Grid container>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    >
                      {map(
                        mezzanineFinancingFields(activeTab - 2),
                        (section) => renderInputFields(section),
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'endFinancing',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>End Buyer Financing</b>
            </div>
            <div>
              <Tooltip title="Projected fund totals an investor will need to produce in order to acquire the property">
                <Button
                  onClick={() =>
                    setOpenBuildToRentEndBuyerFinancingTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Grid
              container
              className="rounded p-2 mb-2 bg-secondary text-center border-light border-1"
            >
              <Grid
                item
                md={4}
                className="d-flex justify-content-center"
              >
                <div>
                  <div className="text-dark pb-1">Loan</div>
                  <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                    <div className="d-flex p-1 align-items-center">
                      {/* <div className="badge badge-success badge-circle mr-2">
                        fast
                      </div> */}
                      <span className="d-20 w-auto">
                        <CountUp
                          start={0}
                          end={analytics.turnkey.loanAmountTurnkey}
                          duration={1}
                          separator=","
                          decimals={0}
                          prefix="$"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                md={4}
                className="d-flex justify-content-center"
              >
                <div>
                  <div className="text-dark pb-1">Mortgage/mo</div>
                  <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                    <div className="d-flex p-1 align-items-center">
                      {/* <div className="badge badge-warning badge-circle mr-2">
                        normal
                      </div> */}
                      <span className="d-20 w-auto">
                        <CountUp
                          start={0}
                          end={analytics.turnkey.mortgageTurnkey}
                          duration={1}
                          delay={2}
                          separator=","
                          decimals={0}
                          prefix="$"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                md={4}
                className="d-flex justify-content-center"
              >
                <div>
                  <div className="text-dark pb-1">DSCR</div>
                  <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                    <div className="d-flex p-1 align-items-center">
                      {/* <div className="badge badge-danger badge-circle mr-2">
                        slow
                      </div> */}
                      <span className="d-20 w-auto">
                        <CountUp
                          start={0}
                          end={
                            analytics.turnkey.debtCoverageRatioTurnkey
                          }
                          duration={1}
                          delay={2}
                          separator=","
                          decimals={2}
                          suffix="x"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <p className="mb-3 text-black-50 text-center">
              Based on your{' '}
              <b>
                {currencyFormat.format(
                  analytics.turnkey.allInCostTurnkey,
                )}
              </b>{' '}
              <Tooltip title="The total amount of money this deal costs, including rehab, closing costs, points, etc.">
                <span
                  className="text-first"
                  onClick={() => toggle1('All-in Cost End Buyer')}
                  style={{ textDecoration: 'underline' }}
                >
                  all-in cost
                </span>
              </Tooltip>
              , you'll need to pay{' '}
              <b>
                {currencyFormat.format(
                  analytics.turnkey.totalOutOfPocketTurnkey,
                )}
              </b>{' '}
              <Tooltip title="Total amount that the buyer has to cover with their own money.">
                <span
                  className="text-first"
                  onClick={() =>
                    toggle1('Total Out-of-Pocket End Buyer')
                  }
                  style={{ textDecoration: 'underline' }}
                >
                  out-of-pocket
                </span>
              </Tooltip>{' '}
              to cover the downpayment, closing costs, and rehab for
              this property.
            </p>
            <Divider style={{ marginBottom: 15, marginTop: 15 }} />
            <Grid container>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  {map(financingFields, (section) =>
                    renderInputFields(section),
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'projection',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Value Over Time</b>
            </div>
            <div>
              <Tooltip title="Projected paydown timelines and cashflow options available on this property">
                <Button
                  onClick={() =>
                    setOpenBuildToRentValueOverTimeTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
              <Grid container className="text-black-50 font-size-sm">
                <Grid
                  item
                  md={4}
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">
                      Property Paid off
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        {/* <div className="badge badge-success badge-circle mr-2">
                         fast
                       </div> */}
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={
                              analytics.turnkey
                                .propertyPayOffYearsTurnkey
                            }
                            duration={1}
                            separator=","
                            decimals={1}
                            suffix=" yrs"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={4}
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">
                      Value at payoff
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        {/* <div className="badge badge-success badge-circle mr-2">
                         fast
                       </div> */}
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={
                              analytics.turnkey
                                .propertyAppreciationValueTurnkey
                            }
                            duration={1}
                            separator=","
                            decimals={0}
                            prefix="$"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={4}
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">
                      Cashflow at payoff
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        {/* <div className="badge badge-warning badge-circle mr-2">
                         normal
                       </div> */}
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={
                              analytics.turnkey
                                .cashFlowAtPayoffTurnkey
                            }
                            duration={1}
                            delay={2}
                            separator=","
                            decimals={0}
                            prefix="$"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            {analytics.B2R.cashFlowB2R >= 0 ? (
              <p className="mb-3 text-black-50 text-center">
                If you took{' '}
                <b>
                  {currencyFormat.format(
                    ((analytics.B2R.cashFlowB2R / 12) *
                      analytics.payDownAccelerator) /
                      100,
                  )}
                </b>{' '}
                ({analytics.payDownAccelerator}% of the net cashflow)
                and paid it towards the{' '}
                <Tooltip title="The amount you borrowed from the lender.">
                  <span
                    className="text-first"
                    style={{ textDecoration: 'underline' }}
                  >
                    loan principal
                  </span>
                </Tooltip>{' '}
                each month, this property could be paid off in{' '}
                <b>
                  {analytics.turnkey.propertyPayOffYearsTurnkey
                    ? analytics.turnkey.propertyPayOffYearsTurnkey.toFixed(
                        1,
                      )
                    : '-'}{' '}
                  years
                </b>
                {analytics.turnkey.propertyPayOffYearsTurnkey
                  ? analytics.turnkey.propertyPayOffYearsTurnkey.toFixed(
                      1,
                    ) ===
                    (assumptions.termTurnkey === 'Years'
                      ? assumptions.termTurnkey
                      : assumptions.termTurnkey / 12
                    ).toFixed(1)
                    ? ''
                    : ` instead of ${
                        assumptions.termTurnkey
                          ? (assumptions.termTurnkey === 'Years'
                              ? assumptions.termTurnkey
                              : assumptions.termTurnkey / 12
                            ).toFixed(1)
                          : 0
                      }`
                  : ''}
                .
              </p>
            ) : (
              <p className="mb-3 text-black-50 text-center">
                The cashflow of this property is less than zero, which
                means you can't take a percent of the cashflow and pay
                it towards the loan balance.
              </p>
            )}
            <Divider style={{ marginBottom: 15, marginTop: 15 }} />
            <Grid container>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  {map(projectionAssumptionsFields, (section) =>
                    renderInputFields(section),
                  )}
                  {/* <Grid item xs={12}>
                     <Typography id="continuous-slider" gutterBottom>
                       Paydown Accelerator (% of net cashflow)
                     </Typography>
                     <Slider value={75} />
                     <Grid container direction='row' justifyContent='space-between'>
                       <Grid item>
                         <Typography id="continuous-slider" gutterBottom>
                           0%
                         </Typography>
                       </Grid>
                       <Grid item>
                         <Typography>100%</Typography>
                       </Grid>
                     </Grid>
                   </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'cashPositioning',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Cash Positioning</b>
            </div>
            <div>
              <Tooltip title="Projected cash growth over one, three, five, and ten years">
                <Button
                  onClick={() =>
                    setOpenBuildToRentCashPositioningTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Suspense fallback={<div>Loading Chart...</div>}>
              <Chart options={options} series={series} type="bar" />
            </Suspense>
            <Tooltip
              title={`Total Return of ${assumptions.rentalHoldingPeriodB2R} years`}
            >
              <div
                className="rounded p-2 mb-2 bg-secondary text-center border-light border-1"
                onClick={() => toggle1('Total Return')}
              >
                <Grid container justifyContent="space-around">
                  <Grid item data-tour="cashPositioning-step-1">
                    <Typography>Total Return</Typography>
                    <Typography>
                      {currencyFormat.format(
                        analytics.B2R.totalReturnOnInvestmentB2R,
                      )}
                    </Typography>
                  </Grid>
                  <Grid item data-tour="cashPositioning-step-2">
                    <Typography>Total Return %</Typography>
                    <Typography>
                      {percentageFormat.format(
                        analytics.B2R.totalOutOfPocketB2R
                          ? analytics.B2R.totalReturnOnInvestmentB2R /
                              analytics.B2R.totalOutOfPocketB2R
                          : 0,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Tooltip>
            {/* <Grid container direction="row">
              <Grid item xs={12}>
                <Button
                  variant='outlined'
                  fullWidth
                  onClick={handleOpenAmortization}
                >
                  <Typography>Amortization</Typography>
                </Button>
              </Grid>
            </Grid> */}
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'comparableProperties',
      card: props.basicView ? null : (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Comparable Properties</b>
            </div>
            <div>
              <Tooltip title="Details about comparable properties">
                <Button
                  onClick={() =>
                    setOpenBuildToRentComparablePropertiesTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
              <Grid container className="text-black-50 font-size-sm">
                <Grid
                  item
                  md={6}
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">
                      Comparable Properties
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={props.determinedComps.length}
                            duration={1}
                            separator=","
                            decimals={0}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={6}
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">
                      Potential Comps
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={
                              props.compProperties.length -
                              props.determinedComps.length
                            }
                            duration={1}
                            separator=","
                            decimals={0}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <List component="div" className="list-group-flush">
              {map(compData, (section) => (
                <ListItem
                  component="a"
                  button
                  disableRipple
                  className="d-flex bg-white hover-scale-rounded justify-content-between py-3"
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Grid
                      item
                      className="font-weight-bold text-black"
                      style={{ olor: 'text' }}
                    >
                      {section.label}
                    </Grid>
                    {section.range ? (
                      <Grid item>
                        <Grid container direction="row" spacing={1}>
                          <Grid item>
                            <CountUp
                              start={0}
                              end={section.value1}
                              duration={1}
                              separator=","
                              delay={1}
                              decimals={1}
                              decimal="."
                              prefix="$"
                              suffix="k"
                            />
                          </Grid>
                          <Grid item>
                            <Typography>-</Typography>
                          </Grid>
                          <Grid item>
                            <CountUp
                              start={0}
                              end={section.value2}
                              duration={1}
                              separator=","
                              delay={1}
                              decimals={1}
                              decimal="."
                              prefix="$"
                              suffix="k"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item>
                        <CountUp
                          start={0}
                          end={section.value}
                          duration={1}
                          separator=","
                          delay={1}
                          decimals={2}
                          decimal="."
                          prefix="$"
                        />
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
              ))}
            </List>
            <Grid container>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: 25 }}
                  onClick={(e) => props.toggleCenter('Comps')}
                >
                  <Typography>View Comps</Typography>
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'disposition',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Disposition Expenses</b>
            </div>
            <div>
              <Tooltip title="All costs and expenses associated with sale of this property">
                <Button
                  onClick={() =>
                    setOpenBuildToRentDispositionExpensesTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Grid content>
              <Grid item>
                <div className="d-flex justify-content-center">
                  <Suspense fallback={<div>Loading Chart...</div>}>
                    <Chart
                      options={dispositionChartOptions}
                      series={dispositionChartSeries}
                      type="donut"
                      width="300"
                    />
                  </Suspense>
                </div>
              </Grid>
              <Divider style={{ marginBottom: 15, marginTop: 15 }} />
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  {map(dispositionFields, (field) =>
                    renderInputFields(field),
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'deactive',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Hide Cards</b>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Typography>
              All cards past this one will be hidden
            </Typography>
          </CardContent>
        </Card>
      ),
    },
  ]

  const getOrderDefault = () => {
    const storedValues = !isEmpty(property.buildToRentOrder)
      ? property.buildToRentOrder
      : merge(
          cloneDeep(DEFAULT_PREFERENCES),
          activeOrg.memberPreferences,
          activeOrg.preferences,
          props.session.me ? props.session.me.preferences : {},
        ).buildToRentOrder || []
    const newCards = difference(
      map(buildToRentCardsToOrder, (card) => card.name),
      storedValues,
    )
    const deactiveIndex = findIndex(
      storedValues,
      (value) => value === 'deactive',
    )
    return uniq(
      concat(
        slice(storedValues, 0, deactiveIndex),
        newCards,
        slice(storedValues, deactiveIndex),
      ),
    )
  }

  const [buildToRentOrder, setBuildToRentOrder] =
    useState(getOrderDefault())
  const [orderChanged, setOrderChanged] = useState(false)
  const [orderDefault, setOrderDefault] = useState(false)
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    setBuildToRentOrder(getOrderDefault())
  }, [activeOrg.member])

  const SortableCard = SortableElement(({ item }) => (
    <Grid item sm={6} xl={4} className="d-flex">
      {item}
    </Grid>
  ))
  const SortableGrid = SortableContainer(({ items }) => (
    <Grid container direction="row" spacing={2} className="p-3">
      {map(items, (item, index) => (
        <SortableCard
          key={`item-${index}`}
          index={index}
          item={item}
        />
      ))}
    </Grid>
  ))

  const [updateProperty] = useMutation(UPDATE_PROPERTY)
  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newOrder = arrayMoveImmutable(
        buildToRentOrder,
        oldIndex,
        newIndex,
      )
      setBuildToRentOrder(newOrder)
      setOrderChanged(true)
    }
  }

  const resetOrder = () => {
    setBuildToRentOrder(getOrderDefault())
    setOrderChanged(false)
  }

  const submitOrder = () => {
    setLoader(true)
    const preferences = merge(
      cloneDeep(DEFAULT_PREFERENCES),
      activeOrg.memberPreferences,
      activeOrg.preferences,
    )

    updateProperty({
      refetchQueries: [
        {
          query: GET_PROPERTY,
          variables: { id: property._id },
        },
      ],
      variables: {
        id: property._id,
        propertyInput: {
          buildToRentOrder,
        },
      },
    })
      .then(() => {
        enqueueSnackbar(`Layout saved`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        if (orderDefault) {
          updateOrganization({
            refetchQueries: [
              {
                query: GET_ME,
              },
            ],
            variables: {
              id: activeOrg._id,
              organizationInput: {
                preferences: {
                  ...preferences,
                  buildToRentOrder,
                },
              },
            },
          })
            .then(() => {
              enqueueSnackbar(`Default Build to Rent layout saved`, {
                variant: 'success',
                autoHideDuration: 3000,
              })
              setOrderChanged(false)
              setLoader(false)
            })
            .catch((error) => {
              enqueueSnackbar(
                `Failed to save default Build to Rent layout`,
                {
                  variant: 'error',
                  autoHideDuration: 3000,
                },
              )
            })
        } else {
          setOrderChanged(false)
          setLoader(false)
        }
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to save layout`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  const sortedBuildToRentCards = sortBy(buildToRentCardsToOrder, [
    function (o) {
      return findIndex(buildToRentOrder, (value) => value === o.name)
    },
  ])

  return (
    <>
      {!props.canDrag && (
        <Grid container direction="row" spacing={2} className="p-3">
          {props.publicImageCard && (
            <Grid
              item
              xs={12}
              md={6}
              lg={props.publicDashboard ? 4 : 6}
              xl={4}
              className="d-flex w-100"
            >
              {props.publicImageCard}
            </Grid>
          )}
          {map(
            slice(
              sortedBuildToRentCards,
              0,
              findIndex(
                sortedBuildToRentCards,
                (card) => card.name === 'deactive',
              ),
            ),
            (card) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={props.publicDashboard ? 4 : 6}
                xl={4}
                className="d-flex w-100"
              >
                {card.card}
              </Grid>
            ),
          )}
        </Grid>
      )}
      {props.canDrag && (
        <SortableGrid
          items={compact(
            concat(
              [props.publicImageCard],
              map(sortedBuildToRentCards, (card) => card.card),
            ),
          )}
          onSortEnd={onSortEnd}
          axis="xy"
        />
      )}
      {map(financials, (section) => (
        <Dialog
          open={modal1 === section.label}
          onClose={toggle1Close}
          maxWidth="lg"
          scroll="body"
          classes={{
            paper:
              'modal-content rounded border-0 bg-white p-3 p-xl-0',
          }}
        >
          {/* <BlockUi blocking={loader} loader={<PacmanLoader color={'var(--primary)'} loading={true} />}> */}
          <Grid container spacing={0}>
            <Grid item xs={5}>
              <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100 rounded br-xl-right-0">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                  <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                    <div className="text-white mt-3">
                      <Typography
                        style={{ fontSize: 30, fontWeight: 600 }}
                      >
                        Calculation
                      </Typography>
                    </div>
                    {section.calculation}
                    <div className="text-white mt-3">
                      <Typography>
                        * Calculation may be a few dollars off due to
                        rounding
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={7}>
              <div>
                <Card>
                  <CardHeader title={section.label} />
                  <Divider />
                  <CardContent style={{ height: '100%' }}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item xs={12} style={{ marginBottom: 10 }}>
                        <Card
                          style={{
                            backgroundColor: '#eff2f5',
                            borderRadius: 0,
                          }}
                        >
                          <CardContent>
                            <Typography>
                              {section.description}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={toggle1Close}
                        >
                          Close
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </div>
            </Grid>
          </Grid>
          {/* </BlockUi> */}
        </Dialog>
      ))}

      <Dialog
        open={openManagementCompanies}
        onClose={handleCloseManagementCompanies}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>Property Management Companies</DialogTitle>
        <DialogContent style={{ paddingTop: 15, paddingBottom: 15 }}>
          <Grid container direction="column" spacing={2}>
            {map(
              filter(propertyManagementCompanies, (company) =>
                includes(
                  property.propertyManagementGroups,
                  company.value,
                ),
              ),
              (section) => (
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <div className="rounded avatar-image overflow-hidden d-40 bg-neutral-dark text-center font-weight-bold text-success d-flex justify-content-center align-items-center">
                        <img
                          className="img-fluid img-fit-container rounded-sm"
                          src={section.image}
                          alt="..."
                        />
                      </div>{' '}
                    </Grid>
                    <Grid item>
                      <Typography>{section.name}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{section.url}</Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        href={section.link}
                        target="_blank"
                      >
                        <Typography>View</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ),
            )}
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modal1 === 'Total Return'}
        onClose={toggle1Close}
        maxWidth="lg"
        scroll="body"
        classes={{
          paper: 'modal-content rounded border-0 bg-white p-3 p-xl-0',
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={5}>
            <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100 rounded br-xl-right-0">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                  <div className="text-white mt-3">
                    <Typography
                      style={{ fontSize: 30, fontWeight: 600 }}
                    >
                      Calculation
                    </Typography>
                  </div>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ marginTop: 5, marginBottom: 5 }}
                  >
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        Cash flow
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        {currencyFormat.format(
                          round(analytics.B2R.cashFlowB2R, 0),
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ marginTop: 5, marginBottom: 5 }}
                  >
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        +Principal Reduction
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        +
                        {currencyFormat.format(
                          round(
                            analytics.B2R.lotPrincipalReduction +
                              analytics.B2R
                                .constructionPrincipalReduction +
                              sum(
                                analytics.B2R
                                  .mezzaninePrincipalReduction,
                              ),
                          ),
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ marginTop: 5, marginBottom: 5 }}
                  >
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        +Anticipated Annual Appreciation
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        +
                        {currencyFormat.format(
                          round(
                            analytics.B2R
                              .anticipatedAnnualAppreciationB2R,
                            0,
                          ),
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider style={{ backgroundColor: '#9198a8' }} />
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ marginTop: 5, marginBottom: 5 }}
                  >
                    <Grid item>
                      <Typography style={{ color: 'white' }}>
                        Total Return
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: 'white' }}>
                        {currencyFormat.format(
                          round(
                            analytics.B2R.totalReturnOnInvestmentB2R,
                            0,
                          ),
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ marginTop: 5, marginBottom: 5 }}
                  >
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        <FontAwesomeIcon
                          icon={['fal', 'divide']}
                          style={{ marginRight: 3 }}
                        />
                        Total Out Of Pocket
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        <FontAwesomeIcon
                          icon={['fal', 'divide']}
                          style={{ marginRight: 3 }}
                        />
                        {currencyFormat.format(
                          round(analytics.B2R.totalOutOfPocketB2R, 0),
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider style={{ backgroundColor: '#9198a8' }} />
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ marginTop: 5, marginBottom: 5 }}
                  >
                    <Grid item>
                      <Typography style={{ color: 'white' }}>
                        Total Return %
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: 'white' }}>
                        {percentageFormat.format(
                          analytics.B2R.totalOutOfPocketB2R
                            ? analytics.B2R
                                .totalReturnOnInvestmentB2R /
                                analytics.B2R.totalOutOfPocketB2R
                            : 0,
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <div className="text-white mt-3">
                    <Typography>
                      * Calculation may be a few dollars off due to
                      rounding
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={7}>
            <div>
              <Card>
                <CardHeader title="Total Return" />
                <Divider />
                <CardContent style={{ height: '100%' }}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={12} style={{ marginBottom: 10 }}>
                      <Card
                        style={{
                          backgroundColor: '#eff2f5',
                          borderRadius: 0,
                        }}
                      >
                        <CardContent>
                          <Typography>
                            The actual rate of return of an investment
                            over a given evaluation period which
                            includes income and appreciation.
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={toggle1Close}
                      >
                        Close
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </div>
          </Grid>
        </Grid>
      </Dialog>

      <AmortizationDialog
        open={openAmortization}
        onClose={handleCloseAmortization}
        tableHeaders={tableHeaders}
        amortizationData={amortizationData}
        currencyFormat2={currencyFormat2}
        assumptions={assumptions}
      />

      <Drawer
        open={orderChanged}
        anchor="bottom"
        variant="persistent"
        PaperProps={{
          style: { backgroundColor: '#253152', padding: 15 },
        }}
      >
        {/* <Alert
         className="mb-4"
         style={{ backgroundColor: '#253152', color: 'white' }}
         icon={false}
         action={
           <IconButton onClick={props.resetPropertyChanges}>
             <Close style={{ color: 'white' }} />
           </IconButton>
         }
       > */}
        {/* <Typography style={{ color: 'white', fontWeight: 600 }}>You have unsaved changes</Typography> */}
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}
          wrap="nowrap"
        >
          <Grid item style={{ marginLeft: 20 }}>
            <IconButton onClick={resetOrder} size="large">
              <Close style={{ color: 'white' }} />
            </IconButton>
          </Grid>
          <Grid item style={{ marginLeft: 0 }}>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: '#4290ff',
                color: 'white',
                boxShadow: 'none',
              }}
              onClick={submitOrder}
              disabled={loader}
            >
              <Typography style={{ fontWeight: 600 }}>
                Save
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <FormControl
              component="fieldset"
              className="pr-4"
              style={{ color: 'white' }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={orderDefault}
                      onChange={() => setOrderDefault(!orderDefault)}
                      style={{ color: 'white' }}
                    />
                  }
                  label="Set this as default Build to Rent layout"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Typography style={{ flex: 1 }} />
        </Grid>
      </Drawer>

      <Dialog
        open={openConfirmLender}
        onClose={toggleOpenConfirmLender}
      >
        <DialogTitle>Disclaimer</DialogTitle>
        <DialogContent>
          Would you like to overwrite the Down Payment, Interest Rate,
          Financing Term, and Points values with the values from the
          selected lender?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              toggleOpenConfirmLender()
            }}
          >
            No
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={(event) => {
              toggleOpenConfirmLender()
              props.lenderOverride(activeTab)
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <BuildToRentStrategyTour
        isOpenBuildToRentStrategyTour={openBuildToRentStrategyTour}
        closeBuildToRentStrategyTour={() =>
          setOpenBuildToRentStrategyTour(false)
        }
      />
      <BuildToRentTargetsTour
        isOpenBuildToRentTargetsTour={openBuildToRentTargetsTour}
        closeBuildToRentTargetsTour={() =>
          setOpenBuildToRentTargetsTour(false)
        }
      />
      <BuildToRentMonthlyCashflowTour
        isOpenBuildToRentMonthlyCashflowTour={
          openBuildToRentMonthlyCashflowTour
        }
        closeBuildToRentMonthlyCashflowTour={() =>
          setOpenBuildToRentMonthlyCashflowTour(false)
        }
      />
      <BuildToRentGrossMonthlyIncomeTour
        isOpenBuildToRentGrossMonthlyIncomeTour={
          openBuildToRentGrossMonthlyIncomeTour
        }
        closeBuildToRentGrossMonthlyIncomeTour={() =>
          setOpenBuildToRentGrossMonthlyIncomeTour(false)
        }
      />
      <BuildToRentMonthlyExpensesTour
        isOpenBuildToRentMonthlyExpensesTour={
          openBuildToRentMonthlyExpensesTour
        }
        closeBuildToRentMonthlyExpensesTour={() =>
          setOpenBuildToRentMonthlyExpensesTour(false)
        }
      />
      <BuildToRentFinancingTour
        isOpenBuildToRentFinancingTour={openBuildToRentFinancingTour}
        closeBuildToRentFinancingTour={() =>
          setOpenBuildToRentFinancingTour(false)
        }
      />
      <BuildToRentConstructionLoanTour
        isOpenBuildToRentConstructionLoanTour={
          openBuildToRentConstructionLoanTour
        }
        closeBuildToRentConstructionLoanTour={() =>
          setOpenBuildToRentConstructionLoanTour(false)
        }
      />
      <BuildToRentMezzanineLoanTour
        isOpenBuildToRentMezzanineLoanTour={
          openBuildToRentMezzanineLoanTour
        }
        closeBuildToRentMezzanineLoanTour={() =>
          setOpenBuildToRentMezzanineLoanTour(false)
        }
      />
      <BuildToRentEndBuyerFinancingTour
        isOpenBuildToRentEndBuyerFinancingTour={
          openBuildToRentEndBuyerFinancingTour
        }
        closeBuildToRentEndBuyerFinancingTour={() =>
          setOpenBuildToRentEndBuyerFinancingTour(false)
        }
      />
      <BuildToRentValueOverTimeTour
        isOpenBuildToRentValueOverTimeTour={
          openBuildToRentValueOverTimeTour
        }
        closeBuildToRentValueOverTimeTour={() =>
          setOpenBuildToRentValueOverTimeTour(false)
        }
      />
      <BuildToRentCashPositioningTour
        isOpenBuildToRentCashPositioningTour={
          openBuildToRentCashPositioningTour
        }
        closeBuildToRentCashPositioningTour={() =>
          setOpenBuildToRentCashPositioningTour(false)
        }
      />
      <BuildToRentDispositionExpensesTour
        isOpenBuildToRentDispositionExpensesTour={
          openBuildToRentDispositionExpensesTour
        }
        closeBuildToRentDispositionExpensesTour={() =>
          setOpenBuildToRentDispositionExpensesTour(false)
        }
      />
      <BuildToRentComparablePropertiesTour
        isOpenBuildToRentComparablePropertiesTour={
          openBuildToRentComparablePropertiesTour
        }
        closeBuildToRentComparablePropertiesTour={() =>
          setOpenBuildToRentComparablePropertiesTour(false)
        }
      />
    </>
  )
}

export default withSession(CenterPageBuildToRent)
