/**
 * @ Author: Housefolios
 * @ Create Time: 2025-04-08 12:01:05
 * @ Modified by: David Helmick
 * @ Modified time: 2025-04-08 12:11:09
 * @ Description: Tours for the Buy & Hold Analysis Page
 */

import Reactour from 'reactour'

import { Button, Typography } from '@mui/material'

import '../../style.css'

import { useLocation } from 'react-router-dom'

export const BuyHoldTargetsTour = ({
  isOpenBuyHoldTargetsTour,
  closeBuyHoldTargetsTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="targets-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              This is the max offer you should you should make on this
              property in order to gain profit.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="targets-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Net cashflow is the amount of money left after the
              mortgage and any other expenses are paid.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="targets-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A cash-on-cash return calculates the return on the money
              put in by the investor on a property with a mortgage.
              Commonly referred to as a Leveraged Return.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="targets-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              IRR measures an investment’s annual return throughout
              the entire property ownership time frame. Unlike a
              cash-on-cash return or cap rate that measures returns in
              annual segments, IRR weighs total earnings from the day
              you purchase your property to the day you sell it.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="targets-step-5"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The Yield-on-Cost is the rate of return an investor can
              expect from an investment in real estate, assuming they
              pay all cash.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuyHoldTargetsTour}
      onRequestClose={closeBuyHoldTargetsTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuyHoldMonthlyCashflowTour = ({
  isOpenBuyHoldMonthlyCashflowTour,
  closeBuyHoldMonthlyCashflowTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="monthlyCashflow-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Monthly Income includes Total Rental Income minus the
              Vacancy Rate plus the Other Monthly Income
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyCashflow-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Monthly Expenses is a sum of all the monthly values from
              the all the fields in the Monthly Expenses card below
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyCashflow-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Monthly Cashflow is your Monthly Income minus your
              Monthly Expenses
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuyHoldMonthlyCashflowTour}
      onRequestClose={closeBuyHoldMonthlyCashflowTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuyHoldAcquisitionExpensesTour = ({
  isOpenBuyHoldAcquisitionExpensesTour,
  closeBuyHoldAcquisitionExpensesTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="acquisitionExpenses-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              This is a breakdown of all your Acquisition Expenses
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="acquisitionExpenses-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Check this box to see additional field options such as
              the acquisition commision $/%, Acquisition Fee,
              Financing Fee & Owner Reserve.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="acquisitionExpenses-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              This is how long this property has been on the market.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="acquisitionExpenses-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The price that has been agreed upon by the owner and
              buyer for the transferring of a property.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="acquisitionExpenses-step-5"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Change where the purchase price data is pulled from by
              clicking on this icon.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="acquisitionExpenses-step-6"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The ARV is the value of a property after you have
              conducted repairs and are ready to sell.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="acquisitionExpenses-step-7"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Change where the after repair value data is pulled from
              by clicking on this icon.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="acquisitionExpenses-step-8"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Fees (attorney/title fees, etc) Paid at Closing.
              Expressed as a dollar amount or precentage of the
              purchase prices.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="acquisitionExpenses-step-9"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A seller credit is the dollar amount the seller will put
              towards your closing costs.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="acquisitionExpenses-step-10"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Advanced Fee - Used in addition to sales of typical
              sales commissions expressed as a percentage of the
              Purchase price or a fixed dollar amount.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="acquisitionExpenses-step-11"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Advanced Fee - Used in addition to sales of typical
              sales commissions expressed as a fixed dollar amount
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="acquisitionExpenses-step-12"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Advanced Fee - A fee that the real estate professional
              charges for securing financing on the property.
              Expressed as a percentage of the loan amount.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="acquisitionExpenses-step-13"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A one-time reserve you set aside (ex: $5,000) to cover
              unforeseen repairs, etc. This amount is in addition to
              the monthly capex (reserve) you set aside each month.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuyHoldAcquisitionExpensesTour}
      onRequestClose={closeBuyHoldAcquisitionExpensesTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuyHoldGrossMonthlyIncomeSingleRentTour = ({
  isOpenBuyHoldGrossMonthlyIncomeSingleRentTour,
  closeBuyHoldGrossMonthlyIncomeSingleRentTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="grossMonthlyIncome-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A breakdown of your Monthly Income
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="grossMonthlyIncomeSingleRent-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Rent Type includes a single rent value or rent range
              values
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="grossMonthlyIncomeSingleRent-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A tenant's monthly payment to a landlord for the use of
              property or land.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="grossMonthlyIncomeSingleRent-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Change where the rental data is pulled from by clicking
              on this icon.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="grossMonthlyIncomeSingleRent-step-5"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The percentage of time that a property is not rented out
              during the course of a year.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="grossMonthlyIncomeSingleRent-step-6"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Additional income you earn through the property. Some
              examples might be coin-op laundry, parking, storage,
              etc.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuyHoldGrossMonthlyIncomeSingleRentTour}
      onRequestClose={closeBuyHoldGrossMonthlyIncomeSingleRentTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuyHoldGrossMonthlyIncomeRentRangeTour = ({
  isOpenBuyHoldGrossMonthlyIncomeRentRangeTour,
  closeBuyHoldGrossMonthlyIncomeRentRangeTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="grossMonthlyIncome-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A breakdown of your Monthly Income
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="grossMonthlyIncomeRentRange-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Rent Type includes a single rent value or rent range
              values
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="grossMonthlyIncomeRentRange-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Lower end of the projected rent
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="grossMonthlyIncomeRentRange-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Change where the rental data is pulled from by clicking
              on this icon.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="grossMonthlyIncomeRentRange-step-5"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Higher end of the projected rent
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="grossMonthlyIncomeRentRange-step-6"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Change where the rental data is pulled from by clicking
              on this icon.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="grossMonthlyIncomeRentRange-step-7"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The percentage of time that a property is not rented out
              during the course of a year.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="grossMonthlyIncomeRentRange-step-8"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Additional income you earn through the property. Some
              examples might be coin-op laundry, parking, storage,
              etc.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuyHoldGrossMonthlyIncomeRentRangeTour}
      onRequestClose={closeBuyHoldGrossMonthlyIncomeRentRangeTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuyHoldMonthlyExpensesTour = ({
  isOpenBuyHoldMonthlyExpensesTour,
  closeBuyHoldMonthlyExpensesTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="monthlyExpenses-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A breakdown of your Monthly Expenses
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyExpenses-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Annual property insurance premium expressed as a dollar
              amount or percentage of the purchase price.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyExpenses-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Property management fee expressed as a dollar amount or
              percentage of Gross Rental Income (rent - vacancy).
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyExpenses-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              An organization in a subdivision, planned community, or
              condominium building that makes and enforces rules for
              the properties and its residents. These residents
              automatically become members and are required to pay
              dues, known as HOA fees.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyExpenses-step-5"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A tax paid on property owned by an individual or other
              legal entity, such as a corporation.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyExpenses-step-6"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              See what other sites are saying the property taxes are
              by clicking on the blue icon
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyExpenses-step-7"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The annual cost of maintenance repairs to the property
              expressed as a percentage of Gross Rental Income (rent -
              vacancy).
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyExpenses-step-8"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Includes electricity, water, sewer, gas, garbage, etc.
              Expressed as a dollar amount or a percentage of Monthly
              Gross Rental Income (rent - vacancy).
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyExpenses-step-9"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The annual cost of maintenance repairs to the property
              expressed as a percentage of Gross Rental Income (rent -
              vacancy).
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyExpenses-step-10"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Includes electricity, water, sewer, gas, garbage, etc.
              Expressed as a dollar amount or a percentage of Monthly
              Gross Rental Income (rent - vacancy).
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyExpenses-step-11"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount of monthly income you want to put aside each
              month as a reserve for future expenses. This includes
              capital expenditure (capex) items such as the roof,
              appliances, water, heater, etc. Expressed as a dollar
              amount or a percentage of Monthly Gross Rental Income
              (rent - vacancy).
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyExpenses-step-10"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Any annual expense that has not already been labeled.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="monthlyExpenses-step-11"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount you pay every month toward your mortgage
              loan. This field is not editable. To change your
              mortgage amount, see the Financing card below.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuyHoldMonthlyExpensesTour}
      onRequestClose={closeBuyHoldMonthlyExpensesTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuyHoldRehabExpensesSingleRehabValueTour = ({
  isOpenBuyHoldRehabExpensesSingleRehabValueTour,
  closeBuyHoldRehabExpensesSingleRehabValueTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="rehabExpensesChart"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A breakdown of your Rehab Expenses
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="rehabExpensesRehabType"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              You can choose multiple ways to estimate your rehab
              expenses. The types include: Single Rehab, Detailed
              Rehab and Rehab Tiers with and without additional
              factors based on property age
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="rehabExpensesEstRehab"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              An expense that is incurred when restoring an entity to
              its normal or near-normal condition.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="rehabExpensesDatabaseIcon"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              If you don't agree with how the "Estimated Rehab" value
              was calculated. Click here to see if it was pulled from
              a different source that may be more accurate in your
              area.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuyHoldRehabExpensesSingleRehabValueTour}
      onRequestClose={closeBuyHoldRehabExpensesSingleRehabValueTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuyHoldRehabExpensesDetailedRehabTour = ({
  isOpenBuyHoldRehabExpensesDetailedRehabTour,
  closeBuyHoldRehabExpensesDetailedRehabTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="rehabExpensesChart"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A breakdown of your Rehab Expenses
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="rehabExpensesRehabType"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              In the detailed rehab option you're able to customize
              your rehab options by clicking add line item below!
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuyHoldRehabExpensesDetailedRehabTour}
      onRequestClose={closeBuyHoldRehabExpensesDetailedRehabTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuyHoldRehabExpensesRehabTierTour = ({
  isOpenBuyHoldRehabExpensesRehabTierTour,
  closeBuyHoldRehabExpensesRehabTierTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="rehabExpensesChart"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A breakdown of your Rehab Expenses
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="rehabExpensesRehabType"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              You can choose multiple ways to estimate your rehab
              expenses. The types include: Single Rehab, Detailed
              Rehab and Rehab Tiers with and without additional
              factors based on property age
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="rehabExpensesRehabTier"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              This is what you estimate to spend per sqft on your
              property
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuyHoldRehabExpensesRehabTierTour}
      onRequestClose={closeBuyHoldRehabExpensesRehabTierTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuyHoldRehabExpensesFlippingAmericaRehabTour = ({
  isOpenBuyHoldRehabExpensesFlippingAmericaRehabTour,
  closeBuyHoldRehabExpensesFlippingAmericaRehabTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="rehabExpensesChart"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A breakdown of your Rehab Expenses
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="rehabExpensesRehabType"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              You can choose multiple ways to estimate your rehab
              expenses. The types include: Single Rehab, Detailed
              Rehab and Rehab Tiers with and without additional
              factors based on property age
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="rehabExpensesRehabTier"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              This is what you estimate to spend per sqft on your
              property
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="rehabExpensesRehabPerDecade"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              This is an additional amount of rehab you estimate to
              spend on your property based on the age of the property
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuyHoldRehabExpensesFlippingAmericaRehabTour}
      onRequestClose={
        closeBuyHoldRehabExpensesFlippingAmericaRehabTour
      }
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuyHoldFinancingTour = ({
  isOpenBuyHoldFinancingTour,
  closeBuyHoldFinancingTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="financing-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              This is your Loan Amount estimate
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="financing-step-6"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              This is your Monthly Mortgage estimate
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="financing-step-7"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Debt Service Coverage Ratio is a measure of the cash
              flow available to pay current debt obligations.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="financing-step-8"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              This is an estimate of how much money is involved to
              purchase the property and how much will come from your
              own pocket
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="financing-step-9"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Investor pays 100% of the purchase price in cash and
              does not have a mortgage payment.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="financing-step-6"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount of cash that a purchaser must put down,
              toward the purchase price, to buy real estate. The down
              payment amount is a percentage of the total purchase
              price, dictated by the lender.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="financing-step-7"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The interest rate is the amount a lender charges for the
              use of capital, expressed as a percentage of the
              principal. The interest rate is typically noted on an
              annual basis known as the annual percentage rate (APR).
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="financing-step-8"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The length of time that the loan is being amortized.
              Expressed in months or years.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="financing-step-9"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Often when you take out a loan, you will pay "points"
              (aka: fees) on the loan. It is essentially prepaid
              interest charged by the lender. One point is equal to 1%
              of the loan amount. For example, a $100,000 loan with 2
              points would be $2,000.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="financing-step-10"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A loan that only requires the payment of interest, not
              principal.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="financing-step-11"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Checking this will include the repair costs into your
              loan
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      // selector: '[data-tour="fixFlip-step-11-financing-step-12-turnkey-step-12-brrrr-bridgeLoan-step-12-brrrr-refinanceLoan-step-14-shortTermRental-step-12"]',
      selector: '[data-tour="applyForLendingButton"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Looking for a lender? Housefolios now offers 100%
              financing on properties!
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuyHoldFinancingTour}
      onRequestClose={closeBuyHoldFinancingTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuyHoldValueOverTimeTour = ({
  isOpenBuyHoldValueOverTimeTour,
  closeBuyHoldValueOverTimeTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="valueOverTime-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount of time until your loan is paid off
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="valueOverTime-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              This is the value of the property at payoff
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="valueOverTime-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              How much your property will be cashflowing at payoff
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="valueOverTime-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              This shows how many years it would take "potentially" if
              you were to pay a percentage of the net cashflow.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="valueOverTime-step-5"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A holding period is the amount of time the investment is
              held by an investor or the period between the purchase
              and sale of the property. Expressed in years.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="valueOverTime-step-6"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The % of Appreciation in Total Dollar Value that the
              Home has gained or lost each year. Expressed as a
              percentage of ARV (after repair value). If your holding
              period is less than 12 months, this field will have no
              effect.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="valueOverTime-step-7"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The % that the annual income, from rent, has increased
              each year. Expressed as a percentage of gross rental
              income.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="valueOverTime-step-8"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The % increase in property expenses per year. Expressed
              as a percentage of gross rental income. If your holding
              period is less than 12 months, this field will have no
              effect.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="valueOverTime-step-9"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Paydown Accelerator is expressed as a percentage of net
              cashflow that is used as an additional principal payment
              on a mortgage
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="valueOverTime-step-10"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Includes the rent and expense growth rate values in the
              Property Paid off calculation
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuyHoldValueOverTimeTour}
      onRequestClose={closeBuyHoldValueOverTimeTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuyHoldCashPositioningTour = ({
  isOpenBuyHoldCashPositioningTour,
  closeBuyHoldCashPositioningTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="cashPositioning-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            {/* //SOURCE COMMENT: Needed Total Return definition https://www.millionacres.com/real-estate-basics/articles/how-to-calculate-a-rental-propertys-return-on-investment/#:~:text=For%20a%20simplified%20example%2C%20if,performance%20after%20you%20sell%20it. */}
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Shows your cash position based on 1, 3, 5 & 10 years
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="cashPositioning-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            {/* //SOURCE COMMENT: Needed Total Return definition https://www.millionacres.com/real-estate-basics/articles/how-to-calculate-a-rental-propertys-return-on-investment/#:~:text=For%20a%20simplified%20example%2C%20if,performance%20after%20you%20sell%20it. */}
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Total return is a combination of income and equity
              appreciation. For a simplified example, if you pay
              $100,000 in cash to acquire a property and it generates
              net income of $10,000 in a year and increases in value
              by $8,000, you have a total return of $18,000, or 18%.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="cashPositioning-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            {/* //SOURCE COMMENT: Needed Total Return definition https://www.millionacres.com/real-estate-basics/articles/how-to-calculate-a-rental-propertys-return-on-investment/#:~:text=For%20a%20simplified%20example%2C%20if,performance%20after%20you%20sell%20it. */}
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Total return is a combination of income and equity
              appreciation. For a simplified example, if you pay
              $100,000 in cash to acquire a property and it generates
              net income of $10,000 in a year and increases in value
              by $8,000, you have a total return of $18,000, or 18%.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="cashPositioning-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              View a detailed Amortization schedule
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuyHoldCashPositioningTour}
      onRequestClose={closeBuyHoldCashPositioningTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuyHoldDispositionExpensesTour = ({
  isOpenBuyHoldDispositionExpensesTour,
  closeBuyHoldDispositionExpensesTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="dispositionExpenses-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              A breakdown of your Disposition Expenses
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="dispositionExpenses-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The price you would sell the property for once the rehab
              is completed.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="dispositionExpenses-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Closing Costs Paid by the Seller. Expressed as a dollar
              amount or percentage of the resale price
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="dispositionExpenses-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Sales Commission on the Future Sale of Property.
              Expressed as a dollar amount or percentage of resale
              price.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="dispositionExpenses-step-5"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Advanced Fee - Additional marketing fee on top of sales
              commission. Expressed as a dollar amount or percentage
              of resale price.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="dispositionExpenses-step-6"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The % of Appreciation in Total Dollar Value that the
              Home has gained or lost each year. Expressed as a
              percentage of ARV (after repair value). If your holding
              period is less than 12 months, this field will have no
              effect.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="dispositionExpenses-step-7"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The % increase in property expenses per year. Expressed
              as a percentage of gross rental income. If your holding
              period is less than 12 months, this field will have no
              effect.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="dispositionExpenses-step-8"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              This field is not editable. The remaining loan amount at
              the end of your flip holding period.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuyHoldDispositionExpensesTour}
      onRequestClose={closeBuyHoldDispositionExpensesTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BuyHoldComparablePropertiesTour = ({
  isOpenBuyHoldComparablePropertiesTour,
  closeBuyHoldComparablePropertiesTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="comparableProperties-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount of Comparable Properties we found
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount of Potential Comps we found
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The average adjusted value of the comparable properties
              that we found
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The lowest and highest comparable property prices make
              up the range
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-5"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The average adjusted value of the comparable properties
              divided by sqft
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-6"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The average adjusted value of the three lowest priced
              comparable properties
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-7"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Click this button to get a detailed look at your
              comparable properties and potential comps
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBuyHoldComparablePropertiesTour}
      onRequestClose={closeBuyHoldComparablePropertiesTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}
