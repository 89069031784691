import { gql } from '@apollo/client'
import { AssumptionsFragments } from '@/housefolios-components/AssumptionsSettings/fragments'
import {
  AcquisitionFragments,
  AssetManagementFragments,
  OfferToCloseFragments,
} from '@/housefolios-components/Property/fragments'

export const GET_PROPERTIES = gql`
  query properties(
    $portfolioId: [ID]
    $limit: Int
    $cursor: String
    $filter: String
    $sort: String
  ) {
    properties(
      portfolioId: $portfolioId
      limit: $limit
      cursor: $cursor
      filter: $filter
      sort: $sort
    ) {
      cursor
      totalCount
      hasNextPage
      portfolioId
      properties {
        _id
        estval
        comments
        address
        city
        county
        state
        zip
        beds
        baths
        sqft
        strategy
        marketplaceStrategy
        strategies
        hideAddress
        hideDaysOnMarketplace
        status
        createdAt
        updatedAt
        location {
          latitude
          longitude
        }
        year
        images {
          _id
          url
          url_small
        }
        favorite
        owned
        propertyType
        propertyUnitAmount
        propertyDashboard
        marketplaces {
          marketplaceName
          approvedMarketplace
          expirationDate
          extraTags
          proformaType
        }
        marketplaceSubmittedBy {
          email
          profile {
            firstname
            lastname
          }
        }
        mainImage
        compCount
        lot {
          _id
          owned
          status
        }
        description
        notes
        proformaDescription
        proformaImages
        proformaImagePage
        proformaLogo
        proformaFloorPlan
        proformaRehabCost
        proformaHideAddress
        proformaContact
        financingProformaSent
        applyForLending
        proformaFile {
          filename
          filetype
          filesize
          url
          url_small
          createdBy
          createdAt
        }
        realeflowReportURL
        deletedAt
        zpid
        createdBy {
          email
          profile {
            firstname
            lastname
          }
        }
        assumptions
        acquisition {
          activeStage
          analysis {
            ...analysis
          }
          analytics {
            ...analytics
          }
        }
        source
        estimates
        MLSID
        MLSAgent {
          MLSID
          brokerage
          name
          phone
          email
        }
        lender
        lenderStatus
        lenderTimeline
        lenderSubmittedBy {
          email
          profile {
            firstname
            lastname
          }
        }
        jv
        jvStatus
        jvTimeline
        jvSubmittedBy {
          email
          profile {
            firstname
            lastname
          }
        }
        sewer
        water
        exchange
        owned
        market
        type
        plan
        zone
        parcelID
        lotSize
        basementSqft
        remodelYear
        parking
        ac
        homeType
        floodZone
        checklists {
          underwritingChecklist
          offerToCloseChecklist
          wholesaleChecklist
          lotChecklist
          buildChecklist
          saleChecklist
          fixFlipChecklist
          affiliateChecklist
          BORChecklist
          astroFlipChecklist
          customChecklist
        }
        otherFiles
        marketplaceFiles
        videoWalkthrough
        kanbanProcessStageId
        kanbanProcessStageState
        southernImpressions
        jvFile
        budgetItems {
          name
          budget
          budgetItemProjectedStartDate
          budgetItemProjectedEndDate
          budgetItemActualStartDate
          budgetItemActualEndDate
          lines {
            name
            amount
          }
          files
        }
        customFields
        houseCanaryPropertyExplorerLink
        ownership
        apn
        linkedProperties
        lien
        mortgageBalance
        estimatedValue
        estimatedEquity
        skipTraceResults
        pitiData
        realeflowData
        subdivision
        offerToClose {
          ...offerToClose
        }
      }
    }
  }
  ${AcquisitionFragments.analysis}
  ${AcquisitionFragments.analytics}
  ${OfferToCloseFragments.offerToClose}
`

export const GET_TRASH = gql`
  query trashedProperties {
    trashedProperties {
      _id
      estval
      comments
      address
      city
      county
      state
      zip
      beds
      baths
      sqft
      strategy
      marketplaceStrategy
      strategies
      hideAddress
      hideDaysOnMarketplace
      status
      createdAt
      updatedAt
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      favorite
      owned
      propertyType
      propertyUnitAmount
      propertyDashboard
      marketplaces {
        marketplaceName
        approvedMarketplace
        expirationDate
        extraTags
        proformaType
      }
      marketplaceSubmittedBy {
        email
        profile {
          firstname
          lastname
        }
      }
      mainImage
      compCount
      lot {
        _id
        owned
        status
      }
      description
      notes
      proformaDescription
      proformaImages
      proformaImagePage
      proformaLogo
      proformaFloorPlan
      proformaFloorPlan
      proformaRehabCost
      proformaHideAddress
      proformaContact
      financingProformaSent
      applyForLending
      proformaFile {
        filename
        filetype
        filesize
        url
        url_small
        createdBy
        createdAt
      }
      realeflowReportURL
      deletedAt
      zpid
      portfolio {
        _id
        name
        strategies
        strategy
        organization {
          _id
          name
          email
          phone
          logo {
            url
            url_small
          }
          legalDescription
          CMALegalDescription
          member
        }
        assumptions {
          ...assumptions
        }
      }
      createdBy {
        email
        profile {
          firstname
          lastname
        }
      }
      assumptions
      acquisition {
        activeStage
        analysis {
          ...analysis
        }
        analytics {
          ...analytics
        }
      }
      source
      estimates
      MLSID
      MLSAgent {
        MLSID
        brokerage
        name
        phone
        email
      }
      lender
      lenderStatus
      lenderTimeline
      lenderSubmittedBy {
        email
        profile {
          firstname
          lastname
        }
      }
      jv
      jvStatus
      jvTimeline
      jvSubmittedBy {
        email
        profile {
          firstname
          lastname
        }
      }
      sewer
      water
      exchange
      owned
      market
      type
      plan
      zone
      parcelID
      lotSize
      basementSqft
      remodelYear
      parking
      ac
      homeType
      floodZone
      checklists {
        underwritingChecklist
        offerToCloseChecklist
        wholesaleChecklist
        lotChecklist
        buildChecklist
        saleChecklist
        fixFlipChecklist
        affiliateChecklist
        BORChecklist
        astroFlipChecklist
        customChecklist
      }
      otherFiles
      marketplaceFiles
      videoWalkthrough
      kanbanProcessStageId
      kanbanProcessStageState
      southernImpressions
      jvFile
      budgetItems {
        name
        budget
        budgetItemProjectedStartDate
        budgetItemProjectedEndDate
        budgetItemActualStartDate
        budgetItemActualEndDate
        lines {
          name
          amount
        }
        files
      }
      subdivision
      customFields
      houseCanaryPropertyExplorerLink
      ownership
      apn
      linkedProperties
      lien
      mortgageBalance
      estimatedValue
      estimatedEquity
      skipTraceResults
      pitiData
      assetManagement {
        ...assetManagement
      }
      offerToClose {
        ...offerToClose
      }
    }
  }
  ${AcquisitionFragments.analysis}
  ${AcquisitionFragments.analytics}
  ${AssumptionsFragments.assumptions}
  ${AssetManagementFragments.assetManagement}
  ${OfferToCloseFragments.offerToClose}
`

export const GET_LENDER_PROPERTIES = gql`
  query lenderProperties($lenders: [String]!) {
    lenderProperties(lenders: $lenders) {
      _id
      estval
      comments
      address
      city
      county
      state
      zip
      beds
      baths
      sqft
      strategy
      marketplaceStrategy
      strategies
      hideAddress
      hideDaysOnMarketplace
      status
      createdAt
      updatedAt
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      favorite
      owned
      propertyType
      propertyUnitAmount
      propertyDashboard
      marketplaces {
        marketplaceName
        approvedMarketplace
        expirationDate
        extraTags
        proformaType
      }
      marketplaceSubmittedBy {
        email
        profile {
          firstname
          lastname
        }
      }
      mainImage
      compCount
      lot {
        _id
        owned
        status
      }
      description
      notes
      proformaDescription
      proformaImages
      proformaImagePage
      proformaLogo
      proformaRehabCost
      proformaHideAddress
      proformaContact
      financingProformaSent
      applyForLending
      proformaFile {
        filename
        filetype
        filesize
        url
        url_small
        createdBy
        createdAt
      }
      realeflowReportURL
      deletedAt
      zpid
      portfolio {
        _id
        name
        strategies
        strategy
        organization {
          _id
          name
          email
          phone
          logo {
            url
            url_small
          }
          legalDescription
          CMALegalDescription
          member
        }
        assumptions {
          ...assumptions
        }
      }
      createdBy {
        email
        profile {
          firstname
          lastname
        }
      }
      assumptions
      acquisition {
        activeStage
        analysis {
          ...analysis
        }
        analytics {
          ...analytics
        }
      }
      source
      estimates
      MLSID
      MLSAgent {
        MLSID
        brokerage
        name
        phone
        email
      }
      lender
      lenderStatus
      lenderTimeline
      lenderSubmittedBy {
        email
        profile {
          firstname
          lastname
        }
      }
      jv
      jvStatus
      jvTimeline
      jvSubmittedBy {
        email
        profile {
          firstname
          lastname
        }
      }
      sewer
      water
      exchange
      owned
      market
      type
      plan
      zone
      parcelID
      lotSize
      basementSqft
      remodelYear
      parking
      ac
      homeType
      checklists {
        underwritingChecklist
        offerToCloseChecklist
        wholesaleChecklist
        lotChecklist
        buildChecklist
        saleChecklist
        fixFlipChecklist
        affiliateChecklist
        BORChecklist
        astroFlipChecklist
        customChecklist
      }
      otherFiles
      marketplaceFiles
      videoWalkthrough
      kanbanProcessStageId
      kanbanProcessStageState
      jvFile
      budgetItems {
        name
        budget
        budgetItemProjectedStartDate
        budgetItemProjectedEndDate
        budgetItemActualStartDate
        budgetItemActualEndDate
        lines {
          name
          amount
        }
        files
      }
      subdivision
      customFields
      houseCanaryPropertyExplorerLink
      ownership
      apn
      linkedProperties
      lien
      mortgageBalance
      estimatedValue
      estimatedEquity
      skipTraceResults
      pitiData
      assetManagement {
        ...assetManagement
      }
      offerToClose {
        ...offerToClose
      }
    }
  }
  ${AcquisitionFragments.analysis}
  ${AcquisitionFragments.analytics}
  ${AssumptionsFragments.assumptions}
  ${AssetManagementFragments.assetManagement}
  ${OfferToCloseFragments.offerToClose}
`

export const GET_JV_PROPERTIES = gql`
  query jvProperties($jvLenders: [String]!) {
    jvProperties(jvLenders: $jvLenders) {
      _id
      estval
      comments
      address
      city
      county
      state
      zip
      beds
      baths
      sqft
      strategy
      marketplaceStrategy
      strategies
      hideAddress
      hideDaysOnMarketplace
      status
      createdAt
      updatedAt
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      favorite
      owned
      propertyType
      propertyUnitAmount
      propertyDashboard
      marketplaces {
        marketplaceName
        approvedMarketplace
        expirationDate
        extraTags
        proformaType
      }
      marketplaceSubmittedBy {
        email
        profile {
          firstname
          lastname
        }
      }
      mainImage
      compCount
      lot {
        _id
        owned
        status
      }
      description
      proformaDescription
      proformaImages
      proformaImagePage
      proformaLogo
      proformaRehabCost
      proformaHideAddress
      proformaContact
      financingProformaSent
      applyForLending
      proformaFile {
        filename
        filetype
        filesize
        url
        url_small
        createdBy
        createdAt
      }
      realeflowReportURL
      deletedAt
      zpid
      portfolio {
        _id
        name
        strategies
        strategy
        organization {
          _id
          name
          email
          phone
          logo {
            url
            url_small
          }
          legalDescription
          CMALegalDescription
          member
        }
        assumptions {
          ...assumptions
        }
      }
      createdBy {
        email
        profile {
          firstname
          lastname
        }
      }
      assumptions
      acquisition {
        activeStage
        analysis {
          ...analysis
        }
        analytics {
          ...analytics
        }
      }
      source
      estimates
      MLSID
      MLSAgent {
        MLSID
        brokerage
        name
        phone
        email
      }
      lender
      lenderStatus
      lenderTimeline
      lenderSubmittedBy {
        email
        profile {
          firstname
          lastname
        }
      }
      jv
      jvStatus
      jvTimeline
      jvSubmittedBy {
        email
        profile {
          firstname
          lastname
        }
      }
      sewer
      water
      exchange
      owned
      market
      type
      plan
      zone
      parcelID
      lotSize
      basementSqft
      remodelYear
      parking
      ac
      homeType
      floodZone
      checklists {
        underwritingChecklist
        offerToCloseChecklist
        wholesaleChecklist
        lotChecklist
        buildChecklist
        saleChecklist
        fixFlipChecklist
        affiliateChecklist
        BORChecklist
        astroFlipChecklist
        customChecklist
      }
      otherFiles
      marketplaceFiles
      videoWalkthrough
      kanbanProcessStageId
      kanbanProcessStageState
      southernImpressions
      jvFile
      budgetItems {
        name
        budget
        budgetItemProjectedStartDate
        budgetItemProjectedEndDate
        budgetItemActualStartDate
        budgetItemActualEndDate
        lines {
          name
          amount
        }
        files
      }
      subdivision
      customFields
      houseCanaryPropertyExplorerLink
      ownership
      apn
      linkedProperties
      lien
      mortgageBalance
      estimatedValue
      estimatedEquity
      skipTraceResults
      pitiData
      assetManagement {
        ...assetManagement
      }
      offerToClose {
        ...offerToClose
      }
    }
  }
  ${AcquisitionFragments.analysis}
  ${AcquisitionFragments.analytics}
  ${AssumptionsFragments.assumptions}
  ${AssetManagementFragments.assetManagement}
  ${OfferToCloseFragments.offerToClose}
`

export const GET_SHARED_PROPERTIES = gql`
  query sharedProperties {
    sharedProperties {
      _id
      estval
      comments
      address
      city
      county
      state
      zip
      beds
      baths
      sqft
      strategy
      marketplaceStrategy
      strategies
      hideAddress
      hideDaysOnMarketplace
      status
      createdAt
      updatedAt
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      favorite
      owned
      propertyType
      propertyUnitAmount
      propertyDashboard
      marketplaces {
        marketplaceName
        approvedMarketplace
        expirationDate
        extraTags
        proformaType
      }
      marketplaceSubmittedBy {
        email
        profile {
          firstname
          lastname
        }
      }
      mainImage
      compCount
      lot {
        _id
        owned
        status
      }
      description
      notes
      proformaDescription
      proformaImages
      proformaImagePage
      proformaLogo
      proformaFloorPlan
      proformaRehabCost
      proformaHideAddress
      proformaContact
      financingProformaSent
      applyForLending
      proformaFile {
        filename
        filetype
        filesize
        url
        url_small
      }
      realeflowReportURL
      deletedAt
      zpid
      portfolio {
        _id
        name
        strategies
        strategy
        organization {
          _id
          name
          email
          phone
          logo {
            url
            url_small
          }
          legalDescription
          CMALegalDescription
          member
        }
        assumptions {
          ...assumptions
        }
      }
      createdBy {
        email
        profile {
          firstname
          lastname
        }
      }
      assumptions
      acquisition {
        activeStage
        analysis {
          ...analysis
        }
        analytics {
          ...analytics
        }
      }
      source
      estimates
      MLSID
      MLSAgent {
        MLSID
        brokerage
        name
        phone
        email
      }
      lender
      lenderStatus
      lenderTimeline
      lenderSubmittedBy {
        email
        profile {
          firstname
          lastname
        }
      }
      jv
      jvStatus
      jvTimeline
      jvSubmittedBy {
        email
        profile {
          firstname
          lastname
        }
      }
      sewer
      water
      exchange
      owned
      market
      type
      plan
      zone
      parcelID
      lotSize
      basementSqft
      remodelYear
      parking
      ac
      homeType
      floodZone
      checklists {
        underwritingChecklist
        offerToCloseChecklist
        wholesaleChecklist
        lotChecklist
        buildChecklist
        saleChecklist
        fixFlipChecklist
        affiliateChecklist
        BORChecklist
        astroFlipChecklist
        customChecklist
      }
      otherFiles
      marketplaceFiles
      videoWalkthrough
      kanbanProcessStageId
      kanbanProcessStageState
      southernImpressions
      jvFile
      budgetItems {
        name
        budget
        budgetItemProjectedStartDate
        budgetItemProjectedEndDate
        budgetItemActualStartDate
        budgetItemActualEndDate
        lines {
          name
          amount
        }
        files
      }
      subdivision
      customFields
      houseCanaryPropertyExplorerLink
      ownership
      apn
      linkedProperties
      lien
      mortgageBalance
      estimatedValue
      estimatedEquity
      skipTraceResults
      pitiData
      assetManagement {
        ...assetManagement
      }
      offerToClose {
        ...offerToClose
      }
    }
  }
  ${AcquisitionFragments.analysis}
  ${AcquisitionFragments.analytics}
  ${AssumptionsFragments.assumptions}
  ${AssetManagementFragments.assetManagement}
  ${OfferToCloseFragments.offerToClose}
`

export const GET_WORKFLOWS = gql`
  query workflows {
    workflows {
      name
      label
      label2
      sideIcon
      member
      views
      toggle
      checklist
      complex
      workflow
      pinned
    }
  }
`

export const GET_CARDS = gql`
  query cards($view: String) {
    cards(view: $view) {
      name
      member
      views
      card
    }
  }
`
