import React from 'react'
import { useNavigate } from 'react-router'

const Terms = (props) => {
  let member =
    props?.affiliate === 'pep'
      ? 'Property Edge Pro'
      : props?.affiliate === 'pacemorby' ||
          props?.affiliate === 'astroflip'
        ? 'Dealsauce'
        : 'Housefolios'

  if (member === 'Dealsauce') {
    window.location.href =
      'https://www.dealsauce.io/terms-of-service/'
  }

  return (
    <section
      className="section"
      style={{
        backgroundColor: '#FFFFFF',
        padding: '40px 0', // Vertical padding for the section
      }}
    >
      <div
        className="container"
        style={{
          maxWidth: '800px', // Limit container width
          margin: '0 auto', // Center container
          padding: '0 20px', // Horizontal padding for small screens
        }}
      >
        <div className="row">
          <div
            className="box-lg"
            style={{
              padding: '20px',
              backgroundColor: '#f9f9f9', // Light background for content area
              borderRadius: '8px', // Rounded corners
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)', // Subtle shadow
            }}
          >
            <h1 style={{ marginBottom: '10px' }}>
              Housefolios Terms of Service
            </h1>
            <p style={{ marginBottom: '20px' }}>
              <i>Last Updated: April 26, 2021</i>
            </p>
            <p style={{ lineHeight: '1.6', marginBottom: '20px' }}>
              Welcome, and thank you for your interest in Housefolios
              Inc. (“Housefolios,” “we,” or “us”) and our website at
              www.housefolios.com, along with our related websites,
              networks, applications, and other services provided by
              us (collectively, the “Service”). These Terms of Service
              are a legally binding contract between you and
              Housefolios regarding your use of the Service.
            </p>
            <p style={{ fontWeight: 'bold', marginBottom: '20px' }}>
              PLEASE READ THE FOLLOWING TERMS CAREFULLY.
            </p>
            <p style={{ lineHeight: '1.6', marginBottom: '20px' }}>
              <strong>
                BY CLICKING “I ACCEPT,” OR OTHERWISE ACCESSING OR
                USING THE SERVICE,
              </strong>{' '}
              YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A
              CONDITION TO YOUR USE OF THE SERVICE, YOU AGREE TO BE
              BOUND BY, THE FOLLOWING TERMS AND CONDITIONS, INCLUDING
              HOUSEFOLIOS’ PRIVACY POLICY (TOGETHER, THESE
              <strong>“TERMS”</strong>). IF YOU ARE NOT ELIGIBLE, OR
              DO NOT AGREE TO THE TERMS, THEN YOU DO NOT HAVE OUR
              PERMISSION TO USE THE SERVICE. YOUR USE OF THE SERVICE,
              AND HOUSEFOLIOS’ PROVISION OF THE SERVICE TO YOU,
              CONSTITUTES AN AGREEMENT BY HOUSEFOLIOS AND BY YOU TO BE
              BOUND BY THESE TERMS.
            </p>
            <p style={{ lineHeight: '1.6', marginBottom: '20px' }}>
              <strong>ARBITRATION NOTICE:</strong> Except for certain
              kinds of disputes described in Section 17, you agree
              that disputes arising under these Terms will be resolved
              by binding, individual arbitration, and BY ACCEPTING
              THESE TERMS, YOU AND HOUSEFOLIOS ARE EACH WAIVING THE
              RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS
              ACTION OR REPRESENTATIVE PROCEEDING. YOU AGREE TO GIVE
              UP YOUR RIGHT TO GO TO COURT to assert or defend your
              rights under this contract (except for matters that may
              be taken to small claims court). Your rights will be
              determined by a NEUTRAL ARBITRATOR and NOT a judge or
              jury. (See Section 17.)
            </p>

            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              1. Housefolios Service Overview.
            </p>
            <p style={{ lineHeight: '1.6', marginBottom: '20px' }}>
              The Service connects users with real estate, real estate
              investing resources, and related service providers on a
              single platform to simplify the way real estate
              investors analyze, buy, hold, and sell real estate.
            </p>

            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              2. Eligibility.
            </p>
            <p style={{ lineHeight: '1.6', marginBottom: '20px' }}>
              You must be at least 18 years old to use the Service. By
              agreeing to these Terms, you represent and warrant to us
              that: (a) you are at least 18 years old; (b) you have
              not previously been suspended or removed from the
              Service; and (c) your registration and your use of the
              Service is in compliance with any and all applicable
              laws and regulations. If you are an entity,
              organization, or company, the individual accepting these
              Terms on your behalf represents and warrants that they
              have authority to bind you to these Terms, and you agree
              to be bound by these Terms.
            </p>

            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              3. Accounts and Registration.
            </p>
            <p style={{ lineHeight: '1.6', marginBottom: '20px' }}>
              To access most features of the Service, you must
              register for an account. When you register for an
              account, you may be required to provide us with some
              information about yourself, such as your first name,
              last name, email address, phone number, organization, or
              other contact information. You agree that the
              information you provide to us is accurate and that you
              will keep it accurate and up-to-date at all times. When
              you register, you will be asked to provide a password.
              You are solely responsible for maintaining the
              confidentiality of your account and password, and you
              accept responsibility for all activities that occur
              under your account. If you believe that your account is
              no longer secure, then you must immediately notify us at
              hi@housefolios.com.
            </p>

            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              4. Limitations and Restrictions on Use of Our Service:
            </p>
            <ul
              style={{
                paddingLeft: '20px',
                marginBottom: '20px',
                lineHeight: '1.6',
              }}
            >
              <li>
                <strong>1. Analyses and Estimates:</strong> Any
                analyses and data we provide as part of our Service
                are for informational purposes only, and you should
                not construe the information or other material
                provided through the Service (collectively,
                “Housefolios Content”) as legal, tax, investment,
                financial, or other advice. Housefolios is not
                registered as an investment adviser with the
                Securities and Exchange Commission or any other
                governmental or regulatory authority, and does not
                intend to register as an investment adviser. You alone
                assume the sole responsibility of evaluating the
                merits and risks associated with the use of
                Housefolios Content on the Service before making any
                investment decisions based on the Housefolios Content.
                In exchange for using the Service, you agree not to
                hold Housefolios or its affiliates liable for any
                possible claim for damages arising from or relating to
                any decision you make based on the Housefolios Content
                made available to you through the Service.
              </li>
              <li>
                <strong>2. Third Party Services and Offers:</strong>{' '}
                Housefolios may provide tools through the Service that
                enable you to import and export information, including
                User Content (as defined in Section 9.1), to third
                party services, including through features that allow
                you to link your account on Housefolios with an
                account of a third party service. By using one of
                these tools, you agree that Housefolios may transfer
                that information to the applicable third party
                service. Third party services are not under
                Housefolios’ control, and, to the fullest extent
                permitted by law, Housefolios is not responsible for
                any third party service’s use of your exported
                information. Your interactions and business dealings
                with third party services providers, including
                products or services offered by such third parties,
                are solely between you and the third party. You should
                review all of the relevant terms and conditions
                associated with the third party services, including
                any privacy policies and terms of service. If you
                enter into any transactions with any third party
                services featured on the Service, Housefolios may be
                entitled to a commission fee in connection with such
                transaction.
              </li>
              <li>
                <strong>3. Linked Websites:</strong> The Service may
                also contain links to third party websites. Linked
                websites are not under Housefolios’ control, and
                Housefolios is not responsible for their content. We
                do not control, maintain, or endorse the services
                offered by such third parties, and we are not
                responsible or liable for any services offered by such
                third parties, including any damages, losses,
                failures, or problems caused by, related to, or
                arising from any third party services.
              </li>
            </ul>

            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              5. General Payment Terms:
            </p>
            <p style={{ lineHeight: '1.6', marginBottom: '20px' }}>
              Certain features of the Service may require you to pay
              fees. Before you pay any fees, you will have an
              opportunity to review and accept the fees that you will
              be charged. All fees are in U.S. Dollars and are
              non-refundable.
            </p>
            <ul
              style={{
                paddingLeft: '20px',
                marginBottom: '20px',
                lineHeight: '1.6',
              }}
            >
              <li>
                <strong>1. Price:</strong> Housefolios reserves the
                right to determine pricing for the Service.
                Housefolios will make reasonable efforts to keep
                pricing information published on the website up to
                date. We encourage you to check our website
                periodically for current pricing information.
                Housefolios may change the fees for any feature of the
                Service, including additional fees or charges, if
                Housefolios gives you advance notice of changes before
                they apply. Housefolios, at its sole discretion, may
                make promotional offers with different features and
                different pricing to any of Housefolios’ customers.
                These promotional offers, unless made to you, will not
                apply to your offer or these Terms.
              </li>
              <li>
                <strong>2. Authorization:</strong> You authorize
                Housefolios to charge all sums for the orders that you
                make and any level of Service you select as described
                in these Terms or published by Housefolios, including
                all applicable taxes, to the payment method specified
                in your account. If you pay any fees with a credit
                card, Housefolios may seek pre-authorization of your
                credit card account prior to your purchase to verify
                that the credit card is valid and has the necessary
                funds or credit available to cover your purchase.
              </li>
              <li>
                <strong>3. Subscription Service:</strong> THE SERVICE
                MAY INCLUDE AUTOMATICALLY RECURRING PAYMENTS FOR
                PERIODIC CHARGES (“SUBSCRIPTION SERVICE”). IF YOU
                ACTIVATE A SUBSCRIPTION SERVICE, YOU AUTHORIZE
                HOUSEFOLIOS TO PERIODICALLY CHARGE, ON A GOING-FORWARD
                BASIS AND UNTIL CANCELLATION OF EITHER THE RECURRING
                PAYMENTS OR YOUR ACCOUNT, ALL ACCRUED SUMS ON OR
                BEFORE THE PAYMENT DUE DATE FOR THE ACCRUED SUMS. THE
                “SUBSCRIPTION BILLING DATE” IS THE DATE WHEN YOU
                PURCHASE YOUR FIRST SUBSCRIPTION TO THE SERVICE. For
                information on the “Subscription Fee”, please see our
                Pricing page available at
                https://www.www.housefolios.com/pricing. YOUR ACCOUNT
                WILL BE CHARGED AUTOMATICALLY ON THE SUBSCRIPTION
                BILLING DATE ALL APPLICABLE FEES AND TAXES FOR THE
                NEXT SUBSCRIPTION PERIOD. THE SUBSCRIPTION WILL
                CONTINUE UNLESS AND UNTIL YOU CANCEL YOUR SUBSCRIPTION
                OR WE TERMINATE IT. WE WILL BILL THE PERIODIC
                SUBSCRIPTION FEE TO THE PAYMENT METHOD YOU PROVIDE TO
                US DURING REGISTRATION (OR TO A DIFFERENT PAYMENT
                METHOD IF YOU CHANGE YOUR PAYMENT INFORMATION). YOU
                MUST CANCEL YOUR SUBSCRIPTION BEFORE IT RENEWS IN
                ORDER TO AVOID BILLING OF THE NEXT PERIODIC
                SUBSCRIPTION FEE TO YOUR ACCOUNT. YOU MAY CANCEL THE
                SUBSCRIPTION SERVICE IN THE BILLING SECTION OF YOUR
                ACCOUNT SETTINGS. IF YOU CANCEL YOUR SUBSCRIPTION,
                THEN YOUR ACCESS TO THE SUBSCRIPTION SERVICE WILL END
                AT THE END OF THE THEN-CURRENT SUBSCRIPTION TERM.
              </li>
              <li>
                <strong>4. Delinquent Accounts:</strong> Housefolios
                may suspend or terminate access to the Service,
                including fee-based portions of the Service, for any
                account for which any amount is due but unpaid. In
                addition to the amount due for the Service, a
                delinquent account will be charged with fees or
                charges that are incidental to any chargeback or
                collection of any the unpaid amount, including
                collection fees.
              </li>
            </ul>

            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              6. Licenses:
            </p>
            <ul
              style={{
                paddingLeft: '20px',
                marginBottom: '20px',
                lineHeight: '1.6',
              }}
            >
              <li>
                <strong>1. Limited License:</strong>Subject to your
                complete and ongoing compliance with these Terms,
                Housefolios grants you, solely for your personal use,
                a limited, non-exclusive, non-transferable,
                non-sublicensable, revocable license to access and use
                the Service.
              </li>
              <li>
                <strong>2. License Restrictions:</strong> Except and
                solely to the extent such a restriction is
                impermissible under applicable law, you may not: (a)
                reproduce, distribute, publicly display, or publicly
                perform the Service; (b) make modifications to the
                Service; or (c) interfere with or circumvent any
                feature of the Service, including any security or
                access control mechanism. If you are prohibited under
                applicable law from using the Service, you may not use
                it.
              </li>
              <li>
                <strong>3. Feedback:</strong> If you choose to provide
                input and suggestions regarding problems with or
                proposed modifications or improvements to the Service
                (“Feedback”), then you hereby grant Housefolios an
                unrestricted, perpetual, irrevocable, non-exclusive,
                fully-paid, royalty-free right to exploit the Feedback
                in any manner and for any purpose, including to
                improve the Service and create other products and
                services.
              </li>
            </ul>
            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              7. Ownership:
            </p>
            <p style={{ lineHeight: '1.6', marginBottom: '20px' }}>
              Proprietary Rights. The Service is owned and operated by
              Housefolios. The Housefolios Content, visual interfaces,
              graphics, design, compilation, information, data,
              computer code (including source code or object code),
              products, software, services, and all other elements of
              the Service (“Materials”) provided by Housefolios are
              protected by intellectual property and other laws. All
              Materials included in the Service are the property of
              Housefolios or its third party licensors. Except as
              expressly authorized by Housefolios, you may not make
              use of the Materials. Housefolios reserves all rights to
              the Materials not granted expressly in these Terms.
            </p>
            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              8. Third Party Software:
            </p>
            <p style={{ lineHeight: '1.6', marginBottom: '20px' }}>
              The Service may include or incorporate third party
              software components that are generally available free of
              charge under licenses granting recipients broad rights
              to copy, modify, and distribute those components (“Third
              Party Components”). Although the Service is provided to
              you subject to these Terms, nothing in these Terms
              prevents, restricts, or is intended to prevent or
              restrict you from obtaining Third Party Components under
              the applicable third party licenses or to limit your use
              of Third Party Components under those third party
              licenses.
            </p>
            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              9. User Content:
            </p>
            <ul
              style={{
                paddingLeft: '20px',
                marginBottom: '20px',
                lineHeight: '1.6',
              }}
            >
              <li>
                <strong>1. User Content Generally:</strong>Certain
                features of the Service may permit users to upload
                content to the Service, including messages, documents,
                information from or regarding third party service
                providers, contact lists, photos, images, folders,
                data, text, and other types of works (“User Content”)
                and to publish User Content on the Service. You retain
                any copyright and other proprietary rights that you
                may hold in the User Content that you post to the
                Service.
              </li>
              <li>
                <strong>
                  2. Limited License Grant to Housefolios:
                </strong>{' '}
                By providing User Content to or via the Service, you
                grant Housefolios a worldwide, non-exclusive,
                irrevocable, royalty-free, fully paid right and
                license (with the right to sublicense) to host, store,
                transfer, display, perform, reproduce, modify for the
                purpose of formatting for display, and distribute your
                User Content, in whole or in part, in any media
                formats and through any media channels now known or
                hereafter developed.
              </li>
              <li>
                <strong>
                  3. User Content Representations and Warranties:
                </strong>{' '}
                Housefolios disclaims any and all liability in
                connection with User Content. You are solely
                responsible for your User Content and the consequences
                of providing User Content via the Service. By
                providing User Content via the Service, you affirm,
                represent, and warrant that:
              </li>
              <li>
                <ul
                  style={{
                    paddingLeft: '20px',
                    marginBottom: '20px',
                    lineHeight: '1.6',
                  }}
                >
                  <li>
                    1. you are the creator and owner of the User
                    Content, or have the necessary licenses, rights,
                    consents, and permissions to authorize Housefolios
                    and users of the Service to use and distribute
                    your User Content as necessary to exercise the
                    licenses granted by you in this Section, in the
                    manner contemplated by Housefolios, the Service,
                    and these Terms;
                  </li>
                  <li>
                    2. your User Content, and the use of your User
                    Content as contemplated by these Terms, does not
                    and will not: (i) infringe, violate, or
                    misappropriate any third party right, including
                    any copyright, trademark, patent, trade secret,
                    moral right, privacy right, right of publicity, or
                    any other intellectual property or proprietary
                    right; (ii) slander, defame, libel, or invade the
                    right of privacy, publicity or other property
                    rights of any other person; or (iii) cause
                    Housefolios to violate any law or regulation; and
                  </li>
                  <li>
                    3. your User Content could not be deemed by a
                    reasonable person to be objectionable, profane,
                    indecent, pornographic, harassing, threatening,
                    embarrassing, hateful, or otherwise inappropriate.
                  </li>
                </ul>
              </li>
              <li>
                <strong>4. User Content Disclaimer:</strong> We are
                under no obligation to edit or control User Content
                that you or other users post or publish, and will not
                be in any way responsible or liable for User Content.
                Housefolios may, however, at any time and without
                prior notice, screen, remove, edit, or block any User
                Content that in our sole judgment violates these Terms
                or is otherwise objectionable. You understand that
                when using the Service you will be exposed to User
                Content from a variety of sources and acknowledge that
                User Content may be inaccurate, offensive, indecent,
                or objectionable. You agree to waive, and do waive,
                any legal or equitable right or remedy you have or may
                have against Housefolios with respect to User Content.
                If notified by a user or content owner that User
                Content allegedly does not conform to these Terms, we
                may investigate the allegation and determine in our
                sole discretion whether to remove the User Content,
                which we reserve the right to do at any time and
                without notice. For clarity, Housefolios does not
                permit copyright-infringing activities on the Service.
              </li>
              <li>
                <strong>5. Monitoring Content:</strong> Housefolios
                does not control and does not have any obligation to
                monitor: (a) User Content; (b) any content made
                available by third parties; or (c) the use of the
                Service by its users. You acknowledge and agree that
                Housefolios reserves the right to, and may from time
                to time, monitor any and all information transmitted
                or received through the Service for operational and
                other purposes. If at any time Housefolios chooses to
                monitor the content, Housefolios still assumes no
                responsibility or liability for content or any loss or
                damage incurred as a result of the use of content.
                During monitoring, information may be examined,
                recorded, copied, and used in accordance with our
                Privacy Policy.
              </li>
            </ul>
            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              10. Prohibited Conduct
            </p>
            <ul
              style={{
                paddingLeft: '20px',
                marginBottom: '20px',
                lineHeight: '1.6',
              }}
            >
              <li>
                1. Use the Service for any illegal purpose or in
                violation of any local, state, national, or
                international law.
              </li>
              <li>
                2. Violate, or encourage others to violate, any right
                of a third party, including by infringing or
                misappropriating any third-party intellectual property
                right.
              </li>
              <li>
                3. Interfere with security-related features of the
                Service, including by:
                <ul
                  style={{
                    paddingLeft: '20px',
                    marginTop: '10px',
                    lineHeight: '1.6',
                  }}
                >
                  <li>
                    (i) Disabling or circumventing features that
                    prevent or limit use or copying of any content.
                  </li>
                  <li>
                    (ii) Reverse engineering or otherwise attempting
                    to discover the source code of any portion of the
                    Service except to the extent that the activity is
                    expressly permitted by applicable law.
                  </li>
                </ul>
              </li>
              <li>
                4. Interfere with the operation of the Service or any
                user’s enjoyment of the Service, including by:
                <ul
                  style={{
                    paddingLeft: '20px',
                    marginTop: '10px',
                    lineHeight: '1.6',
                  }}
                >
                  <li>
                    (i) Uploading or otherwise disseminating any
                    virus, adware, spyware, worm, or other malicious
                    code.
                  </li>
                  <li>
                    (ii) Making any unsolicited offer or advertisement
                    to another user of the Service.
                  </li>
                  <li>
                    (iii) Collecting personal information about
                    another user or third party without consent.
                  </li>
                  <li>
                    (iv) Interfering with or disrupting any network,
                    equipment, or server connected to or used to
                    provide the Service.
                  </li>
                </ul>
              </li>
              <li>
                5. Perform any fraudulent activity, including
                impersonating any person or entity, claiming a false
                affiliation, or accessing any other Service account
                without permission.
              </li>
              <li>
                6. Sell or otherwise transfer the access granted under
                these Terms or any Materials or any right or ability
                to view, access, or use any Materials; or
              </li>
              <li>
                7. Attempt to do any of the acts described in this
                Section 10 or assist or permit any person in engaging
                in any of the acts described in this Section 10.
              </li>
            </ul>
            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              11. Digital Millennium Copyright Act
            </p>
            <ul
              style={{
                paddingLeft: '20px',
                marginBottom: '20px',
                lineHeight: '1.6',
              }}
            >
              <li>
                1. <strong>DMCA Notification:</strong> We comply with
                the provisions of the Digital Millennium Copyright Act
                applicable to Internet service providers (17 U.S.C.
                §512, as amended). If you have an intellectual
                property rights-related complaint about material
                posted on the Service, you may contact our Designated
                Agent at the following address:
                <p style={{ marginTop: '10px', lineHeight: '1.6' }}>
                  Housefolios Inc.
                  <br />
                  ATTN: Legal Department (Copyright Notification)
                  <br />
                  1280 S. 800 E., Suite 400
                  <br />
                  Orem, UT 84097
                  <br />
                  (801) 200-3208
                  <br />
                  Email: copyright@housefolios.com
                </p>
                <p>
                  Any notice alleging that materials hosted by or
                  distributed through the Service infringe
                  intellectual property rights must include the
                  following information:
                </p>
                <ul
                  style={{
                    paddingLeft: '20px',
                    marginTop: '10px',
                    lineHeight: '1.6',
                  }}
                >
                  <li>
                    1. An electronic or physical signature of the
                    person authorized to act on behalf of the owner of
                    the copyright or other right being infringed.
                  </li>
                  <li>
                    2. A description of the copyrighted work or other
                    intellectual property that you claim has been
                    infringed.
                  </li>
                  <li>
                    3. A description of the material that you claim is
                    infringing and where it is located on the Service.
                  </li>
                  <li>
                    4. Your address, telephone number, and email
                    address.
                  </li>
                  <li>
                    5. A statement by you that you have a good faith
                    belief that the use of the materials on the
                    Service of which you are complaining is not
                    authorized by the copyright owner, its agent, or
                    the law. and
                  </li>
                  <li>
                    6. A statement by you that the above information
                    in your notice is accurate and that, under penalty
                    of perjury, you are the copyright or intellectual
                    property owner or authorized to act on the
                    copyright or intellectual property owner’s behalf.
                  </li>
                </ul>
              </li>
              <li>
                2. <strong>Repeat Infringers:</strong> Housefolios
                will promptly terminate the accounts of users that are
                determined by Housefolios to be repeat infringers.
              </li>
              <li>
                3. <strong>Modification of these Terms:</strong> We
                reserve the right to change these Terms on a
                going-forward basis at any time. Please check these
                Terms periodically for changes. If a change to these
                Terms materially modifies your rights or obligations,
                we may require that you accept the modified Terms in
                order to continue to use the Service. Material
                modifications are effective upon your acceptance of
                the modified Terms. Immaterial modifications are
                effective upon publication. Except as expressly
                permitted in this Section 12, these Terms may be
                amended only by a written agreement signed by
                authorized representatives of the parties to these
                Terms. Disputes arising under these Terms will be
                resolved in accordance with the version of these Terms
                that was in effect at the time the dispute arose.
              </li>
            </ul>
            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              12. Term, Termination, and Modification of the Service
            </p>
            <ul
              style={{
                paddingLeft: '20px',
                marginBottom: '20px',
                lineHeight: '1.6',
              }}
            >
              <li>
                1. <strong>Term:</strong> These Terms are effective
                beginning when you accept the Terms or access, or use
                the Service, and ending when terminated as described
                in Section 12.2.
              </li>
              <li>
                2. <strong>Termination:</strong> If you violate any
                provision of these Terms, your authorization to access
                the Service and these Terms automatically terminates.
                In addition, Housefolios may, at its sole discretion,
                terminate these Terms or your account on the Service,
                or suspend or terminate your access to the Service, at
                any time for any reason or no reason, with or without
                notice. You may terminate your account and these Terms
                at any time by contacting customer service at{' '}
                <a href="mailto:hi@housefolios.com">
                  hi@housefolios.com
                </a>
                .
              </li>
              <li>
                3. <strong>Effect of Termination:</strong> Upon
                termination of these Terms:
                <ul
                  style={{
                    paddingLeft: '20px',
                    marginTop: '10px',
                    lineHeight: '1.6',
                  }}
                >
                  <li>
                    a. Your license rights will terminate, and you
                    must immediately cease all use of the Service.
                  </li>
                  <li>
                    b. You will no longer be authorized to access your
                    account or the Service.
                  </li>
                  <li>
                    c. You must pay Housefolios any unpaid amount that
                    was due prior to termination.
                  </li>
                  <li>
                    d. All payment obligations accrued prior to
                    termination and Sections 4, 5, 6.2, 6.3, 7, 8, 9,
                    10, 11, 13.3, 14, 15, 16, 17, and 18 will survive.
                  </li>
                </ul>
              </li>
              <li>
                4. <strong>Modification of the Service:</strong>{' '}
                Housefolios reserves the right to modify or
                discontinue the Service at any time (including by
                limiting or discontinuing certain features of the
                Service), temporarily or permanently, without notice
                to you. Housefolios will have no liability for any
                change to the Service or any suspension or termination
                of your access to or use of the Service.
              </li>
            </ul>
            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              13. Indemnity
            </p>
            <p style={{ lineHeight: '1.6', marginBottom: '20px' }}>
              To the fullest extent permitted by law, you are
              responsible for your use of the Service, and you will
              defend and indemnify Housefolios and its officers,
              directors, employees, consultants, affiliates,
              subsidiaries, and agents (together, the “Housefolios
              Entities”) from and against every claim brought by a
              third party, and any related liability, damage, loss,
              and expense, including reasonable attorneys’ fees and
              costs, arising out of or connected with:
            </p>
            <ul
              style={{
                paddingLeft: '20px',
                marginBottom: '20px',
                lineHeight: '1.6',
              }}
            >
              <li>
                a. Your unauthorized use of, or misuse of, the
                Service.
              </li>
              <li>
                b. Your violation of any portion of these Terms, any
                representation, warranty, or agreement referenced in
                these Terms, or any applicable law or regulation.
              </li>
              <li>
                c. Your violation of any third-party right, including
                any intellectual property right or privacy right.
              </li>
              <li>
                d. Any dispute or issue between you and any third
                party.
              </li>
            </ul>
            <p style={{ lineHeight: '1.6' }}>
              We reserve the right, at our own expense, to assume the
              exclusive defense and control of any matter otherwise
              subject to indemnification by you, and in that case, you
              agree to cooperate with our defense of those claims.
            </p>
            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              14. Disclaimers; No Warranties
            </p>
            <ul
              style={{
                paddingLeft: '20px',
                marginBottom: '20px',
                lineHeight: '1.6',
              }}
            >
              <li>
                NONE OF THE HOUSEFOLIOS ENTITIES MAKES ANY
                REPRESENTATIONS REGARDING THE LIKELIHOOD OR
                PROBABILITY THAT ANY INFORMATION RECEIVED THROUGH THE
                SERVICE WILL ACHIEVE A PARTICULAR INVESTMENT OUTCOME
                OR GOAL. PAST PERFORMANCE IS NOT A GUARANTEE OF FUTURE
                SUCCESS, AND VOLATILITY MEANS THAT RETURNS IN ANY
                PERIOD MAY BE FAR ABOVE OR BELOW THOSE OF PREVIOUS
                PERIODS. YOU FURTHER ACKNOWLEDGE AND AGREE THAT NONE
                OF THE HOUSEFOLIOS ENTITIES MAKES ANY GUARANTEES OR
                OTHER COMMITMENTS ABOUT YOUR ABILITY TO ACCESS OR USE
                THE SERVICE.
              </li>
              <li>
                THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE
                THROUGH THE SERVICE ARE PROVIDED “AS IS” AND ON AN “AS
                AVAILABLE” BASIS. HOUSEFOLIOS DISCLAIMS ALL WARRANTIES
                OF ANY KIND, WHETHER EXPRESS OR IMPLIED, RELATING TO
                THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE
                THROUGH THE SERVICE, INCLUDING: (A) ANY IMPLIED
                WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT;
                AND (B) ANY WARRANTY ARISING OUT OF COURSE OF DEALING,
                USAGE, OR TRADE. HOUSEFOLIOS DOES NOT WARRANT THAT THE
                SERVICE OR ANY PORTION OF THE SERVICE, OR ANY
                MATERIALS OR CONTENT OFFERED THROUGH THE SERVICE, WILL
                BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES,
                OR OTHER HARMFUL COMPONENTS, AND HOUSEFOLIOS DOES NOT
                WARRANT THAT ANY OF THOSE ISSUES WILL BE CORRECTED.
              </li>
              <li>
                NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
                OBTAINED BY YOU FROM THE SERVICE OR HOUSEFOLIOS
                ENTITIES OR ANY MATERIALS OR CONTENT AVAILABLE THROUGH
                THE SERVICE WILL CREATE ANY WARRANTY REGARDING ANY OF
                THE HOUSEFOLIOS ENTITIES OR THE SERVICE THAT IS NOT
                EXPRESSLY STATED IN THESE TERMS. WE ARE NOT
                RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT FROM THE
                SERVICE AND YOUR DEALING WITH ANY OTHER SERVICE USER.
                YOU UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF
                THE SERVICE AT YOUR OWN DISCRETION AND RISK, AND THAT
                WE ARE NOT RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY
                (INCLUDING YOUR COMPUTER SYSTEM OR MOBILE DEVICE USED
                IN CONNECTION WITH THE SERVICE) OR ANY LOSS OF DATA,
                INCLUDING USER CONTENT.
              </li>
              <li>
                THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS
                SECTION APPLY TO THE FULLEST EXTENT PERMITTED BY LAW.
                Housefolios does not disclaim any warranty or other
                right that Housefolios is prohibited from disclaiming
                under applicable law.
              </li>
            </ul>
            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              15. Limitation of Liability
            </p>
            <ul
              style={{
                paddingLeft: '20px',
                marginBottom: '20px',
                lineHeight: '1.6',
              }}
            >
              <li>
                TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT
                WILL THE HOUSEFOLIOS ENTITIES BE LIABLE TO YOU FOR ANY
                INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
                PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF
                PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS)
                ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE
                OF, OR YOUR INABILITY TO ACCESS OR USE, THE SERVICE OR
                ANY MATERIALS OR CONTENT ON THE SERVICE, WHETHER BASED
                ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE),
                STATUTE, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT
                ANY HOUSEFOLIOS ENTITY HAS BEEN INFORMED OF THE
                POSSIBILITY OF DAMAGE.
              </li>
              <li>
                EXCEPT AS PROVIDED IN SECTION 17.5 AND TO THE FULLEST
                EXTENT PERMITTED BY LAW, THE AGGREGATE LIABILITY OF
                THE HOUSEFOLIOS ENTITIES TO YOU FOR ALL CLAIMS ARISING
                OUT OF OR RELATING TO THE USE OF OR ANY INABILITY TO
                USE ANY PORTION OF THE SERVICE OR OTHERWISE UNDER
                THESE TERMS, WHETHER IN CONTRACT, TORT, OR OTHERWISE,
                IS LIMITED TO THE GREATER OF: (A) THE AMOUNT YOU HAVE
                PAID TO HOUSEFOLIOS FOR ACCESS TO AND USE OF THE
                SERVICE IN THE 12 MONTHS PRIOR TO THE EVENT OR
                CIRCUMSTANCE GIVING RISE TO CLAIM; OR (B) $100.
              </li>
              <li>
                EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A
                LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR
                EXCLUSION OF DAMAGES IS INTENDED TO AND DOES ALLOCATE
                THE RISKS BETWEEN THE PARTIES UNDER THESE TERMS. THIS
                ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE
                BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS
                IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS
                OF THESE TERMS. THE LIMITATIONS IN THIS SECTION 16
                WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS
                ESSENTIAL PURPOSE.
              </li>
            </ul>
            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              16. Dispute Resolution and Arbitration
            </p>
            <ul
              style={{
                paddingLeft: '20px',
                marginBottom: '20px',
                lineHeight: '1.6',
              }}
            >
              <li>
                1. <strong>Generally:</strong> In the interest of
                resolving disputes between you and Housefolios in the
                most expedient and cost-effective manner, and except
                as described in Section 17.2 and 17.3, you and
                Housefolios agree that every dispute arising in
                connection with these Terms will be resolved by
                binding arbitration. Arbitration is less formal than a
                lawsuit in court. Arbitration uses a neutral
                arbitrator instead of a judge or jury, may allow for
                more limited discovery than in court, and can be
                subject to very limited review by courts. Arbitrators
                can award the same damages and relief that a court can
                award. This agreement to arbitrate disputes includes
                all claims arising out of or relating to any aspect of
                these Terms, whether based in contract, tort, statute,
                fraud, misrepresentation, or any other legal theory,
                and regardless of whether a claim arises during or
                after the termination of these Terms. YOU UNDERSTAND
                AND AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND
                HOUSEFOLIOS ARE EACH WAIVING THE RIGHT TO A TRIAL BY
                JURY OR TO PARTICIPATE IN A CLASS ACTION.
              </li>
              <li>
                2. <strong>Exceptions:</strong> Despite the provisions
                of Section 17.1, nothing in these Terms will be deemed
                to waive, preclude, or otherwise limit the right of
                either party to: (a) bring an individual action in
                small claims court; (b) pursue an enforcement action
                through the applicable federal, state, or local agency
                if that action is available; (c) seek injunctive
                relief in a court of law in aid of arbitration; or (d)
                to file suit in a court of law to address an
                intellectual property infringement claim.
              </li>
              <li>
                3. <strong>Opt-Out:</strong> If you do not wish to
                resolve disputes by binding arbitration, you may opt
                out of the provisions of this Section 17 within 30
                days after the date that you agree to these Terms by
                sending a letter to Housefolios Inc., Attention: Legal
                Department – Arbitration Opt-Out, 1280 S. 800 E. Suite
                400, Orem, UT 84097 that specifies: your full legal
                name, the email address associated with your account
                on the Service, and a statement that you wish to opt
                out of arbitration (“Opt-Out Notice”). Once
                Housefolios receives your Opt-Out Notice, this Section
                17 will be void and any action arising out of these
                Terms will be resolved as set forth in Section 18.2.
                The remaining provisions of these Terms will not be
                affected by your Opt-Out Notice.
              </li>
              <li>
                4. <strong>Arbitrator:</strong> Any arbitration
                between you and Housefolios will be settled under the
                Federal Arbitration Act and administered by the
                American Arbitration Association (“AAA”) under its
                Consumer Arbitration Rules (collectively, “AAA Rules”)
                as modified by these Terms. The AAA Rules and filing
                forms are available online at{' '}
                <a href="https://www.adr.org">www.adr.org</a>, by
                calling the AAA at 1-800-778-7879, or by contacting
                Housefolios. The arbitrator has exclusive authority to
                resolve any dispute relating to the interpretation,
                applicability, or enforceability of this binding
                arbitration agreement.
              </li>
              <li>
                5. <strong>Notice of Arbitration; Process:</strong> A
                party who intends to seek arbitration must first send
                a written notice of the dispute to the other party by
                certified U.S. Mail or by Federal Express (signature
                required) or, only if that other party has not
                provided a current physical address, then by
                electronic mail (“Notice of Arbitration”).
                Housefolios’ address for Notice of Arbitration is:
                Housefolios Inc., 1280 S. 800 E. Suite 400, Orem, UT
                84097. The Notice of Arbitration must: (a) describe
                the nature and basis of the claim or dispute; and (b)
                set forth the specific relief sought (“Demand”). The
                parties will make good faith efforts to resolve the
                claim directly, but if the parties do not reach an
                agreement to do so within 30 days after the Notice of
                Arbitration is received, you or Housefolios may
                commence an arbitration proceeding. All arbitration
                proceedings between the parties will be confidential
                unless otherwise agreed by the parties in writing.
                During the arbitration, the amount of any settlement
                offer made by you or Housefolios must not be disclosed
                to the arbitrator until after the arbitrator makes a
                final decision and award, if any. If the arbitrator
                awards you an amount higher than the last written
                settlement amount offered by Housefolios in settlement
                of the dispute prior to the award, Housefolios will
                pay to you the higher of: (i) the amount awarded by
                the arbitrator; or (ii) $10,000.
              </li>
              <li>
                6. <strong>Fees:</strong> If you commence arbitration
                in accordance with these Terms, Housefolios will
                reimburse you for your payment of the filing fee,
                unless your claim is for more than $10,000, in which
                case the payment of any fees will be decided by the
                AAA Rules. Any arbitration hearing will take place at
                a location to be agreed upon in Napa County,
                California, but if the claim is for $10,000 or less,
                you may choose whether the arbitration will be
                conducted: (a) solely on the basis of documents
                submitted to the arbitrator; (b) through a
                non-appearance based telephone hearing; or (c) by an
                in-person hearing as established by the AAA Rules in
                the county (or parish) of your billing address. If the
                arbitrator finds that either the substance of your
                claim or the relief sought in the Demand is frivolous
                or brought for an improper purpose (as measured by the
                standards set forth in Federal Rule of Civil Procedure
                11(b)), then the payment of all fees will be governed
                by the AAA Rules. In that case, you agree to reimburse
                Housefolios for all monies previously disbursed by it
                that are otherwise your obligation to pay under the
                AAA Rules. Regardless of the manner in which the
                arbitration is conducted, the arbitrator must issue a
                reasoned written decision sufficient to explain the
                essential findings and conclusions on which the
                decision and award, if any, are based. The arbitrator
                may make rulings and resolve disputes as to the
                payment and reimbursement of fees or expenses at any
                time during the proceeding and upon request from
                either party made within 14 days of the arbitrator’s
                ruling on the merits.
              </li>
              <li>
                7. <strong>No Class Actions:</strong> YOU AND
                HOUSEFOLIOS AGREE THAT EACH MAY BRING CLAIMS AGAINST
                THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND
                NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
                CLASS OR REPRESENTATIVE PROCEEDING. Further, unless
                both you and Housefolios agree otherwise, the
                arbitrator may not consolidate more than one person’s
                claims, and may not otherwise preside over any form of
                a representative or class proceeding.
              </li>
              <li>
                8.{' '}
                <strong>
                  Modifications to this Arbitration Provision:
                </strong>{' '}
                If Housefolios makes any future change to this
                arbitration provision, other than a change to
                Housefolios’ address for Notice of Arbitration, you
                may reject the change by sending us written notice
                within 30 days of the change to Housefolios’ address
                for Notice of Arbitration, in which case your account
                with Housefolios will be immediately terminated and
                this arbitration provision, as in effect immediately
                prior to the changes you rejected will survive.
              </li>
              <li>
                9. <strong>Enforceability:</strong> If Section 17.7 or
                the entirety of this Section 17 is found to be
                unenforceable, or if Housefolios receives an Opt-Out
                Notice from you, then the entirety of this Section 17
                will be null and void and, in that case, the exclusive
                jurisdiction and venue described in Section 18.2 will
                govern any action arising out of or related to these
                Terms.
              </li>
            </ul>
            <p style={{ fontWeight: 'bold', marginTop: '20px' }}>
              17. Miscellaneous
            </p>
            <ul
              style={{
                paddingLeft: '20px',
                marginBottom: '20px',
                lineHeight: '1.6',
              }}
            >
              <li>
                1. <strong>General Terms:</strong> These Terms,
                together with the Privacy Policy and any other
                agreements expressly incorporated by reference into
                these Terms, are the entire and exclusive
                understanding and agreement between you and
                Housefolios regarding your use of the Service. You may
                not assign or transfer these Terms or your rights
                under these Terms, in whole or in part, by operation
                of law or otherwise, without our prior written
                consent. We may assign these Terms at any time without
                notice or consent. The failure to require performance
                of any provision will not affect our right to require
                performance at any other time after that, nor will a
                waiver by us of any breach or default of these Terms,
                or any provision of these Terms, be a waiver of any
                subsequent breach or default or a waiver of the
                provision itself. Use of section headers in these
                Terms is for convenience only and will not have any
                impact on the interpretation of any provision.
                Throughout these Terms the use of the word “including”
                means “including but not limited to”. If any part of
                these Terms is held to be invalid or unenforceable,
                the unenforceable part will be given effect to the
                greatest extent possible, and the remaining parts will
                remain in full force and effect.
              </li>
              <li>
                2. <strong>Governing Law:</strong> These Terms are
                governed by the laws of the State of California
                without regard to conflict of law principles. You and
                Housefolios submit to the personal and exclusive
                jurisdiction of the state courts and federal courts
                located within Napa County, California for resolution
                of any lawsuit or court proceeding permitted under
                these Terms. We operate the Service from our offices
                in California, and we make no representation that
                Materials included in the Service are appropriate or
                available for use in other locations.
              </li>
              <li>
                3. <strong>Privacy Policy:</strong> Please read the
                Housefolios Privacy Policy carefully for information
                relating to our collection, use, storage, disclosure
                of your personal information. The Housefolios Privacy
                Policy is incorporated by this reference into, and
                made a part of, these Terms. You can access these
                terms at app.housefolios.com/Terms
              </li>
              <li>
                4. <strong>Additional Terms:</strong> Your use of the
                Service is subject to all additional terms, policies,
                rules, or guidelines applicable to the Service or
                certain features of the Service that we may post on or
                link to from the Service (the “Additional Terms”). All
                Additional Terms are incorporated by this reference
                into, and made a part of, these Terms.
              </li>
              <li>
                5.{' '}
                <strong>Consent to Electronic Communications:</strong>{' '}
                By using the Service, you consent to receiving certain
                electronic communications from us as further described
                in our Privacy Policy. Please read our Privacy Policy
                to learn more about our electronic communications
                practices. You agree that any notices, agreements,
                disclosures, or other communications that we send to
                you electronically will satisfy any legal
                communication requirements, including that those
                communications be in writing.
              </li>
              <li>
                6. <strong>Contact Information:</strong> The Service
                is offered by Housefolios Inc., located at 1280 S. 800
                E. Suite 400, Orem, UT 84097. You may contact us by
                sending correspondence to that address or by emailing
                us at hi@housefolios.com. You can access a copy of
                these Terms by clicking here:
                app.housefolios.com/Terms
              </li>
              <li>
                7. <strong>Notice to California Residents:</strong> If
                you are a California resident, under California Civil
                Code Section 1789.3, you may contact the Complaint
                Assistance Unit of the Division of Consumer Services
                of the California Department of Consumer Affairs in
                writing at 1625 N. Market Blvd., Suite S-202,
                Sacramento, California 95834, or by telephone at (800)
                952-5210 in order to resolve a complaint regarding the
                Service or to receive further information regarding
                use of the Service.
              </li>
              <li>
                8. <strong>No Support:</strong> We are under no
                obligation to provide support for the Service. In
                instances where we may offer support, the support will
                be subject to published policies.
              </li>
              <li>
                9. <strong>International Use:</strong> The Service is
                intended for visitors located within the United
                States. We make no representation that the Service is
                appropriate or available for use outside of the United
                States. Access to the Service from countries or
                territories or by individuals where such access is
                illegal is prohibited.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Terms
