/**
 * @ Author: Housefolios
 * @ Create Time: 2021-12-01 15:08:58
 * @ Modified by: David Helmick
 * @ Modified time: 2025-04-08 18:06:29
 * @ Description: Fix & Flip Strategy Analysis page
 */

import React, { useEffect, Suspense, lazy, useState } from 'react'
import { useQuery } from '@apollo/client'
import Reactour from 'reactour'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CountUp from 'react-countup'
import filter from 'lodash/filter'
import map from 'lodash/map'
import maxBy from 'lodash/maxBy'
import meanBy from 'lodash/meanBy'
import minBy from 'lodash/minBy'
import round from 'lodash/round'
import sum from 'lodash/sum'
import isUndefined from 'lodash/isUndefined'
import concat from 'lodash/concat'
import every from 'lodash/every'
import difference from 'lodash/difference'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import slice from 'lodash/slice'
import replace from 'lodash/replace'
import includes from 'lodash/includes'
import compact from 'lodash/compact'
import max from 'lodash/max'
import uniq from 'lodash/uniq'
const Chart = lazy(() => import('react-apexcharts'))

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormGroup,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  LinearProgress,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  Drawer,
  IconButton,
  FormControl,
  Select,
} from '@mui/material'

import { arrayMoveImmutable } from 'array-move'
import {
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc'

import ContactAgent from '../../../Marketplace/ContactAgent'
import GaugeChart from 'react-gauge-chart'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { Mutation } from '@apollo/client/react/components'

import PropertyPDF from '../../Proforma/pdf'
import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'
import { useMutation } from '@apollo/client'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import { Close, CollectionsOutlined } from '@mui/icons-material'
import withSession from '@/housefolios-components/Session/withSession'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { enqueueSnackbar } from 'notistack'
import { GET_CARDS } from '@/housefolios-components/Properties/queries'
import Lending from '@/housefolios-components/Dialogs/Lending'

import { assumptionsOptions } from '@/housefolios-components/AssumptionsSettings/Accordion' //SPECIFIC COMMENT: Don't remove. Used for rehab card fields
import { DEFAULT_ANALYTICS } from '@/housefolios-components/Property/fragments'
import { useLocation } from 'react-router-dom'

import {
  ContactInformation,
  getCashEntryFee,
  getCreativeEntryFee,
  getPitiTotal,
} from '@/utils/loanBalance'
import SubtoPITICash from '@/housefolios-components/Dialogs/SubtoPITI/Cash/'
import SubtoPITICreative from '@/housefolios-components/Dialogs/SubtoPITI/Creative/'
import CommentsDialog from '@/housefolios-components/Dialogs/Comments'
import {
  FixFlipAcquisitionExpensesTour,
  FixFlipComparablePropertiesTour,
  FixFlipDispositionExpensesTour,
  FixFlipFinancingTour,
  FixFlipGrossMonthlyIncomeRentRangeTour,
  FixFlipGrossMonthlyIncomeSingleRentTour,
  FixFlipHoldingExpensesTour,
  FixFlipNetProfitFromSaleTour,
  FixFlipRehabExpensesDetailedRehabTour,
  FixFlipRehabExpensesFlippingAmericaRehabTour,
  FixFlipRehabExpensesRehabTierTour,
  FixFlipRehabExpensesSingleRehabValueTour,
  FixFlipStrategyTour,
  FixFlipTargetsTour,
} from './tours'

const pieColors = [
  '#bebebd',
  '#008ffb',
  '#ff6efb',
  '#69A251',
  '#38CDC3',
  '#e3e44f',
  '#feb019',
  '#45818E',
  '#d26767',
]

function CenterPageFixFlip(props) {
  const {
    property,
    propertyInput,
    analysisInput,
    assumptionsInput,
    renderInputFields,
  } = props

  const [openFixFlipStrategyTour, setOpenFixFlipStrategyTour] =
    useState(false)
  const [openFixFlipTargetsTour, setOpenFixFlipTargetsTour] =
    useState(false)
  const [
    openFixFlipNetProfitFromSaleTour,
    setOpenFixFlipNetProfitFromSaleTour,
  ] = useState(false)
  const [
    openFixFlipAcquisitionExpensesTour,
    setOpenFixFlipAcquisitionExpensesTour,
  ] = useState(false)
  // const [openFixFlipMonthlyIncomeTour, setOpenFixFlipMonthlyIncomeTour] = useState(false)
  const [
    openFixFlipGrossMonthlyIncomeSingleRentTour,
    setOpenFixFlipGrossMonthlyIncomeSingleRentTour,
  ] = useState(false)
  const [
    openFixFlipGrossMonthlyIncomeRentRangeTour,
    setOpenFixFlipGrossMonthlyIncomeRentRangeTour,
  ] = useState(false)
  const [
    openFixFlipRehabExpensesSingleRehabValueTour,
    setOpenFixFlipRehabExpensesSingleRehabValueTour,
  ] = useState(false)
  const [
    openFixFlipRehabExpensesDetailedRehabTour,
    setOpenFixFlipRehabExpensesDetailedRehabTour,
  ] = useState(false)
  const [
    openFixFlipRehabExpensesRehabTierTour,
    setOpenFixFlipRehabExpensesRehabTierTour,
  ] = useState(false)
  const [
    openFixFlipRehabExpensesFlippingAmericaRehabTour,
    setOpenFixFlipRehabExpensesFlippingAmericaRehabTour,
  ] = useState(false)
  const [
    openFixFlipHoldingExpensesTour,
    setOpenFixFlipHoldingExpensesTour,
  ] = useState(false)
  const [openFixFlipFinancingTour, setOpenFixFlipFinancingTour] =
    useState(false)
  const [
    openFixFlipDispositionExpensesTour,
    setOpenFixFlipDispositionExpensesTour,
  ] = useState(false)
  const [
    openFixFlipComparablePropertiesTour,
    setOpenFixFlipComparablePropertiesTour,
  ] = useState(false)

  const [openConfirmLender, setOpenConfirmLender] = useState(false)

  const toggleOpenConfirmLender = () => {
    setOpenConfirmLender(!openConfirmLender)
  }

  useEffect(() => {
    if (analysisInput.lender !== analysis.lender)
      toggleOpenConfirmLender()
  }, [analysisInput.lender])

  let results = useQuery(GET_CARDS, {
    variables: { view: 'FixFlip' },
  })
  let data = results.data || {}
  const cardsLoading = results.loading
  const cardsError = results.error
  let { cards = [] } = data

  const { activeOrg = {} } = props.session.me || {}
  const { acquisition = {}, assumptions = {} } = property
  const { analytics = DEFAULT_ANALYTICS, analysis = {} } = acquisition

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const currencyFormat2 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const percentageFormat = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
  })

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  //const [loader, setLoader] = useState(false)
  const [modal1, setModal1] = useState(null)
  const [dealSauceView, setDealSauceView] = useState('creative')
  const [openPITICashData, setOpenPITICashData] = useState(false)
  const [openPITICreativeData, setOpenPITICreativeData] =
    useState(false)

  const pitiTotal = getPitiTotal(property)
  const creativeEntryFee = getCreativeEntryFee(property)
  const cashEntryFee = getCashEntryFee(property)

  const toggle1 = (name) => {
    setModal1(name)
    //setLoader(false)
  }

  const toggle1Close = () => {
    setModal1(null)
    //setLoader(false)
  }

  const [maxOfferDescription, setMaxOfferDescription] =
    useState(false)

  const toggleMaxOffer = () => {
    setMaxOfferDescription(!maxOfferDescription)
  }

  const [getFinancing, setGetFinancing] = useState(null)

  const toggleGetFinancing = (name) => {
    setGetFinancing(name)
  }

  const toggleGetFinancingClose = () => {
    setGetFinancing(null)
  }

  const basicAcquisitionFields = [
    {
      type: 'dollar',
      name: 'offerPrice',
      label: 'Purchase Price',
      tooltipButtonText: 'Show Purchase Price data',
      value:
        analysisInput.offerPrice === null
          ? //? analysisInput.listPrice.toString()
            ''
          : analysisInput.offerPrice.toString(),
      helper:
        !analysisInput.offerPrice || analysisInput.offerPrice === '0'
          ? '0% Discount to Price'
          : (
              (1 -
                analysisInput.offerPrice / analysisInput.listPrice) *
              100
            ).toFixed(0) + '% Discount to Price',
      xs: 12,
      lg: 6,
      definition: 'offerPrice',
      tooltipField:
        'The price that the seller is asking for this home. *Not necessarily the price that the investor will purchase the home for.',
      dataTour: 'acquisitionExpenses-step-4',
      dataTourDatabase: 'acquisitionExpenses-step-5',
    },
    {
      type: 'dollar',
      name: includes(
        assumptionsInput.strategySpecificFields,
        'afterRepairValue',
      )
        ? 'afterRepairValueFixFlip'
        : 'afterRepairValue',
      label: 'After Repair Value',
      tooltipButtonText: 'Show ARV data',
      value: includes(
        assumptionsInput.strategySpecificFields,
        'afterRepairValue',
      )
        ? analysisInput.afterRepairValueFixFlip === null
          ? ''
          : analysisInput.afterRepairValueFixFlip.toString()
        : analysisInput.afterRepairValue === null
          ? ''
          : analysisInput.afterRepairValue.toString(),
      //helper: currencyFormat.format(analytics.fixFlip.equity) + ' in equity',
      xs: 12,
      lg: 6,
      definition: 'afterRepairValue',
      definitionTarget: includes(
        assumptionsInput.strategySpecificFields,
        'afterRepairValue',
      )
        ? 'afterRepairValueFixFlip'
        : 'afterRepairValue',
      tooltipField:
        'The ARV is the value of a property after you have conducted repairs and are ready to sell',
      dataTour: 'acquisitionExpenses-step-6',
      dataTourDatabase: 'acquisitionExpenses-step-7',
    },
    {
      type: 'dropdown',
      name: 'closingCostFixFlip',
      value: assumptionsInput.closingCostFixFlip,
      label: 'Closing Cost',
      adornmentToggle: assumptionsInput.closingCostFixFlipType,
      adornment: assumptionsInput.closingCostFixFlipType,
      adornmentName: 'closingCostFixFlipType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Fees (attorney/title fees, etc) Paid at Closing. Expressed as a dollar amount or precentage of the purchase prices.',
      dataTour: 'acquisitionExpenses-step-8',
    },
    {
      type: 'dollar',
      name: 'creditFromSeller',
      value: assumptionsInput.creditFromSeller,
      label: 'Credit From Seller',
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'A seller credit is the dollar amount the seller will put towards your closing costs.',
      dataTour: 'acquisitionExpenses-step-9',
    },
  ]

  const advancedAcquisitionFields = [
    {
      type: 'dropdown',
      name: 'acquisitionCommission',
      label: 'Acq Commission',
      value: assumptionsInput.acquisitionCommission,
      adornmentToggle: assumptionsInput.acquisitionCommissionType,
      adornment: assumptionsInput.acquisitionCommissionType,
      adornmentName: 'acquisitionCommissionType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Advanced Fee - Used in addition to sales of typical sales commissions expressed as a percentage of the Purchase price or a fixed dollar amount',
      dataTour: 'acquisitionExpenses-step-10',
    },
    {
      type: 'dollar',
      name: 'acquisitionFee',
      label: 'Acquisition Fee',
      value: assumptionsInput.acquisitionFee,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Advanced Fee - Used in addition to sales of typical sales commissions expressed as a fixed dollar amount.',
      dataTour: 'acquisitionExpenses-step-11',
    },
    // {
    //   type: 'percentage',
    //   name: 'assetManagementFee',
    //   label: 'Asset Mgmt Fee',
    //   value: assumptionsInput.assetManagementFee,
    //   xs: 6,
    //   assumptions: true,
    // },
    {
      type: 'dropdown',
      name: 'financingFeeFixFlip',
      label: 'Financing Fee',
      value: assumptionsInput.financingFeeFixFlip,
      adornment: assumptionsInput.financingFeeFixFlipType,
      adornmentToggle: assumptionsInput.financingFeeFixFlipType,
      adornmentName: 'financingFeeFixFlipType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Advanced Fee - A fee that the real estate professional charges for securing financing on the property. Expressed as a percentage of the loan amount.',
      dataTour: 'acquisitionExpenses-step-12',
    },
    {
      type: 'dollar',
      name: 'ownerReservesFixFlip',
      label: '1x Owner Reserve',
      value: assumptionsInput.ownerReservesFixFlip,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'A single contribution of money paid shortly after closing to keep in the bank to be used for large future expenses (capex items, etc.)',
      dataTour: 'acquisitionExpenses-step-13',
    },
  ]

  const customAcquisitonField = [
    {
      type: 'budgetLines',
      name: 'customAcquisitionExpenses',
      value: analysisInput.customAcquisitionExpenses,
      label: 'Custom Acquisition Expenses',
      options: activeOrg.customAcquisitionExpenseTypes,
      xs: 12,
      optionName: 'customAcquisitionExpenseTypes',
      removeOption: true,
    },
  ]

  const uncheckAdvanced = every(
    advancedAcquisitionFields,
    (field) => !field.value,
  )

  const [state, setState] = React.useState({
    checkedA: !uncheckAdvanced,
    checkedB: false,
    checkedF: false,
    checkedG: false,
  })

  const acquisitionChartOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              formatter: (value) => currencyFormat.format(value),
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              formatter: (value) => {
                const total = sum(value.config.series)
                return currencyFormat.format(total)
              },
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => currencyFormat.format(value),
      },
    },
    legend: {
      position: 'bottom',
      show: false,
    },
    colors: pieColors,
    labels: concat(
      [
        'Purchase Price',
        'Closing Costs',
        'Acquisition Commission',
        'Acquisition Fee',
        'Financing Fee',
        'One-time Owner Reserve',
      ],
      map(
        analysis.customAcquisitionExpenses,
        (expense) => expense.value,
      ),
    ),
  }
  const acquisitionChartSeries = concat(
    [
      round(
        analysis.offerPrice === null
          ? analysis.listPrice
          : analysis.offerPrice,
        2,
      ) || 0.0001,
      round(analytics.fixFlip.closeCostDollarFixFlip, 2) || 0,
      round(analytics.acquisitionCommissionDollar, 2) || 0,
      round(assumptions.acquisitionFee, 2) || 0,
      round(analytics.fixFlip.financingFeeTotalFixFlip, 2) || 0,
      round(assumptions.ownerReservesFixFlip, 2) || 0,
    ],
    map(
      analysis.customAcquisitionExpenses,
      (expense) => round(expense.value, 2) || 0,
    ),
  )

  let grossMonthlyIncomeSingleRentFields = filter(
    [
      {
        type: 'select',
        name: 'rentType',
        label: 'Rent Type',
        value: assumptionsInput.rentType,
        options: assumptionsOptions(activeOrg)?.rentTypes,
        xs: 12,
        lg: 12,
        assumptions: true,
        dataTour: 'grossMonthlyIncomeSingleRent-step-2',
      },
      {
        type: 'dollar',
        name: `rent.${props.activeUnit}`,
        label: 'Total Rental Income',
        tooltipButtonText: 'Show Total Rent data',
        value:
          analysisInput.rent[props.activeUnit] === null
            ? ''
            : analysisInput.rent[props.activeUnit].toString(),
        helper:
          property.sqft &&
          property.sqft[props.activeUnit] &&
          analysisInput.rent[props.activeUnit]
            ? currencyFormat2.format(
                analysisInput.rent[props.activeUnit] /
                  property.sqft[props.activeUnit],
              ) + ' rent per sqft'
            : '$0.00 rent per sqft',
        xs: 12,
        lg: 6,
        definition: 'rent',
        // disabled: !isNil(analysisInput.ownerOccupiedFixFlip) && props.activeUnit === analysisInput.ownerOccupiedFixFlip,
        tooltipField:
          "A tenant's monthly payment to a landlord for the use of property or land.",
        hide: assumptionsInput.rentType !== 'singleRent',
        dataTour: 'grossMonthlyIncomeSingleRent-step-3',
        dataTourDatabase: 'grossMonthlyIncomeSingleRent-step-4',
      },
      {
        type: 'percentage',
        name: `vacancy.${props.activeUnit}`,
        label: 'Vacancy Rate',
        value: assumptionsInput.vacancy[props.activeUnit],
        xs: 12,
        lg: 6,
        assumptions: true,
        tooltipField:
          'The percentage of time that a property is not rented out during the course of a year.',
        homeType: 'both',
        dataTour: 'grossMonthlyIncomeSingleRent-step-5',
      },
      {
        type: 'dollar',
        name: `otherIncome.${props.activeUnit}`,
        label: 'Other Monthly Income',
        value:
          analysisInput.otherIncome[props.activeUnit] === null
            ? ''
            : analysisInput.otherIncome[props.activeUnit].toString(),
        xs: 12,
        lg: 6,
        tooltipField:
          'Additional income you earn through the property. Some examples might be coin-op laundry, parking, storage, etc.',
        homeType: 'both',
        dataTour: 'grossMonthlyIncomeSingleRent-step-6',
      },
    ],
    (field) => !field.hide,
  )

  // if (analysisInput.rent.length > 1) {
  //   grossMonthlyIncomeSingleRentFields.push({
  //     type: 'checkbox',
  //     label: 'Owner Occupied',
  //     name: 'ownerOccupiedFixFlip',
  //     value: !isNil(analysisInput.ownerOccupiedFixFlip) && analysisInput.ownerOccupiedFixFlip === props.activeUnit,
  //     xs: 12,
  //     lg: 6,
  //     tooltipField: 'This unit is occupied by the owner',
  //   })
  // }

  let grossMonthlyIncomeRentRangeFields = filter(
    [
      {
        type: 'select',
        name: 'rentType',
        label: 'Rent Type',
        value: assumptionsInput.rentType,
        options: assumptionsOptions(activeOrg)?.rentTypes,
        xs: 12,
        lg: 12,
        assumptions: true,
        dataTour: 'grossMonthlyIncomeRentRange-step-2',
      },
      // {
      //   type: 'dollar',
      //   name: `rent.${props.activeUnit}`,
      //   label: 'Total Rental Income',
      //   tooltipButtonText: 'Show Total Rent data',
      //   value:
      //     analysisInput.rent[props.activeUnit] === null
      //       ? ''
      //       : analysisInput.rent[props.activeUnit].toString(),
      //   helper: property.sqft && property.sqft[props.activeUnit] && analysisInput.rent[props.activeUnit] ? currencyFormat2.format(analysisInput.rent[props.activeUnit] / property.sqft[props.activeUnit]) + ' rent per sqft' : '$0.00 rent per sqft',
      //   xs: 12,
      //   lg: 6,
      //   definition: 'rent',
      //   disabled: !isNil(analysisInput.ownerOccupied) && props.activeUnit === analysisInput.ownerOccupied,
      //   tooltipField: "A tenant's monthly payment to a landlord for the use of property or land.",
      //   hide: assumptionsInput.rentType !== 'singleRent',
      //   dataTour: "grossMonthlyIncome-step-1",
      // },
      {
        type: 'dollar',
        name: `rentLow.${props.activeUnit}`,
        label: 'Rent Range Low',
        tooltipButtonText: 'Show Total Rent data',
        value:
          analysisInput.rentLow[props.activeUnit] === null
            ? ''
            : analysisInput.rentLow[props.activeUnit].toString(),
        xs: 12,
        lg: 6,
        definition: 'rent',
        tooltipField: 'Lower end of projected rent',
        hide: assumptionsInput.rentType !== 'rentRange',
        dataTour: 'grossMonthlyIncomeRentRange-step-3',
        dataTourDatabase: 'grossMonthlyIncomeRentRange-step-4',
      },
      {
        type: 'dollar',
        name: `rentHigh.${props.activeUnit}`,
        label: 'Rent Range High',
        tooltipButtonText: 'Show Total Rent data',
        value:
          analysisInput.rentHigh[props.activeUnit] === null
            ? ''
            : analysisInput.rentHigh[props.activeUnit].toString(),
        xs: 12,
        lg: 6,
        definition: 'rent',
        tooltipField: 'Higher end of projected rent',
        hide: assumptionsInput.rentType !== 'rentRange',
        dataTour: 'grossMonthlyIncomeRentRange-step-5',
        dataTourDatabase: 'grossMonthlyIncomeRentRange-step-6',
      },
      {
        type: 'percentage',
        name: `vacancy.${props.activeUnit}`,
        label: 'Vacancy Rate',
        value: assumptionsInput.vacancy[props.activeUnit],
        xs: 12,
        lg: 6,
        assumptions: true,
        tooltipField:
          'The percentage of time that a property is not rented out during the course of a year.',
        homeType: 'both',
        dataTour: 'grossMonthlyIncomeRentRange-step-7',
      },
      {
        type: 'dollar',
        name: `otherIncome.${props.activeUnit}`,
        label: 'Other Monthly Income',
        value:
          analysisInput.otherIncome[props.activeUnit] === null
            ? ''
            : analysisInput.otherIncome[props.activeUnit].toString(),
        xs: 12,
        lg: 6,
        tooltipField:
          'Additional income you earn through the property. Some examples might be coin-op laundry, parking, storage, etc.',
        homeType: 'both',
        dataTour: 'grossMonthlyIncomeRentRange-step-8',
      },
    ],
    (field) => !field.hide,
  )

  // if (analysisInput.rent.length > 1) {
  //   grossMonthlyIncomeRentRangeFields.push({
  //     type: 'checkbox',
  //     label: 'Owner Occupied',
  //     name: 'ownerOccupiedFixFlip',
  //     value: !isNil(analysisInput.ownerOccupiedFixFlip) && analysisInput.ownerOccupiedFixFlip === props.activeUnit,
  //     xs: 12,
  //     lg: 6,
  //     tooltipField: 'This unit is occupied by the owner',
  //     dataTour: "grossMonthlyIncomeRentRange-step-9",
  //   })
  // }

  const grossMonthlyIncomeChartOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              formatter: (value) => currencyFormat.format(value),
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              formatter: (value) => {
                const total = sum(value.config.series)
                return currencyFormat.format(total)
              },
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => currencyFormat.format(value),
      },
    },
    legend: {
      position: 'bottom',
      show: false,
    },
    colors: pieColors,
    labels: concat(
      map(
        analytics.grossAnnualRent,
        (value, index) => `Unit ${index + 1} Monthly Rental Income`,
      ),
      map(
        analysis.otherIncome,
        (value, index) => `Unit ${index + 1} Other Monthly Income`,
      ),
    ),
  }
  const grossMonthlyIncomeChartSeries = concat(
    map(
      analytics.grossAnnualRent,
      (grossAnnualRent, index) =>
        round(grossAnnualRent / 12, 2) || (index === 0 ? 0.0001 : 0),
    ),
    map(
      analysis.otherIncome,
      (otherIncome) => round(otherIncome, 2) || 0,
    ),
  )

  const gorillaRehabDays = isNil(analysisInput.rehabDays)
    ? analysisInput.rehabCost / 500 + 55
    : analysisInput.rehabDays
  const gorillaFee =
    ((analysisInput.offerPrice === null
      ? analysisInput.listPrice
      : analysisInput.offerPrice) +
      analysisInput.rehabCostFixFlip) *
    0.0005 *
    gorillaRehabDays
  const gorillaMinFee =
    (analysisInput.offerPrice === null
      ? analysisInput.listPrice
      : analysisInput.offerPrice) > 100000
      ? (analysisInput.offerPrice === null
          ? analysisInput.listPrice
          : analysisInput.offerPrice) * 0.06
      : 6000
  const gorillaFinalFee =
    gorillaFee > gorillaMinFee ? gorillaFee : gorillaMinFee
  const gorillaSplit =
    gorillaRehabDays <= 30
      ? 0.05
      : gorillaRehabDays <= 60
        ? 0.1
        : gorillaRehabDays <= 90
          ? 0.2
          : gorillaRehabDays <= 120
            ? 0.3
            : 0.4

  // const rehabCard =
  //   find(
  //     cards,
  //     (card) =>
  //       card.name === 'rehab' && card.member === activeOrg.member,
  //   ) ||
  //   find(
  //     cards,
  //     (card) => card.name === 'rehab' && card.member === null,
  //   ) ||
  //   {}
  // const rehabFields = filter(
  //   map(get(rehabCard, 'card.fields'), (field) => {
  //     const input = field.analytics
  //       ? analytics.fixFlip
  //       : field.assumptions
  //       ? assumptionsInput
  //       : analysisInput
  //     const options =
  //       field.options && field.assumptions
  //         ? get(assumptionsOptions(activeOrg), field.options) ||
  //           get(activeOrg, field.options) ||
  //           field.options
  //         : get(activeOrg, field.options) || field.options
  //     const memberOptions =
  //       field.memberOptions && field.assumptions
  //         ? get(assumptionsOptions(activeOrg), field.memberOptions) ||
  //           get(activeOrg, field.memberOptions)
  //         : get(activeOrg, field.memberOptions)
  //     const name = field.suffix
  //       ? includes(field.name, 'Type')
  //         ? replace(field.name, 'Type', 'FixFlipType')
  //         : field.name + 'FixFlip'
  //       : field.name
  //     const hide =
  //       field.suffix && field.hide
  //         ? includes(field.hide, 'Type')
  //           ? replace(field.hide, 'Type', 'FixFlipType')
  //           : field.hide + 'FixFlip'
  //         : field.hide
  //     const adornmentName =
  //       field.suffix && field.adornmentName
  //         ? includes(field.adornmentName, 'Type')
  //           ? replace(field.adornmentName, 'Type', 'FixFlipType')
  //           : field.adornmentName + 'FixFlip'
  //         : field.adornmentName
  //     const definition =
  //       field.suffix && field.definition
  //         ? includes(field.definition, 'Type')
  //           ? replace(field.definition, 'Type', 'FixFlipType')
  //           : field.definition + 'FixFlip'
  //         : field.definition

  //     const helper = includes(field.name, 'rehabTier')
  //       ? currencyFormat.format(
  //           (field.value ? eval(field.value) : get(input, name)) *
  //             (assumptionsInput.percentageOfARVFixFlip / 100) *
  //             propertyInput.sqft,
  //         ) + ' Total'
  //       : null
  //     return {
  //       ...field,
  //       name,
  //       value: field.value ? eval(field.value) : get(input, name),
  //       adornmentToggle: get(input, adornmentName),
  //       adornment: get(input, adornmentName),
  //       definition: definition,
  //       options: filter(
  //         options,
  //         (option) =>
  //           isEmpty(option.showMarkets) ||
  //           includes(option.showMarkets, analysisInput.marketType),
  //       ),
  //       memberOptions: filter(
  //         memberOptions,
  //         (option) =>
  //           isEmpty(option.showMarkets) ||
  //           includes(option.showMarkets, analysisInput.marketType),
  //       ),
  //       helper,
  //       hide,
  //     }
  //   }),
  //   (field) => !field.hide || !eval(field.hide),
  // )

  const rehabFields = filter(
    [
      {
        type: 'select',
        name: 'rehabFixFlipType',
        value: assumptionsInput.rehabFixFlipType,
        label: 'Rehab Type',
        xs: 12,
        options: assumptionsOptions(activeOrg)?.rehabOptions,
        assumptions: true,
      },
      {
        type: 'dollar',
        name: 'rehabCostFixFlip',
        value: analysisInput.rehabCostFixFlip,
        label: 'Est Rehab',
        tooltipButtonText: 'Show Estimated Rehab data',
        xs: 12,
        lg: 6,
        definition: 'rehabCostFixFlip',
        tooltipField:
          'An expense that is incurred when restoring an entity to its normal or near-normal condition.',
        hide:
          assumptionsInput.rehabFixFlipType !== 'singleRehabValue',
        dataTour: 'rehabExpenses-step-1',
      },
      {
        type: 'budgetLines',
        name: 'detailedRehabCostFixFlip',
        value: analysisInput.detailedRehabCostFixFlip,
        label: 'Rehab Costs',
        xs: 12,
        options: [
          'Appliances',
          'Cabinets/Vanities',
          'Carpet',
          'Countertops/Sinks/Toilets',
          'Driveway/Decking/Patio',
          'Electrical',
          'Exterior',
          'Flooring',
          'Framing',
          'Insulation/Drywall',
          'Landscaping',
          'Masonry',
          'Mechanical',
          'Molding/Tile/Trim',
          'Paint',
          'Plans/Permits',
          'Plumbing',
          'Roof',
          'Siding/Gutters',
          'Site Prep',
          'Staging',
          'Windows/Doors',
          'Other',
        ],
        hide: assumptionsInput.rehabFixFlipType !== 'detailedRehab',
        dataTour: 'rehabExpenses-step-1',
      },
      {
        type: 'rehabCalc',
        name: 'rehabCalcTotalFixFlip',
        label: 'Rehab Calculator',
        value: analysisInput.rehabCalcTotalFixFlip,
        xs: 12,
        lg: 6,
        property: property,
        assumptionsInput: assumptionsInput,
        affiliate: props.affiliate,
        hide: assumptionsInput.rehabFixFlipType !== 'rehabCalc',
        dataTour: 'rehabExpenses-step-1',
      },
      {
        type: 'select',
        name: 'rehabTierFixFlip',
        value: assumptionsInput.rehabTierFixFlip,
        label: 'Rehab Tier',
        tooltipButtonText: 'Show Estimated Rehab data',
        xs: 12,
        lg: 6,
        options: filter(
          activeOrg.rehabTiers,
          (option) =>
            isEmpty(option.showMarkets) ||
            includes(
              option.showMarkets,
              analysisInput.marketType ||
                props.affiliate?.defaultMarketType,
            ),
        ),
        optionName: 'rehabTiers',
        memberOptions: filter(
          activeOrg.memberRehabTiers,
          (option) =>
            isEmpty(option.showMarkets) ||
            includes(
              option.showMarkets,
              analysisInput.marketType ||
                props.affiliate?.defaultMarketType,
            ),
        ),
        dynamicMemberOptions: map(
          filter(
            activeOrg.dynamicMemberRehabTiers,
            (option) =>
              isEmpty(option.showMarkets) ||
              includes(
                option.showMarkets,
                analysisInput.marketType ||
                  props.affiliate?.defaultMarketType,
              ),
          ),
          (option) => {
            let breakPointIndex = 0
            const arv = includes(
              assumptionsInput.strategySpecificFields,
              'afterRepairValue',
            )
              ? analysisInput.afterRepairValueFixFlip === null
                ? null
                : analysisInput.afterRepairValueFixFlip
              : analysisInput.afterRepairValue === null
                ? null
                : analysisInput.afterRepairValue
            while (
              option?.breakpoints[breakPointIndex] < arv &&
              breakPointIndex < option?.breakpoints.length
            ) {
              breakPointIndex++
            }
            return {
              ...option,
              label: `$${option.values[breakPointIndex]}/Sqft`,
            }
          },
        ),
        removeOption: true,
        assumptions: true,
        hide:
          assumptionsInput.rehabFixFlipType !== 'rehabTier' &&
          assumptionsInput.rehabFixFlipType !==
            'flippingAmericaRehab',
        dataTour: 'rehabExpenses-step-1',
        helper: currencyFormat.format(
          analytics.fixFlip.rehabTierValueFixFlip,
        ),
      },
      {
        name: 'marketType',
        value: !analysisInput.marketType
          ? props.affiliate?.defaultMarketType
          : analysisInput.marketType,
        label: 'Market Type',
        options: concat(
          [props.affiliate?.defaultMarketType],
          props.affiliate?.marketTypes,
        ),
        type: 'select',
        xs: 12,
        lg: 6,
        hide:
          isEmpty(props.affiliate?.marketTypes) ||
          (assumptionsInput.rehabFixFlipType !== 'rehabTier' &&
            assumptionsInput.rehabFixFlipType !==
              'flippingAmericaRehab'),
      },
      {
        type: 'dollar',
        name: 'rehabDecadeCostFixFlip',
        value: assumptionsInput.rehabDecadeCostFixFlip,
        label: 'Rehab Per Decade',
        xs: 12,
        lg: 6,
        assumptions: true,
        hide:
          assumptionsInput.rehabFixFlipType !==
          'flippingAmericaRehab',
      },
      {
        type: 'number',
        name: 'rehabDays',
        label: 'Est Hold Time (days)',
        value:
          analysisInput.rehabDays === null ||
          analysisInput.rehabDays === undefined
            ? analysisInput.rehabCost === null
              ? ''
              : analysisInput.rehabCost / 500
            : analysisInput.rehabDays,
        xs: 12,
        lg: 6,
        tooltipField: '',
        hide: activeOrg.member !== 'gorillacapital',
      },
      {
        type: 'dollar',
        name: 'gorillaFee',
        label: 'Gorilla Fee',
        value: gorillaFinalFee,
        xs: 12,
        lg: 6,
        disabled: true,
        tooltipField: '',
        hide: activeOrg.member !== 'gorillacapital',
      },
      {
        type: 'dollar',
        name: 'gorillaBuyBox',
        label: 'Gorilla Buy Box',
        value:
          analysisInput.afterRepairValue * 0.75 -
          analysisInput.rehabCost,
        xs: 12,
        lg: 6,
        disabled: true,
        tooltipField: '',
        hide: activeOrg.member !== 'gorillacapital',
      },
      {
        type: 'dollar',
        name: 'myProfit',
        label: 'My Profit',
        value:
          analytics.fixFlip.saleProceeds * gorillaSplit <
          gorillaFinalFee
            ? analytics.fixFlip.saleProceeds - gorillaFinalFee
            : analytics.fixFlip.saleProceeds * (1 - gorillaSplit),
        xs: 12,
        lg: 6,
        disabled: true,
        tooltipField: '',
        hide: activeOrg.member !== 'gorillacapital',
      },
      {
        type: 'dollar',
        name: 'Gorilla Profit',
        label: 'Gorilla Profit',
        value:
          analytics.fixFlip.saleProceeds * gorillaSplit <
          gorillaFinalFee
            ? 0
            : analytics.saleProceeds * gorillaSplit - gorillaFinalFee,
        xs: 12,
        lg: 6,
        disabled: true,
        tooltipField: '',
        hide: activeOrg.member !== 'gorillacapital',
      },
      {
        type: 'percentage',
        name: 'rehabContingencyFixFlip',
        value: assumptionsInput.rehabContingencyFixFlip,
        adornmentToggle: assumptionsInput.rehabContingencyFixFlipType,
        adornment: assumptionsInput.rehabContingencyFixFlipType,
        adornmentName: 'rehabContingencyFixFlipType',
        helper:
          assumptionsInput.rehabContingencyFixFlipType === '%'
            ? currencyFormat.format(
                assumptionsInput.rehabTierFixFlip *
                  property.sqft *
                  (assumptionsInput.rehabContingencyFixFlip / 100),
              )
            : null,
        label: 'Rehab Contingency',
        xs: 12,
        lg: 6,
        assumptions: true,
        dropdown: true,
        tooltipField:
          'Percentage of calculated rehab cost added as a saftey margin',
        // dataTour: "wholesaleDetails-step-5"
      },
    ],
    (field) => !field.hide,
  )

  const rehabChartOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              formatter: (value) => currencyFormat.format(value),
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              formatter: (value) => {
                const total = sum(value.config.series)
                return currencyFormat.format(total)
              },
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => currencyFormat.format(value),
      },
    },
    legend: {
      position: 'bottom',
      show: false,
    },
    colors: pieColors,
    labels: concat(
      assumptionsInput.rehabFixFlipType === 'detailedRehab'
        ? isEmpty(analysis.detailedRehabCostFixFlip)
          ? ['Total Rehab']
          : map(
              analysis.detailedRehabCostFixFlip,
              (line) => line.type || 'Unlabeled',
            )
        : ['Total Rehab'],
      activeOrg.member === 'gorillacapital' ? ['Gorilla Fee'] : [],
    ),
  }
  const rehabChartSeries = concat(
    assumptionsInput.rehabFixFlipType === 'detailedRehab'
      ? isEmpty(analysis.detailedRehabCostFixFlip)
        ? [0.0001]
        : map(analysis.detailedRehabCostFixFlip, (line, index) =>
            index === 0
              ? round(line.value, 2) || 0.0001
              : round(line.value, 2) || 0,
          )
      : [round(analytics.fixFlip.corTotalFixFlip, 2) || 0.0001],
    activeOrg.member === 'gorillacapital'
      ? [round(gorillaFinalFee, 2) || 0]
      : [],
  )

  const holdingExpensesFields = [
    {
      dropdown: true,
      type: 'dropdown',
      name: 'propertyInsuranceFixFlip',
      label: 'Annual Insurance',
      value: assumptionsInput.propertyInsuranceFixFlip,
      adornmentToggle: assumptionsInput.propertyInsuranceFixFlipType,
      adornment: assumptionsInput.propertyInsuranceFixFlipType,
      adornmentName: 'propertyInsuranceFixFlipType',
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Annual property insurance premium expressed as a dollar amount or percentage of the purchase price',
      dataTour: 'holdingExpenses-step-2',
    },
    {
      dropdown: true,
      type: 'dropdown',
      name: 'propertyManagementFixFlip',
      label: 'Property Mgmt Fee',
      value: assumptionsInput.propertyManagementFixFlip,
      adornmentToggle: assumptionsInput.propertyManagementFixFlipType,
      adornment: assumptionsInput.propertyManagementFixFlipType,
      adornmentName: 'propertyManagementFixFlipType',
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Annual property insurance premium expressed as a dollar amount or percentage of the purchase price',
    },
    {
      dropdown: true,
      type: 'dropdown',
      name: 'HOA',
      label: 'HOA',
      value: analysisInput.HOA,
      adornmentToggle: analysisInput.HOAType,
      adornment: analysisInput.HOAType,
      adornmentName: 'HOAType',
      xs: 12,
      lg: 6,
      tooltipField:
        'An organization in a subdivision, planned community, or condominium building that makes and enforces rules for the properties and its residents. These residents automatically become members and are required to pay dues, known as HOA fees.',
      dataTour: 'holdingExpenses-step-3',
    },
    {
      type: 'dollar',
      name: 'propertyTaxes',
      label: 'Property Taxes',
      tooltipButtonText: 'Show Property Tax data',
      value:
        analysisInput.propertyTaxes === null
          ? ''
          : analysisInput.propertyTaxes.toString(),
      xs: 12,
      lg: 6,
      definition: 'propertyTaxes',
      tooltipField:
        'A tax paid on property owned by an individual or other legal entity, such as a corporation.',
      dataTour: 'holdingExpenses-step-4',
      dataTourDatabase: 'holdingExpenses-step-5',
    },
    {
      type: 'dropdown',
      name: 'utilitiesFixFlip',
      label: 'Monthly Utilities',
      value: assumptionsInput.utilitiesFixFlip,
      adornmentToggle: assumptionsInput.utilitiesFixFlipType,
      adornment: assumptionsInput.utilitiesFixFlipType,
      adornmentName: 'utilitiesFixFlipType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Includes electricity, water, sewer, gas, garbage, etc.  Expressed as a dollar amount or a percentage of Monthly Gross Rental Income (rent - vacancy).',
      dataTour: 'holdingExpenses-step-6',
    },
    {
      type: 'percentage',
      name: 'maintenanceFixFlip',
      label: 'Annual Maintenance',
      value: assumptionsInput.maintenanceFixFlip,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The annual cost of maintenance repairs to the property expressed as a percentage of Gross Rental Income (rent - vacancy).',
      dataTour: 'holdingExpenses-step-7',
    },
    {
      dropdown: true,
      type: 'dropdown',
      name: 'monthlyReservesFixFlip',
      label: 'Capex (reserve/mo)',
      value: assumptionsInput.monthlyReservesFixFlip,
      adornment: assumptionsInput.monthlyReservesFixFlipType,
      adornmentToggle: assumptionsInput.monthlyReservesFixFlipType,
      adornmentName: 'monthlyReservesFixFlipType',
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The amount of monthly income you want to put aside each month as a reserve for future expenses.  This includes capital expenditure (capex) items such as the roof, appliances, water, heater, etc. Expressed as a dollar amount or a percentage of Monthly Gross Rental Income (rent - vacancy).',
    },
    {
      type: 'dollar',
      name: 'otherExpenseFixFlip',
      label: 'Other Expenses / yr',
      value:
        assumptionsInput.otherExpenseFixFlip === null
          ? ''
          : assumptionsInput.otherExpenseFixFlip.toString(),
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Any annual expense that has not already been labeled.',
      dataTour: 'holdingExpenses-step-8',
    },
    {
      type: 'dollar',
      name: 'mortgageFixFlip',
      label: 'Monthly Mortgage',
      value:
        analytics.fixFlip.mortgageFixFlip === null
          ? ''
          : analytics.fixFlip.mortgageFixFlip,
      xs: 12,
      lg: 6,
      disabled: true,
      tooltipField: `The amount you pay every month toward your mortgage loan. This field is not editable. To change your mortgage amount, see the Financing card below.`,
      dataTour: 'holdingExpenses-step-9',
    },
    {
      type: 'number',
      name: 'holdingPeriod',
      label: 'Holding Period (months)',
      value: assumptionsInput.holdingPeriod,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'A holding period is the amount of time the investment is held by an investor or the period between the purchase and sale of the property. Expressed in months.',
      dataTour: 'holdingExpenses-step-10',
    },
    {
      type: 'budgetLines',
      name: 'customMonthlyExpenses',
      label: 'Custom Monthly Expenses',
      value: analysisInput.customMonthlyExpenses,
      xs: 12,
      options: activeOrg.customMonthlyExpenseTypes,
      optionName: 'customMonthlyExpenseTypes',
      removeOption: true,
    },
  ]

  const holdingExpensesChartOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              formatter: (value) => currencyFormat.format(value),
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              formatter: (value) => {
                const total = sum(value.config.series)
                return currencyFormat.format(total)
              },
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => currencyFormat.format(value),
      },
    },
    legend: {
      position: 'bottom',
      show: false,
    },
    colors: pieColors,
    labels: concat(
      [
        'Property Insurance',
        'Property Mgmt Fee',
        'HOA',
        'Property Taxes',
        'Maintenance',
        'Utilities',
        'Capex',
        'Other Expenses',
        'Mortgage',
      ],
      map(analysis.customMonthlyExpenses, (expense) => expense.type),
    ),
  }
  const holdingExpensesChartSeries = concat(
    [
      round(
        (analytics.fixFlip.propertyInsuranceDollarFixFlip / 12) *
          assumptionsInput.holdingPeriod,
        2,
      ) || 0.0001,
      round(
        (analytics.fixFlip.propertyManagementDollarFixFlip / 12) *
          assumptionsInput.holdingPeriod,
        2,
      ) || 0,
      round(
        (analytics.fixFlip.hoaTotal / 12) *
          assumptionsInput.holdingPeriod,
        2,
      ) || 0,
      round(
        (analysis.propertyTaxes / 12) *
          assumptionsInput.holdingPeriod,
        2,
      ) || 0,
      round(
        (analytics.fixFlip.maintenanceCostFixFlip / 12) *
          assumptionsInput.holdingPeriod,
        2,
      ) || 0,
      round(
        analytics.fixFlip.utilitiesTypeDollarFixFlip *
          assumptionsInput.holdingPeriod,
        2,
      ) || 0,
      round(
        analytics.fixFlip.monthlyReservesTypeDollarFixFlip *
          assumptionsInput.holdingPeriod,
        2,
      ) || 0,
      round(
        (assumptions.otherExpenseFixFlip / 12) *
          assumptionsInput.holdingPeriod,
        2,
      ) || 0,
      round(
        analytics.fixFlip.mortgageFixFlip *
          assumptionsInput.holdingPeriod,
        2,
      ) || 0,
    ],
    map(
      analysis.customMonthlyExpenses,
      (expense) =>
        round(expense.value * assumptionsInput.holdingPeriod, 2) || 0,
    ),
  )

  const financingFields = activeOrg.isRealeflow
    ? [
        {
          type: 'checkbox',
          label: 'Cash Purchase',
          name: 'allCashPurchaseFixFlip',
          value: assumptionsInput.allCashPurchaseFixFlip,
          xs: 12,
          assumptions: true,
          tooltipField:
            'Investor pays 100% of the purchase price in cash and does not have a mortgage payment.',
          dataTour: 'financing-step-4',
        },
        {
          type: 'percentage',
          name: 'percentageOfPurchase',
          label: '% of Purchase Price',
          value: 100 - assumptionsInput.downPaymentFixFlip,
          xs: 12,
          lg: 6,
          assumptions: true,
          tooltipField:
            'The percentage of the purchase price that is being covered by the loan',
          dataTour: 'financing-step-5',
        },
        {
          type: 'percentage',
          name: 'financingRepairsPercentageFixFlip',
          label: '% of Rehab',
          value: analysisInput.financingRepairsPercentageFixFlip || 0,
          xs: 12,
          lg: 6,
          tooltipField:
            'The percentage of the repair cost that is being covered by the loan',
          dataTour: 'financing-step-10',
        },
        {
          type: 'percentage',
          name: 'rateFixFlip',
          label: 'Interest Rate',
          value: assumptionsInput.rateFixFlip,
          xs: 12,
          lg: 6,
          assumptions: true,
          tooltipField:
            'The interest rate is the amount a lender charges for the use of capital, expressed as a percentage of the principal. The interest rate is typically noted on an annual basis known as the annual percentage rate (APR).',
          dataTour: 'financing-step-6',
        },
        {
          type: 'dropdown',
          name: 'termFixFlip',
          label: 'Financing Term',
          value: assumptionsInput.termFixFlip,
          adornment: assumptionsInput.termFixFlipType,
          adornmentToggle: assumptionsInput.termFixFlipType,
          adornmentName: 'termFixFlipType',
          xs: 12,
          lg: 6,
          assumptions: true,
          dropdown: true,
          tooltipField:
            'The length of time that the loan is being amortized. Expressed in months or years.',
          dataTour: 'financing-step-7',
        },
        {
          type: 'number',
          name: 'pointsFixFlip',
          label: 'Points',
          value: assumptionsInput.pointsFixFlip,
          xs: 12,
          lg: 6,
          assumptions: true,
          tooltipField:
            'Often when you take out a loan, you will pay "points" (aka: fees) on the loan. It is essentially prepaid interest charged by the lender. One point is equal to 1% of the loan amount. For example, a $100,000 loan with 2 points would be $2,000.',
          dataTour: 'financing-step-8',
        },
        {
          type: 'dropdown',
          name: 'financingFeeFixFlip',
          label: 'Financing Fee',
          value: assumptionsInput.financingFeeFixFlip,
          adornment: assumptionsInput.financingFeeFixFlipType,
          adornmentToggle: assumptionsInput.financingFeeFixFlipType,
          adornmentName: 'financingFeeFixFlipType',
          xs: 12,
          lg: 6,
          assumptions: true,
          dropdown: true,
          tooltipField:
            'A fee that the real estate professional charges for securing financing on the property. Expressed as a percentage of the loan amount.',
        },
        {
          name: 'lender',
          value: analysisInput.lender || '',
          label: 'Lender',
          options: filter(activeOrg.lenders, (lender) =>
            isEmpty(lender.type),
          ),
          optionName: 'lenders',
          removeOption: true,
          type: 'select',
          xs: 12,
          lg: 6,
        },
        {
          type: 'checkbox',
          name: 'interestOnlyLoanFixFlip',
          label: 'Interest Only Loan',
          value: assumptionsInput.interestOnlyLoanFixFlip,
          xs: 12,
          lg: 6,
          assumptions: true,
          tooltipField:
            'A loan that only requires the payment of interest, not principal.',
          dataTour: 'financing-step-9',
        },
        // {
        //   type: 'checkbox',
        //   name: 'financingRepairsFixFlip',
        //   label: 'Finance Repairs',
        //   value: analysisInput.financingRepairsFixFlip || false,
        //   xs: 12,
        //   lg: 6,
        //   tooltipField: 'Checking this will include the repair costs into your loan',
        //   dataTour: 'financing-step-10'
        // },
      ]
    : [
        {
          type: 'checkbox',
          label: 'Cash Purchase',
          name: 'allCashPurchaseFixFlip',
          value: assumptionsInput.allCashPurchaseFixFlip,
          xs: 12,
          assumptions: true,
          tooltipField:
            'Investor pays 100% of the purchase price in cash and does not have a mortgage payment.',
          dataTour: 'financing-step-4',
        },
        {
          type: 'percentage',
          name: 'downPaymentFixFlip',
          label: 'Down Payment',
          value: assumptionsInput.downPaymentFixFlip,
          xs: 12,
          lg: 6,
          assumptions: true,
          tooltipField:
            'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
          dataTour: 'financing-step-5',
        },
        {
          type: 'percentage',
          name: 'financingRepairsPercentageFixFlip',
          label: '% of Rehab',
          value: analysisInput.financingRepairsPercentageFixFlip || 0,
          xs: 12,
          lg: 6,
          tooltipField:
            'The percentage of the repair cost that is being covered by the loan',
          dataTout: 'financing-step-10',
        },
        {
          type: 'percentage',
          name: 'rateFixFlip',
          label: 'Interest Rate',
          value: assumptionsInput.rateFixFlip,
          xs: 12,
          lg: 6,
          assumptions: true,
          tooltipField:
            'The interest rate is the amount a lender charges for the use of capital, expressed as a percentage of the principal. The interest rate is typically noted on an annual basis known as the annual percentage rate (APR).',
          dataTour: 'financing-step-6',
        },
        {
          type: 'dropdown',
          name: 'termFixFlip',
          label: 'Financing Term',
          value: assumptionsInput.termFixFlip,
          adornment: assumptionsInput.termFixFlipType,
          adornmentToggle: assumptionsInput.termFixFlipType,
          adornmentName: 'termFixFlipType',
          xs: 12,
          lg: 6,
          assumptions: true,
          dropdown: true,
          tooltipField:
            'The length of time that the loan is being amortized. Expressed in months or years.',
          dataTour: 'financing-step-7',
        },
        {
          type: 'number',
          name: 'pointsFixFlip',
          label: 'Points',
          value: assumptionsInput.pointsFixFlip,
          xs: 12,
          lg: 6,
          assumptions: true,
          tooltipField:
            'Often when you take out a loan, you will pay "points" (aka: fees) on the loan. It is essentially prepaid interest charged by the lender. One point is equal to 1% of the loan amount. For example, a $100,000 loan with 2 points would be $2,000.',
          dataTour: 'financing-step-8',
        },
        {
          type: 'checkbox',
          name: 'interestOnlyLoanFixFlip',
          label: 'Interest Only Loan',
          value: assumptionsInput.interestOnlyLoanFixFlip,
          xs: 12,
          lg: 6,
          assumptions: true,
          tooltipField:
            'A loan that only requires the payment of interest, not principal.',
          dataTour: 'financing-step-9',
        },
        // {
        //   type: 'checkbox',
        //   name: 'financingRepairsFixFlip',
        //   label: 'Finance Repairs',
        //   value: analysisInput.financingRepairsFixFlip || false,
        //   xs: 12,
        //   lg: 6,
        //   tooltipField: 'Checking this will include the repair costs into your loan',
        //   dataTour: 'financing-step-10'
        // },
      ]

  const dispositionFields = [
    {
      type: 'dollar',
      name: 'resellPriceFixFlip',
      label: 'Resale Price',
      value:
        analysisInput.resellPriceFixFlip === null
          ? ''
          : analysisInput.resellPriceFixFlip.toString(),
      xs: 12,
      lg: 6,
      tooltipField:
        'The price you would sell the property for once the rehab is completed.',
      dataTour: 'dispositionExpenses-step-2',
    },
    {
      type: 'dropdown',
      name: 'sellerClosingCostFixFlip',
      label: 'Seller Closing Cost',
      value: assumptionsInput.sellerClosingCostFixFlip,
      adornmentToggle: assumptionsInput.sellerClosingCostFixFlipType,
      adornment: assumptionsInput.sellerClosingCostFixFlipType,
      adornmentName: 'sellerClosingCostFixFlipType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Closing Costs Paid by the Seller. Expressed as a dollar amount or percentage of the resale price',
      dataTour: 'dispositionExpenses-step-3',
    },
    {
      type: 'dropdown',
      name: 'salesCommissionFixFlip',
      label: 'Sales Commission',
      value: assumptionsInput.salesCommissionFixFlip,
      adornmentToggle: assumptionsInput.salesCommissionFixFlipType,
      adornment: assumptionsInput.salesCommissionFixFlipType,
      adornmentName: 'salesCommissionFixFlipType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Sales Commission on the Future Sale of Property. Expressed as a dollar amount or percentage of resale price.',
      dataTour: 'dispositionExpenses-step-4',
    },
    {
      type: 'dropdown',
      name: 'dispositionFeeFixFlip',
      label: 'Disposition Fee',
      value: assumptionsInput.dispositionFeeFixFlip,
      adornmentToggle: assumptionsInput.dispositionFeeFixFlipType,
      adornment: assumptionsInput.dispositionFeeFixFlipType,
      adornmentName: 'dispositionFeeFixFlipType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Advanced Fee - Additional marketing fee on top of sales commission. Expressed as a dollar amount or percentage of resale price.',
      dataTour: 'dispositionExpenses-step-5',
    },
    {
      type: 'percentage',
      name: 'homePriceAppreciation',
      label: 'Home Price Appreciation',
      value: assumptionsInput.homePriceAppreciation,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The % of Appreciation in Total Dollar Value that the Home has gained or lost each year. Expressed as a percentage of ARV (after repair value). If your holding period is less than 12 months, this field will have no effect.',
      dataTour: 'dispositionExpenses-step-6',
    },
    {
      type: 'percentage',
      name: 'growthRateExpense',
      label: 'Expense Growth Rate',
      value: assumptionsInput.growthRateExpense,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The % increase in property expenses per year. Expressed as a percentage of gross rental income. If your holding period is less than 12 months, this field will have no effect.',
      dataTour: 'dispositionExpenses-step-7',
    },
    {
      type: 'dollar',
      name: 'loanBalanceAtHoldingPeriod',
      label: 'Loan Balance at Resale',
      value:
        analytics.fixFlip.loanBalanceAtHoldingPeriod === null
          ? ''
          : analytics.fixFlip.loanBalanceAtHoldingPeriod,
      xs: 12,
      lg: 6,
      disabled: true,
      tooltipField:
        'This field is not editable. The remaining loan amount at the end of your flip holding period.',
      dataTour: 'dispositionExpenses-step-8',
    },
  ]

  const dispositionChartOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              formatter: (value) => currencyFormat.format(value),
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              formatter: (value) => {
                const total = sum(value.config.series)
                return currencyFormat.format(total)
              },
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => currencyFormat.format(value),
      },
    },
    legend: {
      position: 'bottom',
      show: false,
    },
    colors: pieColors,
    labels: [
      'Seller Closing Cost',
      'Sales Commission',
      'Disposition Fee',
      'Loan Balance at Resale',
    ],
  }

  const dispositionChartSeries = [
    round(analytics.fixFlip.sellingClosingCostDollarFixFlip, 2) ||
      0.0001,
    round(analytics.fixFlip.salesCommissionTotalFixFlip, 2) || 0,
    round(analytics.fixFlip.dispositionFeeDollarFixFlip, 2) || 0,
    round(analytics.fixFlip.loanBalanceAtHoldingPeriod, 2) || 0,
  ]

  const chartOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    colors: ['#1CC943', '#F83244', '#04A3E3'],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: true,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box">' +
          '<span>' +
          //[w.globals.labels][dataPointIndex] +
          ['Resale Price', 'Expenses', 'Net Profit'][dataPointIndex] +
          ': ' +
          currencyFormat.format(series[seriesIndex][dataPointIndex]) +
          '</span>' +
          '</div>'
        )
      },
    },
    xaxis: {
      categories: ['Res...', 'Exp...', 'Net...'],
      labels: {
        rotate: -30,
        rotateAlways: true,
        offsetY: -10,
        formatter: currencyFormat.format,
      },
    },
  }
  const chartSeries = [
    {
      data: [
        round(analysis.resellPriceFixFlip),
        round(
          analytics.fixFlip.totalOutOfPocketFixFlip +
            //+ sum(holdingExpensesChartSeries)  ##SPECIFIC COMMENT: holding expenses are part of the sellingTotalOutOfPocket
            //+ analytics.fixFlip.closeCostDollarFixFlip
            analytics.fixFlip.salesCommissionTotalFixFlip +
            analytics.fixFlip.dispositionFeeDollarFixFlip +
            analytics.fixFlip.loanBalanceAtHoldingPeriod,
        ),
        round(analytics.fixFlip.saleProceeds),
      ],
      // colors: [
      //   ranges: [
      //     {
      //       from: 100,
      //       to: 0,
      //       color: '#f83145',
      //     },
      //   ],
      // ],
    },
  ]

  //The investor's profit should be $25,000 OR 10% of the ARV, Whichever is greater.
  const higherPepProfit =
    analytics.fixFlip.arvTotalFixFlip * 0.1 > 25000
      ? analytics.fixFlip.arvTotalFixFlip * 0.1
      : 25000

  const pepMaxOfferPrice = Math.max(
    (includes(
      assumptionsInput.strategySpecificFields,
      'afterRepairValue',
    )
      ? analysisInput.afterRepairValueFixFlip || 0
      : analysisInput.afterRepairValue) *
      (assumptionsInput.percentageOfARVFixFlip / 100) -
      analytics.fixFlip.corTotalFixFlip,
    0,
  )

  const effectiveOfferPrice =
    analysis.pepAdjustedPriceFixFlip || pepMaxOfferPrice
  // - analytics.fixFlip.arvTotalFixFlip * 0.1 <-- COMMENTED OUT BASED ON KARL'S NEW CALCULATOR
  // (analytics.fixFlip.corTotalFixFlip + higherPepProfit) <-- JEREMY SAID TO COMMENT OUT RIGHT NOW

  const pepEstProfit =
    analytics.fixFlip.arvTotalFixFlip -
    (analytics.fixFlip.arvTotalFixFlip * 0.15 +
      analytics.fixFlip.corTotalFixFlip +
      effectiveOfferPrice)

  const pepMaxEstProfit = max([
    // pepEstProfit,
    0.1 * analytics.fixFlip.arvTotalFixFlip,
    25000,
  ])

  const extraPepProfit = pepMaxOfferPrice - effectiveOfferPrice
  const dataValues = [analytics.fixFlip.arvTotalFixFlip * 0.1, 25000]

  // Determine the colors dynamically
  // const sortedIndices =
  //   dataValues[0] > dataValues[1] ? [0, 1] : [1, 0]
  // const dynamicColors = sortedIndices.map((index) =>
  //   index === 0 ? '#1BC943' : '#AAEA16',
  // )

  const bigPepChartOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false, // Hides the menu
      },
    },
    plotOptions: {
      bar: {
        // distributed: true, // This ensures each bar gets a different color
      },
    },
    legend: {
      show: true, // Hides the legend
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#1BC943', extraPepProfit > 0 ? '#AAEA16' : '#F83244'],
    // colors: dynamicColors,
    // colors: ['#008FFB', '#00E396', '#FEB019'], // Applies per data point when `distributed: true`
    xaxis: {
      // categories: ['Est Profit', '10% of ARV', '$25,000'],
      categories: ['10% of ARV', '$25,000'],
      labels: {
        show: true, // Ensure it's enabled only once
        // style: { colors: ['#008FFB', '#00E396', '#FEB019'] },
      },
    },
    yaxis: {
      labels: {
        formatter: currencyFormat.format,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box">' +
          '<span>' +
          // ['Est Profit', '10% of ARV', '$25,000'][dataPointIndex] +
          ['10% of ARV', '$25,000'][dataPointIndex] +
          ': ' +
          currencyFormat.format(series[seriesIndex][dataPointIndex]) +
          '</span>' +
          '</div>'
        )
      },
    },
  }

  const bigPepChartSeries = [
    {
      name: 'Mimimum target profit',
      data: dataValues,
    },
    {
      name: 'Profit from adjusted price',
      data: [extraPepProfit, extraPepProfit],
    },
  ]

  const pepBigPictureFields = [
    // {
    //   type: 'slider',
    //   name: 'belowNetProfitPep',
    //   label: 'Net Profit',
    //   value: assumptionsInput['belowNetProfitPep'],
    //   xs: 12,
    //   assumptions: true,
    //   valueLabelFormat: (x) => (x === 25 ? `${x}+` : `${x}%`),
    //   step: 1,
    //   min: 0,
    //   max: 25,
    // },
    // {
    //   type: 'slider',
    //   name: 'belowHoldingClosingCostPep',
    //   label: 'Holding + Closing Cost',
    //   value: assumptionsInput['belowHoldingClosingCostPep'],
    //   xs: 12,
    //   assumptions: true,
    //   valueLabelFormat: (x) => (x === 25 ? `${x}+` : `${x}%`),
    //   step: 1,
    //   min: 0,
    //   max: 25,
    // },
    // {
    //   type: 'slider',
    //   name: 'belowTotalCostFinancingPep',
    //   label: 'Total Cost Financing',
    //   value: assumptionsInput['belowTotalCostFinancingPep'],
    //   xs: 12,
    //   assumptions: true,
    //   valueLabelFormat: (x) => (x === 25 ? `${x}+` : `${x}%`),
    //   step: 1,
    //   min: 0,
    //   max: 25,
    // },
    {
      type: 'dollar',
      name: 'listPrice',
      label: 'Asking Price',
      value:
        analysisInput.listPrice === null
          ? ''
          : analysisInput.listPrice.toString(),
      xs: 12,
      lg: 6,
      definition: 'listPrice',
      // tooltipField: 'The price that the seller is asking for this home. *Not necessarily the price that the investor will purchase the home for.',
      // tooltipButtonText: 'Asking Price Options',
      adornmentNumber: 1,
    },
    {
      type: 'dollar',
      name: includes(
        assumptionsInput.strategySpecificFields,
        'afterRepairValue',
      )
        ? 'afterRepairValueFixFlip'
        : 'afterRepairValue',
      label: 'After Repair Value',
      // tooltipButtonText: 'Show ARV data',
      value: includes(
        assumptionsInput.strategySpecificFields,
        'afterRepairValue',
      )
        ? analysisInput.afterRepairValueFixFlip === null
          ? ''
          : analysisInput.afterRepairValueFixFlip.toString()
        : analysisInput.afterRepairValue === null
          ? ''
          : analysisInput.afterRepairValue.toString(),
      //helper: currencyFormat.format(analytics.fixFlip.equity) + ' in equity',
      xs: 12,
      lg: 6,
      definition: 'afterRepairValue',
      definitionTarget: includes(
        assumptionsInput.strategySpecificFields,
        'afterRepairValue',
      )
        ? 'afterRepairValueFixFlip'
        : 'afterRepairValue',
      // tooltipField: 'The ARV is the value of a property after you have conducted repairs and are ready to sell',
      dataTour: 'acquisitionExpenses-step-6',
      dataTourDatabase: 'acquisitionExpenses-step-7',
      adornmentNumber: 2,
    },
    ...map(rehabFields, (field, index) => ({
      ...field,
      adornmentNumber: index + 3,
    })),
    {
      type: 'percentage',
      name: 'percentageOfARVFixFlip',
      label: 'Percent of ARV',
      // tooltipButtonText: 'Show Purchase Price data',
      helper: currencyFormat.format(
        (includes(
          assumptionsInput.strategySpecificFields,
          'afterRepairValue',
        )
          ? analysisInput.afterRepairValueFixFlip === null
            ? 0
            : Number(analysisInput.afterRepairValueFixFlip)
          : analysisInput.afterRepairValue === null
            ? 0
            : Number(analysisInput.afterRepairValue)) *
          (assumptionsInput.percentageOfARVFixFlip / 100),
      ),
      value: assumptionsInput.percentageOfARVFixFlip,
      xs: 12,
      lg: 6,
      assumptions: true,
      // tooltipField: 'Percentage of ARV used to calculate Offer Price',
      adornmentNumber: 6,
    },

    {
      type: 'dollar',
      name: 'pepAdjustedPriceFixFlip',
      label: 'Adjusted Purchase Price',
      // tooltipButtonText: 'Show Purchase Price data',
      value:
        analysis.pepAdjustedPriceFixFlip === null
          ? //? analysisInput.listPrice.toString()
            ''
          : analysis.pepAdjustedPriceFixFlip.toString(),
      xs: 12,
      lg: 6,
      // tooltipField: 'The price that the seller is asking for this home. *Not necessarily the price that the investor will purchase the home for.',
      dataTour: 'acquisitionExpenses-step-4',
      dataTourDatabase: 'acquisitionExpenses-step-5',
      adornmentNumber: 7,
    },
  ]

  const pitiFields = [
    {
      type: 'dollar',
      label: 'Principal',
      name: 'pitiData.principal',
      value: propertyInput?.pitiData.principal,
      xs: 12,
      lg: 6,
      details: true,
    },
    {
      type: 'dollar',
      label: 'Interest',
      name: 'pitiData.interest',
      value: propertyInput?.pitiData.interest,
      xs: 12,
      lg: 6,
      details: true,
    },
    {
      type: 'dollar',
      label: 'Taxes',
      name: 'pitiData.taxes',
      value: propertyInput?.pitiData.taxes,
      xs: 12,
      lg: 6,
      details: true,
    },
    {
      type: 'dollar',
      label: 'Insurance',
      name: 'pitiData.insurance',
      value: propertyInput?.pitiData.insurance,
      xs: 12,
      lg: 6,
      details: true,
    },
    {
      type: 'text',
      label: 'Loan type',
      name: 'pitiData.loanType',
      value: propertyInput?.pitiData.loanType,
      xs: 12,
      lg: 6,
      details: true,
    },
    {
      type: 'datePicker',
      label: 'Origination Date',
      name: 'pitiData.originDate',
      value: new Date(propertyInput?.pitiData.originDate),
      xs: 12,
      lg: 6,
      details: true,
    },
    {
      name: 'pitiData.mortgageStatement',
      label: 'Mortgage statement',
      type: 'file',
      value: propertyInput?.pitiData.mortgageStatement,
      uploadFile: props.uploadFileProperty(
        'pitiData.mortgageStatement',
      ),
      // deleteFile: props.deleteFileProperty(),
      buttonText: 'Upload Mortgage statement',
      xs: 12,
      lg: 6,
      details: true,
    },
    {
      name: 'pitiData.insuranceStatement',
      label: 'Insurance statement',
      type: 'file',
      value: propertyInput?.pitiData.insuranceStatement,
      uploadFile: props.uploadFileProperty(
        'pitiData.insuranceStatement',
      ),
      // deleteFile: props.deleteFileProperty(),
      buttonText: 'Upload Insurance statement',
      xs: 12,
      lg: 6,
      details: true,
    },
  ]

  const strategySummary = activeOrg.isRealeflow
    ? {
        name: 'Fix & Flip',
        percentage:
          (analytics.fixFlip.onTargetFlipPep +
            analytics.fixFlip.aboveTargetFlipPep) /
          (analytics.fixFlip.belowTargetFlipPep +
            analytics.fixFlip.onTargetFlipPep +
            analytics.fixFlip.aboveTargetFlipPep),
        analytics: [
          {
            value: analytics.fixFlip.netProfitPep || '-',
            arrow: analytics.fixFlip.netProfitPepArrow || 0,
            label: 'Net Profit',
            target: assumptions.belowNetProfitPep,
            targetLabel: 'Min Net Profit Target',
            targetName: 'belowNetProfitPep',
            // tourStep: 'targets-step-2',
            min: 0,
            max: 25,
            minLabel: '0%',
            maxLabel: '25%',
            description: 'Profit as a percentage of ARV',
            calculation: (
              <>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      {analysis.resellPriceFixFlip
                        ? 'Resale Price'
                        : 'After Repair Value'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      {currencyFormat.format(
                        round(
                          analysis.resellPriceFixFlip ||
                            analytics.fixFlip.arvTotalFixFlip,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                {/* <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>-Holding Expenses</Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>-{currencyFormat.format(round(sum(holdingExpensesChartSeries), 0))}</Typography>
              </Grid>
            </Grid> */}
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -Sales Commission
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip
                            .salesCommissionTotalFixFlip,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -Disposititon Fee
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip
                            .dispositionFeeDollarFixFlip,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -Total Out-of-Pocket
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip.totalOutOfPocketFixFlip,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -Remaining Loan Balance
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip
                            .loanBalanceAtHoldingPeriod,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: 'white' }}>
                      Net Profit
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: 'white' }}>
                      {currencyFormat.format(
                        round(analytics.fixFlip.saleProceeds, 0),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      <FontAwesomeIcon
                        icon={['fal', 'divide']}
                        style={{ marginRight: 3 }}
                      />
                      After Repair Value
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      <FontAwesomeIcon
                        icon={['fal', 'divide']}
                        style={{ marginRight: 3 }}
                      />
                      {currencyFormat.format(
                        round(analytics.fixFlip.arvTotalFixFlip, 0),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: 'white' }}>
                      Net Profit to ARV
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: 'white' }}>
                      {analytics.fixFlip.netProfitPep > 1000
                        ? '+1000%'
                        : percentageFormat.format(
                            analytics.fixFlip.netProfitPep,
                          )}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ),
          },
          {
            value: analytics.fixFlip.holdingClosingCostPep || '-',
            arrow: analytics.fixFlip.holdingClosingCostPepArrow || 0,
            label: 'Holding + Closing Cost',
            target: assumptions.belowHoldingClosingCostPep,
            targetLabel: 'Min Holding + Closing Cost Target',
            targetName: 'belowHoldingClosingCostPep',
            // tourStep: 'targets-step-3',
            min: 0,
            max: 25,
            minLabel: '0%',
            maxLabel: '25%',
            description:
              'Holding + Closing Cost as a percentage of ARV.',
            calculation: (
              <>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      Closing Costs
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip.closeCostDollarFixFlip,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +Holding Expenses
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +
                      {currencyFormat.format(
                        round(sum(holdingExpensesChartSeries), 0),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: 'white' }}>
                      Holding + Closing Cost
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: 'white' }}>
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip.closeCostDollarFixFlip +
                            sum(holdingExpensesChartSeries),
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      <FontAwesomeIcon
                        icon={['fal', 'divide']}
                        style={{ marginRight: 3 }}
                      />
                      After Repair Value
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      <FontAwesomeIcon
                        icon={['fal', 'divide']}
                        style={{ marginRight: 3 }}
                      />
                      {currencyFormat.format(
                        round(analytics.fixFlip.arvTotalFixFlip, 0),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: 'white' }}>
                      Holding + Closing Cost to ARV
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: 'white' }}>
                      {analytics.fixFlip.holdingClosingCostPep > 1000
                        ? '+1000%'
                        : percentageFormat.format(
                            analytics.fixFlip.holdingClosingCostPep,
                          )}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ),
          },
          {
            value: analytics.fixFlip.totalCostFinancingPep || '-',
            arrow: analytics.fixFlip.totalCostFinancingPepArrow || 0,
            label: 'Total Cost Financing',
            target: assumptions.belowTotalCostFinancingPep,
            targetLabel: 'Min Total Cost Financing Target',
            targetName: 'belowTotalCostFinancingPep',
            // tourStep: 'targets-step-4',
            min: 0,
            max: 25,
            minLabel: '0%',
            maxLabel: '25%',
            description: `Total Cost Financing as a percentage of ARV`,
            calculation: (
              <>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      Loan Points
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip.pointsAmountFixFlip,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +Financing Fee
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip.financingFeeTotalFixFlip,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +Loan Balance Interest
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip
                            .mpInterestOnlyLoanFixFlip *
                            assumptions.holdingPeriod,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: 'white' }}>
                      Total Cost Financing
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: 'white' }}>
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip.pointsAmountFixFlip +
                            analytics.fixFlip
                              .financingFeeTotalFixFlip +
                            analytics.fixFlip
                              .mpInterestOnlyLoanFixFlip *
                              assumptions.holdingPeriod,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      <FontAwesomeIcon
                        icon={['fal', 'divide']}
                        style={{ marginRight: 3 }}
                      />
                      After Repair Value
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      <FontAwesomeIcon
                        icon={['fal', 'divide']}
                        style={{ marginRight: 3 }}
                      />
                      {currencyFormat.format(
                        round(analytics.fixFlip.arvTotalFixFlip, 0),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: 'white' }}>
                      Total Cost Financing to ARV
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: 'white' }}>
                      {analytics.fixFlip.totalCostFinancingPep > 1000
                        ? '+1000%'
                        : percentageFormat.format(
                            analytics.fixFlip.totalCostFinancingPep,
                          )}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ),
          },
        ],
      }
    : {
        name: 'Fix & Flip',
        percentage:
          (analytics.fixFlip.onTargetFlip +
            analytics.fixFlip.aboveTargetFlip) /
          (analytics.fixFlip.belowTargetFlip +
            analytics.fixFlip.onTargetFlip +
            analytics.fixFlip.aboveTargetFlip),
        analytics: [
          {
            value: analytics.fixFlip.saleProceeds || '-',
            arrow: analytics.fixFlip.saleProceedsArrow || 0,
            label: 'Net Profit',
            type: 'currency',
            target: assumptions.belowSaleProceeds,
            targetLabel: 'Min Net Profit Target',
            targetName: 'belowSaleProceeds',
            tourStep: 'targets-step-2',
            min: 0,
            max: 35000,
            minLabel: '$ 0',
            maxLabel: '$ 35k',
            step: 1000,
            valueLabelFormat: (x) =>
              x >= 1000 ? `${x / 1000}k` : `${x}`,
            description:
              'The amount of money left over from selling your home after accounting for the remaining mortgage balance, closing costs, and realtor fees.',
            calculation: (
              <>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      {analysis.resellPriceFixFlip
                        ? 'Resale Price'
                        : 'After Repair Value'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      {currencyFormat.format(
                        round(
                          analysis.resellPriceFixFlip ||
                            analytics.fixFlip.arvTotalFixFlip,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                {/* <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>-Holding Expenses</Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>-{currencyFormat.format(round(sum(holdingExpensesChartSeries), 0))}</Typography>
              </Grid>
            </Grid> */}
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -Sales Commission
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip
                            .salesCommissionTotalFixFlip,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -Disposititon Fee
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip
                            .dispositionFeeDollarFixFlip,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -Total Out-of-Pocket
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip.totalOutOfPocketFixFlip,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -Remaining Loan Balance
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip
                            .loanBalanceAtHoldingPeriod,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: 'white' }}>
                      Net Profit
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: 'white' }}>
                      {currencyFormat.format(
                        round(analytics.fixFlip.saleProceeds, 0),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ),
          },
          {
            value: analytics.fixFlip.netROIFixFlip || '-',
            arrow: analytics.fixFlip.netROIArrow || 0,
            label: 'Net ROI',
            target: assumptions.belowNetROI,
            targetLabel: 'Min Net ROI Target',
            targetName: 'belowNetROI',
            tourStep: 'targets-step-3',
            min: 0,
            max: 25,
            minLabel: '0%',
            maxLabel: '25%',
            description:
              'Return on investment (ROI) indicates the percentage of investment money that is recouped after the deduction of associated costs.',
            calculation: (
              <>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      {analysis.resellPriceFixFlip
                        ? 'Resale Price'
                        : 'After Repair Value'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      {currencyFormat.format(
                        round(
                          analysis.resellPriceFixFlip ||
                            analytics.fixFlip.arvTotalFixFlip,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -Holding Expenses
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -
                      {currencyFormat.format(
                        round(sum(holdingExpensesChartSeries), 0),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -Sales Commission
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip
                            .salesCommissionTotalFixFlip,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -Disposititon Fee
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip
                            .dispositionFeeDollarFixFlip,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -Total Out-of-Pocket
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip.totalOutOfPocketFixFlip,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -Remaininig Loan Balance
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      -
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip
                            .loanBalanceAtHoldingPeriod,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: 'white' }}>
                      Net Profit
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: 'white' }}>
                      {currencyFormat.format(
                        round(analytics.fixFlip.saleProceeds, 0),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      <FontAwesomeIcon
                        icon={['fal', 'divide']}
                        style={{ marginRight: 3 }}
                      />
                      All-in-cost
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      <FontAwesomeIcon
                        icon={['fal', 'divide']}
                        style={{ marginRight: 3 }}
                      />
                      {currencyFormat.format(
                        round(analytics.fixFlip.allInCostFixFlip, 0),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: 'white' }}>
                      Net ROI
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: 'white' }}>
                      {analytics.fixFlip.netROIFixFlip > 1000
                        ? '+1000%'
                        : percentageFormat.format(
                            analytics.fixFlip.netROIFixFlip,
                          )}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ),
          },
          {
            value: analytics.fixFlip.percentageOfARV,
            arrow: analytics.fixFlip.percentageOfARVArrow || 0,
            label: 'All-In Cost to ARV',
            target: assumptions.belowARV,
            targetLabel: 'Min All-In Cost to ARV Target',
            targetName: 'belowARV',
            tourStep: 'targets-step-4',
            min: 50,
            max: 90,
            minLabel: '50%',
            maxLabel: '90%',
            description: `The All-In Cost to ARV (After Repair Value) that you purchase the property for. There is a popular term referred to as the “70% rule” when applied to fix and flipping houses.  The 70% rule states that an investor should pay 70 percent of the ARV of a property, minus the repairs needed.  The ARV is the after repaired value and is what a home is worth after it is fully repaired.  This “rule” says 70% but we have still found success at 80% of the ARV.`,
            calculation: (
              <>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      Purchase Price
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      {currencyFormat.format(
                        round(
                          analysis.offerPrice || analysis.listPrice,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +Acquisition Commission
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +
                      {currencyFormat.format(
                        round(
                          analytics.acquisitionCommissionDollar,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +Acquisition Fee
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +
                      {currencyFormat.format(
                        round(assumptions.acquisitionFee, 0),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +Closing Costs
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip.closeCostDollarFixFlip,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +Seller Closing Costs
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip
                            .sellingClosingCostDollarFixFlip,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +Loan Points
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip.pointsAmountFixFlip,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +Custom Acquisition Expenses
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +
                      {currencyFormat.format(
                        round(
                          analytics.customAcquisitionExpenseTotal,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +Rehab Amount
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip.repairsOutOfPocketFixFlip,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +Financing Fee
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +
                      {currencyFormat.format(
                        round(
                          analytics.fixFlip.financingFeeTotalFixFlip,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +Holding Expenses
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      +
                      {currencyFormat.format(
                        round(sum(holdingExpensesChartSeries), 0),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: 'white' }}>
                      All-in Cost
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: 'white' }}>
                      {currencyFormat.format(
                        round(analytics.fixFlip.allInCostFixFlip, 0),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      <FontAwesomeIcon
                        icon={['fal', 'divide']}
                        style={{ marginRight: 3 }}
                      />
                      After Repair Value
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: '#9198a8' }}>
                      <FontAwesomeIcon
                        icon={['fal', 'divide']}
                        style={{ marginRight: 3 }}
                      />
                      {currencyFormat.format(
                        round(analytics.fixFlip.arvTotalFixFlip, 0),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  <Grid item>
                    <Typography style={{ color: 'white' }}>
                      All-in Cost to ARV
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{ color: 'white' }}>
                      {analytics.fixFlip.percentageOfARV > 1000
                        ? '+1000%'
                        : percentageFormat.format(
                            analytics.fixFlip.percentageOfARV,
                          )}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ),
          },
        ],
      }

  const financials = [
    {
      value: analytics.fixFlip.allInCostFixFlip,
      label: 'All-in Cost',
      description:
        'Total amount of initial investment into a property, including renovation and points paid for financing.',
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Purchase Price
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                {currencyFormat.format(
                  round(analysis.offerPrice || analysis.listPrice, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Commission
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.acquisitionCommissionDollar, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Fee
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(assumptions.acquisitionFee, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Closing Costs
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.fixFlip.closeCostDollarFixFlip, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Seller Closing Costs
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(
                    analytics.fixFlip.sellingClosingCostDollarFixFlip,
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Loan Points
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.fixFlip.pointsAmountFixFlip, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Rehab Amount
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(
                    analytics.fixFlip.repairsOutOfPocketFixFlip,
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Financing Fee
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(
                    analytics.fixFlip.financingFeeTotalFixFlip,
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Owner Reserves
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(assumptions.ownerReservesFixFlip, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Custom Acquisition Expenses
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.customAcquisitionExpenseTotal, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Holding Expenses
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(sum(holdingExpensesChartSeries), 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                All-in Cost
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'white' }}>
                {currencyFormat.format(
                  round(analytics.fixFlip.allInCostFixFlip, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      value: analytics.fixFlip.totalOutOfPocketFixFlip,
      label: 'Total Out-of-Pocket',
      description:
        'Total amount of cash that a buyer has put into a property of his/her own capital.',
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Down Payment
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                {currencyFormat.format(
                  round(analytics.fixFlip.downPaymentTotalFixFlip, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Commission
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.acquisitionCommissionDollar, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Fee
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(assumptions.acquisitionFee, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Closing Costs
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.fixFlip.closeCostDollarFixFlip, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Seller Closing Costs
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(
                    analytics.fixFlip.sellingClosingCostDollarFixFlip,
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Financing Fee
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(
                    analytics.fixFlip.financingFeeTotalFixFlip,
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Loan Points
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.fixFlip.pointsAmountFixFlip, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Custom Acquisition Expenses
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.customAcquisitionExpenseTotal, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Credit From Seller
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -
                {currencyFormat.format(
                  round(assumptions.creditFromSeller, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Rehab Amount
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(
                    analytics.fixFlip.repairsOutOfPocketFixFlip,
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Holding Expenses
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(sum(holdingExpensesChartSeries), 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                Total Out-of-Pocket
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'white' }}>
                {currencyFormat.format(
                  round(analytics.fixFlip.totalOutOfPocketFixFlip, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      value: pepMaxOfferPrice,
      label: 'Property Edge Pro - Max Offer Price',
      description: 'Highest Recommended Offer Price',
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                After Repair Value
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                {currencyFormat.format(
                  analytics.fixFlip.arvTotalFixFlip,
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                *Percentage of ARV
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                *
                {percentageFormat.format(
                  assumptions.percentageOfARVFixFlip / 100,
                )}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Sugested Offer Price
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                {currencyFormat.format(
                  (analytics.fixFlip.arvTotalFixFlip *
                    assumptions.percentageOfARVFixFlip) /
                    100,
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Rehab Costs
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -
                {currencyFormat.format(
                  round(analytics.fixFlip.corTotalFixFlip, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -10% of ARV
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -
                {currencyFormat.format(
                  analytics.fixFlip.arvTotalFixFlip * 0.1,
                )}
              </Typography>
            </Grid>
          </Grid> */}
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                Offer Price
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'white' }}>
                {currencyFormat.format(pepMaxOfferPrice)}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
  ]

  const compData = [
    {
      label: 'Avg. Adjusted Value',
      value: isEmpty(props.compValues)
        ? 0
        : meanBy(
            filter(props.compValues, 'propertyPrice'),
            'propertyPrice',
          ),
      dataTour: 'comparableProperties-step-3',
    },
    {
      range: true,
      label: 'Adjusted Range',
      value1: isEmpty(props.compValues)
        ? 0
        : minBy(props.compValues, 'propertyPrice').propertyPrice /
          1000,
      value2: isEmpty(props.compValues)
        ? 0
        : maxBy(props.compValues, 'propertyPrice').propertyPrice /
          1000,
      dataTour: 'comparableProperties-step-4',
    },
    {
      label: 'Avg Adjusted Price/sqft',
      value: isEmpty(props.compValues)
        ? 0
        : meanBy(
            filter(props.compValues, 'propertyPerSqft'),
            'propertyPerSqft',
          ),
      dataTour: 'comparableProperties-step-5',
    },
    {
      label: 'Lowest 3 comp avg',
      value: props.lowestAverage.afterRepairValue,
      dataTour: 'comparableProperties-step-6',
    },
  ]

  const fixAndFlipCardsToOrder = [
    {
      name: 'bigPicture',
      hide: !activeOrg.isRealeflow,
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Big Picture</b>
            </div>
            {/* <div>
              <Tooltip title="Projected fund totals an investor will need to produce in order to acquire the property">
                <Button onClick={() => setOpenFixFlipFinancingTour(true)} className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div> */}
          </div>
          <Divider />
          <CardContent>
            {/* <p className="mb-3 text-black-80 text-center" data-tour="financing-step-3">
              Based on your <b>{currencyFormat.format(analytics.fixFlip.allInCostFixFlip)}</b> <Tooltip title='The total amount of money this deal costs, including rehab, closing costs, points, etc.'><span className="text-first" onClick={() => toggle1('All-in Cost')} style={{ textDecoration: 'underline' }}>all-in cost</span></Tooltip>, you'll need to pay <b>{currencyFormat.format(analytics.fixFlip.totalOutOfPocketFixFlip)}</b> <Tooltip title='Total amount that the buyer has to cover with their own money.'><span className="text-first" onClick={() => toggle1('Total Out-of-Pocket')} style={{ textDecoration: 'underline' }}>out-of-pocket</span></Tooltip> to cover the downpayment, closing costs, and rehab for this property.
            </p> */}
            {/* <Divider style={{ marginBottom: 15, marginTop: 15 }} /> */}
            <Grid container>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  // alignItems="center"
                  spacing={1}
                >
                  {map(pepBigPictureFields, (section) =>
                    renderInputFields(section),
                  )}
                </Grid>
                <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
                  <Grid
                    container
                    className="d-flex bg-white justify-content-between py-3"
                  >
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="space-around"
                      className="d-flex bg-white justify-content-between py-3"
                      onClick={() =>
                        toggle1('Property Edge Pro - Max Offer Price')
                      }
                    >
                      <Grid item className="gridItem2">
                        <Typography variant="h3">
                          {pepMaxOfferPrice > 0
                            ? currencyFormat.format(pepMaxOfferPrice)
                            : '-'}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        className="gridItem2"
                        style={{ textAlign: 'center' }}
                      >
                        <Typography style={{ color: 'black' }}>
                          Suggested Offer Price
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      className="d-flex justify-content-center"
                    >
                      <div data-tour="financing-step-1">
                        <div className="text-dark pb-1">
                          Estimated Profit
                        </div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div className="d-flex p-1 align-items-center">
                            {/* <div className="badge badge-success badge-circle mr-2">
                        fast
                      </div> */}
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={pepMaxEstProfit + extraPepProfit}
                                duration={1}
                                separator=","
                                decimals={0}
                                prefix="$"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      className="d-flex justify-content-center"
                    >
                      <div data-tour="financing-step-2">
                        <div className="text-dark pb-1">
                          Strength of Offer
                        </div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div className="d-flex p-1 align-items-center">
                            {/* <div className="badge badge-warning badge-circle mr-2">
                        normal
                      </div> */}
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={round(
                                  (effectiveOfferPrice /
                                    analysis.listPrice || 0) * 100,
                                  0,
                                )}
                                duration={1}
                                delay={2}
                                separator=","
                                decimals={0}
                                suffix="%"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                {/* {!props.shared && (
                  <div className="px-4 pb-4 pt-2">
                    <ContactAgent
                      property={property}
                      propertyPage={true}
                      canDrag={props.canDrag}
                      defaultOffer={effectiveOfferPrice}
                    />
                  </div>
                )} */}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'bigPictureSummary',
      hide: !activeOrg.isRealeflow,
      card: (
        <Card className="card-box w-100">
          <div className="card-header">
            <div className="card-header--title">
              <b>Big Picture Summary</b>
            </div>
            {/* <div>
              <Tooltip title="Quick glance determination seen on the main page">
                <Button onClick={() => setOpenFixFlipStrategyTour(true)} className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div> */}
          </div>
          <Divider />
          <div className="d-flex flex-column flex-grow-1 justify-content-center w-100">
            <div className="card-header-alt d-flex justify-content-center px-4 pt-4 pb-3" />
            <span
              data-tour-fixFlip="gauge-step-1"
              className="d-flex flex-column flex-grow-1 justify-content-center px-4 pt-4 pb-3"
            >
              <div
                className="mx-auto overflow-hidden"
                style={{ paddingBottom: 20 }}
              >
                <GaugeChart
                  id="chartsGauges3B"
                  nrOfLevels={6}
                  colors={['#f83245', '#f4772e', '#1bc943']}
                  arcWidth={0.4}
                  hideText
                  percent={
                    effectiveOfferPrice / analysis.listPrice > 0.9
                      ? 1
                      : effectiveOfferPrice / analysis.listPrice < 0.7
                        ? 0
                        : (effectiveOfferPrice / analysis.listPrice -
                            0.7) *
                            ((5 * 2) / 3) +
                          1 / 6
                  }
                />
              </div>
              <p
                className="mb-3 text-black-80 text-center"
                style={{ paddingLeft: '10px', paddingRight: '10px' }}
              >
                Your current Strength of Offer is{' '}
                {analysis.listPrice
                  ? `${round(
                      (effectiveOfferPrice / analysis.listPrice ||
                        0) * 100,
                      0,
                    )}
                  %${' '}`
                  : '-'}
              </p>
            </span>
            <span
              data-tour-fixFlip="gauge-step-2"
              className="d-flex flex-column flex-grow-1 justify-content-center px-4 pt-4 pb-3"
            >
              <div
                className="mx-auto overflow-hidden"
                style={{ paddingBottom: 20, width: '100%' }}
              >
                <Suspense fallback={<div>Loading Chart...</div>}>
                  <Chart
                    options={bigPepChartOptions}
                    series={bigPepChartSeries}
                    type="bar"
                  />
                </Suspense>
              </div>
              <p
                className="mb-3 text-black-80 text-center"
                style={{ paddingLeft: '10px', paddingRight: '10px' }}
              >
                {/* Between your Net Profit and 10% of the ARV,{' '}
                {(pepEstProfit || 25000) >
                0.1 * analytics.fixFlip.arvTotalFixFlip
                  ? 'Net Profit'
                  : '10% of ARV'}{' '}
                is higher with a total of{' '}
                {currencyFormat.format(pepMaxEstProfit)}{' '} */}
                Check to see that the total profit is at least $25,000
                or 10% of ARV, whichever is higher.
              </p>
            </span>
            {!props.shared && (
              <div className="px-4 pb-4 pt-2">
                <ContactAgent
                  property={property}
                  propertyPage={true}
                  canDrag={props.canDrag}
                  defaultOffer={effectiveOfferPrice}
                />
              </div>
            )}
          </div>
        </Card>
      ),
    },
    {
      name: 'strategy',
      card: (
        <Card className="card-box w-100">
          <div className="card-header">
            <div className="card-header--title">
              <small className="d-block text-uppercase mt-1">
                Strategy
              </small>
              <b>Fix & Flip</b>
            </div>
            <div>
              <Tooltip title="Quick glance determination seen on the main page">
                <Button
                  onClick={() => setOpenFixFlipStrategyTour(true)}
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <div className="d-flex flex-column flex-grow-1 justify-content-center w-100">
            <div className="card-header-alt d-flex justify-content-center px-4 pt-4 pb-3" />
            <span
              data-tour-fixFlip="strategy-step-1"
              className="d-flex flex-column flex-grow-1 justify-content-center px-4 pt-4 pb-3"
            >
              <div
                className="mx-auto overflow-hidden"
                style={{ paddingBottom: 20 }}
              >
                <GaugeChart
                  id="chartsGauges3B"
                  nrOfLevels={6}
                  colors={['#f83245', '#f4772e', '#1bc943']}
                  arcWidth={0.4}
                  hideText
                  percent={strategySummary.percentage}
                />
              </div>
              <p
                className="mb-3 text-black-80 text-center"
                style={{ paddingLeft: '10px', paddingRight: '10px' }}
              >
                According to your inputs, a {strategySummary.name}{' '}
                strategy meets{' '}
                {strategySummary.percentage < 0.33 && (
                  <b style={{ color: '#f83245' }}>
                    {round(strategySummary.percentage * 100)}%
                  </b>
                )}
                {strategySummary.percentage > 0.33 &&
                  strategySummary.percentage < 0.66 && (
                    <b style={{ color: '#f4772e' }}>
                      {round(strategySummary.percentage * 100)}%
                    </b>
                  )}
                {strategySummary.percentage > 0.66 && (
                  <b style={{ color: '#1bc943' }}>
                    {round(strategySummary.percentage * 100)}%
                  </b>
                )}{' '}
                of your targets
              </p>
            </span>
            {!props.shared &&
              activeOrg.member !== 'gorillacapital' && (
                <div className="px-4 pb-4 pt-2">
                  <ContactAgent
                    property={property}
                    propertyPage={true}
                    canDrag={props.canDrag}
                  />
                </div>
              )}
            {activeOrg.member === 'gorillacapital' && (
              <div className="px-4 pb-4 pt-2">
                {property.lender ? (
                  <Card className="card-box">
                    <CardContent className="px-4 py-3">
                      <div className="pb-3 d-flex justify-content-between">
                        Property Submission Status
                      </div>
                      <div className="d-flex align-items-center justify-content-start">
                        <span
                          style={{
                            color: 'white',
                            backgroundColor: '#368CFF',
                            paddingLeft: 5,
                            paddingRight: 5,
                          }}
                        >
                          {property.lenderStatus}
                        </span>
                      </div>
                    </CardContent>
                  </Card>
                ) : (
                  <Button
                    fullWidth
                    className="btn-success"
                    onClick={
                      props.fixFlipPercentage < 100
                        ? () => props.setOpenMemberDisclaimer(true)
                        : () => props.setOpenMember(true)
                    }
                  >
                    <span className="btn-wrapper--icon">
                      <img
                        src="/images/white-gorilla-only.png"
                        alt="gorilla"
                        height={50}
                      />
                    </span>
                    <span
                      className="btn-wrapper--label"
                      style={{ fontSize: 16 }}
                    >
                      Submit to Gorilla
                    </span>
                  </Button>
                )}
              </div>
            )}
          </div>
        </Card>
      ),
    },
    {
      name: 'targets',
      card: (
        <Card className="card-box w-100">
          <div className="card-header">
            <div className="card-header--title">
              <small className="d-block text-uppercase mt-1">
                Does it meet your
              </small>
              <b>Targets</b>
            </div>
            <div>
              <Tooltip title="Does this property meet your specified targets?">
                <Button
                  onClick={() => setOpenFixFlipTargetsTour(true)}
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="space-around"
            onClick={toggleMaxOffer}
            data-tour="targets-step-1"
            className="d-flex bg-white hover-scale-rounded justify-content-between py-3"
          >
            <Grid item className="gridItem2">
              <Typography variant="h3">
                {!activeOrg.isRealeflow
                  ? analytics.fixFlip.maxFixAndFlipPricePep
                    ? currencyFormat.format(
                        analytics.fixFlip.maxFixAndFlipPricePep,
                      )
                    : '-'
                  : analytics.fixFlip.maxFixAndFlipPrice
                    ? currencyFormat.format(
                        analytics.fixFlip.maxFixAndFlipPrice,
                      )
                    : '-'}
              </Typography>
            </Grid>
            <Grid
              item
              className="gridItem2"
              style={{ textAlign: 'center' }}
            >
              <Typography style={{ color: 'grey' }}>
                Max offer based on targets
              </Typography>
            </Grid>
          </Grid>
          <Dialog
            open={maxOfferDescription}
            onClose={toggleMaxOffer}
            maxWidth="lg"
            scroll="body"
            classes={{
              paper:
                'modal-content rounded border-0 bg-white p-3 p-xl-0',
            }}
          >
            {/* <BlockUi blocking={loader} loader={<PacmanLoader color={'var(--primary)'} loading={true} />}> */}
            <Grid container spacing={0} direction="row">
              <Grid item sm={5}>
                <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100  br-xl-right-0">
                  <div className="flex-grow-1 w-100 d-flex align-items-center">
                    <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                    <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                      <div className="text-white mt-3">
                        <Typography
                          style={{ fontSize: 30, fontWeight: 600 }}
                        >
                          Relevant Data
                        </Typography>
                      </div>
                      <>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              Purchase Price:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {currencyFormat.format(
                                analysisInput.offerPrice
                                  ? analysisInput.offerPrice
                                  : analysisInput.listPrice,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              Resale Price:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {currencyFormat.format(
                                analysisInput.resellPriceFixFlip,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              All In Cost:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {currencyFormat.format(
                                analytics.fixFlip.allInCostFixFlip,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              Total Out Of Pocket:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {currencyFormat.format(
                                analytics.fixFlip
                                  .totalOutOfPocketFixFlip,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              Net Profit:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {currencyFormat.format(
                                analytics.fixFlip.saleProceeds,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              Holding Period (months):
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {assumptionsInput.holdingPeriod}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item sm={7}>
                <div className="hero-wrapper h-100 br-xl-right-0">
                  <Card className="flex-grow-1 w-100">
                    <CardHeader title="Max Offer" />
                    <Divider />
                    <CardContent>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid
                          item
                          xs={12}
                          style={{ marginBottom: 25 }}
                        >
                          <Card
                            style={{
                              backgroundColor: '#eff2f5',
                              borderRadius: 0,
                            }}
                          >
                            <CardContent>
                              <Typography>
                                Max Offer is the highest purchase
                                price that still meets all of your
                                strategy's targets. The Max Offer will
                                show a dash if the calculation is less
                                than zero or no purchase price can
                                meet all targets.
                              </Typography>
                              <Typography>
                                The Max Offer Price is calculated by
                                determining the the highest purchase
                                price that will meet each specific
                                target then taking the lowest value
                                from them. This means that any value
                                that affects a target besides purchase
                                price can affect the Max Offer Price
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={toggleMaxOffer}
                          >
                            Close
                          </Button>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                </div>
              </Grid>
            </Grid>
            {/* </BlockUi> */}
          </Dialog>
          <Divider />
          <List component="div" className="list-group-flush">
            {map(strategySummary.analytics, (section) => (
              <>
                <ListItem
                  component="a"
                  button
                  data-tour={section.tourStep}
                  href={null}
                  disableRipple
                  onClick={() => toggle1(section.label)}
                  className="d-flex bg-white hover-scale-rounded justify-content-between py-3"
                >
                  <div className="avatar-icon-wrapper avatar-initials d-50 mr-3">
                    {!isUndefined(section.arrow) && (
                      <>
                        {section.arrow ? (
                          section.arrow === 2 ? (
                            <Avatar
                              style={{
                                backgroundColor: '#2296F3',
                              }}
                            >
                              <ArrowUpwardIcon
                                style={{
                                  color: 'white',
                                }}
                              />
                            </Avatar>
                          ) : (
                            <Avatar
                              style={{
                                backgroundColor: '#46de64',
                              }}
                            >
                              <ArrowUpwardIcon
                                style={{
                                  color: 'white',
                                }}
                              />
                            </Avatar>
                          )
                        ) : (
                          <Avatar
                            style={{
                              backgroundColor: '#F05248',
                            }}
                          >
                            <ArrowDownwardIcon
                              style={{
                                color: 'white',
                              }}
                            />
                          </Avatar>
                        )}
                      </>
                    )}
                  </div>
                  <div className="flex-fill">
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Grid
                        item
                        className="font-weight-bold text-black"
                      >
                        {section.label}
                      </Grid>
                      <Grid item className="ml-auto">
                        <b>
                          {section.value !== '-'
                            ? section.type === 'currency'
                              ? currencyFormat.format(section.value)
                              : section.value > 10
                                ? '+1000%'
                                : percentageFormat.format(
                                    section.value,
                                  )
                            : section.value}
                        </b>
                      </Grid>
                    </Grid>
                    <LinearProgress
                      variant="determinate"
                      className={
                        section.arrow === 0
                          ? 'progress-xs progress-animated-alt my-2 progress-bar-danger'
                          : 'progress-xs progress-animated-alt my-2 progress-bar-success'
                      }
                      //value={!section.target ? 0 : section.arrow ? 100 : section.type === 'currency' ? section.value / section.target * 100 : section.value / section.target * 10000}
                      value={
                        section.arrow
                          ? 100
                          : section.type === 'currency'
                            ? (section.value / section.target) * 100
                            : section.label ===
                                  'All-In Cost to ARV' ||
                                section.label ===
                                  'Holding + Closing Cost' ||
                                section.label ===
                                  'Total Cost Financing'
                              ? 200 -
                                (section.value / section.target) *
                                  10000
                              : (section.value / section.target) *
                                10000
                      }
                    />
                    <div className="d-flex justify-content-between font-size-xs">
                      {!isUndefined(section.arrow) && (
                        <div
                          className={
                            section.arrow === 0
                              ? 'text-danger'
                              : 'text-success'
                          }
                        >
                          {section.arrow === 0
                            ? 'Below target'
                            : section.arrow === 1
                              ? 'Target met'
                              : 'Above target'}
                        </div>
                      )}
                      <div className="opacity-5">
                        Target:{' '}
                        {section.type === 'currency'
                          ? currencyFormat.format(section.target)
                          : section.target + '%'}
                      </div>
                    </div>
                  </div>
                </ListItem>
                <Dialog
                  open={modal1 === section.label}
                  onClose={toggle1Close}
                  maxWidth="lg"
                  scroll="body"
                  classes={{
                    paper:
                      'modal-content rounded border-0 bg-white p-3 p-xl-0',
                  }}
                >
                  {/* <BlockUi blocking={loader} loader={<PacmanLoader color={'var(--primary)'} loading={true} />}> */}
                  <Grid container spacing={0} direction="row">
                    <Grid item sm={5}>
                      <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100  br-xl-right-0">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                          <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                            <div className="text-white mt-3">
                              <Typography
                                style={{
                                  fontSize: 30,
                                  fontWeight: 600,
                                }}
                              >
                                Calculation
                              </Typography>
                            </div>
                            {section.calculation}
                            <div className="text-white mt-3">
                              <Typography>
                                * Calculation may be a few dollars off
                                due to rounding
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item sm={7}>
                      <div className="hero-wrapper h-100 br-xl-right-0">
                        <Card className="flex-grow-1 w-100">
                          <CardHeader title={section.label} />
                          <Divider />
                          <CardContent>
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Grid
                                item
                                xs={12}
                                style={{ marginBottom: 25 }}
                              >
                                <Card
                                  style={{
                                    backgroundColor: '#eff2f5',
                                    borderRadius: 0,
                                  }}
                                >
                                  <CardContent>
                                    <Typography>
                                      {section.description}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Grid>
                              {renderInputFields({
                                type: 'slider',
                                name: section.targetName,
                                label: section.targetLabel,
                                value:
                                  assumptionsInput[
                                    section.targetName
                                  ],
                                xs: 12,
                                assumptions: true,
                                valueLabelFormat:
                                  section.valueLabelFormat
                                    ? section.valueLabelFormat
                                    : (x) =>
                                        x === section.max
                                          ? `${x}+`
                                          : `${x}%`,
                                step: section.step,
                                min: section.min,
                                max: section.max,
                              })}
                            </Grid>
                          </CardContent>
                          <Divider />
                          <CardActions>
                            <Grid container justifyContent="center">
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={toggle1Close}
                                >
                                  Close
                                </Button>
                              </Grid>
                            </Grid>
                          </CardActions>
                        </Card>
                      </div>
                    </Grid>
                  </Grid>
                  {/* </BlockUi> */}
                </Dialog>
              </>
            ))}
          </List>
        </Card>
      ),
    },
    {
      name: 'netProfit',
      card: (
        <Card className="card-box w-100">
          <div className="card-header">
            <div className="card-header--title">
              <small className="d-block text-uppercase mt-1">
                Summary
              </small>
              <b>Net Profit from Sale</b>
            </div>
            <div>
              <Tooltip title="Breakdown of the resale price minus expenses to show net profit">
                <Button
                  onClick={() =>
                    setOpenFixFlipNetProfitFromSaleTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Grid content>
              <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
                <Grid
                  container
                  className="text-black-80 font-size-sm"
                >
                  <Grid
                    item
                    md={4}
                    data-tour="netProfitFromSale-step-1"
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">
                        Resale Price
                      </div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          <div className="badge badge-success badge-circle mr-2">
                            fast
                          </div>
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={analysis.resellPriceFixFlip / 1000}
                              duration={1}
                              delay={2}
                              decimals={1}
                              decimal="."
                              prefix="$"
                              suffix="k"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    data-tour="netProfitFromSale-step-2"
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">Expenses</div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          <div className="badge badge-danger badge-circle mr-2">
                            normal
                          </div>
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={
                                (analytics.fixFlip
                                  .totalOutOfPocketFixFlip +
                                  //+ sum(holdingExpensesChartSeries)  ##SPECIFIC COMMENT: holding expenses are part of the sellingTotalOutOfPocket
                                  //+ analytics.fixFlip.closeCostDollarFixFlip
                                  analytics.fixFlip
                                    .salesCommissionTotalFixFlip +
                                  analytics.fixFlip
                                    .dispositionFeeDollarFixFlip +
                                  analytics.fixFlip
                                    .loanBalanceAtHoldingPeriod) /
                                1000
                              }
                              duration={1}
                              delay={2}
                              decimals={1}
                              decimal="."
                              prefix="$"
                              suffix="k"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    data-tour="netProfitFromSale-step-3"
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">Net Profit</div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          <div className="badge badge-primary badge-circle mr-2">
                            slow
                          </div>
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={
                                analytics.fixFlip.saleProceeds / 1000
                              }
                              duration={1}
                              delay={2}
                              separator=","
                              decimals={0}
                              prefix="$"
                              suffix="k"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <>
                <Suspense fallback={<div>Loading Chart...</div>}>
                  <Chart
                    options={chartOptions}
                    series={chartSeries}
                    type="bar"
                  />
                </Suspense>
              </>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'acquisition',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Acquisition Expenses</b>
            </div>
            <div>
              <Tooltip title="The total projected expenses required to purchase the property">
                <Button
                  onClick={() =>
                    setOpenFixFlipAcquisitionExpensesTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Grid content>
              <Grid item>
                <div className="d-flex justify-content-center">
                  <Suspense fallback={<div>Loading Chart...</div>}>
                    <Chart
                      data-tour="acquisitionExpenses-step-1"
                      options={acquisitionChartOptions}
                      series={acquisitionChartSeries}
                      type="donut"
                      width="300"
                    />
                  </Suspense>
                </div>
              </Grid>
              <Divider style={{ marginBottom: 15, marginTop: 15 }} />
              <Grid item>
                <Grid
                  container
                  direction="row"
                  // alignItems="center"
                  spacing={1}
                >
                  <Grid
                    container
                    item
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item data-tour="acquisitionExpenses-step-2">
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="Advanced Fields"
                              checked={state.checkedA}
                              onChange={handleChange}
                              name="checkedA"
                            />
                          }
                          label="Advanced Fields"
                        />
                      </FormGroup>
                    </Grid>
                    {(!props.publicDashboard ||
                      !property.hideDaysOnMarketplace) && (
                      <Grid
                        item
                        data-tour="acquisitionExpenses-step-3"
                      >
                        <Tooltip title="Refers to the number of days a house is listed on the market and the day it is sold.">
                          <Typography
                            style={{
                              fontSize: 14,
                              textAlign: 'right',
                              backgroundColor: '#e8ecef',
                              padding: 5,
                            }}
                          >
                            <FontAwesomeIcon
                              icon={['far', 'clock']}
                              style={{ marginRight: 5 }}
                            />
                            {analysisInput.daysOnMarket} days on
                            market
                          </Typography>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                  {map(
                    state.checkedA
                      ? concat(
                          basicAcquisitionFields,
                          advancedAcquisitionFields,
                          customAcquisitonField,
                        )
                      : concat(
                          basicAcquisitionFields,
                          customAcquisitonField,
                        ),
                    (field) => renderInputFields(field),
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'income',
      card: (
        <Card className="card-box w-100" style={{ width: '100%' }}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            direction="row"
          >
            <Grid item xs={8} style={{ overflow: 'scroll' }}>
              <List
                className="nav-line d-flex"
                style={{
                  width:
                    analysisInput.rent.length > 1
                      ? analysisInput.rent.length * 100
                      : '100%',
                }}
              >
                {analysisInput.rent.length > 1 ? (
                  map(analysisInput.rent, (value, index) => (
                    <ListItem
                      button
                      disableRipple
                      selected={props.activeUnit === index}
                      onClick={() => {
                        props.setActiveUnit(index)
                      }}
                      className="mt-3"
                    >
                      Unit {index + 1}
                    </ListItem>
                  ))
                ) : (
                  <ListItem className="my-1">
                    <div className="card-header--title">
                      <b>Gross Monthly Income</b>
                    </div>
                  </ListItem>
                )}
              </List>
            </Grid>
            <Grid item style={{ marginRight: 20 }}>
              <Tooltip title="Total income generated from the property on a monthly basis">
                {assumptionsInput.rentType === 'singleRent' ? (
                  <Button
                    onClick={() =>
                      setOpenFixFlipGrossMonthlyIncomeSingleRentTour(
                        true,
                      )
                    }
                    className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                  >
                    <FontAwesomeIcon icon={['fal', 'info-circle']} />
                  </Button>
                ) : (
                  <Button
                    onClick={() =>
                      setOpenFixFlipGrossMonthlyIncomeRentRangeTour(
                        true,
                      )
                    }
                    className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                  >
                    <FontAwesomeIcon icon={['fal', 'info-circle']} />
                  </Button>
                )}
              </Tooltip>
            </Grid>
          </Grid>
          <Divider />
          <CardContent>
            <Grid content>
              <Grid item>
                <div className="d-flex justify-content-center">
                  <Suspense fallback={<div>Loading Chart...</div>}>
                    <Chart
                      data-tour="grossMonthlyIncomeSingleRent-step-1-grossMonthlyIncomeRentRange-step-1"
                      options={grossMonthlyIncomeChartOptions}
                      series={grossMonthlyIncomeChartSeries}
                      type="donut"
                      width="300"
                    />
                  </Suspense>
                </div>
              </Grid>
              <Divider style={{ marginBottom: 15, marginTop: 15 }} />
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  spacing={1}
                >
                  {assumptionsInput.rentType === 'singleRent'
                    ? map(
                        grossMonthlyIncomeSingleRentFields,
                        (field) => renderInputFields(field),
                      )
                    : map(
                        grossMonthlyIncomeRentRangeFields,
                        (field) => renderInputFields(field),
                      )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'rehab',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Rehab Expenses</b>
            </div>
            <div>
              {/* <Tooltip title="Estimated expenses allocated to the renovations of a property">
                <Button onClick={() => setOpenFixFlipRehabExpensesTour(true)} className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip> */}
              <Tooltip title="Estimated expenses allocated to the renovations of a property">
                {assumptionsInput.rehabType === 'detailedRehab' ? (
                  <Button
                    onClick={() =>
                      setOpenFixFlipRehabExpensesDetailedRehabTour(
                        true,
                      )
                    }
                    className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                  >
                    <FontAwesomeIcon icon={['fal', 'info-circle']} />
                  </Button>
                ) : assumptionsInput.rehabType === 'rehabTier' ? (
                  <Button
                    onClick={() =>
                      setOpenFixFlipRehabExpensesRehabTierTour(true)
                    }
                    className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                  >
                    <FontAwesomeIcon icon={['fal', 'info-circle']} />
                  </Button>
                ) : assumptionsInput.rehabType ===
                  'flippingAmericaRehab' ? (
                  <Button
                    onClick={() =>
                      setOpenFixFlipRehabExpensesFlippingAmericaRehabTour(
                        true,
                      )
                    }
                    className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                  >
                    <FontAwesomeIcon icon={['fal', 'info-circle']} />
                  </Button>
                ) : (
                  <Button
                    onClick={() =>
                      setOpenFixFlipRehabExpensesSingleRehabValueTour(
                        true,
                      )
                    }
                    className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                  >
                    <FontAwesomeIcon icon={['fal', 'info-circle']} />
                  </Button>
                )}
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Grid content>
              <Grid item>
                <div className="d-flex justify-content-center">
                  <Suspense fallback={<div>Loading Chart...</div>}>
                    <Chart
                      data-tour="rehabExpenses-step-1"
                      options={rehabChartOptions}
                      series={rehabChartSeries}
                      type="donut"
                      width="300"
                    />
                  </Suspense>
                </div>
              </Grid>
              <Divider style={{ marginBottom: 15, marginTop: 15 }} />
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  {map(rehabFields, (field) =>
                    renderInputFields(field),
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'holding',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Holding Expenses</b>
            </div>
            <div>
              <Tooltip title="Projected breakdown of a property’s expenses during a determined holding period">
                <Button
                  onClick={() =>
                    setOpenFixFlipHoldingExpensesTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Grid content>
              <Grid item>
                <div className="d-flex justify-content-center">
                  <Suspense fallback={<div>Loading Chart...</div>}>
                    <Chart
                      data-tour="holdingExpenses-step-1"
                      options={holdingExpensesChartOptions}
                      series={holdingExpensesChartSeries}
                      type="donut"
                      width="300"
                    />
                  </Suspense>
                </div>
              </Grid>
              <Divider style={{ marginBottom: 15, marginTop: 15 }} />
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  spacing={1}
                >
                  {map(holdingExpensesFields, (field) =>
                    renderInputFields(field),
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'financing',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Financing</b>
            </div>
            <div>
              <Tooltip title="Projected fund totals an investor will need to produce in order to acquire the property">
                <Button
                  onClick={() => setOpenFixFlipFinancingTour(true)}
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
              <Grid container className="text-black-80 font-size-sm">
                <Grid
                  item
                  md={
                    !activeOrg.isRealeflow &&
                    (!activeOrg.isAffiliate ||
                      !activeOrg.isAffiliate.isRealeflow)
                      ? 6
                      : 12
                  }
                  className="d-flex justify-content-center"
                >
                  <div data-tour="financing-step-1">
                    <div className="text-dark pb-1">Loan Amount</div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        {/* <div className="badge badge-success badge-circle mr-2">
                        fast
                      </div> */}
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={analytics.fixFlip.loanAmountFixFlip}
                            duration={1}
                            separator=","
                            decimals={0}
                            prefix="$"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                {!activeOrg.isRealeflow &&
                  (!activeOrg.isAffiliate ||
                    !activeOrg.isAffiliate.isRealeflow) && (
                    <Grid
                      item
                      md={6}
                      className="d-flex justify-content-center"
                    >
                      <div data-tour="financing-step-2">
                        <div className="text-dark pb-1">
                          Mortgage/mo
                        </div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div className="d-flex p-1 align-items-center">
                            {/* <div className="badge badge-warning badge-circle mr-2">
                          normal
                        </div> */}
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={
                                  analytics.fixFlip.mortgageFixFlip
                                }
                                duration={1}
                                delay={2}
                                separator=","
                                decimals={0}
                                prefix="$"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  )}
                {/* <Grid item md={4} className="d-flex justify-content-center">
                  <div>
                    <div className="text-dark pb-1">DSCR</div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                      <div className="badge badge-danger badge-circle mr-2">
                        slow
                      </div>
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={analytics.fixFlip.debtCoverageRatio}
                            duration={1}
                            delay={2}
                            separator=","
                            decimals={2}
                            suffix="x"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid> */}
              </Grid>
            </div>
            <p
              className="mb-3 text-black-80 text-center"
              data-tour="financing-step-3"
            >
              Based on your{' '}
              <b>
                {currencyFormat.format(
                  analytics.fixFlip.allInCostFixFlip,
                )}
              </b>{' '}
              <Tooltip title="The total amount of money this deal costs, including rehab, closing costs, points, etc.">
                <span
                  className="text-first"
                  onClick={() => toggle1('All-in Cost')}
                  style={{ textDecoration: 'underline' }}
                >
                  all-in cost
                </span>
              </Tooltip>
              , you'll need to pay{' '}
              <b>
                {currencyFormat.format(
                  analytics.fixFlip.totalOutOfPocketFixFlip,
                )}
              </b>{' '}
              <Tooltip title="Total amount that the buyer has to cover with their own money.">
                <span
                  className="text-first"
                  onClick={() => toggle1('Total Out-of-Pocket')}
                  style={{ textDecoration: 'underline' }}
                >
                  out-of-pocket
                </span>
              </Tooltip>{' '}
              to cover the downpayment, closing costs, and rehab for
              this property.
            </p>
            <Divider style={{ marginBottom: 15, marginTop: 15 }} />
            <Grid container>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  {map(financingFields, (section) =>
                    renderInputFields(section),
                  )}
                </Grid>
                {(!activeOrg.isAffiliate ||
                  (activeOrg.isAffiliate?.memberValue !==
                    'flippingamerica' &&
                    activeOrg.isAffiliate !== 'gorillacapital')) &&
                  activeOrg.member !== 'flippingamerica' &&
                  activeOrg.member !== 'gorillacapital' && (
                    <Lending
                      property={property}
                      shared={props.shared}
                      canDrag={props.canDrag}
                    />
                  )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'disposition',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Disposition Expenses</b>
            </div>
            <div>
              <Tooltip title="All costs and expenses associated with sale of this property">
                <Button
                  onClick={() =>
                    setOpenFixFlipDispositionExpensesTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Grid content>
              <Grid item>
                <div className="d-flex justify-content-center">
                  <Suspense fallback={<div>Loading Chart...</div>}>
                    <Chart
                      data-tour="dispositionExpenses-step-1"
                      options={dispositionChartOptions}
                      series={dispositionChartSeries}
                      type="donut"
                      width="300"
                    />
                  </Suspense>
                </div>
              </Grid>
              <Divider style={{ marginBottom: 15, marginTop: 15 }} />
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  {map(dispositionFields, (field) =>
                    renderInputFields(field),
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'comparableProperties',
      card: props.basicView ? null : (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Comparable Properties</b>
            </div>
            <div>
              <Tooltip title="Details about comparable properties">
                <Button
                  onClick={() =>
                    setOpenFixFlipComparablePropertiesTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
              <Grid container className="text-black-80 font-size-sm">
                <Grid
                  item
                  md={6}
                  className="d-flex justify-content-center"
                >
                  <div data-tour="comparableProperties-step-1">
                    <div className="text-dark pb-1">
                      Comparable Properties
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={props.determinedComps.length}
                            duration={1}
                            separator=","
                            decimals={0}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={6}
                  className="d-flex justify-content-center"
                >
                  <div data-tour="comparableProperties-step-2">
                    <div className="text-dark pb-1">
                      Potential Comps
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={
                              props.compProperties.length -
                              props.determinedComps.length
                            }
                            duration={1}
                            separator=","
                            decimals={0}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <List component="div" className="list-group-flush">
              {map(compData, (section) => (
                <ListItem
                  component="a"
                  button
                  disableRipple
                  data-tour={section.dataTour}
                  className="d-flex bg-white hover-scale-rounded justify-content-between py-3"
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Grid
                      item
                      className="font-weight-bold text-black"
                      style={{ olor: 'text' }}
                    >
                      {section.label}
                    </Grid>
                    {section.range ? (
                      <Grid item>
                        <Grid container direction="row" spacing={1}>
                          <Grid item>
                            <CountUp
                              start={0}
                              end={section.value1}
                              duration={1}
                              separator=","
                              delay={1}
                              decimals={1}
                              decimal="."
                              prefix="$"
                              suffix="k"
                            />
                          </Grid>
                          <Grid item>
                            <Typography>-</Typography>
                          </Grid>
                          <Grid item>
                            <CountUp
                              start={0}
                              end={section.value2}
                              duration={1}
                              separator=","
                              delay={1}
                              decimals={1}
                              decimal="."
                              prefix="$"
                              suffix="k"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item>
                        <CountUp
                          start={0}
                          end={section.value}
                          duration={1}
                          separator=","
                          delay={1}
                          decimals={2}
                          decimal="."
                          prefix="$"
                        />
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
              ))}
            </List>
            <Grid container>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: 25 }}
                  onClick={(e) => props.toggleCenter('Comps')}
                >
                  <Typography>View Comps</Typography>
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'piti',
      hide:
        props?.session?.me?.activeOrg?.member !== 'pacemorby' &&
        props?.session?.me?.activeOrg?.member !== 'astroflip',
      card: props.basicView ? null : (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Deal Sauce Summary</b>
            </div>
            <Select
              value={dealSauceView}
              name="dealSauceView"
              onChange={(event) =>
                setDealSauceView(event.target.value)
              }
              style={{ width: '100%' }}
            >
              <MenuItem value="cash" key="cash">
                Cash
              </MenuItem>
              <MenuItem value="creative" key="creative">
                Creative
              </MenuItem>
            </Select>
          </div>
          <Divider />
          <CardContent>
            {dealSauceView === 'cash' && (
              <>
                <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
                  <Grid
                    container
                    className="text-black-80 font-size-sm"
                  >
                    <Grid
                      item
                      md={6}
                      className="d-flex justify-content-center"
                    >
                      <div>
                        <div className="text-dark pb-1">
                          Entry Fee
                        </div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div
                            className="d-flex p-1 align-items-center"
                            style={{ minHeight: 50 }}
                          >
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={cashEntryFee}
                                duration={0}
                                separator=","
                                delay={1}
                                decimals={0}
                                decimal="."
                                prefix="$"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      className="d-flex justify-content-center"
                    >
                      <div>
                        <div className="text-dark pb-1">
                          Max Allowable Offer
                        </div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div
                            className="d-flex p-1 align-items-center"
                            style={{ minHeight: 50 }}
                          >
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={
                                  analysis.afterRepairValue -
                                  cashEntryFee
                                }
                                duration={0}
                                separator=","
                                delay={1}
                                decimals={0}
                                decimal="."
                                prefix="$"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <Grid item xs={12} style={{ marginTop: 35 }}>
                  <Grid container direction="column" spacing={4}>
                    <ContactInformation property={property} />
                  </Grid>
                </Grid>
                <CommentsDialog
                  property={property}
                  userId={props?.session?.me?._id}
                />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: 25 }}
                  onClick={(e) => setOpenPITICashData(true)}
                >
                  <Typography>View Cash Underwriting</Typography>
                </Button>
              </>
            )}
            {dealSauceView === 'creative' && (
              <>
                <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
                  <Grid
                    container
                    className="text-black-80 font-size-sm"
                  >
                    <Grid
                      item
                      md={6}
                      className="d-flex justify-content-center"
                    >
                      <div>
                        <div className="text-dark pb-1">
                          Entry Fee
                        </div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div className="d-flex p-1 align-items-center">
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={creativeEntryFee}
                                duration={1}
                                separator=","
                                delay={1}
                                decimals={0}
                                decimal="."
                                prefix="$"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      className="d-flex justify-content-center"
                    >
                      <div>
                        <div className="text-dark pb-1">PITI</div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div className="d-flex p-1 align-items-center">
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={pitiTotal}
                                duration={1}
                                separator=","
                                delay={1}
                                decimals={0}
                                decimal="."
                                prefix="$"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      className="d-flex justify-content-center"
                    >
                      <div>
                        <div className="text-dark pb-1">
                          % of Purchase Price
                        </div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div className="d-flex p-1 align-items-center">
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={
                                  analysis?.offerPrice &&
                                  analysis.offerPrice !== 0
                                    ? (creativeEntryFee /
                                        analysis.offerPrice) *
                                      100
                                    : 0
                                }
                                duration={1}
                                delay={1}
                                decimals={2}
                                decimal="."
                                suffix="%"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container direction="column" spacing={4}>
                      <ContactInformation property={property} />
                    </Grid>
                  </Grid>
                  <CommentsDialog
                    property={property}
                    userId={props?.session?.me?._id}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ marginTop: 25 }}
                    onClick={(e) => setOpenPITICreativeData(true)}
                  >
                    <Typography>
                      View Creative Underwriting
                    </Typography>
                  </Button>
                </Grid>
              </>
            )}
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'deactive',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Hide Cards</b>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Typography>
              All cards past this one will be hidden
            </Typography>
          </CardContent>
        </Card>
      ),
    },
  ]

  const getOrderDefault = () => {
    const storedValues = !isEmpty(property.fixAndFlipOrder)
      ? property.fixAndFlipOrder
      : merge(
          cloneDeep(DEFAULT_PREFERENCES),
          activeOrg.memberPreferences,
          activeOrg.preferences,
          props.session.me ? props.session.me.preferences : {},
        ).fixAndFlipOrder || []
    const newCards = difference(
      map(
        filter(fixAndFlipCardsToOrder, (card) => !card.hide),
        (card) => card.name,
      ),
      storedValues,
    )
    const deactiveIndex = findIndex(
      storedValues,
      (value) => value === 'deactive',
    )
    return uniq(
      concat(
        slice(storedValues, 0, deactiveIndex),
        newCards,
        slice(storedValues, deactiveIndex),
      ),
    )
  }

  const [fixAndFlipOrder, setFixAndFlipOrder] =
    useState(getOrderDefault())
  const [orderChanged, setOrderChanged] = useState(false)
  const [orderDefault, setOrderDefault] = useState(false)
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    setFixAndFlipOrder(getOrderDefault())
  }, [activeOrg.member])

  const SortableCard = SortableElement(({ item }) => (
    <Grid item sm={6} xl={4} className="d-flex">
      {item}
    </Grid>
  ))
  const SortableGrid = SortableContainer(({ items }) => (
    <Grid container direction="row" spacing={2} className="p-3">
      {map(items, (item, index) => (
        <SortableCard
          key={`item-${index}`}
          index={index}
          item={item}
        />
      ))}
    </Grid>
  ))

  const [updateProperty] = useMutation(UPDATE_PROPERTY)
  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newOrder = arrayMoveImmutable(
        fixAndFlipOrder,
        oldIndex,
        newIndex,
      )
      setFixAndFlipOrder(newOrder)
      setOrderChanged(true)
    }
  }

  const resetOrder = () => {
    setFixAndFlipOrder(getOrderDefault())
    setOrderChanged(false)
  }

  const submitOrder = () => {
    setLoader(true)
    const preferences = merge(
      cloneDeep(DEFAULT_PREFERENCES),
      activeOrg.memberPreferences,
      activeOrg.preferences,
    )
    updateProperty({
      refetchQueries: [
        {
          query: GET_PROPERTY,
          variables: { id: property._id },
        },
      ],
      variables: {
        id: property._id,
        propertyInput: {
          fixAndFlipOrder,
        },
      },
    })
      .then(() => {
        enqueueSnackbar(`Layout saved`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        if (orderDefault) {
          updateOrganization({
            refetchQueries: [
              {
                query: GET_ME,
              },
            ],
            variables: {
              id: activeOrg._id,
              organizationInput: {
                preferences: {
                  ...preferences,
                  fixAndFlipOrder,
                },
              },
            },
          })
            .then(() => {
              enqueueSnackbar(`Default Fix and Flip layout saved`, {
                variant: 'success',
                autoHideDuration: 3000,
              })
              setOrderChanged(false)
              setLoader(false)
            })
            .catch((error) => {
              enqueueSnackbar(
                `Failed to save default Fix and Flip layout`,
                {
                  variant: 'error',
                  autoHideDuration: 3000,
                },
              )
            })
        } else {
          setOrderChanged(false)
          setLoader(false)
        }
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to save layout`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  const sortedFixAndFlipCards = sortBy(
    filter(fixAndFlipCardsToOrder, (card) => !card.hide),
    [
      function (o) {
        return findIndex(fixAndFlipOrder, (value) => value === o.name)
      },
    ],
  )

  return (
    <>
      {!props.canDrag && (
        <Grid container direction="row" spacing={2} className="p-3">
          {props.publicImageCard && (
            <Grid
              item
              xs={12}
              md={6}
              lg={props.publicDashboard ? 4 : 6}
              xl={4}
              className="d-flex w-100"
            >
              {props.publicImageCard}
            </Grid>
          )}
          {map(
            slice(
              sortedFixAndFlipCards,
              0,
              findIndex(
                sortedFixAndFlipCards,
                (card) => card.name === 'deactive',
              ),
            ),
            (card) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={props.publicDashboard ? 4 : 6}
                xl={4}
                className="d-flex w-100"
              >
                {card.card}
              </Grid>
            ),
          )}
        </Grid>
      )}
      {props.canDrag && (
        <SortableGrid
          items={compact(
            concat(
              [props.publicImageCard],
              map(sortedFixAndFlipCards, (card) => card.card),
            ),
          )}
          onSortEnd={onSortEnd}
          axis="xy"
        />
      )}
      {map(financials, (section) => (
        <Dialog
          open={modal1 === section.label}
          onClose={toggle1Close}
          maxWidth="lg"
          scroll="body"
          classes={{
            paper:
              'modal-content rounded border-0 bg-white p-3 p-xl-0',
          }}
        >
          {/* <BlockUi blocking={loader} loader={<PacmanLoader color={'var(--primary)'} loading={true} />}> */}
          <Grid container spacing={0}>
            <Grid item xs={5}>
              <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100 rounded br-xl-right-0">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                  <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                    <div className="text-white mt-3">
                      <Typography
                        style={{ fontSize: 30, fontWeight: 600 }}
                      >
                        Calculation
                      </Typography>
                    </div>
                    {section.calculation}
                    <div className="text-white mt-3">
                      <Typography>
                        * Calculation may be a few dollars off due to
                        rounding
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={7}>
              <div>
                <Card>
                  <CardHeader title={section.label} />
                  <Divider />
                  <CardContent style={{ height: '100%' }}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item xs={12} style={{ marginBottom: 10 }}>
                        <Card
                          style={{
                            backgroundColor: '#eff2f5',
                            borderRadius: 0,
                          }}
                        >
                          <CardContent>
                            <Typography>
                              {section.description}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={toggle1Close}
                        >
                          Close
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </div>
            </Grid>
          </Grid>
          {/* </BlockUi> */}
        </Dialog>
      ))}
      <SubtoPITICash
        open={openPITICashData}
        onClose={() => setOpenPITICashData(false)}
        propertyId={property?._id}
        address={property?.address}
        city={property.city}
        state={property.state}
        zipcode={property?.zip}
      />
      <SubtoPITICreative
        open={openPITICreativeData}
        onClose={() => setOpenPITICreativeData(false)}
        propertyId={property?._id}
        address={property?.address}
        city={property.city}
        state={property.state}
        zipcode={property?.zip}
      />
      <Drawer
        open={orderChanged}
        anchor="bottom"
        variant="persistent"
        PaperProps={{
          style: { backgroundColor: '#253152', padding: 15 },
        }}
      >
        {/* <Alert
        className="mb-4"
        style={{ backgroundColor: '#253152', color: 'white' }}
        icon={false}
        action={
          <IconButton onClick={props.resetPropertyChanges}>
            <Close style={{ color: 'white' }} />
          </IconButton>
        }
      > */}
        {/* <Typography style={{ color: 'white', fontWeight: 600 }}>You have unsaved changes</Typography> */}
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}
          wrap="nowrap"
        >
          <Grid item style={{ marginLeft: 20 }}>
            <IconButton onClick={resetOrder} size="large">
              <Close style={{ color: 'white' }} />
            </IconButton>
          </Grid>
          <Grid item style={{ marginLeft: 0 }}>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: '#4290ff',
                color: 'white',
                boxShadow: 'none',
              }}
              onClick={submitOrder}
              disabled={loader}
            >
              <Typography style={{ fontWeight: 600 }}>
                Save
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <FormControl
              component="fieldset"
              className="pr-4"
              style={{ color: 'white' }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={orderDefault}
                      onChange={() => setOrderDefault(!orderDefault)}
                      style={{ color: 'white' }}
                    />
                  }
                  label="Set this as default Fix and Flip layout"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Typography style={{ flex: 1 }} />
        </Grid>
      </Drawer>

      <Dialog
        open={openConfirmLender}
        onClose={toggleOpenConfirmLender}
      >
        <DialogTitle>Disclaimer</DialogTitle>
        <DialogContent>
          Would you like to overwrite the % of Purchase Price, % of
          Rehab, Points, Interest Only, and Financing Fee values with
          the values from the selected lender?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              toggleOpenConfirmLender()
            }}
          >
            No
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={(event) => {
              toggleOpenConfirmLender()
              props.lenderOverride('FixFlip')
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <FixFlipStrategyTour
        isOpenFixFlipStrategyTour={openFixFlipStrategyTour}
        closeFixFlipStrategyTour={() =>
          setOpenFixFlipStrategyTour(false)
        }
      />
      <FixFlipTargetsTour
        isOpenFixFlipTargetsTour={openFixFlipTargetsTour}
        closeFixFlipTargetsTour={() =>
          setOpenFixFlipTargetsTour(false)
        }
      />
      <FixFlipNetProfitFromSaleTour
        isOpenFixFlipNetProfitFromSaleTour={
          openFixFlipNetProfitFromSaleTour
        }
        closeFixFlipNetProfitFromSaleTour={() =>
          setOpenFixFlipNetProfitFromSaleTour(false)
        }
      />
      <FixFlipAcquisitionExpensesTour
        isOpenFixFlipAcquisitionExpensesTour={
          openFixFlipAcquisitionExpensesTour
        }
        closeFixFlipAcquisitionExpensesTour={() =>
          setOpenFixFlipAcquisitionExpensesTour(false)
        }
      />
      {/* <FixFlipGrossMonthlyIncomeTour
      isOpenFixFlipGrossMonthlyIncomeTour={openFixFlipMonthlyIncomeTour} closeFixFlipGrossMonthlyIncomeTour={() => setOpenFixFlipMonthlyIncomeTour(false)}
    /> */}
      <FixFlipGrossMonthlyIncomeSingleRentTour
        isOpenFixFlipGrossMonthlyIncomeSingleRentTour={
          openFixFlipGrossMonthlyIncomeSingleRentTour
        }
        closeFixFlipGrossMonthlyIncomeSingleRentTour={() =>
          setOpenFixFlipGrossMonthlyIncomeSingleRentTour(false)
        }
      />
      <FixFlipGrossMonthlyIncomeRentRangeTour
        isOpenFixFlipGrossMonthlyIncomeRentRangeTour={
          openFixFlipGrossMonthlyIncomeRentRangeTour
        }
        closeFixFlipGrossMonthlyIncomeRentRangeTour={() =>
          setOpenFixFlipGrossMonthlyIncomeRentRangeTour(false)
        }
      />
      <FixFlipRehabExpensesSingleRehabValueTour
        isOpenFixFlipRehabExpensesSingleRehabValueTour={
          openFixFlipRehabExpensesSingleRehabValueTour
        }
        closeFixFlipRehabExpensesSingleRehabValueTour={() =>
          setOpenFixFlipRehabExpensesSingleRehabValueTour(false)
        }
      />
      <FixFlipRehabExpensesDetailedRehabTour
        isOpenFixFlipRehabExpensesDetailedRehabTour={
          openFixFlipRehabExpensesDetailedRehabTour
        }
        closeFixFlipRehabExpensesDetailedRehabTour={() =>
          setOpenFixFlipRehabExpensesDetailedRehabTour(false)
        }
      />
      <FixFlipRehabExpensesRehabTierTour
        isOpenFixFlipRehabExpensesRehabTierTour={
          openFixFlipRehabExpensesRehabTierTour
        }
        closeFixFlipRehabExpensesRehabTierTour={() =>
          setOpenFixFlipRehabExpensesRehabTierTour(false)
        }
      />
      <FixFlipRehabExpensesFlippingAmericaRehabTour
        isOpenFixFlipRehabExpensesFlippingAmericaRehabTour={
          openFixFlipRehabExpensesFlippingAmericaRehabTour
        }
        closeFixFlipRehabExpensesFlippingAmericaRehabTour={() =>
          setOpenFixFlipRehabExpensesFlippingAmericaRehabTour(false)
        }
      />
      <FixFlipHoldingExpensesTour
        isOpenFixFlipHoldingExpensesTour={
          openFixFlipHoldingExpensesTour
        }
        closeFixFlipHoldingExpensesTour={() =>
          setOpenFixFlipHoldingExpensesTour(false)
        }
      />
      <FixFlipFinancingTour
        isOpenFixFlipFinancingTour={openFixFlipFinancingTour}
        closeFixFlipFinancingTour={() =>
          setOpenFixFlipFinancingTour(false)
        }
      />
      <FixFlipDispositionExpensesTour
        isOpenFixFlipDispositionExpensesTour={
          openFixFlipDispositionExpensesTour
        }
        closeFixFlipDispositionExpensesTour={() =>
          setOpenFixFlipDispositionExpensesTour(false)
        }
      />
      <FixFlipComparablePropertiesTour
        isOpenFixFlipComparablePropertiesTour={
          openFixFlipComparablePropertiesTour
        }
        closeFixFlipComparablePropertiesTour={() =>
          setOpenFixFlipComparablePropertiesTour(false)
        }
      />
    </>
  )
}

export default withSession(CenterPageFixFlip)
