/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-29 10:22:56
 * @ Modified by: David Helmick
 * @ Modified time: 2025-04-04 11:50:36
 * @ Description: 2nd highest level component. Mainly imports fontawesome icons.
 */

import React, { Component, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import configureStore from './config/configureStore'
import { Provider } from 'react-redux'
import Routes from './Routes'
import ScrollToTop from './utils/ScrollToTop'
import './assets/base.scss'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
// import Affiliate from '../Affiliate/affiliate'
// import CorpSignupPage from '../SignUp/corpSignupPage'

/////IMPORT FONTS/////

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  //fab,
  //faDiscord,
  //faDribbble,
  //faGithub
  //faHtml5,
  //faInstagram,
  //faPinterest,
  //faReact,
  //faSlack,
  //faVuejs,
  //faYoutube,
  faFacebook,
  faGoogle,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import {
  //fad,
  //faQuestionSquare,
  //faWallet,
  faBell as faBellDuo,
  faRocketLaunch,
  faSign as faSignHangingDuo,
} from '@fortawesome/pro-duotone-svg-icons'
import {
  //fal,
  faBell,
  faFile,
  faFileChartPie,
  faChessQueenAlt,
  faCommentDots,
  faCloudDownload,
  faDatabase,
  faDivide,
  faFolder as faFolderLight,
  faHeart,
  faInfoCircle,
  faPaperPlane,
  faPen as faPenLight,
  faToolbox,
  faUserSlash,
  faExclamationTriangle,
  faMapMarkedAlt,
} from '@fortawesome/pro-light-svg-icons'
import {
  //far,
  // faAddressCard,
  // faBell,
  // faCalendarAlt,
  // faChartBar
  // faCheckCircle,
  // faCommentDots,
  // faComments,
  // faDotCircle,
  // faEnvelope,
  // faEye,
  // faFileArchive,
  // faFileAudio,
  // faFileCode,
  // faFileExcel,
  // faFileImage,
  // faFilePdf,
  // faFileVideo,
  // faFileWord,
  // faFolder,
  // faGem,
  // faImages,
  // faKeyboard,
  // faLifeRing,
  // faLightbulb,
  // faMap,
  // faObjectGroup,
  // faSquare,
  faThumbsUp,
  faThumbsDown,
  // faTimesCircle,
  // faUserCircle,
  faAddressCard,
  faAngleDown,
  faAngleRight,
  faArrowAltToTop,
  faArrowRight,
  faArrowUpRightFromSquare,
  faBuilding,
  faChartLineUp,
  faCheck as faCheckRegular,
  faCircle,
  faClock,
  faCloudDownload as faCloudDownloadRegular,
  faCodeCompare,
  faCommentAltLines,
  faCompass,
  faCopy,
  faCrosshairs,
  faDatabase as faDatabaseRegular,
  faDollarSign,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExternalLink,
  faFileAlt,
  faFileChartLine,
  faFileExcel,
  faFileExport,
  faFileInvoiceDollar,
  faFileTimes,
  faFolder,
  faFolderCog,
  faFolderOpen,
  faFolderPlus,
  faFolderUpload,
  faFolderXmark,
  faHandshake,
  faHomeLg,
  faImagePolaroid,
  faInfoCircle as faInfoCircleRegular,
  faLandmark,
  faLaptopHouse,
  faMapMarkerExclamation,
  faMapMarkerPlus,
  faMoneyCheckEditAlt,
  faObjectUngroup,
  faPaperclip,
  faPen,
  faPeopleRoof,
  faPhone,
  faPlus,
  faPrint,
  faQuestionCircle,
  faQuestionSquare,
  faRocketLaunch as faRocketLaunchRegular,
  faSave,
  faSearch,
  faShare,
  faShareAlt,
  faSign as faSignRegular,
  faSignOutAlt,
  faSlidersH as faSlidersHRegular,
  faStars,
  faSortAmountDown,
  faSquare,
  faStar,
  faTimes as faTimesRegular,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faTriangle,
  faUser,
  faUserGraduate,
  faUserFriends,
  faUserPlus,
  faCartShopping,
  faHouseCircleCheck,
  faHouseCircleExclamation,
  faHouseChimney,
} from '@fortawesome/pro-regular-svg-icons'
import {
  //fas,
  // faAlignCenter,
  // faAngleDoubleLeft,
  // faAngleDoubleRight,
  // faAngleDown,
  // faAngleLeft,
  // faAngleRight,
  // faAngleUp,
  // faArrowDown,
  // faArrowLeft,
  // faArrowRight,
  // faArrowUp,
  faAward,
  // faBars,
  // faBatteryEmpty,
  // faBatteryFull,
  // faBirthdayCake,
  // faBomb,
  // faBusAlt,
  // faCameraRetro,
  // faCarBattery,
  // faCaretRight,
  // faCheck,
  // faChevronDown,
  // faChevronLeft,
  // faChevronRight,
  // faChevronUp,
  // faCog,
  // faCubes,
  // faDownload,
  // faEllipsisH,
  // faEllipsisV,
  // faExclamation,
  // faExternalLinkAlt,
  // faEyeDropper,
  // faFilm,
  // faHeart,
  // faInfoCircle,
  // faLemon,
  // faLink
  // faNetworkWired,
  // faPager,
  // faPlus,
  // faPlusCircle,
  // faPrint,
  // faQuoteRight,
  // faReply,
  // faSave,
  // faSearch,
  // faShapes,
  // faShareSquare,
  // faSitemap,
  // faSmile,
  // faStar,
  // faStarHalfAlt,
  // faSync,
  // faTable,
  // faTachometerAlt,
  // faTimes,
  // faUnlockAlt,
  // faUpload,
  faAngleDown as faAngleDownSolid,
  faAngleUp,
  faAsterisk,
  faArrowRight as faArrowRightSolid,
  faArrowsAltH,
  faBars,
  faBell as faBellSolid,
  faBuilding as faBuildingSolid,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faExclamation,
  faEnvelope as faEnvelopSolid,
  faBackwardFast,
  faFolderUpload as faFolderUploadSolid,
  faGripVertical,
  faHammer,
  faHeart as faHeartSolid,
  faHome,
  faHouseChimney as faHouseChimneySolid,
  faKey,
  faListOl,
  faMapMarkerCheck,
  faMoneyCheckDollar,
  faPaperPlane as faPaperPlaneSolid,
  faPlay,
  faPlus as faPlusSolid,
  faSearch as faSearchSolid,
  faSign as faSignSolid,
  faSignOutAlt as faRightFromBracket,
  faSlidersH as faSlidersHSolid,
  faTimes as faTimesSolid,
  faRocket as faRocketSolid,
  faTrashCan,
  faUniversity,
  faUpload as faUploadSolid,
  faVideo,
  faWallet as faWalletSolid,
  faWandMagicSparkles,
} from '@fortawesome/pro-solid-svg-icons'

/////ADD TO LIBRARIES///

library.add(
  //fab,
  //faDiscord,
  //faDribbble,
  //faGithub
  //faHtml5,
  //faInstagram,
  //faPinterest,
  //faReact,
  //faSlack,
  //faVuejs,
  //faYoutube,
  faFacebook,
  faGoogle,
  faLinkedin,
  faTwitter,
)
library.add(
  //fad,
  faBellDuo,
  faRocketLaunch,
  faSignHangingDuo,
)
library.add(
  //fal,
  faBell,
  faFile,
  faFileChartPie,
  faChessQueenAlt,
  faCommentDots,
  faCloudDownload,
  faDatabase,
  faDivide,
  faFolderLight,
  faHammer,
  faHeart,
  faInfoCircle,
  faPaperPlane,
  faPenLight,
  faToolbox,
  faUserSlash,
  faExclamationTriangle,
  faMapMarkedAlt,
)
library.add(
  //far,
  // faAddressCard,
  // faBell,
  // faCalendarAlt,
  // faChartBar
  // faCheckCircle,
  // faCommentDots,
  // faComments,
  // faDotCircle,
  // faEnvelope,
  // faEye,
  // faFileArchive,
  // faFileAudio,
  // faFileCode,
  // faFileExcel,
  // faFileImage,
  // faFilePdf,
  // faFileVideo,
  // faFileWord,
  // faFolder,
  // faGem,
  // faImages,
  // faKeyboard,
  // faLifeRing,
  // faLightbulb,
  // faMap,
  // faObjectGroup,
  // faSquare,
  faThumbsUp,
  faThumbsDown,
  // faTimesCircle,
  // faUserCircle,
  faAddressCard,
  faAngleDown,
  faAngleRight,
  faArrowAltToTop,
  faArrowRight,
  faArrowUpRightFromSquare,
  faBuilding,
  faChartLineUp,
  faCheckRegular,
  faCircle,
  faClock,
  faCloudDownloadRegular,
  faCodeCompare,
  faCommentAltLines,
  faCompass,
  faCopy,
  faCrosshairs,
  faDatabaseRegular,
  faDollarSign,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExternalLink,
  faFileAlt,
  faFileChartLine,
  faFileExcel,
  faFileExport,
  faFileInvoiceDollar,
  faFileTimes,
  faFolder,
  faFolderCog,
  faFolderOpen,
  faFolderPlus,
  faFolderUpload,
  faFolderXmark,
  faHandshake,
  faHomeLg,
  faImagePolaroid,
  faInfoCircleRegular,
  faLandmark,
  faLaptopHouse,
  faMapMarkerExclamation,
  faMapMarkerPlus,
  faMoneyCheckEditAlt,
  faObjectUngroup,
  faPaperclip,
  faPen,
  faPeopleRoof,
  faPhone,
  faPlus,
  faPrint,
  faQuestionCircle,
  faQuestionSquare,
  faRocketLaunchRegular,
  faSave,
  faSearch,
  faShare,
  faShareAlt,
  faSignOutAlt,
  faSignRegular,
  faSlidersHRegular,
  faStars,
  faSortAmountDown,
  faSquare,
  faStar,
  faTimesCircle,
  faTimesRegular,
  faTrash,
  faTrashAlt,
  faTriangle,
  faUser,
  faUserGraduate,
  faUserFriends,
  faUserPlus,
  faCartShopping,
  faHouseCircleCheck,
  faHouseCircleExclamation,
)
library.add(
  //fas,
  // faAlignCenter,
  // faAngleDoubleLeft,
  // faAngleDoubleRight,
  // faAngleDown,
  // faAngleLeft,
  // faAngleRight,
  // faAngleUp,
  // faArrowDown,
  // faArrowLeft,
  // faArrowRight,
  // faArrowUp,
  faAward,
  // faBars,
  // faBatteryEmpty,
  // faBatteryFull,
  // faBirthdayCake,
  // faBomb,
  // faBusAlt,
  // faCameraRetro,
  // faCarBattery,
  // faCaretRight,
  // faCheck,
  // faChevronDown,
  // faChevronLeft,
  // faChevronRight,
  // faChevronUp,
  // faCog,
  // faCubes,
  // faDownload,
  // faEllipsisH,
  // faEllipsisV,
  // faExclamation,
  // faExternalLinkAlt,
  // faEyeDropper,
  // faFilm,
  // faHeart,
  // faInfoCircle,
  // faLemon,
  // faLink
  // faNetworkWired,
  // faPager,
  // faPlus,
  // faPlusCircle,
  // faPrint,
  // faQuoteRight,
  // faReply,
  // faSave,
  // faSearch,
  // faShapes,
  // faShareSquare,
  // faSitemap,
  // faSmile,
  // faStar,
  // faStarHalfAlt,
  // faSync,
  // faTable,
  // faTachometerAlt,
  // faTimes,
  // faUnlockAlt,
  // faUpload,
  faAngleDownSolid,
  faAngleUp,
  faAsterisk,
  faArrowRightSolid,
  faArrowsAltH,
  faBars,
  faBellSolid,
  faBuildingSolid,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faExclamation,
  faEnvelopSolid,
  faBackwardFast,
  faFolderUploadSolid,
  faGripVertical,
  faHeartSolid,
  faHome,
  faHouseChimneySolid,
  faKey,
  faListOl,
  faMapMarkerCheck,
  faMoneyCheckDollar,
  faPaperPlaneSolid,
  faPlay,
  faPlusSolid,
  faSearchSolid,
  faRightFromBracket,
  faSignSolid,
  faSlidersHSolid,
  faTimesSolid,
  faRocketSolid,
  faTrashCan,
  faUniversity,
  faUploadSolid,
  faVideo,
  faWalletSolid,
  faWandMagicSparkles,
)

const store = configureStore()

import { Helmet, HelmetProvider } from 'react-helmet-async'

const facebookPixelOptions = {
  autoConfig: true,
  debug: false,
}
var FB_PIXEL = import.meta.env.VITE_FB_PIXEL
ReactPixel.init(FB_PIXEL, facebookPixelOptions)

// ReactPixel.pageView(document.title)

//var GA_MARKETING_ID = import.meta.env.VITE_GA_MARKETING_ID
// var GA_INTERNAL_ID = import.meta.env.VITE_GA_INTERNAL_ID

//ReactGA.initialize(GA_MARKETING_ID)

// ReactGA.initialize(
//   [
//     {
//       trackingId: GA_MARKETING_ID,
//       gaOptions: {
//         name: 'marketing',
//       },
//     },
//     {
//       trackingId: GA_INTERNAL_ID,
//       gaOptions: { name: 'internal' },
//     },
//   ],
//   { debug: false, alwaysSendToDefaultTracker: false },
// )
// ReactGA.pageview(window.location.pathname + window.location.search, [
//   'internal',
// ])
ReactGA.plugin.require('ecommerce')

const App = () => {
  useEffect(() => {
    const removeLoomElement = () => {
      const loomElement = document.querySelector(
        '#shadow-host-companion',
      )
      if (loomElement) {
        loomElement.style.display = 'none'
      }
    }

    removeLoomElement()
  }, [])

  // useEffect(() => {
  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker.addEventListener(
  //       'controllerchange',
  //       () => {
  //         // Notify the user about the new version and ask for a refresh
  //         alert(
  //           'A new version is available. Please refresh the page.',
  //         )
  //       },
  //     )
  //   }
  // }, [])

  const helmetContext = {}

  return (
    <Provider store={store}>
      <HelmetProvider context={helmetContext}>
        <Helmet>
          {/* <link
            id="favicon"
            rel="shortcut icon"
            href="/images/LeftNavLogo.png"
            async
          /> */}
          <title id="titleId">
            Housefolios - Analyze, Manage and Sell Real Estate
          </title>
        </Helmet>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ScrollToTop>
              <Routes />
            </ScrollToTop>
          </LocalizationProvider>
        </BrowserRouter>
      </HelmetProvider>
    </Provider>
  )
}

export default App
