/**
 * @ Author: Housefolios
 * @ Create Time: 2021-10-28 10:24:18
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-04-09 11:35:05
 * @ Description: Short Term Rental Strategy Analysis
 */

import React, { Suspense, lazy, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import Reactour from 'reactour'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'

import CountUp from 'react-countup'
import map from 'lodash/map'
import mean from 'lodash/mean'
import maxBy from 'lodash/maxBy'
import meanBy from 'lodash/meanBy'
import minBy from 'lodash/minBy'
import isUndefined from 'lodash/isUndefined'
import concat from 'lodash/concat'
import sum from 'lodash/sum'
import round from 'lodash/round'
import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import difference from 'lodash/difference'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import zip from 'lodash/zip'
import slice from 'lodash/slice'
import replace from 'lodash/replace'
import compact from 'lodash/compact'
import head from 'lodash/head'
import last from 'lodash/last'
import uniq from 'lodash/uniq'
const Chart = lazy(() => import('react-apexcharts'))

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormGroup,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  LinearProgress,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TextField,
  Typography,
  Drawer,
  IconButton,
  FormControl,
  Backdrop,
  CircularProgress,
  Collapse,
  Select,
} from '@mui/material'

import dark365Square from '@/assets/images/365_square_Dark.png'

import { arrayMoveImmutable } from 'array-move'
import {
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc'

import ContactAgent from '../../../Marketplace/ContactAgent'
import GaugeChart from 'react-gauge-chart'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import '../../style.css'
import {
  GET_RENTAL_COMPS,
  UPDATE_PROPERTY,
} from '@/housefolios-components/PropertyContainer/mutations'
import {
  GET_COMPPROPERTIES,
  GET_PROPERTY,
} from '@/housefolios-components/PropertyContainer/queries'
import { useMutation } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'

import PropertyPDF from '../../Proforma/pdf'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import { AddTwoTone, Close } from '@mui/icons-material'
import withSession from '@/housefolios-components/Session/withSession'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { enqueueSnackbar } from 'notistack'
import { GET_CARDS } from '@/housefolios-components/Properties/queries'
import Lending from '@/housefolios-components/Dialogs/Lending'

import { assumptionsOptions } from '@/housefolios-components/AssumptionsSettings/Accordion' //SPECIFIC COMMENT: Don't remove. Used for rehab card fields
import { DEFAULT_ANALYTICS } from '@/housefolios-components/Property/fragments'
import { useLocation } from 'react-router-dom'
import useAmortizationDialog from '@/hooks/useAmortizationDialog'
import AmortizationDialog from '@/housefolios-components/Dialogs/Amortization'
import { loanBalance } from '@/utils/loanBalance'

import {
  ContactInformation,
  getCashEntryFee,
  getCreativeEntryFee,
  getPitiTotal,
} from '@/utils/loanBalance'
import SubtoPITICash from '@/housefolios-components/Dialogs/SubtoPITI/Cash/'
import SubtoPITICreative from '@/housefolios-components/Dialogs/SubtoPITI/Creative/'
import CommentsDialog from '@/housefolios-components/Dialogs/Comments'
import {
  ShortTermRentalAcquisitionExpensesTour,
  ShortTermRentalCashPositioningTour,
  ShortTermRentalComparablePropertiesTour,
  ShortTermRentalDispositionExpensesTour,
  ShortTermRentalFinancingTour,
  ShortTermRentalGrossMonthlyIncomeTour,
  ShortTermRentalMonthlyCashflowTour,
  ShortTermRentalMonthlyExpensesTour,
  ShortTermRentalRehabExpensesTour,
  ShortTermRentalStrategyTour,
  ShortTermRentalTargetsTour,
  ShortTermRentalValueOverTimeTour,
} from './tours'

const pieColors = [
  '#bebebd',
  '#008ffb',
  '#ff6efb',
  '#69A251',
  '#38CDC3',
  '#e3e44f',
  '#feb019',
  '#45818E',
  '#d26767',
]

function CenterPageShortTermRental(props) {
  const {
    property,
    propertyInput,
    analysisInput,
    assumptionsInput,
    renderInputFields,
  } = props

  const {
    openAmortization,
    handleOpenAmortization,
    handleCloseAmortization,
  } = useAmortizationDialog()

  const [
    openShortTermRentalStrategyTour,
    setOpenShortTermRentalStrategyTour,
  ] = useState(false)
  const [
    openShortTermRentalTargetsTour,
    setOpenShortTermRentalTargetsTour,
  ] = useState(false)
  const [
    openShortTermRentalMonthlyCashflowTour,
    setOpenShortTermRentalMonthlyCashflowTour,
  ] = useState(false)
  const [
    openShortTermRentalAcquisitionExpensesTour,
    setOpenShortTermRentalAcquisitionExpensesTour,
  ] = useState(false)
  const [
    openShortTermRentalGrossMonthlyIncomeTour,
    setOpenShortTermRentalGrossMonthlyIncomeTour,
  ] = useState(false)
  const [
    openShortTermRentalMonthlyExpensesTour,
    setOpenShortTermRentalMonthlyExpensesTour,
  ] = useState(false)
  const [
    openShortTermRentalRehabExpensesTour,
    setOpenShortTermRentalRehabExpensesTour,
  ] = useState(false)
  const [
    openShortTermRentalFinancingTour,
    setOpenShortTermRentalFinancingTour,
  ] = useState(false)
  const [
    openShortTermRentalValueOverTimeTour,
    setOpenShortTermRentalValueOverTimeTour,
  ] = useState(false)
  const [
    openShortTermRentalCashPositioningTour,
    setOpenShortTermRentalCashPositioningTour,
  ] = useState(false)
  const [
    openShortTermRentalDispositionExpensesTour,
    setOpenShortTermRentalDispositionExpensesTour,
  ] = useState(false)
  const [
    openShortTermRentalComparablePropertiesTour,
    setOpenShortTermRentalComparablePropertiesTour,
  ] = useState(false)

  let results = useQuery(GET_CARDS, {
    variables: { view: 'ShortTermRental' },
  })
  let data = results.data || {}
  const cardsLoading = results.loading
  const cardsError = results.error
  let { cards = [] } = data

  const { activeOrg = {} } = props.session.me || {}
  const { acquisition = {}, assumptions = {} } = property
  const { analytics = DEFAULT_ANALYTICS, analysis = {} } = acquisition

  let amortizationData = loanBalance({
    financeAmount: analytics.STR.loanAmountSTR,
    netOperatingIncome: analytics.STR.netOperatingIncomeSTR,
    mortgagePayment: analytics.STR.mortgageSTR,
    monthlyRate: assumptions.rateSTR / 100 / 12,
    holdingPeriod: assumptions.rentalHoldingPeriodSTR * 12,
    payDownAccelerator: assumptions.payDownAccelerator,
    payDownGrowth: assumptions.payDownGrowth,
    growthRateIncome: assumptions.growthRateIncome,
    growthRateExpense: assumptions.growthRateIncome,
  })

  // const [openAmortization, setOpenAmortization] = useState(false)
  // const handleOpenAmortization = () => {
  //   setOpenAmortization(true)
  // }
  // const handleCloseAmortization = () => {
  //   setOpenAmortization(false)
  // }

  const tableHeaders = [
    { title: '', name: 'Date', align: 'left' },
    { title: '', name: 'Interest', align: 'center' },
    { title: '', name: 'Principal', align: 'center' },
    { title: '', name: 'Additional Principal', align: 'center' },
    { title: '', name: 'Balance', align: 'center' },
  ]

  const dollarPercentAmount = (ofAmount, value, peramt) => {
    let typeData = []
    let dollarAmount = 0
    let percentAmount = 0

    if (peramt === '%') {
      dollarAmount = (value / 100) * ofAmount
      percentAmount = value
    } else if (peramt === '$') {
      dollarAmount = value
      if (ofAmount !== 0)
        percentAmount = ((value / ofAmount) * 100).toFixed(2)
    }

    typeData.push({
      dollar: dollarAmount,
      percent: percentAmount,
    })

    return typeData
  }

  let IRRDataFirst = {}
  let IRRData = []
  let IRRDataLast = {}

  const IRRTable = (args) => {
    let {
      valueAppreciation, //arv ? arv : sellPrice
      grossAnnualRent,
      annualExpenses,
      mp_monthly,
      rentalHoldingPeriod,
      growthRateIncomeMonthly,
      growthRateExpenseMonthly,
      rehabPeriod,
      totalOutOfPocket,
      homePriceAppreciation,
      listPriceToSalePriceRatio,
      salesCommission,
      salesCommissionType,
      sellingClosingCostDollar,
      loanBalanceAtHoldingPeriod,
      dispositionFee,
      dispositionFeeType,
    } = args

    let cashFlowTotal = 0
    let arrInHoldingPeriodMonthly = grossAnnualRent / 12
    let expensesInHoldingPeriodMonthly = annualExpenses / 12
    let cashFlowInHoldingPeriodMonthly = 0
    let salePrice = 0

    let month
    let year
    let monthYear

    const today = new Date()

    const IRRDate = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      1,
    )

    for (let index = 1; index < rentalHoldingPeriod + 1; index++) {
      if (index > 1) {
        arrInHoldingPeriodMonthly *= 1 + growthRateIncomeMonthly
        expensesInHoldingPeriodMonthly *= 1 + growthRateExpenseMonthly
      }
      let income =
        index > rehabPeriod + 1 ? arrInHoldingPeriodMonthly : 0
      cashFlowInHoldingPeriodMonthly =
        mp_monthly < 0
          ? income - expensesInHoldingPeriodMonthly
          : income - expensesInHoldingPeriodMonthly - mp_monthly
      if (index === 1) {
        month = IRRDate.toLocaleString('default', { month: 'long' })
        year = IRRDate.getFullYear().toString()
        monthYear = month + ' ' + year
        IRRDataFirst = {
          totalOutOfPocket,
          monthYear,
        }
      } else {
        valueAppreciation *= 1 + homePriceAppreciation / 12 / 100
        IRRDate.setMonth(IRRDate.getMonth() + 1)
        let month = IRRDate.toLocaleString('default', {
          month: 'long',
        })
        let year = IRRDate.getFullYear().toString()
        monthYear = month + ' ' + year
        if (index < rentalHoldingPeriod) {
          IRRData.push({
            flow: cashFlowInHoldingPeriodMonthly,
            income,
            expenses: expensesInHoldingPeriodMonthly,
            mortgage: mp_monthly,
            monthYear,
          })
        } else if (index === rentalHoldingPeriod) {
          let cashFlowInHoldingPeriod = cashFlowTotal

          salePrice =
            (listPriceToSalePriceRatio / 100) * valueAppreciation
          let salesCommissionTotal =
            salesCommissionType === '$'
              ? salesCommission
              : (salesCommission / 100) * salePrice

          const dispositionFeeIRRDP = dollarPercentAmount(
            salePrice,
            dispositionFee,
            dispositionFeeType,
          )

          const lastMonthCashFlow =
            salePrice -
            loanBalanceAtHoldingPeriod -
            salesCommissionTotal -
            sellingClosingCostDollar -
            dispositionFeeIRRDP[0].dollar +
            cashFlowInHoldingPeriodMonthly

          IRRDataLast = {
            lastMonthCashFlow,
            monthYear,
          }
        }
      }
    }
  }

  IRRTable({
    valueAppreciation: analytics.STR.arvTotalSTR
      ? analytics.STR.arvTotalSTR
      : analysis.resellPriceSTR,
    grossAnnualRent: sum(analytics.STR.grossAnnualRentSTR),
    annualExpenses: analytics.STR.annualExpensesSTR,
    mp_monthly: analytics.STR.mortgageSTR,
    rentalHoldingPeriod: assumptions.rentalHoldingPeriodSTR,
    growthRateIncomeMonthly: assumptions.growthRateIncome / 12 / 100,
    growthRateExpenseMonthly:
      assumptions.growthRateExpense / 12 / 100,
    rehabPeriod: assumptions.rehabPeriodSTR,
    totalOutOfPocket: analytics.STR.totalOutOfPocketSTR,
    homePriceAppreciation: assumptions.homePriceAppreciation,
    listPriceToSalePriceRatio: assumptions.listPriceToSalePriceRatio,
    salesCommission: assumptions.salesCommissionSTR,
    salesCommissionType: assumptions.salesCommissionSTRType,
    sellingClosingCostDollar:
      analytics.STR.sellingClosingCostDollarSTR,
    loanBalanceAtHoldingPeriod:
      analytics.STR.loanBalanceAtHoldingPeriodSTR,
    dispositionFee: assumptions.dispositionFeeSTR,
    dispositionFeeType: assumptions.dispositionFeeSTRType,
  })

  const IRRTableFirstHeaders = [
    { title: '', name: 'Date', align: 'left' },
    { title: '', name: 'Total Out of Pocket', align: 'center' },
  ]

  const IRRTableHeaders = [
    { title: '', name: 'Date', align: 'left' },
    { title: '', name: 'Income', align: 'center' },
    { title: '', name: 'Expenses', align: 'center' },
    { title: '', name: 'Mortgage', align: 'center' },
    { title: '', name: 'Cashflow', align: 'center' },
  ]

  const IRRTableLastHeaders = [
    { title: '', name: 'Date', align: 'left' },
    { title: '', name: 'Last Month Cashflow', align: 'center' },
  ]

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const currencyFormat2 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const percentageFormat = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
  })

  // const numberFormat = new Intl.NumberFormat('en-US', {
  //   minimumFractionDigits: 0,
  //   maximumFractionDigits: 0,
  // })

  const [openManagementCompanies, setOpenManagementCompanies] =
    useState(false)
  const handleOpenManagementCompanies = () => {
    setOpenManagementCompanies(true)
  }
  const handleCloseManagementCompanies = () => {
    setOpenManagementCompanies(false)
  }

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  const [modal1, setModal1] = useState(null)
  const [dealSauceView, setDealSauceView] = useState('creative')
  const [openPITICashData, setOpenPITICashData] = useState(false)
  const [openPITICreativeData, setOpenPITICreativeData] =
    useState(false)

  const pitiTotal = getPitiTotal(property)
  const creativeEntryFee = getCreativeEntryFee(property)
  const cashEntryFee = getCashEntryFee(property)

  const toggle1 = (name) => {
    setModal1(name)
  }

  const toggle1Close = () => {
    setModal1(null)
  }

  const [accordion, setAccordion] = useState(-1)

  const toggleAccordion = (index) => {
    if (index === accordion) setAccordion(-1)
    else setAccordion(index)
  }

  const [maxOfferDescription, setMaxOfferDescription] =
    useState(false)

  const toggleMaxOffer = () => {
    setMaxOfferDescription(!maxOfferDescription)
  }

  const [getFinancing, setGetFinancing] = useState(null)

  const toggleGetFinancing = (name) => {
    setGetFinancing(name)
  }

  const toggleGetFinancingClose = () => {
    setGetFinancing(null)
  }
  const cashFlowAccumulationCalculate = (args) => {
    const {
      accelerator,
      term,
      rentAmount,
      vacancy,
      propertyTaxes,
      homeInsurance,
      monthlyHOA,
      propertyMgmtPercent,
      maintenance,
      utilities,
      annualRevenueIncrease,
      annualOperatingExpenseIncrease,
      //added so that cash flow is with Financing
      mortgagePayment,
    } = args

    let pdAccelerator = accelerator / 100
    let rent = rentAmount
    let rentIncrease = map(rent, (value) => 0)
    let vac = map(
      zip(rentAmount, vacancy),
      ([rentAmount, vacancy]) => rentAmount * vacancy,
    )
    let revenue = 0
    let propTax = propertyTaxes
    let propTaxIncrease = 0
    let insurance = homeInsurance
    let insuranceIncrease = 0
    let hoa = monthlyHOA
    let hoaIncrease = 0
    let propMgmt = sum(rentAmount) * propertyMgmtPercent
    let main = sum(rentAmount) * maintenance
    let mainIncrease = 0
    let uti = utilities
    let utiIncrease = 0
    let expenses = 0
    let cashFlow = 0
    let cfyear = []
    //added so that cash flow is with Financing
    let mortgage = 0

    for (let index = 1; index < term; index++) {
      if (index > 1 && index < 12) {
        rent = map(
          zip(rent, rentAmount),
          ([rent, rentAmount]) => rent + rentAmount,
        )
        propTax += propertyTaxes
        insurance += homeInsurance
        hoa += monthlyHOA
        //added so that cash flow is with Financing
        mortgage += mortgagePayment
      } else if (index % 12 === 0) {
        if (index / 12 === 1) {
          rent = map(
            zip(rent, rentAmount),
            ([rent, rentAmount]) => rent + rentAmount,
          )
          vac = map(
            zip(rent, vacancy),
            ([rent, vacancy]) => rent * vacancy,
          )
          revenue = sum(rent) - sum(vac)

          propTax += propertyTaxes
          insurance += homeInsurance
          hoa += monthlyHOA
          propMgmt = revenue * propertyMgmtPercent
          main = sum(rent) * maintenance
          expenses = propTax + insurance + hoa + propMgmt + main + uti

          //added so that cash flow is with Financing
          mortgage += mortgagePayment

          cashFlow = revenue - expenses - mortgage
          cashFlow = cashFlow - pdAccelerator * cashFlow

          cfyear.push({
            year: index / 12,
            cashFlow: cashFlow,
          })

          rentIncrease = map(
            rentAmount,
            (rentAmount) =>
              rentAmount + rentAmount * annualRevenueIncrease,
          )
          propTaxIncrease =
            propTax * (1 + annualOperatingExpenseIncrease)
          insuranceIncrease =
            insurance * (1 + annualOperatingExpenseIncrease)
          hoaIncrease = hoa * (1 + annualOperatingExpenseIncrease)
          mainIncrease = main * (1 + annualOperatingExpenseIncrease)
          utiIncrease = uti * (1 + annualOperatingExpenseIncrease)
        } else {
          rent = map(
            zip(rent, rentIncrease),
            ([rent, rentIncrease]) => rent + rentIncrease,
          )
          vac = map(
            zip(rent, vacancy),
            ([rent, vacancy]) => rent * vacancy,
          )
          revenue = sum(rent) - sum(vac)

          propTax += propTaxIncrease
          insurance += insuranceIncrease
          hoa += hoaIncrease
          propMgmt = revenue * propertyMgmtPercent
          main += mainIncrease
          uti += utiIncrease
          expenses = propTax + insurance + hoa + propMgmt + main + uti

          //added so that cash flow is with Financing
          mortgage += mortgagePayment

          cashFlow = revenue - expenses - mortgage
          cashFlow = cashFlow - pdAccelerator * cashFlow

          cfyear.push({
            year: index / 12,
            cashFlow: cashFlow,
          })

          rentIncrease = map(
            rentIncrease,
            (rentIncrease) =>
              rentIncrease + rentIncrease * annualRevenueIncrease,
          )
          propTaxIncrease =
            propTaxIncrease * (1 + annualOperatingExpenseIncrease)
          insuranceIncrease =
            insuranceIncrease * (1 + annualOperatingExpenseIncrease)
          hoaIncrease =
            hoaIncrease * (1 + annualOperatingExpenseIncrease)
          mainIncrease =
            mainIncrease * (1 + annualOperatingExpenseIncrease)
          utiIncrease =
            utiIncrease * (1 + annualOperatingExpenseIncrease)
        }
      } else {
        rent = map(
          zip(rent, rentIncrease),
          ([rent, rentIncrease]) => rent + rentIncrease,
        )
        //added so that cash flow is with Financing
        mortgage += mortgagePayment
      }
    }

    return cfyear
  }

  const cashFlowAccumulation = cashFlowAccumulationCalculate({
    accelerator: assumptions.payDownAccelerator || 0,
    term:
      assumptions.termSTRType === 'Years'
        ? (assumptions.termSTR || 0) * 12 + 1
        : (assumptions.termSTR || 0) + 1,
    rentAmount: map(
      zip(assumptions.avgRate, assumptions.avgRateType),
      ([avgRate, avgRateType]) =>
        (avgRateType === 'Weekly'
          ? (avgRate || 150) / 7
          : avgRate || 150) * 365 || 0,
    ),
    vacancy: map(
      assumptions.occupancy,
      (occupancy) => (1 - (occupancy || 0)) / 100,
    ),
    propertyTaxes: (analysis.propertyTaxes || 0) / 12,
    homeInsurance:
      (analytics.STR.propertyInsuranceDollarSTR || 0) / 12,
    monthlyHOA: analytics.STR.hoaTotal / 12 || 0,
    propertyMgmtPercent: (assumptions.cohosting || 0) / 100,
    maintenance: (assumptions.maintenanceSTR || 0) / 100,
    utilities: analytics.STR.utilitiesTypeDollarSTR || 0,
    annualRevenueIncrease: (assumptions.growthRateIncome || 0) / 100,
    annualOperatingExpenseIncrease:
      (assumptions.growthRateExpense || 0) / 100,
    mortgagePayment: analytics.STR.mortgageSTR || 0,
  })

  let cashFlow1 = 0
  let cashFlow3 = 0
  let cashFlow5 = 0
  let cashFlow10 = 0

  for (let key in cashFlowAccumulation) {
    const tmp = cashFlowAccumulation[key]
    if (tmp.year === 1) cashFlow1 = tmp.cashFlow
    if (tmp.year === 3) cashFlow3 = tmp.cashFlow
    if (tmp.year === 5) cashFlow5 = tmp.cashFlow
    if (tmp.year === 10) cashFlow10 = tmp.cashFlow
  }

  const options = {
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['1 yr', '3 yrs', '5 yrs', '10 yrs'],
    },
    yaxis: {
      labels: {
        formatter: currencyFormat.format,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box">' +
          '<span>' +
          //[w.globals.labels][dataPointIndex] +
          ['1 yr', '3 yrs', '5 yrs', '10 yrs'][dataPointIndex] +
          ': ' +
          currencyFormat.format(series[seriesIndex][dataPointIndex]) +
          '</span>' +
          '</div>'
        )
      },
    },
  }
  const series = [
    {
      data: [
        round(cashFlow1),
        round(cashFlow3),
        round(cashFlow5),
        round(cashFlow10),
      ],
    },
  ]

  const basicAcquisitionFields = [
    {
      type: 'dollar',
      name: 'offerPrice',
      label: 'Purchase Price',
      value:
        analysisInput.offerPrice === null
          ? //? analysisInput.listPrice.toString()
            ''
          : analysisInput.offerPrice.toString(),
      helper:
        !analysisInput.offerPrice || analysisInput.offerPrice === '0'
          ? '0% Discount to Price'
          : (
              (1 -
                analysisInput.offerPrice / analysisInput.listPrice) *
              100
            ).toFixed(0) + '% Discount to Price',
      // xs: 6,
      xs: 12,
      lg: 6,
      definition: 'offerPrice',
      tooltipField:
        'The price that has been agreed upon by the owner and buyer for the transferring of a property.',
      tooltipButtonText: 'Show Purchase Price data',
      dataTour: 'acquisitionExpenses-step-3',
    },
    {
      type: 'dollar',
      name: includes(
        assumptionsInput.strategySpecificFields,
        'afterRepairValue',
      )
        ? 'afterRepairValueSTR'
        : 'afterRepairValue',
      label: 'After Repair Value',
      tooltipButtonText: 'Show ARV data',
      value: includes(
        assumptionsInput.strategySpecificFields,
        'afterRepairValue',
      )
        ? analysisInput.afterRepairValueSTR === null
          ? ''
          : analysisInput.afterRepairValueSTR.toString()
        : analysisInput.afterRepairValue === null
          ? ''
          : analysisInput.afterRepairValue.toString(),
      //helper: currencyFormat.format(analytics.STR.equitySTR) + ' in equity',
      // xs: 6,
      xs: 12,
      lg: 6,
      definition: 'afterRepairValue',
      definitionTarget: includes(
        assumptionsInput.strategySpecificFields,
        'afterRepairValue',
      )
        ? 'afterRepairValueSTR'
        : 'afterRepairValue',
      tooltipField:
        'The ARV is the value of a property after you have conducted repairs and are ready to sell.',
      dataTour: 'acquisitionExpenses-step-4',
    },
    {
      type: 'dropdown',
      name: 'closingCostSTR',
      value: assumptionsInput.closingCostSTR,
      label: 'Closing Cost',
      adornmentToggle: assumptionsInput.closingCostSTRType,
      adornment: assumptionsInput.closingCostSTRType,
      adornmentName: 'closingCostSTRType',
      // xs: 6,
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Fees (attorney/title fees, etc) Paid at Closing. Expressed as a dollar amount or precentage of the purchase prices.',
      dataTour: 'acquisitionExpenses-step-5',
    },
    {
      type: 'dollar',
      name: 'creditFromSeller',
      value: assumptionsInput.creditFromSeller,
      label: 'Credit From Seller',
      // xs: 6,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'A seller credit is the dollar amount the seller will put towards your closing costs.',
      dataTour: 'acquisitionExpenses-step-6',
    },
  ]

  const advancedAcquisitionFields = [
    {
      type: 'dropdown',
      name: 'acquisitionCommission',
      label: 'Acq Commission',
      value: assumptionsInput.acquisitionCommission,
      adornmentToggle: assumptionsInput.acquisitionCommissionType,
      adornment: assumptionsInput.acquisitionCommissionType,
      adornmentName: 'acquisitionCommissionType',
      // xs: 6,
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Advanced Fee - Used in addition to sales of typical sales commissions expressed as a percentage of the Purchase price or a fixed dollar amount.',
    },
    {
      type: 'dollar',
      name: 'acquisitionFee',
      label: 'Acquisition Fee',
      value: assumptionsInput.acquisitionFee,
      // xs: 6,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Advanced Fee - Used in addition to sales of typical sales commissions expressed as a fixed dollar amount',
    },
    // {
    //   type: 'percentage',
    //   name: 'assetManagementFee',
    //   label: 'Asset Mgmt Fee',
    //   value: assumptionsInput.assetManagementFee,
    //   xs: 6,
    //   assumptions: true,
    // },
    {
      type: 'dropdown',
      name: 'financingFeeSTR',
      label: 'Financing Fee',
      value: assumptionsInput.financingFeeSTR,
      adornment: assumptionsInput.financingFeeSTRType,
      adornmentToggle: assumptionsInput.financingFeeSTRType,
      adornmentName: 'financingFeeSTRType',
      // xs: 6,
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Advanced Fee - A fee that the real estate professional charges for securing financing on the property. Expressed as a percentage of the loan amount.',
    },
    {
      type: 'dollar',
      name: 'ownerReservesSTR',
      label: '1x Owner Reserve',
      value: assumptionsInput.ownerReservesSTR,
      // xs: 6,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'A one-time reserve you set aside (ex: $5,000) to cover unforeseen repairs, etc. This amount is in addition to the monthly capex (reserve) you set aside each month.',
    },
  ]

  const propertyManagementCompanies = [
    {
      image:
        'https://assets-global.website-files.com/5ebdcacf6fe55d6b95c4f2a1/5f244161238395a76fe1c352_logo-dark-vertical.svg',
      name: 'Mynd',
      // link: 'https://www.mynd.co/partners/housefolios',
      link: 'https://www.mynd.co',
      value: 'mynd',
    },
    {
      image: dark365Square,
      name: 'home365',
      link: 'https://www.home365.co',
      value: 'home365',
    },
    {
      image: '/images/guardianAsset.jpeg',
      name: 'Guardian Asset',
      link: 'https://guardianassetmgt.com/Home/Contact',
      value: 'guardianAsset',
    },
  ]

  const customAcquisitonField = [
    {
      type: 'budgetLines',
      name: 'customAcquisitionExpenses',
      value: analysisInput.customAcquisitionExpenses,
      label: 'Custom Acquisition Expenses',
      options: activeOrg.customAcquisitionExpenseTypes,
      xs: 12,
      optionName: 'customAcquisitionExpenseTypes',
      removeOption: true,
    },
  ]

  const uncheckAdvanced = every(
    advancedAcquisitionFields,
    (field) => !field.value,
  )

  const [state, setState] = React.useState({
    checkedA: !uncheckAdvanced,
    checkedB: false,
    checkedF: false,
    checkedG: false,
  })

  const acquisitionChartOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              formatter: (value) => currencyFormat.format(value),
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              formatter: (value) => {
                const total = sum(value.config.series)
                return currencyFormat.format(total)
              },
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => currencyFormat.format(value),
      },
    },
    legend: {
      position: 'bottom',
      show: false,
    },
    colors: pieColors,
    labels: concat(
      [
        'Purchase Price',
        'Closing Costs',
        'Acquisition Commission',
        'Acquisition Fee',
        /*'Asset Mangement Fee',*/ 'Points',
        'Financing Fee',
        'One-time Owner Reserve',
      ],
      map(
        analysis.customAcquisitionExpenses,
        (expense) => expense.type,
      ),
    ),
  }
  const acquisitionChartSeries = concat(
    [
      round(
        analysis.offerPrice === null
          ? analysis.listPrice
          : analysis.offerPrice,
        2,
      ) || 0.0001,
      round(analytics.STR.closeCostDollarSTR, 2) || 0,
      round(analytics.acquisitionCommissionDollar, 2) || 0,
      round(assumptions.acquisitionFee, 2) || 0,
      /*round(analytics.STR.assetManagementFeeTotal, 2) || 0,*/ round(
        analytics.STR.pointsAmountSTR,
        2,
      ) || 0,
      round(analytics.STR.financingFeeTotalSTR, 2) || 0,
      round(assumptions.ownerReservesSTR, 2) || 0,
    ],
    map(
      analysis.customAcquisitionExpenses,
      (expense) => round(expense.value, 2) || 0,
    ),
  )

  const grossMonthlyIncomeFields = [
    {
      type: 'dropdown',
      name: `avgRate.${props.activeUnit}`,
      label: 'Avg Rate',
      value: get(assumptionsInput, `avgRate.${props.activeUnit}`),
      adornment:
        get(assumptionsInput, `avgRateType.${props.activeUnit}`) ||
        'Daily',
      adornmentToggle: get(
        assumptionsInput,
        `avgRateType.${props.activeUnit}`,
      ),
      adornmentName: `avgRateType.${props.activeUnit}`,
      xs: 12,
      lg: 6,
      dropdown: true,
      assumptions: true,
      // definition: 'avgRate',
      tooltipField: 'The amount you charge the occupant per day/week',
      dataTour: 'grossMonthlyIncome-step-1',
    },
    {
      type: 'percentage',
      name: `occupancyRate.${props.activeUnit}`,
      label: 'Occupancy Rate',
      value: get(
        assumptionsInput,
        `occupancyRate.${props.activeUnit}`,
      ),
      xs: 12,
      lg: 6,
      assumptions: true,
      // definition: 'occupancyRate',
      tooltipField:
        'The percentage of time that a property is rented out during the course of a year.',
      dataTour: 'grossMonthlyIncome-step-2',
    },
    {
      type: 'dollar',
      name: `otherIncome.${props.activeUnit}`,
      label: 'Other Monthly Income',
      value: get(analysisInput, `otherIncome.${props.activeUnit}`),
      xs: 12,
      lg: 6,
      tooltipField:
        'Additional income you earn through the property. Some examples might be coin-op laundry, parking, storage, etc.',
      dataTour: 'grossMonthlyIncome-step-3',
    },
  ]

  const grossMonthlyIncomeChartOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              formatter: (value) => currencyFormat.format(value),
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              formatter: (value) => {
                const total = sum(value.config.series)
                return currencyFormat.format(total)
              },
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => currencyFormat.format(value),
      },
    },
    legend: {
      position: 'bottom',
      show: false,
    },
    colors: pieColors,
    labels: concat(
      map(
        analytics.STR.grossAnnualRentSTR,
        (value, index) => `Unit ${index + 1} Monthly Rental Income`,
      ),
      map(
        analysis.otherIncome,
        (value, index) => `Unit ${index + 1} Other Monthly Income`,
      ),
    ),
  }
  const grossMonthlyIncomeChartSeries = concat(
    map(
      analytics.STR.grossAnnualRentSTR,
      (grossAnnualRentSTR, index) =>
        round(grossAnnualRentSTR / 12, 2) ||
        (index === 0 ? 0.0001 : 0),
    ),
    map(
      analysis.otherIncome,
      (otherIncome) => round(otherIncome, 2) || 0,
    ),
  )

  const expenseCard =
    find(
      cards,
      (card) =>
        card.name === 'expenses' && card.member === activeOrg.member,
    ) ||
    find(
      cards,
      (card) => card.name === 'expenses' && card.member === null,
    ) ||
    {}
  const monthlyExpensesFields = map(
    get(expenseCard, 'card.fields'),
    (field) => {
      const input = field.analytics
        ? analytics.STR
        : field.assumptions
          ? assumptionsInput
          : analysisInput
      const name = field.suffix
        ? includes(field.name, 'Type')
          ? replace(field.name, 'Type', 'STRType')
          : field.name + 'STR'
        : field.name
      const adornmentName =
        field.suffix && field.adornmentName
          ? includes(field.adornmentName, 'Type')
            ? replace(field.adornmentName, 'Type', 'STRType')
            : field.adornmentName + 'Turnkey'
          : field.adornmentName
      const definition =
        field.suffix && field.definition
          ? includes(field.definition, 'Type')
            ? replace(field.definition, 'Type', 'STRType')
            : field.definition + 'Turnkey'
          : field.definition
      const options =
        field.options && field.assumptions
          ? get(assumptionsOptions(activeOrg), field.options) ||
            get(activeOrg, field.options) ||
            field.options
          : get(activeOrg, field.options) || field.options
      return {
        ...field,
        name,
        value: get(input, name),
        adornmentToggle: get(input, adornmentName),
        adornment: get(input, adornmentName),
        adornmentName: adornmentName,
        definition: definition,
        options: options,
      }
    },
  )

  const monthlyExpensesChartOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              formatter: (value) => currencyFormat.format(value),
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              formatter: (value) => {
                const total = sum(value.config.series)
                return currencyFormat.format(total)
              },
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => currencyFormat.format(value),
      },
    },
    legend: {
      position: 'bottom',
      show: false,
    },
    colors: pieColors,
    labels: concat(
      [
        'Property Insurance',
        'Cohosting Fee',
        'Monthly HOA',
        'Property Taxes',
        'Maintenance',
        'Utilities',
        'Capex',
        'Other Expenses',
        'Mortgage',
      ],
      map(analysis.customMonthlyExpenses, (expense) => expense.type),
    ),
  }
  const monthlyExpensesChartSeries = concat(
    [
      round(analytics.STR.propertyInsuranceDollarSTR / 12, 2) ||
        0.0001,
      round(analytics.STR.cohostingDollar / 12, 2) || 0,
      round(analytics.STR.hoaTotal / 12, 2) || 0,
      round(analysis.propertyTaxes / 12, 2) || 0,
      round(analytics.STR.maintenanceCostSTR / 12, 2) || 0,
      round(analytics.STR.utilitiesTypeDollarSTR, 2) || 0,
      round(analytics.STR.monthlyReservesTypeDollarSTR, 2) || 0,
      round(assumptions.otherExpenseSTR / 12, 2) || 0,
      round(analytics.STR.mortgageSTR, 2) || 0,
    ],
    map(
      analysis.customMonthlyExpenses,
      (expense) => round(expense.value, 2) || 0,
    ),
  )

  // const rehabCard =
  //   find(
  //     cards,
  //     (card) =>
  //       card.name === 'rehab' && card.member === activeOrg.member,
  //   ) ||
  //   find(
  //     cards,
  //     (card) => card.name === 'rehab' && card.member === null,
  //   ) ||
  //   {}
  // const rehabFields = filter(
  //   map(get(rehabCard, 'card.fields'), (field) => {
  //     const input = field.analytics
  //       ? analytics.STR
  //       : field.assumptions
  //       ? assumptionsInput
  //       : analysisInput
  //     const name = field.suffix
  //       ? includes(field.name, 'Type')
  //         ? replace(field.name, 'Type', 'STRType')
  //         : field.name + 'STR'
  //       : field.name
  //     const hide =
  //       field.suffix && field.hide
  //         ? includes(field.hide, 'Type')
  //           ? replace(field.hide, 'Type', 'STRType')
  //           : field.hide + 'STR'
  //         : field.hide
  //     const options =
  //       field.options && field.assumptions
  //         ? get(assumptionsOptions(activeOrg), field.options) ||
  //           get(activeOrg, field.options) ||
  //           field.options
  //         : get(activeOrg, field.options) || field.options
  //     const memberOptions =
  //       field.memberOptions && field.assumptions
  //         ? get(assumptionsOptions(activeOrg), field.memberOptions) ||
  //           get(activeOrg, field.memberOptions)
  //         : get(activeOrg, field.memberOptions)
  //     const adornmentName =
  //       field.suffix && field.adornmentName
  //         ? includes(field.adornmentName, 'Type')
  //           ? replace(field.adornmentName, 'Type', 'STRType')
  //           : field.adornmentName + 'Turnkey'
  //         : field.adornmentName
  //     const definition =
  //       field.suffix && field.definition
  //         ? includes(field.definition, 'Type')
  //           ? replace(field.definition, 'Type', 'STRType')
  //           : field.definition + 'Turnkey'
  //         : field.definition
  //     const helper = includes(field.name, 'rehabTier')
  //       ? currencyFormat.format(
  //           (field.value ? eval(field.value) : get(input, name)) *
  //             (assumptionsInput.percentageOfARVFixFlip / 100) *
  //             propertyInput.sqft,
  //         ) + ' Total'
  //       : null
  //     return {
  //       ...field,
  //       name,
  //       value: get(input, name),
  //       adornmentToggle: get(input, adornmentName),
  //       adornment: get(input, adornmentName),
  //       adornmentName: adornmentName,
  //       options: filter(
  //         options,
  //         (option) =>
  //           isEmpty(option.showMarkets) ||
  //           includes(option.showMarkets, analysisInput.marketType),
  //       ),
  //       memberOptions: filter(
  //         memberOptions,
  //         (option) =>
  //           isEmpty(option.showMarkets) ||
  //           includes(option.showMarkets, analysisInput.marketType),
  //       ),
  //       definition: definition,
  //       helper,
  //       hide,
  //     }
  //   }),
  //   (field) => !field.hide || !eval(field.hide),
  // )
  const rehabFields = filter(
    [
      {
        type: 'select',
        name: 'rehabSTRType',
        value: assumptionsInput.rehabSTRType,
        label: 'Rehab Type',
        xs: 12,
        options: assumptionsOptions(activeOrg)?.rehabOptions,
        assumptions: true,
        dataTour: 'rehabExpensesRehabType',
      },
      {
        type: 'dollar',
        name: 'rehabCostSTR',
        value: analysisInput.rehabCostSTR,
        label: 'Est Rehab',
        tooltipButtonText: 'Show Estimated Rehab data',
        xs: 12,
        lg: 6,
        definition: 'rehabCostTurnkey',
        tooltipField:
          'An expense that is incurred when restoring an entity to its normal or near-normal condition.',
        hide: assumptionsInput.rehabSTRType !== 'singleRehabValue',
        dataTour: 'rehabExpensesEstRehab',
        dataTourDatabase: 'rehabExpensesDatabaseIcon',
      },
      {
        type: 'budgetLines',
        name: 'detailedRehabCostSTR',
        value: analysisInput.detailedRehabCostSTR,
        label: 'Rehab Costs',
        xs: 12,
        options: [
          'Appliances',
          'Cabinets/Vanities',
          'Carpet',
          'Countertops/Sinks/Toilets',
          'Driveway/Decking/Patio',
          'Electrical',
          'Exterior',
          'Flooring',
          'Framing',
          'Insulation/Drywall',
          'Landscaping',
          'Masonry',
          'Mechanical',
          'Molding/Tile/Trim',
          'Paint',
          'Plans/Permits',
          'Plumbing',
          'Roof',
          'Siding/Gutters',
          'Site Prep',
          'Staging',
          'Windows/Doors',
          'Other',
        ],
        hide: assumptionsInput.rehabSTRType !== 'detailedRehab',
      },
      {
        type: 'rehabCalc',
        name: 'rehabCalcTotalSTR',
        label: 'Rehab Calculator',
        value: analysisInput.rehabCalcTotalSTR,
        xs: 12,
        lg: 6,
        property: property,
        assumptionsInput: assumptionsInput,
        affiliate: props.affiliate,
        hide: assumptionsInput.rehabSTRType !== 'rehabCalc',
      },
      {
        type: 'select',
        name: 'rehabTierSTR',
        value: assumptionsInput.rehabTierSTR,
        label: 'Rehab Tier',
        tooltipButtonText: 'Show Estimated Rehab data',
        xs: 12,
        lg: 6,
        options: filter(
          activeOrg.rehabTiers,
          (option) =>
            isEmpty(option.showMarkets) ||
            includes(
              option.showMarkets,
              analysisInput.marketType ||
                props.affiliate?.defaultMarketType,
            ),
        ),
        optionName: 'rehabTiers',
        memberOptions: filter(
          activeOrg.memberRehabTiers,
          (option) =>
            isEmpty(option.showMarkets) ||
            includes(
              option.showMarkets,
              analysisInput.marketType ||
                props.affiliate?.defaultMarketType,
            ),
        ),
        dynamicMemberOptions: map(
          filter(
            activeOrg.dynamicMemberRehabTiers,
            (option) =>
              isEmpty(option.showMarkets) ||
              includes(
                option.showMarkets,
                analysisInput.marketType ||
                  props.affiliate?.defaultMarketType,
              ),
          ),
          (option) => {
            let breakPointIndex = 0
            const arv = includes(
              assumptionsInput.strategySpecificFields,
              'afterRepairValue',
            )
              ? analysisInput.afterRepairValueSTR === null
                ? null
                : analysisInput.afterRepairValueSTR
              : analysisInput.afterRepairValue === null
                ? null
                : analysisInput.afterRepairValue
            while (
              option?.breakpoints[breakPointIndex] < arv &&
              breakPointIndex < option?.breakpoints.length
            ) {
              breakPointIndex++
            }
            return {
              ...option,
              label: `$${option.values[breakPointIndex]}/Sqft`,
            }
          },
        ),
        removeOption: true,
        assumptions: true,
        hide:
          assumptionsInput.rehabSTRType !== 'rehabTier' &&
          assumptionsInput.rehabSTRType !== 'flippingAmericaRehab',
        dataTour: 'rehabExpensesRehabTier',
        helper: currencyFormat.format(
          analytics.STR.rehabTierValueSTR,
        ),
      },
      {
        name: 'marketType',
        value: !analysisInput.marketType
          ? props.affiliate?.defaultMarketType
          : analysisInput.marketType,
        label: 'Market Type',
        options: concat(
          [props.affiliate?.defaultMarketType],
          props.affiliate?.marketTypes,
        ),
        type: 'select',
        xs: 12,
        lg: 6,
        hide:
          isEmpty(props.affiliate?.marketTypes) ||
          (assumptionsInput.rehabSTRType !== 'rehabTier' &&
            assumptionsInput.rehabSTRType !== 'flippingAmericaRehab'),
      },
      {
        type: 'dollar',
        name: 'rehabDecadeCostSTR',
        value: assumptionsInput.rehabDecadeCostSTR,
        label: 'Rehab Per Decade',
        xs: 12,
        lg: 6,
        assumptions: true,
        hide:
          assumptionsInput.rehabSTRType !== 'flippingAmericaRehab',
        dataTour: 'rehabExpensesRehabPerDecade',
      },
      {
        type: 'number',
        name: 'rehabPeriodSTR',
        value: assumptionsInput.rehabPeriodSTR,
        label: 'Rehab Period (mo)',
        xs: 12,
        lg: 6,
        tooltipField:
          'AnA holding period is the amount of time the investment is held by an investor or the period between the purchase and sale of the property. Expressed in months.',
        assumptions: true,
      },
      {
        type: 'number',
        name: 'rehabContingency',
        value: assumptionsInput.rehabContingencySTR,
        label: 'Rehab Contingency',
        adornment: assumptionsInput.rehabContingencySTRType,
        adornmentToggle: assumptionsInput.rehabContingencySTRType,
        adornmentName: 'rehabContingencySTRType',
        xs: 12,
        lg: 6,
        tooltipField:
          'Percentage of calculated rehab cost added as a saftey margin',
        assumptions: true,
        helper:
          assumptionsInput.rehabContingencySTRType === '%'
            ? currencyFormat.format(
                (analytics.STR.corTotalSTR *
                  assumptionsInput.rehabContingencySTR) /
                  100,
              )
            : null,
        suffix: true,
      },
    ],
    (field) => !field.hide,
  )

  const rehabChartOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              formatter: (value) => currencyFormat.format(value),
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              formatter: (value) => {
                const total = sum(value.config.series)
                return currencyFormat.format(total)
              },
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => currencyFormat.format(value),
      },
    },
    legend: {
      position: 'bottom',
      show: false,
    },
    colors: pieColors,
    labels: concat(
      assumptionsInput.rehabSTRType === 'detailedRehab'
        ? isEmpty(analysis.detailedRehabCostSTR)
          ? ['Total Rehab']
          : map(
              analysis.detailedRehabCostSTR,
              (line) => line.type || 'Unlabeled',
            )
        : ['Total Rehab'],
      [],
    ),
  }
  const rehabChartSeries = concat(
    assumptionsInput.rehabSTRType === 'detailedRehab'
      ? isEmpty(analysis.detailedRehabCostSTR)
        ? [0.0001]
        : map(analysis.detailedRehabCostSTR, (line, index) =>
            index === 0
              ? round(line.value, 2) || 0.0001
              : round(line.value, 2) || 0,
          )
      : [round(analytics.STR.corTotalSTR, 2) || 0.0001],
    [],
  )

  const financingFields = [
    {
      type: 'checkbox',
      label: 'Cash Purchase',
      name: 'allCashPurchaseSTR',
      value: assumptionsInput.allCashPurchaseSTR,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Investor pays 100% of the purchase price in cash and does not have a mortgage payment.',
      dataTour: 'financing-step-1',
    },
    {
      type: 'percentage',
      name: 'downPaymentSTR',
      label: 'Down Payment',
      value: assumptionsInput.downPaymentSTR || 0,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
      dataTour: 'financing-step-2',
    },
    {
      type: 'percentage',
      name: 'financingRepairsPercentageSTR',
      label: '% of Rehab',
      value: analysisInput.financingRepairsPercentageSTR || 0,
      xs: 12,
      lg: 6,
      tooltipField:
        'The percentage of the repair cost that is being covered by the loan',
      dataTour: 'financing-step-7',
    },
    {
      type: 'percentage',
      name: 'rateSTR',
      label: 'Interest Rate',
      value: assumptionsInput.rateSTR || 0,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The interest rate is the amount a lender charges for the use of capital, expressed as a percentage of the principal. The interest rate is typically noted on an annual basis known as the annual percentage rate (APR).',
      dataTour: 'financing-step-3',
    },
    {
      type: 'dropdown',
      name: 'termSTR',
      label: 'Financing Term',
      value: assumptionsInput.termSTR || 0,
      adornment: assumptionsInput.termSTRType,
      adornmentToggle: assumptionsInput.termSTRType,
      adornmentName: 'termSTRType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'The length of time that the loan is being amortized. Expressed in months or years.',
      dataTour: 'financing-step-4',
    },
    {
      type: 'number',
      name: 'pointsSTR',
      label: 'Points',
      value: assumptionsInput.pointsSTR || 0,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Often when you take out a loan, you will pay "points" (aka: fees) on the loan. It is essentially prepaid interest charged by the lender. One point is equal to 1% of the loan amount. For example, a $100,000 loan with 2 points would be $2,000.',
      dataTour: 'financing-step-5',
    },
    {
      type: 'checkbox',
      name: 'interestOnlyLoanSTR',
      label: 'Interest Only Loan',
      value: assumptionsInput.interestOnlyLoanSTR,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'A loan that only requires the payment of interest, not principal.',
      dataTour: 'financing-step-6',
    },
    // {
    //   type: 'checkbox',
    //   name: 'financingRepairsSTR',
    //   label: 'Finance Repairs',
    //   value: analysisInput.financingRepairsSTR || false,
    //   xs: 12,
    //   lg: 6,
    //   tooltipField: 'Your down payment adjusts what percentage of your repairs will be financed.',
    //   dataTour: "financing-step-7",
    // },
  ]

  const projectionCard =
    find(
      cards,
      (card) =>
        card.name === 'projection' &&
        card.member === activeOrg.member,
    ) ||
    find(
      cards,
      (card) => card.name === 'projection' && card.member === null,
    ) ||
    {}
  const projectionAssumptionsFields = map(
    get(projectionCard, 'card.fields'),
    (field) => {
      const input = field.analytics
        ? analytics.STR
        : field.assumptions
          ? assumptionsInput
          : analysisInput
      const name = field.suffix
        ? includes(field.name, 'Type')
          ? replace(field.name, 'Type', 'STRType')
          : field.name + 'STR'
        : field.name
      const adornmentName =
        field.suffix && field.adornmentName
          ? includes(field.adornmentName, 'Type')
            ? replace(field.adornmentName, 'Type', 'STRType')
            : field.adornmentName + 'Turnkey'
          : field.adornmentName
      const definition =
        field.suffix && field.definition
          ? includes(field.definition, 'Type')
            ? replace(field.definition, 'Type', 'STRType')
            : field.definition + 'Turnkey'
          : field.definition
      return {
        ...field,
        name,
        value: get(input, name),
        adornmentToggle: get(input, adornmentName),
        adornment: get(input, adornmentName),
        adornmentName: adornmentName,
        definition: definition,
      }
    },
  )

  const chartOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    colors: ['#1CC943', '#F83244', '#04A3E3'],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: true,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box">' +
          '<span>' +
          //[w.globals.labels][dataPointIndex] +
          ['Income', 'Expenses', 'Cashflow'][dataPointIndex] +
          ': ' +
          currencyFormat.format(series[seriesIndex][dataPointIndex]) +
          '</span>' +
          '</div>'
        )
      },
    },
    xaxis: {
      categories: ['Inc...', 'Exp...', 'Cash...'],
      labels: {
        rotate: -30,
        rotateAlways: true,
        offsetY: -10,
        formatter: currencyFormat.format,
      },
    },
  }
  const chartSeries = [
    {
      data: [
        round(sum(grossMonthlyIncomeChartSeries)),
        round(sum(monthlyExpensesChartSeries)),
        round(analytics.STR.cashFlowSTR / 12),
      ],
      // colors: [
      //   ranges: [
      //     {
      //       from: 100,
      //       to: 0,
      //       color: '#f83145',
      //     },
      //   ],
      // ],
    },
  ]

  const pitiFields = [
    {
      type: 'dollar',
      label: 'Principal',
      name: 'pitiData.principal',
      value: propertyInput?.pitiData.principal,
      xs: 12,
      lg: 6,
      details: true,
    },
    {
      type: 'dollar',
      label: 'Interest',
      name: 'pitiData.interest',
      value: propertyInput?.pitiData.interest,
      xs: 12,
      lg: 6,
      details: true,
    },
    {
      type: 'dollar',
      label: 'Taxes',
      name: 'pitiData.taxes',
      value: propertyInput?.pitiData.taxes,
      xs: 12,
      lg: 6,
      details: true,
    },
    {
      type: 'dollar',
      label: 'Insurance',
      name: 'pitiData.insurance',
      value: propertyInput?.pitiData.insurance,
      xs: 12,
      lg: 6,
      details: true,
    },
    {
      type: 'text',
      label: 'Loan type',
      name: 'pitiData.loanType',
      value: propertyInput?.pitiData.loanType,
      xs: 12,
      lg: 6,
      details: true,
    },
    {
      type: 'datePicker',
      label: 'Origination Date',
      name: 'pitiData.originDate',
      value: new Date(propertyInput?.pitiData.originDate),
      xs: 12,
      lg: 6,
      details: true,
    },
    {
      name: 'pitiData.mortgageStatement',
      label: 'Mortgage statement',
      type: 'file',
      value: propertyInput?.pitiData.mortgageStatement,
      uploadFile: props.uploadFileProperty(
        'pitiData.mortgageStatement',
      ),
      // deleteFile: props.deleteFileProperty(),
      buttonText: 'Upload Mortgage statement',
      xs: 12,
      lg: 6,
      details: true,
    },
    {
      name: 'pitiData.insuranceStatement',
      label: 'Insurance statement',
      type: 'file',
      value: propertyInput?.pitiData.insuranceStatement,
      uploadFile: props.uploadFileProperty(
        'pitiData.insuranceStatement',
      ),
      // deleteFile: props.deleteFileProperty(),
      buttonText: 'Upload Insurance statement',
      xs: 12,
      lg: 6,
      details: true,
    },
  ]

  const strategySummary = {
    name: 'Short Term Rental',
    percentage:
      (analytics.STR.onTargetSTR + analytics.STR.aboveTargetSTR) /
      (analytics.STR.belowTargetSTR +
        analytics.STR.onTargetSTR +
        analytics.STR.aboveTargetSTR),
    analytics: [
      {
        value: analytics.STR.cashFlowSTR / 12 || '-',
        arrow: analytics.STR.cashFlowSTRArrow || 0,
        label: 'Net Cashflow',
        type: 'currency',
        target: assumptions.belowCashFlowSTR,
        targetLabel: 'Min Net Cashflow Target',
        targetName: 'belowCashFlowSTR',
        tourStep: 'targets-step-1',
        min: 100,
        max: 2000,
        minLabel: '$100',
        maxLabel: '$2,000',
        step: 100,
        valueLabelFormat: (x) =>
          x >= 1000 ? `${x / 1000}k` : `${x}`,
        description: `Net cashflow is the amount of money left after the mortgage and any other expenses are paid.`,
        calculation: (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(sum(grossMonthlyIncomeChartSeries) * 12),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Cohosting
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.STR.cohostingDollar, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.STR.maintenanceCostSTR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.STR.propertyInsuranceDollarSTR,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analysis.propertyTaxes, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.STR.hoaTotal, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.STR.assetManagementFeeTotalSTR,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.STR.utilitiesTypeDollarSTR * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.STR.monthlyReservesTypeDollarSTR * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(assumptions.otherExpenseSTR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Custom Expenses
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.customMonthlyExpenseTotal * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(analytics.STR.netOperatingIncomeSTR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Annual Mortgage
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.STR.mortgageSTR * 12, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Net Annual Cashflow
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(analytics.STR.cashFlowSTR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  12 months
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  12
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Net Monthly Cashflow
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(analytics.STR.cashFlowSTR / 12, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
          </>
        ),
      },
      {
        value: analytics.STR.cashOnCashReturnSTR || '-',
        arrow: analytics.STR.cashOnCashReturnSTRArrow || 0,
        label: 'CoC ROI',
        target: assumptions.belowCashOnCashReturnSTR,
        targetLabel: 'Min Cash on Cash Return Target',
        targetName: 'belowCashOnCashReturnSTR',
        tourStep: 'targets-step-2',
        min: 0,
        max: 30,
        minLabel: '0%',
        maxLabel: '30%',
        description: `A cash-on-cash return calculates the return on the money put in by the investor on a property with a mortgage. Commonly referred to as a Leveraged Return.`,
        calculation: (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(sum(grossMonthlyIncomeChartSeries) * 12),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Cohosting
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.STR.cohostingDollar, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.STR.maintenanceCostSTR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.STR.propertyInsuranceDollarSTR,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analysis.propertyTaxes, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.STR.hoaTotal, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.STR.assetManagementFeeTotalSTR,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.STR.utilitiesTypeDollarSTR * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.STR.monthlyReservesTypeDollarSTR * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(assumptions.otherExpenseSTR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Custom Expenses
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.customMonthlyExpenseTotal * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(analytics.STR.netOperatingIncomeSTR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Annual Mortgage
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.STR.mortgageSTR * 12, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Net Annual Cashflow
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(analytics.STR.cashFlowSTR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  Total Out-of-Pocket
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  {currencyFormat.format(
                    round(analytics.STR.totalOutOfPocketSTR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Cash on Cash Return
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {analytics.STR.cashOnCashReturnSTR > 1000
                    ? '+1000%'
                    : percentageFormat.format(
                        analytics.STR.cashOnCashReturnSTR,
                      )}
                </Typography>
              </Grid>
            </Grid>
          </>
        ),
      },
      {
        value: analytics.STR.IRRSTR || '-',
        arrow: analytics.STR.IRRSTRArrow || 0,
        label: 'IRR',
        target: assumptions.belowIRRSTR,
        targetLabel: 'Min IRR Target',
        targetName: 'belowIRRSTR',
        tourStep: 'targets-step-3',
        min: 0,
        max: 30,
        minLabel: '0%',
        maxLabel: '30%',
        description: `IRR measures an investment’s annual return throughout the entire property ownership time frame. Unlike a cash-on-cash return or cap rate that measures returns in annual segments, IRR weighs total earnings from the day you purchase your property to the day you sell it.`,
        calculation: (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography
                  style={{ color: '#9198a8', marginBottom: 20 }}
                >
                  Due to the complexity of this calculation, we only
                  show an overview of what goes into calculating the
                  IRR
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Initial Investment (Out of pocket)
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(analytics.STR.totalOutOfPocketSTR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Monthly Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(sum(grossMonthlyIncomeChartSeries)),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Rent Growth Rate
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {assumptions.growthRateIncome > 1000
                    ? '+1000%'
                    : assumptions.growthRateIncome}
                  %
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Monthly Expenses
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(sum(monthlyExpensesChartSeries)),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Expenses Growth Rate
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {assumptions.growthRateExpense > 1000
                    ? '+1000%'
                    : assumptions.growthRateExpense}
                  %
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  After Repair Value
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(analytics.STR.arvTotalSTR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Appreciation Rate
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {assumptions.homePriceAppreciation > 1000
                    ? '+1000%'
                    : assumptions.homePriceAppreciation}
                  %
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Holding Period (years)
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {assumptions.rentalHoldingPeriodSTR}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Future property sale
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(analytics.STR.futureSaleValueSTR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  IRR
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {analytics.STR.IRRSTR > 1000
                    ? '+1000%'
                    : percentageFormat.format(analytics.STR.IRRSTR)}
                </Typography>
              </Grid>
            </Grid>
            {/* <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>-Annual Expenses</Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>-1,308</Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>Net Operating Income</Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>14892</Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon icon={['fal', 'divide']} style={{ marginRight: 3 }} />All-in-cost
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon icon={['fal', 'divide']} style={{ marginRight: 3 }} />14,892
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>Cap Rate</Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>12.82%</Typography>
              </Grid>
            </Grid> */}
          </>
        ),
      },
      {
        value: analytics.STR.capRateSTR || '-',
        arrow: analytics.STR.capRateSTRArrow || 0,
        label: 'Yield-on-Cost',
        target: assumptions.belowCapRateSTR,
        targetLabel: 'Min Yield on Cost Target',
        targetName: 'belowCapRateSTR',
        tourStep: 'targets-step-4',
        min: 0,
        max: 30,
        minLabel: '0%',
        maxLabel: '30%',
        description: `The Yield-on-Cost is the rate of return an investor can expect from an investment in real estate, assuming they pay all cash.`,
        calculation: (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(sum(grossMonthlyIncomeChartSeries) * 12),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Cohosting
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.STR.cohostingDollar, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.STR.maintenanceCostSTR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.STR.propertyInsuranceDollarSTR,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analysis.propertyTaxes, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.STR.hoaTotal, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.STR.assetManagementFeeTotalSTR,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.STR.utilitiesTypeDollarSTR * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.STR.monthlyReservesTypeDollarSTR * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(assumptions.otherExpenseSTR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Custom Expenses
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.customMonthlyExpenseTotal * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(analytics.STR.netOperatingIncomeSTR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  All-in-cost
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  {currencyFormat.format(
                    round(analytics.STR.allInCostSTR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Yield-on-Cost
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {percentageFormat.format(analytics.STR.capRateSTR)}
                </Typography>
              </Grid>
            </Grid>
          </>
        ),
      },
    ],
  }

  const financials = [
    {
      value: analytics.STR.allInCostSTR,
      label: 'All-in Cost',
      description:
        'Total amount of initial investment into a property, including renovation and points paid for financing.',
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Purchase Price
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                {currencyFormat.format(
                  round(analysis.offerPrice || analysis.listPrice, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Commission
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.acquisitionCommissionDollar, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Fee
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(assumptions.acquisitionFee, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Closing Costs
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.STR.closeCostDollarSTR, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Loan Points
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.STR.pointsAmountSTR, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Rehab Amount
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.STR.repairsOutOfPocketSTR, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Financing Fee
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.STR.financingFeeTotalSTR, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Owner Reserves
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(assumptions.ownerReservesSTR, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Custom Acquisition Expenses
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.customAcquisitionExpenseTotal, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                All-in Cost
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'white' }}>
                {currencyFormat.format(
                  round(analytics.STR.allInCostSTR, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      value: analytics.STR.totalOutOfPocketSTR,
      label: 'Total Out-of-Pocket',
      description:
        'Total amount of cash that a buyer has put into a property of his/her own capital.',
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Down Payment
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                {currencyFormat.format(
                  round(analytics.STR.downPaymentTotalSTR, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Commission
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.acquisitionCommissionDollar, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Fee
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(assumptions.acquisitionFee, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Closing Costs
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.STR.closeCostDollarSTR, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Financing Fee
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.STR.financingFeeTotalSTR, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Loan Points
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.STR.pointsAmountSTR, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Custom Acquisition Expenses
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.customAcquisitionExpenseTotal, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Credit From Seller
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -
                {currencyFormat.format(
                  round(assumptions.creditFromSeller, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Rehab Amount
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.STR.repairsOutOfPocketSTR, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                Total Out-of-Pocket
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'white' }}>
                {currencyFormat.format(
                  round(analytics.STR.totalOutOfPocketSTR, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
  ]

  const dispositionFields = [
    {
      type: 'dollar',
      name: 'resellPriceSTR',
      label: 'Resale Price',
      value:
        analysisInput.resellPriceSTR === null
          ? ''
          : analysisInput.resellPriceSTR.toString(),
      xs: 12,
      lg: 6,
      tooltipField:
        'The price you will resell the property for once the rehab is completed.',
      dataTour: 'dispositionExpenses-step-1',
    },
    {
      type: 'dropdown',
      name: 'sellerClosingCostSTR',
      label: 'Seller Closing Cost',
      value: assumptionsInput.sellerClosingCostSTR,
      adornmentToggle: assumptionsInput.sellerClosingCostSTRType,
      adornment: assumptionsInput.sellerClosingCostSTRType,
      adornmentName: 'sellerClosingCostSTRType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Closing Costs Paid by the Seller. Expressed as a dollar amount or percentage of the resale price',
      dataTour: 'dispositionExpenses-step-2',
    },
    {
      type: 'dropdown',
      name: 'salesCommissionSTR',
      label: 'Sales Commission',
      value: assumptionsInput.salesCommissionSTR,
      adornmentToggle: assumptionsInput.salesCommissionSTRType,
      adornment: assumptionsInput.salesCommissionSTRType,
      adornmentName: 'salesCommissionSTRType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Sales Commission on the Future Sale of Property. Expressed as a dollar amount or percentage of resale price.',
      dataTour: 'dispositionExpenses-step-3',
    },
    {
      type: 'dropdown',
      name: 'dispositionFeeSTR',
      label: 'Disposition Fee',
      value: assumptionsInput.dispositionFeeSTR,
      adornmentToggle: assumptionsInput.dispositionFeeSTRType,
      adornment: assumptionsInput.dispositionFeeSTRType,
      adornmentName: 'dispositionFeeSTRType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Advanced Fee - Additional marketing fee on top of sales commission. Expressed as a dollar amount or percentage of resale price.',
      dataTour: 'dispositionExpenses-step-4',
    },
    {
      type: 'percentage',
      name: 'homePriceAppreciation',
      label: 'Home Price Appreciation',
      value: assumptionsInput.homePriceAppreciation,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The % of Appreciation in Total Dollar Value that the Home has gained or lost each year. Expressed as a percentage of ARV (after repair value). If your holding period is less than 12 months, this field will have no effect.',
      dataTour: 'dispositionExpenses-step-5',
    },
    {
      type: 'percentage',
      name: 'growthRateExpense',
      label: 'Expense Growth Rate',
      value: assumptionsInput.growthRateExpense,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The % increase in property expenses per year. Expressed as a percentage of gross rental income. If your holding period is less than 12 months, this field will have no effect.',
      dataTour: 'dispositionExpenses-step-6',
    },
    {
      type: 'dollar',
      name: 'loanBalanceAtHoldingPeriodSTR',
      label: 'Loan Balance at Resale',
      value:
        analytics.STR.loanBalanceAtHoldingPeriodSTR === null
          ? ''
          : analytics.STR.loanBalanceAtHoldingPeriodSTR,
      xs: 12,
      lg: 6,
      disabled: true,
      tooltipField:
        'This field is not editable. The remaining loan amount at the end of your flip holding period.',
      dataTour: 'dispositionExpenses-step-7',
    },
  ]

  const dispositionChartOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              formatter: (value) => currencyFormat.format(value),
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              formatter: (value) => {
                const total = sum(value.config.series)
                return currencyFormat.format(total)
              },
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => currencyFormat.format(value),
      },
    },
    legend: {
      position: 'bottom',
      show: false,
    },
    colors: pieColors,
    labels: [
      'Seller Closing Cost',
      'Sales Commission',
      'Disposition Fee',
      'Loan Balance at Resale',
    ],
  }
  const dispositionChartSeries = [
    round(analytics.STR.sellingClosingCostDollarSTR, 2) || 0.0001,
    round(analytics.STR.salesCommissionTotalSTR, 2) || 0,
    round(analytics.STR.dispositionFeeDollarSTR, 2) || 0,
    round(analytics.STR.loanBalanceAtHoldingPeriodSTR, 2) || 0,
  ]

  const compData = [
    {
      label: 'Avg. Adjusted Value',
      value: isEmpty(props.compValues)
        ? 0
        : meanBy(
            filter(props.compValues, 'propertyPrice'),
            'propertyPrice',
          ),
    },
    {
      range: true,
      label: 'Adjusted Range',
      value1: isEmpty(props.compValues)
        ? 0
        : minBy(props.compValues, 'propertyPrice').propertyPrice /
          1000,
      value2: isEmpty(props.compValues)
        ? 0
        : maxBy(props.compValues, 'propertyPrice').propertyPrice /
          1000,
    },
    {
      label: 'Avg Adjusted Price/sqft',
      value: isEmpty(props.compValues)
        ? 0
        : meanBy(
            filter(props.compValues, 'propertyPerSqft'),
            'propertyPerSqft',
          ),
    },
    {
      label: 'Lowest 3 comp avg',
      value: props.lowestAverage.afterRepairValue,
    },
  ]

  const rentalCompData = [
    {
      label: 'Average Daily Rate',
      value: isEmpty(props.rentalComps)
        ? 0
        : meanBy(
            filter(props.rentalComps, 'rentalPrice'),
            'rentalPrice',
          ),
    },
    {
      label: 'Average Occupancy Rate',
      value: isEmpty(props.rentalComps)
        ? 0
        : meanBy(
            filter(props.rentalComps, 'occupancyRate'),
            'occupancyRate',
          ),
      percent: true,
    },
    // {
    //   label: "Average Property Value",
    //   value: isEmpty(props.compValues) ? 0 : meanBy(filter(props.compValues, 'propertyPerSqft'), 'propertyPerSqft'),
    // },
    {
      label: 'Average Monthly Revenue Value',
      value: mean(
        compact(
          map(
            props.rentalComps,
            (comp) => comp.rentalPrice * comp.occupancyRate * 30,
          ),
        ),
      ),
    },
  ]

  const [backdrop, setBackdrop] = useState(false)

  const [compView, setCompView] = useState('sold')

  // const [getRentalComps] = useMutation(GET_RENTAL_COMPS)

  // const pullRentalComps = () => {
  //   const { location = {} } = property
  //   setBackdrop(true)

  //   getRentalComps({
  //     variables: {
  //       propertyId: property._id,
  //       address: property.address,
  //       city: property.city,
  //       state: property.state,
  //       location: {
  //         latitude: location.latitude,
  //         longitude: location.longitude,
  //       },
  //       beds: sum(property.beds),
  //     },
  //     refetchQueries: [
  //       {
  //         query: GET_COMPPROPERTIES,
  //         variables: {
  //           propertyId: property._id,
  //         },
  //       },
  //       {
  //         query: GET_PROPERTY,
  //         variables: { id: property._id },
  //       },
  //     ],
  //   })
  //     .then(async ({ data = {} }) => {
  //       if (
  //         data.getRentalComps &&
  //         !isEmpty(data.getRentalComps.properties)
  //       )
  //         enqueueSnackbar(`Comps added successfully`, {
  //           variant: 'success',
  //           autoHideDuration: 3000,
  //         })
  //       else
  //         enqueueSnackbar(`We couldn't find any comps at this time`, {
  //           variant: 'error',
  //           autoHideDuration: 3000,
  //         })
  //     })
  //     .catch((error) => {
  //       enqueueSnackbar(`Something went wrong`, {
  //         variant: 'error',
  //         autoHideDuration: 3000,
  //       })
  //     })
  //     .finally(() => {
  //       setBackdrop(false)
  //     })
  // }

  const shortTermRentalCardsToOrder = [
    {
      name: 'strategy',
      card: (
        <Card className="card-box w-100">
          <div className="card-header">
            <div className="card-header--title">
              <small className="d-block text-uppercase mt-1">
                Strategy
              </small>
              <b>Short Term Rental</b>
            </div>
            <div>
              <Tooltip title="Quick glance determination seen on the main page">
                <Button
                  onClick={() =>
                    setOpenShortTermRentalStrategyTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <div className="d-flex flex-column flex-grow-1 justify-content-center w-100">
            <div className="card-header-alt d-flex justify-content-center px-4 pt-4 pb-3" />
            <div className="mx-auto" style={{ paddingBottom: 20 }}>
              <GaugeChart
                id="chartsGauges3B"
                nrOfLevels={6}
                colors={['#f83245', '#f4772e', '#1bc943']}
                arcWidth={0.4}
                hideText
                percent={strategySummary.percentage}
              />
            </div>
            <p
              className="mb-3 text-black-50 text-center"
              style={{ paddingLeft: '10px', paddingRight: '10px' }}
            >
              According to your inputs, a {strategySummary.name}{' '}
              strategy meets{' '}
              {strategySummary.percentage < 0.33 && (
                <b style={{ color: '#f83245' }}>
                  {round(strategySummary.percentage * 100)}%
                </b>
              )}
              {strategySummary.percentage > 0.33 &&
                strategySummary.percentage < 0.66 && (
                  <b style={{ color: '#f4772e' }}>
                    {round(strategySummary.percentage * 100)}%
                  </b>
                )}
              {strategySummary.percentage > 0.66 && (
                <b style={{ color: '#1bc943' }}>
                  {round(strategySummary.percentage * 100)}%
                </b>
              )}{' '}
              of your targets
            </p>
            {!props.shared &&
              activeOrg.member !== 'gorillacapital' && (
                <div className="px-4 pb-4 pt-2">
                  <ContactAgent
                    property={property}
                    propertyPage={true}
                    canDrag={props.canDrag}
                  />
                </div>
              )}
          </div>
        </Card>
      ),
    },
    {
      name: 'targets',
      card: (
        <Card className="card-box w-100">
          <div className="card-header">
            <div className="card-header--title">
              <small className="d-block text-uppercase mt-1">
                Does it meet your
              </small>
              <b>Targets</b>
            </div>
            <div>
              <Tooltip title="Does this property meet your specified targets?">
                <Button
                  onClick={() =>
                    setOpenShortTermRentalTargetsTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="space-around"
            onClick={toggleMaxOffer}
            className="d-flex bg-white hover-scale-rounded justify-content-between py-3"
          >
            <Grid item className="gridItem2">
              <Typography
                variant="h3"
                style={{ textAlign: 'center' }}
              >
                {analytics.STR.maxSTRPrice
                  ? currencyFormat.format(analytics.STR.maxSTRPrice)
                  : '-'}
              </Typography>
            </Grid>
            <Grid
              item
              className="gridItem2"
              style={{ textAlign: 'center' }}
            >
              <Typography style={{ color: 'grey' }}>
                Max offer based on targets
              </Typography>
            </Grid>
          </Grid>
          <Dialog
            open={maxOfferDescription}
            onClose={toggleMaxOffer}
            maxWidth="lg"
            scroll="body"
            classes={{
              paper:
                'modal-content rounded border-0 bg-white p-3 p-xl-0',
            }}
          >
            {/* <BlockUi blocking={loader} loader={<PacmanLoader color={'var(--primary)'} loading={true} />}> */}
            <Grid container spacing={0} direction="row">
              <Grid item sm={5}>
                <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100  br-xl-right-0">
                  <div className="flex-grow-1 w-100 d-flex align-items-center">
                    <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                    <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                      <div className="text-white mt-3">
                        <Typography
                          style={{ fontSize: 30, fontWeight: 600 }}
                        >
                          Relevant Data
                        </Typography>
                      </div>
                      <>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              Purchase Price:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {currencyFormat.format(
                                analysisInput.offerPrice
                                  ? analysisInput.offerPrice
                                  : analysisInput.listPrice,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              ARV:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {currencyFormat.format(
                                analytics.STR.arvTotalSTR,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              All In Cost:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {currencyFormat.format(
                                analytics.STR.allInCostSTR,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              Total Out Of Pocket:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {currencyFormat.format(
                                analytics.STR.totalOutOfPocketSTR,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              Annual Cash Flow:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {currencyFormat.format(
                                analytics.STR.cashFlowSTR,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              Holding Period (yr):
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {
                                assumptionsInput.rentalHoldingPeriodSTR
                              }
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item sm={7}>
                <div className="hero-wrapper h-100 br-xl-right-0">
                  <Card className="flex-grow-1 w-100">
                    <CardHeader title="Max Offer" />
                    <Divider />
                    <CardContent>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid
                          item
                          xs={12}
                          style={{ marginBottom: 25 }}
                        >
                          <Card
                            style={{
                              backgroundColor: '#eff2f5',
                              borderRadius: 0,
                            }}
                          >
                            <CardContent>
                              <Typography>
                                Max Offer is the highest purchase
                                price that still meets all of your
                                strategy's targets. The Max Offer will
                                show a dash if the calculation is less
                                than zero or no purchase price can
                                meet all targets.
                              </Typography>
                              <Typography>
                                The Max Offer Price is calculated by
                                determining the the highest purchase
                                price that will meet each specific
                                target then taking the lowest value
                                from them. This means that any value
                                that affects a target besides purchase
                                price can affect the Max Offer Price
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={toggleMaxOffer}
                          >
                            Close
                          </Button>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                </div>
              </Grid>
            </Grid>
            {/* </BlockUi> */}
          </Dialog>
          <Divider />
          <List component="div" className="list-group-flush">
            {map(strategySummary.analytics, (section) => (
              <>
                <ListItem
                  component="a"
                  button
                  data-tour={section.tourStep}
                  href={null}
                  disableRipple
                  onClick={() => toggle1(section.label)}
                  className="d-flex bg-white hover-scale-rounded justify-content-between py-3"
                >
                  <div className="avatar-icon-wrapper avatar-initials d-50 mr-3">
                    {!isUndefined(section.arrow) && (
                      <>
                        {section.arrow ? (
                          section.arrow === 2 ? (
                            <Avatar
                              style={{
                                backgroundColor: '#2296F3',
                              }}
                            >
                              <ArrowUpwardIcon
                                style={{
                                  color: 'white',
                                }}
                              />
                            </Avatar>
                          ) : (
                            <Avatar
                              style={{
                                backgroundColor: '#46de64',
                              }}
                            >
                              <ArrowUpwardIcon
                                style={{
                                  color: 'white',
                                }}
                              />
                            </Avatar>
                          )
                        ) : (
                          <Avatar
                            style={{
                              backgroundColor: '#F05248',
                            }}
                          >
                            <ArrowDownwardIcon
                              style={{
                                color: 'white',
                              }}
                            />
                          </Avatar>
                        )}
                      </>
                    )}
                  </div>
                  <div className="flex-fill">
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Grid
                        item
                        className="font-weight-bold text-black"
                      >
                        {section.label}
                      </Grid>
                      <Grid item className="ml-auto">
                        <b>
                          {section.value !== '-'
                            ? section.type === 'currency'
                              ? currencyFormat.format(section.value)
                              : section.value > 10
                                ? '+1000%'
                                : percentageFormat.format(
                                    section.value,
                                  )
                            : section.value}
                        </b>
                      </Grid>
                    </Grid>
                    <LinearProgress
                      variant="determinate"
                      className={
                        section.arrow === 0
                          ? 'progress-xs progress-animated-alt my-2 progress-bar-danger'
                          : 'progress-xs progress-animated-alt my-2 progress-bar-success'
                      }
                      value={
                        !section.target
                          ? 0
                          : section.arrow
                            ? 100
                            : section.type === 'currency'
                              ? (section.value / section.target) * 100
                              : (section.value / section.target) *
                                10000
                      }
                    />
                    <div className="d-flex justify-content-between font-size-xs">
                      {!isUndefined(section.arrow) && (
                        <div
                          className={
                            section.arrow === 0
                              ? 'text-danger'
                              : 'text-success'
                          }
                        >
                          {section.arrow === 0
                            ? 'Below target'
                            : section.arrow === 1
                              ? 'Target met'
                              : 'Above target'}
                        </div>
                      )}
                      <div className="opacity-5">
                        Target:{' '}
                        {section.type === 'currency'
                          ? currencyFormat.format(section.target)
                          : section.target + '%'}
                      </div>
                    </div>
                  </div>
                </ListItem>
                <Dialog
                  open={modal1 === section.label}
                  onClose={toggle1Close}
                  maxWidth="lg"
                  scroll="body"
                  classes={{
                    paper:
                      'modal-content rounded border-0 bg-white p-3 p-xl-0',
                  }}
                >
                  {/* <BlockUi blocking={loader} loader={<PacmanLoader color={'var(--primary)'} loading={true} />}> */}
                  <Grid container spacing={0} direction="row">
                    <Grid item sm={5}>
                      <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100  br-xl-right-0">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                          <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                            <div className="text-white mt-3">
                              <Typography
                                style={{
                                  fontSize: 30,
                                  fontWeight: 600,
                                }}
                              >
                                Calculation
                              </Typography>
                            </div>
                            {section.calculation}
                            <div className="text-white mt-3">
                              <Typography>
                                * Calculation may be a few dollars off
                                due to rounding
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item sm={7}>
                      <div className="hero-wrapper h-100 br-xl-right-0">
                        <Card className="flex-grow-1 w-100">
                          <CardHeader title={section.label} />
                          <Divider />
                          <CardContent>
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Grid
                                item
                                xs={12}
                                style={{ marginBottom: 25 }}
                              >
                                <Card
                                  style={{
                                    backgroundColor: '#eff2f5',
                                    borderRadius: 0,
                                  }}
                                >
                                  <CardContent>
                                    <Typography>
                                      {section.description}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Grid>
                              {includes(section.label, 'IRR') && (
                                <Grid
                                  item
                                  xs={12}
                                  style={{ marginBottom: 10 }}
                                >
                                  <div className="accordion">
                                    <Card
                                      className={clsx('card-box', {
                                        'panel-open': accordion === 0,
                                      })}
                                    >
                                      <Card>
                                        <div className="card-header">
                                          <div className="panel-title">
                                            <div className="accordion-toggle">
                                              <Button
                                                variant="text"
                                                size="large"
                                                className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                                                onClick={() =>
                                                  toggleAccordion(0)
                                                }
                                                aria-expanded={
                                                  accordion === 0
                                                }
                                              >
                                                <span>
                                                  Start Month
                                                </span>
                                                <FontAwesomeIcon
                                                  icon={[
                                                    'fas',
                                                    'angle-up',
                                                  ]}
                                                  className="font-size-xl accordion-icon"
                                                />
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                        <Collapse
                                          in={accordion === 0}
                                        >
                                          <Paper
                                            style={{
                                              width: '100%',
                                              overflow: 'hidden',
                                            }}
                                          >
                                            <TableContainer
                                              style={{
                                                maxHeight: 440,
                                              }}
                                            >
                                              <Table
                                                stickyHeader
                                                aria-label="sticky table"
                                              >
                                                <TableHead>
                                                  <TableRow>
                                                    {IRRTableFirstHeaders.map(
                                                      (row) => (
                                                        <TableCell
                                                          align={
                                                            row.align
                                                          }
                                                        >
                                                          {row.name}
                                                        </TableCell>
                                                      ),
                                                    )}
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  <TableRow
                                                    key={
                                                      IRRDataFirst.name
                                                    }
                                                    style={{
                                                      '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                    }}
                                                  >
                                                    <TableCell
                                                      component="th"
                                                      scope="row"
                                                    >
                                                      {
                                                        IRRDataFirst.monthYear
                                                      }
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {currencyFormat2.format(
                                                        IRRDataFirst.totalOutOfPocket,
                                                      )}
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </Paper>
                                        </Collapse>
                                      </Card>
                                    </Card>
                                    <Card
                                      className={clsx('card-box', {
                                        'panel-open': accordion === 1,
                                      })}
                                    >
                                      <Card>
                                        <div className="card-header">
                                          <div className="panel-title">
                                            <div className="accordion-toggle">
                                              <Button
                                                variant="text"
                                                size="large"
                                                className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                                                onClick={() =>
                                                  toggleAccordion(1)
                                                }
                                                aria-expanded={
                                                  accordion === 1
                                                }
                                              >
                                                <span>
                                                  Appreciation Months
                                                </span>
                                                <FontAwesomeIcon
                                                  icon={[
                                                    'fas',
                                                    'angle-up',
                                                  ]}
                                                  className="font-size-xl accordion-icon"
                                                />
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                        <Collapse
                                          in={accordion === 1}
                                        >
                                          <Paper
                                            style={{
                                              width: '100%',
                                              overflow: 'hidden',
                                            }}
                                          >
                                            <TableContainer
                                              style={{
                                                maxHeight: 440,
                                              }}
                                            >
                                              <Table
                                                stickyHeader
                                                aria-label="sticky table"
                                              >
                                                <TableHead>
                                                  <TableRow>
                                                    {IRRTableHeaders.map(
                                                      (row) => (
                                                        <TableCell
                                                          align={
                                                            row.align
                                                          }
                                                        >
                                                          {row.name}
                                                        </TableCell>
                                                      ),
                                                    )}
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {IRRData.map(
                                                    (row) => (
                                                      <>
                                                        <TableRow
                                                          key={
                                                            row.name
                                                          }
                                                          style={{
                                                            '&:last-child td, &:last-child th':
                                                              {
                                                                border: 0,
                                                              },
                                                          }}
                                                        >
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            {
                                                              row.monthYear
                                                            }
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {currencyFormat2.format(
                                                              row.income,
                                                            )}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {currencyFormat2.format(
                                                              row.expenses,
                                                            )}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {currencyFormat2.format(
                                                              row.mortgage,
                                                            )}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {currencyFormat2.format(
                                                              row.flow,
                                                            )}
                                                          </TableCell>
                                                        </TableRow>
                                                      </>
                                                    ),
                                                  )}
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </Paper>
                                        </Collapse>
                                      </Card>
                                    </Card>
                                    <Card
                                      className={clsx('card-box', {
                                        'panel-open': accordion === 2,
                                      })}
                                    >
                                      <Card>
                                        <div className="card-header">
                                          <div className="panel-title">
                                            <div className="accordion-toggle">
                                              <Button
                                                variant="text"
                                                size="large"
                                                className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                                                onClick={() =>
                                                  toggleAccordion(2)
                                                }
                                                aria-expanded={
                                                  accordion === 2
                                                }
                                              >
                                                <span>
                                                  Final Month
                                                </span>
                                                <FontAwesomeIcon
                                                  icon={[
                                                    'fas',
                                                    'angle-up',
                                                  ]}
                                                  className="font-size-xl accordion-icon"
                                                />
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                        <Collapse
                                          in={accordion === 2}
                                        >
                                          <Paper
                                            style={{
                                              width: '100%',
                                              overflow: 'hidden',
                                            }}
                                          >
                                            <TableContainer
                                              style={{
                                                maxHeight: 440,
                                              }}
                                            >
                                              <Table
                                                stickyHeader
                                                aria-label="sticky table"
                                              >
                                                <TableHead>
                                                  <TableRow>
                                                    {IRRTableLastHeaders.map(
                                                      (row) => (
                                                        <TableCell
                                                          align={
                                                            row.align
                                                          }
                                                        >
                                                          {row.name}
                                                        </TableCell>
                                                      ),
                                                    )}
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  <TableRow
                                                    key={
                                                      IRRDataLast.name
                                                    }
                                                    style={{
                                                      '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                    }}
                                                  >
                                                    <TableCell
                                                      component="th"
                                                      scope="row"
                                                    >
                                                      {
                                                        IRRDataLast.monthYear
                                                      }
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {currencyFormat2.format(
                                                        IRRDataLast.lastMonthCashFlow,
                                                      )}
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </Paper>
                                        </Collapse>
                                      </Card>
                                    </Card>
                                  </div>
                                </Grid>
                              )}
                              {renderInputFields({
                                type: 'slider',
                                name: section.targetName,
                                label: section.targetLabel,
                                value:
                                  assumptionsInput[
                                    section.targetName
                                  ],
                                xs: 12,
                                assumptions: true,
                                valueLabelFormat:
                                  section.valueLabelFormat
                                    ? section.valueLabelFormat
                                    : (x) =>
                                        x === section.max
                                          ? `${x}+`
                                          : `${x}%`,
                                step: section.step,
                                min: section.min,
                                max: section.max,
                              })}
                            </Grid>
                          </CardContent>
                          <Divider />
                          <CardActions>
                            <Grid container justifyContent="center">
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={toggle1Close}
                                >
                                  Close
                                </Button>
                              </Grid>
                            </Grid>
                          </CardActions>
                        </Card>
                      </div>
                    </Grid>
                  </Grid>
                  {/* </BlockUi> */}
                </Dialog>
              </>
            ))}
          </List>
        </Card>
      ),
    },
    {
      name: 'monthlyCashflow',
      card: (
        <Card className="card-box w-100">
          <div className="card-header">
            <div className="card-header--title">
              <small className="d-block text-uppercase mt-1">
                Summary
              </small>
              <b>Monthly Cashflow</b>
            </div>
            <div>
              <Tooltip title="Shows the income, expenses, and projected overall cashflow of the property">
                <Button
                  onClick={() =>
                    setOpenShortTermRentalMonthlyCashflowTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
              <Grid container className="text-black-50 font-size-sm">
                <Grid
                  item
                  md={4}
                  data-tour="monthlyCashflow-step-1"
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">Income</div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <div className="badge badge-success badge-circle mr-2">
                          fast
                        </div>
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={
                              sum(grossMonthlyIncomeChartSeries) /
                              1000
                            }
                            duration={1}
                            delay={2}
                            decimals={1}
                            decimal="."
                            prefix="$"
                            suffix="k"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={4}
                  data-tour="monthlyCashflow-step-2"
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">Expenses</div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <div className="badge badge-danger badge-circle mr-2">
                          normal
                        </div>
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={
                              sum(monthlyExpensesChartSeries) / 1000
                            }
                            duration={1}
                            delay={2}
                            decimals={1}
                            decimal="."
                            prefix="$"
                            suffix="k"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={4}
                  data-tour="monthlyCashflow-step-3"
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">Cashflow</div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <div className="badge badge-primary badge-circle mr-2">
                          slow
                        </div>
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={
                              analytics.STR.cashFlowSTR / 12 / 1000
                            }
                            duration={1}
                            delay={2}
                            decimals={1}
                            decimal="."
                            prefix="$"
                            suffix="k"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <>
              <Suspense fallback={<div>Loading Chart...</div>}>
                <Chart
                  options={chartOptions}
                  series={chartSeries}
                  type="bar"
                  width="100%"
                  height="auto"
                />
              </Suspense>
            </>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'acquisition',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Acquisition Expenses</b>
            </div>
            <div>
              <Tooltip title="The total projected expenses required to purchase the property">
                <Button
                  onClick={() =>
                    setOpenShortTermRentalAcquisitionExpensesTour(
                      true,
                    )
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Grid content>
              <Grid item>
                <div className="d-flex justify-content-center">
                  <Suspense fallback={<div>Loading Chart...</div>}>
                    <Chart
                      options={acquisitionChartOptions}
                      series={acquisitionChartSeries}
                      type="donut"
                      width="300"
                    />
                  </Suspense>
                </div>
              </Grid>
              <Divider style={{ marginBottom: 15, marginTop: 15 }} />
              <Grid item>
                <Grid
                  container
                  direction="row"
                  /*alignItems='center'*/ spacing={1}
                >
                  <Grid
                    container
                    item
                    alignItems="center"
                    justifyContent="space-between"
                    wrap="nowrap"
                  >
                    <Grid item data-tour="acquisitionExpenses-step-1">
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="Advanced Fields"
                              checked={state.checkedA}
                              onChange={handleChange}
                              name="checkedA"
                            />
                          }
                          label="Advanced Fields"
                        />
                      </FormGroup>
                    </Grid>
                    {(!props.publicDashboard ||
                      !property.hideDaysOnMarketplace) && (
                      <Grid
                        item
                        data-tour="acquisitionExpenses-step-2"
                      >
                        <Tooltip title="Refers to the number of days a house is listed on the market and the day it is sold.">
                          <Typography
                            style={{
                              fontSize: 14,
                              textAlign: 'right',
                              backgroundColor: '#e8ecef',
                              padding: 5,
                            }}
                          >
                            <FontAwesomeIcon
                              icon={['far', 'clock']}
                              style={{ marginRight: 5 }}
                            />
                            {analysisInput.daysOnMarket} days on
                            market
                          </Typography>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                  {map(
                    state.checkedA
                      ? concat(
                          basicAcquisitionFields,
                          advancedAcquisitionFields,
                          customAcquisitonField,
                        )
                      : concat(
                          basicAcquisitionFields,
                          customAcquisitonField,
                        ),
                    (field) => renderInputFields(field),
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'income',
      card: (
        <Card className="card-box w-100" style={{ width: '100%' }}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            direction="row"
          >
            <Grid item xs={8} style={{ overflow: 'scroll' }}>
              <List
                className="nav-line d-flex"
                style={{
                  width: assumptionsInput.avgRate.length * 100,
                }}
              >
                {assumptionsInput.avgRate.length > 1 ? (
                  map(assumptionsInput.avgRate, (value, index) => (
                    <ListItem
                      button
                      disableRipple
                      selected={props.activeUnit === index}
                      onClick={() => {
                        props.setActiveUnit(index)
                      }}
                      className="mt-3"
                    >
                      Unit {index + 1}
                    </ListItem>
                  ))
                ) : (
                  <ListItem className="my-1">
                    <div className="card-header--title">
                      <b>Gross Monthly Income</b>
                    </div>
                  </ListItem>
                )}
              </List>
            </Grid>
            <Grid item style={{ marginRight: 20 }}>
              <Tooltip title="Total income generated from the property on a monthly basis">
                <Button
                  onClick={() =>
                    setOpenShortTermRentalGrossMonthlyIncomeTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          <Divider />
          <CardContent>
            <Grid content>
              <Grid item>
                <div className="d-flex justify-content-center">
                  <Suspense fallback={<div>Loading Chart...</div>}>
                    <Chart
                      options={grossMonthlyIncomeChartOptions}
                      series={grossMonthlyIncomeChartSeries}
                      type="donut"
                      width="300"
                    />
                  </Suspense>
                </div>
              </Grid>
              <Divider style={{ marginBottom: 15, marginTop: 15 }} />
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  spacing={1}
                >
                  {map(grossMonthlyIncomeFields, (field) =>
                    renderInputFields(field),
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'expenses',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Monthly Expenses</b>
            </div>
            <div>
              <Tooltip title="Projected monthly expenditures and applicable costs associated with the property">
                <Button
                  onClick={() =>
                    setOpenShortTermRentalMonthlyExpensesTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Grid content>
              <Grid item>
                <div className="d-flex justify-content-center">
                  <Suspense fallback={<div>Loading Chart...</div>}>
                    <Chart
                      options={monthlyExpensesChartOptions}
                      series={monthlyExpensesChartSeries}
                      type="donut"
                      width="300"
                    />
                  </Suspense>
                </div>
              </Grid>
              <Divider style={{ marginBottom: 15, marginTop: 15 }} />
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  {map(monthlyExpensesFields, (field) =>
                    renderInputFields(field),
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Grid item container direction="row" spacing={1}>
                  {!isEmpty(property.propertyManagementGroups) && (
                    <Grid item xs={12}>
                      <Button
                        fullWidth={true}
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={handleOpenManagementCompanies}
                      >
                        Property Management Companies
                      </Button>
                    </Grid>
                  )}
                  {/* {activeOrg.member === 'birmingham' && ( */}
                  <Grid item xs={12}>
                    <Button
                      fullWidth={true}
                      variant="contained"
                      size="small"
                      color="primary"
                      target="_blank"
                      href="https://jjc.arcanainsurancehub.com"
                      style={{ marginTop: 25 }}
                    >
                      Get Insurance Quote
                    </Button>
                  </Grid>
                  {/* )} */}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'rehab',
      card: (
        <Card className="card-box w-100" style={{ width: '100%' }}>
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Rehab Expenses</b>
            </div>
            <div>
              <Tooltip title="Estimated expenses allocated to the renovations of a property">
                <Button
                  onClick={() =>
                    setOpenShortTermRentalRehabExpensesTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Grid content>
              <Grid item>
                <div className="d-flex justify-content-center">
                  <Suspense fallback={<div>Loading Chart...</div>}>
                    <Chart
                      options={rehabChartOptions}
                      series={rehabChartSeries}
                      type="donut"
                      width="300"
                    />
                  </Suspense>
                </div>
              </Grid>
              <Divider style={{ marginBottom: 15, marginTop: 15 }} />
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  {map(rehabFields, (field) =>
                    renderInputFields(field),
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'financing',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Financing</b>
            </div>
            <div>
              <Tooltip title="Projected fund totals an investor will need to produce in order to acquire the property">
                <Button
                  onClick={() =>
                    setOpenShortTermRentalFinancingTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Grid
              container
              className="rounded p-2 mb-2 bg-secondary text-center border-light border-1"
            >
              <Grid
                item
                md={4}
                className="d-flex justify-content-center"
              >
                <div>
                  <div className="text-dark pb-1">Loan</div>
                  <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                    <div className="d-flex p-1 align-items-center">
                      {/* <div className="badge badge-success badge-circle mr-2">
                      fast
                    </div> */}
                      <span className="d-20 w-auto">
                        <CountUp
                          start={0}
                          end={analytics.STR.loanAmountSTR}
                          duration={1}
                          separator=","
                          decimals={0}
                          prefix="$"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                md={4}
                className="d-flex justify-content-center"
              >
                <div>
                  <div className="text-dark pb-1">Mortgage/mo</div>
                  <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                    <div className="d-flex p-1 align-items-center">
                      {/* <div className="badge badge-warning badge-circle mr-2">
                      normal
                    </div> */}
                      <span className="d-20 w-auto">
                        <CountUp
                          start={0}
                          end={analytics.STR.mortgageSTR}
                          duration={1}
                          delay={2}
                          separator=","
                          decimals={0}
                          prefix="$"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                md={4}
                className="d-flex justify-content-center"
              >
                <div>
                  <div className="text-dark pb-1">DSCR</div>
                  <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                    <div className="d-flex p-1 align-items-center">
                      {/* <div className="badge badge-danger badge-circle mr-2">
                      slow
                    </div> */}
                      <span className="d-20 w-auto">
                        <CountUp
                          start={0}
                          end={analytics.STR.debtCoverageRatioSTR}
                          duration={1}
                          delay={2}
                          separator=","
                          decimals={2}
                          suffix="x"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <p className="mb-3 text-black-50 text-center">
              Based on your{' '}
              <b>
                {currencyFormat.format(analytics.STR.allInCostSTR)}
              </b>{' '}
              <Tooltip title="The total amount of money this deal costs, including rehab, closing costs, points, etc.">
                <span
                  className="text-first"
                  onClick={() => toggle1('All-in Cost')}
                  style={{ textDecoration: 'underline' }}
                >
                  all-in cost
                </span>
              </Tooltip>
              , you'll need to pay{' '}
              <b>
                {currencyFormat.format(
                  analytics.STR.totalOutOfPocketSTR,
                )}
              </b>{' '}
              <Tooltip title="Total amount that the buyer has to cover with their own money.">
                <span
                  className="text-first"
                  onClick={() => toggle1('Total Out-of-Pocket')}
                  style={{ textDecoration: 'underline' }}
                >
                  out-of-pocket
                </span>
              </Tooltip>{' '}
              to cover the downpayment, closing costs, and rehab for
              this property.
            </p>
            <Divider style={{ marginBottom: 15, marginTop: 15 }} />
            <Grid container>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  {map(financingFields, (section) =>
                    renderInputFields(section),
                  )}
                </Grid>
                {(!activeOrg.isAffiliate ||
                  (activeOrg.isAffiliate?.memberValue !==
                    'flippingamerica' &&
                    activeOrg.isAffiliate !== 'gorillacapital')) &&
                  activeOrg.member !== 'flippingamerica' &&
                  activeOrg.member !== 'gorillacapital' && (
                    <Lending
                      property={property}
                      shared={props.shared}
                      canDrag={props.canDrag}
                    />
                  )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'projection',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Value Over Time</b>
            </div>
            <div>
              <Tooltip title="Projected paydown timelines and cashflow options available on this property">
                <Button
                  onClick={() =>
                    setOpenShortTermRentalValueOverTimeTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
              <Grid container className="text-black-50 font-size-sm">
                <Grid
                  item
                  md={4}
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">
                      Property Paid off
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        {/* <div className="badge badge-success badge-circle mr-2">
                        fast
                      </div> */}
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={analytics.STR.propertyPayOffYearsSTR}
                            duration={1}
                            separator=","
                            decimals={1}
                            suffix=" yrs"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={4}
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">
                      Value at payoff
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        {/* <div className="badge badge-success badge-circle mr-2">
                        fast
                      </div> */}
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={
                              analytics.STR
                                .propertyAppreciationValueSTR
                            }
                            duration={1}
                            separator=","
                            decimals={0}
                            prefix="$"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={4}
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">
                      Cashflow at payoff
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        {/* <div className="badge badge-warning badge-circle mr-2">
                        normal
                      </div> */}
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={analytics.STR.cashFlowAtPayoffSTR}
                            duration={1}
                            delay={2}
                            separator=","
                            decimals={0}
                            prefix="$"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            {analytics.STR.cashFlowSTR >= 0 ? (
              <p className="mb-3 text-black-50 text-center">
                If you took{' '}
                <b>
                  {currencyFormat.format(
                    ((analytics.STR.cashFlowSTR / 12) *
                      analytics.payDownAccelerator) /
                      100,
                  )}
                </b>{' '}
                ({analytics.payDownAccelerator}% of the net cashflow)
                and paid it towards the{' '}
                <Tooltip title="The amount you borrowed from the lender.">
                  <span
                    className="text-first"
                    style={{ textDecoration: 'underline' }}
                  >
                    loan principal
                  </span>
                </Tooltip>{' '}
                each month, this property could be paid off in{' '}
                <b>
                  {analytics.STR.propertyPayOffYearsSTR
                    ? analytics.STR.propertyPayOffYearsSTR.toFixed(1)
                    : '-'}{' '}
                  years
                </b>
                {analytics.STR.propertyPayOffYearsSTR &&
                analytics.STR.propertyPayOffYearsSTR.toFixed(1) ===
                  (assumptions.termSTRType === 'Years'
                    ? assumptions.termSTR
                    : assumptions.termSTR / 12
                  ).toFixed(1)
                  ? ''
                  : ` instead of ${
                      assumptions.termSTR
                        ? (assumptions.termSTRType === 'Years'
                            ? assumptions.termSTR
                            : assumptions.termSTR / 12
                          ).toFixed(1)
                        : 0
                    }`}
                .
              </p>
            ) : (
              <p className="mb-3 text-black-50 text-center">
                The cashflow of this property is less than zero, which
                means you can't take a percent of the cashflow and pay
                it towards the loan balance.
              </p>
            )}

            <Divider style={{ marginBottom: 15, marginTop: 15 }} />
            <Grid container>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  {map(projectionAssumptionsFields, (section) =>
                    renderInputFields(section),
                  )}
                  {/* <Grid item xs={12}>
                    <Typography id="continuous-slider" gutterBottom>
                      Paydown Accelerator (% of net cashflow)
                    </Typography>
                    <Slider value={75} />
                    <Grid container direction='row' justifyContent='space-between'>
                      <Grid item>
                        <Typography id="continuous-slider" gutterBottom>
                          0%
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>100%</Typography>
                      </Grid>
                    </Grid>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'cashPositioning',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Cash Positioning</b>
            </div>
            <div>
              <Tooltip title="Projected cash growth over one, three, five, and ten years">
                <Button
                  onClick={() =>
                    setOpenShortTermRentalCashPositioningTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Suspense fallback={<div>Loading Chart...</div>}>
              <Chart options={options} series={series} type="bar" />
            </Suspense>{' '}
            <Tooltip
              title={`Total Return of ${assumptions.rentalHoldingPeriodSTR} years`}
            >
              <div
                className="rounded p-2 mb-2 bg-secondary text-center border-light border-1"
                onClick={() => toggle1('Total Return')}
              >
                <Grid container justifyContent="space-around">
                  <Grid item data-tour="cashPositioning-step-1">
                    <Typography>Total Return</Typography>
                    <Typography>
                      {currencyFormat.format(
                        analytics.STR.totalReturnOnInvestmentSTR,
                      )}
                    </Typography>
                  </Grid>
                  <Grid item data-tour="cashPositioning-step-2">
                    <Typography>Total Return %</Typography>
                    <Typography>
                      {percentageFormat.format(
                        analytics.STR.totalOutOfPocketSTR
                          ? analytics.STR.totalReturnOnInvestmentSTR /
                              analytics.STR.totalOutOfPocketSTR
                          : 0,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Tooltip>
            <Grid container direction="row">
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={handleOpenAmortization}
                >
                  <Typography>Amortization</Typography>
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'disposition',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Disposition Expenses</b>
            </div>
            <div>
              <Tooltip title="All costs and expenses associated with sale of this property">
                <Button
                  onClick={() =>
                    setOpenShortTermRentalDispositionExpensesTour(
                      true,
                    )
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Grid content>
              <Grid item>
                <div className="d-flex justify-content-center">
                  <Suspense fallback={<div>Loading Chart...</div>}>
                    <Chart
                      options={dispositionChartOptions}
                      series={dispositionChartSeries}
                      type="donut"
                      width="300"
                    />
                  </Suspense>
                </div>
              </Grid>
              <Divider style={{ marginBottom: 15, marginTop: 15 }} />
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  {map(dispositionFields, (field) =>
                    renderInputFields(field),
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'comparableProperties',
      card: props.basicView ? null : (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            {/* <List className="nav-line d-flex" style={{ width: 400 }}>
              <ListItem
                className="my-1"
                button
                onClick={() => setCompView('rental')}
                selected={compView === 'rental'}
              >
                <div className="card-header--title">
                  <b>Rental Comps</b>
                </div>
              </ListItem>
              <ListItem
                className="my-1"
                button
                onClick={() => setCompView('sold')}
                selected={compView === 'sold'}
              >
                <div className="card-header--title">
                  <b>Sold Comps</b>
                </div>
              </ListItem>
            </List> */}
            <div className="card-header--title">
              <b>Comparable Properties</b>
            </div>
            <div>
              <Tooltip title="Details about comparable properties">
                <Button
                  onClick={() =>
                    setOpenShortTermRentalComparablePropertiesTour(
                      true,
                    )
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          {compView === 'rental' ? (
            <CardContent>
              {!isEmpty(props.rentalComps) ? (
                <>
                  <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
                    <Grid
                      container
                      className="text-black-50 font-size-sm"
                    >
                      <Grid
                        item
                        md={12}
                        className="d-flex justify-content-center"
                      >
                        <div>
                          <div className="text-dark pb-1">
                            Total Comps
                          </div>
                          <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                            <div className="d-flex p-1 align-items-center">
                              <span className="d-20 w-auto">
                                <CountUp
                                  start={0}
                                  end={props.rentalComps.length}
                                  duration={1}
                                  separator=","
                                  decimals={0}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <List component="div" className="list-group-flush">
                    {map(rentalCompData, (section) => (
                      <ListItem
                        component="a"
                        button
                        disableRipple
                        className="d-flex bg-white hover-scale-rounded justify-content-between py-3"
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Grid
                            item
                            className="font-weight-bold text-black"
                            style={{ olor: 'text' }}
                          >
                            {section.label}
                          </Grid>
                          {section.range ? (
                            <Grid item>
                              <Grid
                                container
                                direction="row"
                                spacing={1}
                              >
                                <Grid item>
                                  <CountUp
                                    start={0}
                                    end={section.value1}
                                    duration={1}
                                    separator=","
                                    delay={1}
                                    decimals={1}
                                    decimal="."
                                    prefix="$"
                                    suffix="k"
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography>-</Typography>
                                </Grid>
                                <Grid item>
                                  <CountUp
                                    start={0}
                                    end={section.value2}
                                    duration={1}
                                    separator=","
                                    delay={1}
                                    decimals={1}
                                    decimal="."
                                    prefix="$"
                                    suffix="k"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          ) : section.percent ? (
                            <Grid item>
                              <CountUp
                                start={0}
                                end={section.value * 100}
                                duration={1}
                                delay={1}
                                decimals={2}
                                decimal="."
                                suffix="%"
                              />
                            </Grid>
                          ) : (
                            <Grid item>
                              <CountUp
                                start={0}
                                end={section.value}
                                duration={1}
                                separator=","
                                delay={1}
                                decimals={2}
                                decimal="."
                                prefix="$"
                              />
                            </Grid>
                          )}
                        </Grid>
                      </ListItem>
                    ))}
                  </List>
                </>
              ) : // <Button
              //   variant="contained"
              //   fullWidth
              //   onClick={pullRentalComps}
              //   style={{
              //     backgroundColor: '#1cc943',
              //     color: 'white',
              //   }}
              //   disabled={props.shared}
              // >
              //   <AddTwoTone
              //     /*className="w-50"*/ style={{ marginRight: 10 }}
              //   />
              //   Get Rental Comps
              // </Button>
              null}
              {/* <Grid container>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ marginTop: 25 }}
                    onClick={(e) => props.toggleCenter('RentalComps')}
                  >
                    <Typography>View Comps</Typography>
                  </Button>
                </Grid>
              </Grid> */}
              {backdrop && (
                <Backdrop
                  open={backdrop}
                  style={{ color: '#fff', zIndex: 100000 }}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              )}
            </CardContent>
          ) : (
            <CardContent>
              <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
                <Grid
                  container
                  className="text-black-50 font-size-sm"
                >
                  <Grid
                    item
                    md={6}
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">
                        Comparable Properties
                      </div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={props.determinedComps.length}
                              duration={1}
                              separator=","
                              decimals={0}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">
                        Potential Comps
                      </div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={
                                props.compProperties.length -
                                props.determinedComps.length
                              }
                              duration={1}
                              separator=","
                              decimals={0}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <List component="div" className="list-group-flush">
                {map(compData, (section) => (
                  <ListItem
                    component="a"
                    button
                    disableRipple
                    className="d-flex bg-white hover-scale-rounded justify-content-between py-3"
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Grid
                        item
                        className="font-weight-bold text-black"
                        style={{ olor: 'text' }}
                      >
                        {section.label}
                      </Grid>
                      {section.range ? (
                        <Grid item>
                          <Grid container direction="row" spacing={1}>
                            <Grid item>
                              <CountUp
                                start={0}
                                end={section.value1}
                                duration={1}
                                separator=","
                                delay={1}
                                decimals={1}
                                decimal="."
                                prefix="$"
                                suffix="k"
                              />
                            </Grid>
                            <Grid item>
                              <Typography>-</Typography>
                            </Grid>
                            <Grid item>
                              <CountUp
                                start={0}
                                end={section.value2}
                                duration={1}
                                separator=","
                                delay={1}
                                decimals={1}
                                decimal="."
                                prefix="$"
                                suffix="k"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : section.percent ? (
                        <Grid item>
                          <CountUp
                            start={0}
                            end={section.value * 100}
                            duration={1}
                            delay={1}
                            decimals={2}
                            decimal="."
                            suffix="%"
                          />
                        </Grid>
                      ) : (
                        <Grid item>
                          <CountUp
                            start={0}
                            end={section.value}
                            duration={1}
                            separator=","
                            delay={1}
                            decimals={2}
                            decimal="."
                            prefix="$"
                          />
                        </Grid>
                      )}
                    </Grid>
                  </ListItem>
                ))}
              </List>
              <Grid container>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ marginTop: 25 }}
                    onClick={(e) => props.toggleCenter('Comps')}
                  >
                    <Typography>View Comps</Typography>
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          )}
        </Card>
      ),
    },
    {
      name: 'piti',
      hide:
        props?.session?.me?.activeOrg?.member !== 'pacemorby' &&
        props?.session?.me?.activeOrg?.member !== 'astroflip',
      card: props.basicView ? null : (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Deal Sauce Summary</b>
            </div>
            <Select
              value={dealSauceView}
              name="dealSauceView"
              onChange={(event) =>
                setDealSauceView(event.target.value)
              }
              style={{ width: '100%' }}
            >
              <MenuItem value="cash" key="cash">
                Cash
              </MenuItem>
              <MenuItem value="creative" key="creative">
                Creative
              </MenuItem>
            </Select>
          </div>
          <Divider />
          <CardContent>
            {dealSauceView === 'cash' && (
              <>
                <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
                  <Grid
                    container
                    className="text-black-50 font-size-sm"
                  >
                    <Grid
                      item
                      md={6}
                      className="d-flex justify-content-center"
                    >
                      <div>
                        <div className="text-dark pb-1">
                          Entry Fee
                        </div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div
                            className="d-flex p-1 align-items-center"
                            style={{ minHeight: 50 }}
                          >
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={cashEntryFee}
                                duration={0}
                                separator=","
                                delay={1}
                                decimals={0}
                                decimal="."
                                prefix="$"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      className="d-flex justify-content-center"
                    >
                      <div>
                        <div className="text-dark pb-1">
                          Max Allowable Offer
                        </div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div
                            className="d-flex p-1 align-items-center"
                            style={{ minHeight: 50 }}
                          >
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={
                                  analysis.afterRepairValue -
                                  cashEntryFee
                                }
                                duration={0}
                                separator=","
                                delay={1}
                                decimals={0}
                                decimal="."
                                prefix="$"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <Grid item xs={12} style={{ marginTop: 35 }}>
                  <Grid container direction="column" spacing={4}>
                    <ContactInformation property={property} />
                  </Grid>
                </Grid>
                <CommentsDialog
                  property={property}
                  userId={props?.session?.me?._id}
                />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: 25 }}
                  onClick={(e) => setOpenPITICashData(true)}
                >
                  <Typography>View Cash Underwriting</Typography>
                </Button>
              </>
            )}
            {dealSauceView === 'creative' && (
              <>
                <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
                  <Grid
                    container
                    className="text-black-50 font-size-sm"
                  >
                    <Grid
                      item
                      md={6}
                      className="d-flex justify-content-center"
                    >
                      <div>
                        <div className="text-dark pb-1">
                          Entry Fee
                        </div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div className="d-flex p-1 align-items-center">
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={creativeEntryFee}
                                duration={1}
                                separator=","
                                delay={1}
                                decimals={0}
                                decimal="."
                                prefix="$"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      className="d-flex justify-content-center"
                    >
                      <div>
                        <div className="text-dark pb-1">PITI</div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div className="d-flex p-1 align-items-center">
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={pitiTotal}
                                duration={1}
                                separator=","
                                delay={1}
                                decimals={0}
                                decimal="."
                                prefix="$"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      className="d-flex justify-content-center"
                    >
                      <div>
                        <div className="text-dark pb-1">
                          % of Purchase Price
                        </div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div className="d-flex p-1 align-items-center">
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={
                                  analysis?.offerPrice &&
                                  analysis.offerPrice !== 0
                                    ? (creativeEntryFee /
                                        analysis.offerPrice) *
                                      100
                                    : 0
                                }
                                duration={1}
                                delay={1}
                                decimals={2}
                                decimal="."
                                suffix="%"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container direction="column" spacing={4}>
                      <ContactInformation property={property} />
                    </Grid>
                  </Grid>
                  <CommentsDialog
                    property={property}
                    userId={props?.session?.me?._id}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ marginTop: 25 }}
                    onClick={(e) => setOpenPITICreativeData(true)}
                  >
                    <Typography>
                      View Creative Underwriting
                    </Typography>
                  </Button>
                </Grid>
              </>
            )}
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'deactive',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Hide Cards</b>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Typography>
              All cards past this one will be hidden
            </Typography>
          </CardContent>
        </Card>
      ),
    },
  ]

  const getOrderDefault = () => {
    const storedValues = !isEmpty(property.shortTermRentalOrder)
      ? property.shortTermRentalOrder
      : merge(
          cloneDeep(DEFAULT_PREFERENCES),
          activeOrg.memberPreferences,
          activeOrg.preferences,
          props.session.me ? props.session.me.preferences : {},
        ).shortTermRentalOrder || []
    const newCards = difference(
      map(
        filter(shortTermRentalCardsToOrder, (card) => !card.hide),
        (card) => card.name,
      ),
      storedValues,
    )
    const deactiveIndex = findIndex(
      storedValues,
      (value) => value === 'deactive',
    )
    return uniq(
      concat(
        slice(storedValues, 0, deactiveIndex),
        newCards,
        slice(storedValues, deactiveIndex),
      ),
    )
  }

  const [shortTermRentalOrder, setShortTermRentalOrder] =
    useState(getOrderDefault())
  const [orderChanged, setOrderChanged] = useState(false)
  const [orderDefault, setOrderDefault] = useState(false)
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    setShortTermRentalOrder(getOrderDefault())
  }, [activeOrg.member])

  const SortableCard = SortableElement(({ item }) => (
    <Grid item sm={6} xl={4} className="d-flex">
      {item}
    </Grid>
  ))
  const SortableGrid = SortableContainer(({ items }) => (
    <Grid container direction="row" spacing={2} className="p-3">
      {map(items, (item, index) => (
        <SortableCard
          key={`item-${index}`}
          index={index}
          item={item}
        />
      ))}
    </Grid>
  ))

  const [updateProperty] = useMutation(UPDATE_PROPERTY)
  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newOrder = arrayMoveImmutable(
        shortTermRentalOrder,
        oldIndex,
        newIndex,
      )
      setShortTermRentalOrder(newOrder)
      setOrderChanged(true)
    }
  }

  const resetOrder = () => {
    setShortTermRentalOrder(getOrderDefault())
    setOrderChanged(false)
  }

  const submitOrder = () => {
    setLoader(true)
    const preferences = merge(
      cloneDeep(DEFAULT_PREFERENCES),
      activeOrg.memberPreferences,
      activeOrg.preferences,
    )
    updateProperty({
      refetchQueries: [
        {
          query: GET_PROPERTY,
          variables: { id: property._id },
        },
      ],
      variables: {
        id: property._id,
        propertyInput: {
          shortTermRentalOrder,
        },
      },
    })
      .then(() => {
        enqueueSnackbar(`Layout saved`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        if (orderDefault) {
          updateOrganization({
            refetchQueries: [
              {
                query: GET_ME,
              },
            ],
            variables: {
              id: activeOrg._id,
              organizationInput: {
                preferences: {
                  ...preferences,
                  shortTermRentalOrder,
                },
              },
            },
          })
            .then(() => {
              enqueueSnackbar(
                `Default Short Term Rental layout saved`,
                {
                  variant: 'success',
                  autoHideDuration: 3000,
                },
              )
              setOrderChanged(false)
              setLoader(false)
            })
            .catch((error) => {
              enqueueSnackbar(
                `Failed to save default Short Term Rental layout`,
                {
                  variant: 'error',
                  autoHideDuration: 3000,
                },
              )
            })
        } else {
          setOrderChanged(false)
          setLoader(false)
        }
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to save layout`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  const sortedShortTermRentalCard = sortBy(
    filter(shortTermRentalCardsToOrder, (card) => !card.hide),
    [
      function (o) {
        return findIndex(
          shortTermRentalOrder,
          (value) => value === o.name,
        )
      },
    ],
  )

  return (
    <>
      {!props.canDrag && (
        <Grid container direction="row" spacing={2} className="p-3">
          {props.publicImageCard && (
            <Grid
              item
              xs={12}
              md={6}
              lg={props.publicDashboard ? 4 : 6}
              xl={4}
              className="d-flex w-100"
            >
              {props.publicImageCard}
            </Grid>
          )}
          {map(
            slice(
              sortedShortTermRentalCard,
              0,
              findIndex(
                sortedShortTermRentalCard,
                (card) => card.name === 'deactive',
              ),
            ),
            (card) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={props.publicDashboard ? 4 : 6}
                xl={4}
                className="d-flex w-100"
              >
                {card.card}
              </Grid>
            ),
          )}
        </Grid>
      )}
      {props.canDrag && (
        <SortableGrid
          items={compact(
            concat(
              [props.publicImageCard],
              map(sortedShortTermRentalCard, (card) => card.card),
            ),
          )}
          onSortEnd={onSortEnd}
          axis="xy"
        />
      )}
      {map(financials, (section) => (
        <Dialog
          open={modal1 === section.label}
          onClose={toggle1Close}
          maxWidth="lg"
          scroll="body"
          classes={{
            paper:
              'modal-content rounded border-0 bg-white p-3 p-xl-0',
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={5}>
              <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100 rounded br-xl-right-0">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                  <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                    <div className="text-white mt-3">
                      <Typography
                        style={{ fontSize: 30, fontWeight: 600 }}
                      >
                        Calculation
                      </Typography>
                    </div>
                    {section.calculation}
                    <div className="text-white mt-3">
                      <Typography>
                        * Calculation may be a few dollars off due to
                        rounding
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={7}>
              <div>
                <Card>
                  <CardHeader title={section.label} />
                  <Divider />
                  <CardContent style={{ height: '100%' }}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item xs={12} style={{ marginBottom: 10 }}>
                        <Card
                          style={{
                            backgroundColor: '#eff2f5',
                            borderRadius: 0,
                          }}
                        >
                          <CardContent>
                            <Typography>
                              {section.description}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={toggle1Close}
                        >
                          Close
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </div>
            </Grid>
          </Grid>
        </Dialog>
      ))}
      <Dialog
        open={openManagementCompanies}
        onClose={handleCloseManagementCompanies}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>Property Management Companies</DialogTitle>
        <DialogContent style={{ paddingTop: 15, paddingBottom: 15 }}>
          <Grid container direction="column" spacing={2}>
            {map(
              filter(propertyManagementCompanies, (company) =>
                includes(
                  property.propertyManagementGroups,
                  company.value,
                ),
              ),
              (section) => (
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <div className="rounded avatar-image overflow-hidden d-40 bg-neutral-dark text-center font-weight-bold text-success d-flex justify-content-center align-items-center">
                        <img
                          className="img-fluid img-fit-container rounded-sm"
                          src={section.image}
                          alt="..."
                        />
                      </div>
                    </Grid>
                    <Grid item>
                      <Typography>{section.name}</Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        href={section.link}
                        target="_blank"
                      >
                        <Typography>View</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ),
            )}
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modal1 === 'Total Return'}
        onClose={toggle1Close}
        maxWidth="lg"
        scroll="body"
        classes={{
          paper: 'modal-content rounded border-0 bg-white p-3 p-xl-0',
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={5}>
            <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100 rounded br-xl-right-0">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                  <div className="text-white mt-3">
                    <Typography
                      style={{ fontSize: 30, fontWeight: 600 }}
                    >
                      Calculation
                    </Typography>
                  </div>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ marginTop: 5, marginBottom: 5 }}
                  >
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        Cash flow
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        {currencyFormat.format(
                          round(analytics.STR.cashFlowSTR, 0),
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ marginTop: 5, marginBottom: 5 }}
                  >
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        +Principal Reduction
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        +
                        {currencyFormat.format(
                          round(
                            analytics.STR.principalReductionSTR,
                            0,
                          ),
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ marginTop: 5, marginBottom: 5 }}
                  >
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        +Anticipated Annual Appreciation
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        +
                        {currencyFormat.format(
                          round(
                            analytics.STR
                              .anticipatedAnnualAppreciationSTR,
                            0,
                          ),
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider style={{ backgroundColor: '#9198a8' }} />
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ marginTop: 5, marginBottom: 5 }}
                  >
                    <Grid item>
                      <Typography style={{ color: 'white' }}>
                        Total Return
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: 'white' }}>
                        {currencyFormat.format(
                          round(
                            analytics.STR.totalReturnOnInvestmentSTR,
                            0,
                          ),
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ marginTop: 5, marginBottom: 5 }}
                  >
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        <FontAwesomeIcon
                          icon={['fal', 'divide']}
                          style={{ marginRight: 3 }}
                        />
                        Total Out Of Pocket
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        <FontAwesomeIcon
                          icon={['fal', 'divide']}
                          style={{ marginRight: 3 }}
                        />
                        {currencyFormat.format(
                          round(analytics.STR.totalOutOfPocketSTR, 0),
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider style={{ backgroundColor: '#9198a8' }} />
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ marginTop: 5, marginBottom: 5 }}
                  >
                    <Grid item>
                      <Typography style={{ color: 'white' }}>
                        Total Return %
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: 'white' }}>
                        {percentageFormat.format(
                          analytics.STR.totalOutOfPocketSTR
                            ? analytics.STR
                                .totalReturnOnInvestmentSTR /
                                analytics.STR.totalOutOfPocketSTR
                            : 0,
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <div className="text-white mt-3">
                    <Typography>
                      * Calculation may be a few dollars off due to
                      rounding
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={7}>
            <div>
              <Card>
                <CardHeader title="Total Return" />
                <Divider />
                <CardContent style={{ height: '100%' }}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={12} style={{ marginBottom: 10 }}>
                      <Card
                        style={{
                          backgroundColor: '#eff2f5',
                          borderRadius: 0,
                        }}
                      >
                        <CardContent>
                          <Typography>
                            The actual rate of return of an investment
                            over a given evaluation period which
                            includes income and appreciation.
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={toggle1Close}
                      >
                        Close
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </div>
          </Grid>
        </Grid>
      </Dialog>

      <AmortizationDialog
        open={openAmortization}
        onClose={handleCloseAmortization}
        tableHeaders={tableHeaders}
        amortizationData={amortizationData}
        currencyFormat2={currencyFormat2}
        assumptions={assumptions}
      />

      <SubtoPITICash
        open={openPITICashData}
        onClose={() => setOpenPITICashData(false)}
        propertyId={property?._id}
        address={property?.address}
        city={property.city}
        state={property.state}
        zipcode={property?.zip}
      />
      <SubtoPITICreative
        open={openPITICreativeData}
        onClose={() => setOpenPITICreativeData(false)}
        propertyId={property?._id}
        address={property?.address}
        city={property.city}
        state={property.state}
        zipcode={property?.zip}
      />

      <Drawer
        open={orderChanged}
        anchor="bottom"
        variant="persistent"
        PaperProps={{
          style: { backgroundColor: '#253152', padding: 15 },
        }}
      >
        {/* <Alert
        className="mb-4"
        style={{ backgroundColor: '#253152', color: 'white' }}
        icon={false}
        action={
          <IconButton onClick={props.resetPropertyChanges}>
            <Close style={{ color: 'white' }} />
          </IconButton>
        }
      > */}
        {/* <Typography style={{ color: 'white', fontWeight: 600 }}>You have unsaved changes</Typography> */}
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}
          wrap="nowrap"
        >
          <Grid item style={{ marginLeft: 20 }}>
            <IconButton onClick={resetOrder} size="large">
              <Close style={{ color: 'white' }} />
            </IconButton>
          </Grid>
          <Grid item style={{ marginLeft: 0 }}>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: '#4290ff',
                color: 'white',
                boxShadow: 'none',
              }}
              onClick={submitOrder}
              disabled={loader}
            >
              <Typography style={{ fontWeight: 600 }}>
                Save
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <FormControl
              component="fieldset"
              className="pr-4"
              style={{ color: 'white' }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={orderDefault}
                      onChange={() => setOrderDefault(!orderDefault)}
                      style={{ color: 'white' }}
                    />
                  }
                  label="Set this as default Short Term Rental layout"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Typography style={{ flex: 1 }} />
        </Grid>
      </Drawer>

      <ShortTermRentalStrategyTour
        isOpenShortTermRentalStrategyTour={
          openShortTermRentalStrategyTour
        }
        closeShortTermRentalStrategyTour={() =>
          setOpenShortTermRentalStrategyTour(false)
        }
      />
      <ShortTermRentalTargetsTour
        isOpenShortTermRentalTargetsTour={
          openShortTermRentalTargetsTour
        }
        closeShortTermRentalTargetsTour={() =>
          setOpenShortTermRentalTargetsTour(false)
        }
      />
      <ShortTermRentalMonthlyCashflowTour
        isOpenShortTermRentalMonthlyCashflowTour={
          openShortTermRentalMonthlyCashflowTour
        }
        closeShortTermRentalMonthlyCashflowTour={() =>
          setOpenShortTermRentalMonthlyCashflowTour(false)
        }
      />
      <ShortTermRentalAcquisitionExpensesTour
        isOpenShortTermRentalAcquisitionExpensesTour={
          openShortTermRentalAcquisitionExpensesTour
        }
        closeShortTermRentalAcquisitionExpensesTour={() =>
          setOpenShortTermRentalAcquisitionExpensesTour(false)
        }
      />
      <ShortTermRentalGrossMonthlyIncomeTour
        isOpenShortTermRentalGrossMonthlyIncomeTour={
          openShortTermRentalGrossMonthlyIncomeTour
        }
        closeShortTermRentalGrossMonthlyIncomeTour={() =>
          setOpenShortTermRentalGrossMonthlyIncomeTour(false)
        }
      />
      <ShortTermRentalMonthlyExpensesTour
        isOpenShortTermRentalMonthlyExpensesTour={
          openShortTermRentalMonthlyExpensesTour
        }
        closeShortTermRentalMonthlyExpensesTour={() =>
          setOpenShortTermRentalMonthlyExpensesTour(false)
        }
      />
      <ShortTermRentalRehabExpensesTour
        isOpenShortTermRentalRehabExpensesTour={
          openShortTermRentalRehabExpensesTour
        }
        closeShortTermRentalRehabExpensesTour={() =>
          setOpenShortTermRentalRehabExpensesTour(false)
        }
      />
      <ShortTermRentalFinancingTour
        isOpenShortTermRentalFinancingTour={
          openShortTermRentalFinancingTour
        }
        closeShortTermRentalFinancingTour={() =>
          setOpenShortTermRentalFinancingTour(false)
        }
      />
      <ShortTermRentalValueOverTimeTour
        isOpenShortTermRentalValueOverTimeTour={
          openShortTermRentalValueOverTimeTour
        }
        closeShortTermRentalValueOverTimeTour={() =>
          setOpenShortTermRentalValueOverTimeTour(false)
        }
      />
      <ShortTermRentalCashPositioningTour
        isOpenShortTermRentalCashPositioningTour={
          openShortTermRentalCashPositioningTour
        }
        closeShortTermRentalCashPositioningTour={() =>
          setOpenShortTermRentalCashPositioningTour(false)
        }
      />
      <ShortTermRentalDispositionExpensesTour
        isOpenShortTermRentalDispositionExpensesTour={
          openShortTermRentalDispositionExpensesTour
        }
        closeShortTermRentalDispositionExpensesTour={() =>
          setOpenShortTermRentalDispositionExpensesTour(false)
        }
      />
      <ShortTermRentalComparablePropertiesTour
        isOpenShortTermRentalComparablePropertiesTour={
          openShortTermRentalComparablePropertiesTour
        }
        closeShortTermRentalComparablePropertiesTour={() =>
          setOpenShortTermRentalComparablePropertiesTour(false)
        }
      />
    </>
  )
}

export default withSession(CenterPageShortTermRental)
