/**
 * @ Author: Housefolios
 * @ Create Time: 2025-04-08 14:24:10
 * @ Modified by: David Helmick
 * @ Modified time: 2025-04-08 14:25:13
 * @ Description: Tours for the BOR strategy page
 */

import Reactour from 'reactour'

import { Button, Typography } from '@mui/material'

import '../../style.css'

import { useLocation } from 'react-router-dom'

export const BORStrategyTour = ({
  isOpenBORStrategyTour,
  closeBORStrategyTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="strategy-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              At a glance you can see how well this property will do
              if you follow this strategy. It's based on the targets
              that were either set by you or by default.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="make-offer-button"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Want to purchase this property? Fill out the Make Offer
              form to take the next steps!
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBORStrategyTour}
      onRequestClose={closeBORStrategyTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BORTargetsTour = ({
  isOpenBORTargetsTour,
  closeBORTargetsTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="targets-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount of money left over after subtracting the
              Purchase Price from the Resale Price
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="targets-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              IRR measures an investment’s annual return throughout
              the entire property ownership time frame. Unlike a
              cash-on-cash return or cap rate that measures returns in
              annual segments, IRR weighs total earnings from the day
              you purchase your property to the day you sell it.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBORTargetsTour}
      onRequestClose={closeBORTargetsTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BORBORDetailsTour = ({
  isOpenBORBORDetailsTour,
  closeBORBORDetailsTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="netProfitFromSale-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The selling price of the property
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="netProfitFromSale-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Total amount of initial investment into a property,
              including renovation.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="netProfitFromSale-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Total yearly income.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="netProfitFromSale-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The Net Profit is the Resale Price minus the Total
              Expenses
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBORBORDetailsTour}
      onRequestClose={closeBORBORDetailsTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BORBORInputsTour = ({
  isOpenBORBORInputsTour,
  closeBORBORInputsTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="BORDetails-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The price that the seller is asking for this home. *Not
              necessarily the price that the investor will purchase
              the home for.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="BORDetails-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The ARV is the value of a property after you have
              conducted repairs and are ready to sell
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="BORDetails-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The estimated closing cost the end buyer will have for a
              BOR property. Expressed as a dollar amount or percentage
              of ARV minus the rehab.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="BORDetails-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The monthly house payment.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="rehabExpenses-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              An estimate for how much rehab is needed for the subject
              property.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="BORDetails-step-6"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              An estimate for how much rehab is needed for the subject
              property per decade the property was built.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="financing-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The interest rate is the amount a lender charges for the
              use of capital, expressed as a percentage of the
              principal. The interest rate is typically noted on an
              annual basis known as the annual percentage rate (APR).
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },

    {
      selector: '[data-tour="BORDetails-step-8"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount of cash that a purchaser must put down,
              toward the purchase price, to buy real estate.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="financing-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The length of time that the loan is being amortized.
              Expressed in years.
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBORBORInputsTour}
      onRequestClose={closeBORBORInputsTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}

export const BORComparablePropertiesTour = ({
  isOpenBORComparablePropertiesTour,
  closeBORComparablePropertiesTour,
}) => {
  let goToTo
  let shifted = false
  const { pathname } = useLocation()
  const lockTour = false
  const steps = [
    {
      selector: '[data-tour="comparableProperties-step-1"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount of Comparable Properties we found
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-2"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The amount of Potential Comps we found
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-3"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The average adjusted value of the comparable properties
              that we found
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-4"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The lowest and highest comparable property prices make
              up the range
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-5"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The average adjusted value of the comparable properties
              divided by sqft
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-6"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              The average adjusted value of the three lowest priced
              comparable properties
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
    {
      selector: '[data-tour="comparableProperties-step-7"]',
      content: ({ goTo, inDOM }) => {
        goToTo = goTo
        return (
          <div>
            <Typography
              variant="h5"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Click this button to get a detailed look at your
              comparable properties and potential comps
            </Typography>
          </div>
        )
      },
      action: (node) => {
        if (node)
          node.onclick = () => {
            if (!shifted) {
              setTimeout(() => goToTo(1), 500)
              shifted = true
            }
          }
      },
    },
  ]
  return (
    <Reactour
      steps={steps}
      isOpen={isOpenBORComparablePropertiesTour}
      onRequestClose={closeBORComparablePropertiesTour}
      update={pathname}
      startAt={0}
      lastStepNextButton={
        <Button color="primary" variant="contained">
          End Tour
        </Button>
      }
      disableKeyboardNavigation={lockTour}
      disableDotsNavigation={lockTour}
      showButtons={!lockTour}
    />
  )
}
